import { useEffect, useState } from "react";
import { IoMdCloseCircle } from "react-icons/io";
import { toast } from "react-toastify";
import { alertOptions, formatDateHourOrders } from "../../../utils/Utilities";

const DetailReturns = ({ returnElement, onClose, onCancel, onApproved }) => {
    const [reasonDetail, setReasonDetail] = useState("");

    const handleResponse = (type) => {
        if (reasonDetail !== "" && String(reasonDetail).trim().length > 0) {
            if (type === "cancel") {
                onCancel({ _id: returnElement._id, reason: reasonDetail });
            } else {
                onApproved({ _id: returnElement._id, total: returnElement.total, reason: reasonDetail });
            }
        } else {
            toast.info("Please enter the reason for the cancellation or acceptance of the return", alertOptions);
        }
    }

    return (
        <>
            <div className="block">
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-20 outline-none focus:outline-none">
                    <div className="w-[320px] md:w-[700px] lg:w-[800px]">
                        <div className="p-3 md:p-5 border-0 rounded-lg shadow-lg flex-col w-full bg-white outline-none focus:outline-none">
                            <div className="w-full flex items-center justify-center rounded text-center">
                                <div className="w-[95%]">
                                    <span className="pl-[5%] text-xl text-ag-secondary font-bold">
                                        Return Detail
                                    </span>
                                </div>
                                <div className="w-[5%]">
                                    <button
                                        className="text-ag-secondary float-right text-3xl leading-none outline-none focus:outline-none"
                                        onClick={() => onClose(true)}
                                    >
                                        <IoMdCloseCircle />
                                    </button>
                                </div>
                            </div>
                            <div className="relative px-2 md:px-5 pb-2 pt-5 flex-auto space-y-5">
                                <div className="px-2 md:px-5 pb-3 text-ag-secondary-letter">
                                    <ul className="space-y-2">
                                        <li className="flex justify-start items-center space-x-5">
                                            <span className="font-semibold">Date:</span>
                                            <span>{ formatDateHourOrders(returnElement.createdAt) }</span>
                                        </li>
                                        <li className="flex justify-start items-center space-x-5">
                                            <span className="font-semibold">Costumer:</span>
                                            <span>{ returnElement.wholesaler?.names }</span>
                                        </li>
                                        <li className="flex justify-start items-center space-x-5">
                                            <span className="font-semibold">Items:</span>
                                            <span>{ returnElement.productOrder.productInventory.product.nags }</span>
                                        </li>
                                        <li className="flex justify-start items-center space-x-5">
                                            <span className="font-semibold">Warehouse:</span>
                                            <span>{ returnElement.warehouse.name }</span>
                                        </li>
                                        <li className="flex justify-start items-center space-x-5">
                                            <span className="font-semibold">Reason:</span>
                                            <span>{ returnElement.reason }</span>
                                        </li>
                                        <li className="flex justify-start items-center space-x-5">
                                            <span className="font-semibold">Employee:</span>
                                            <span>{ returnElement.requestedBy ? `${returnElement.requestedBy?.names} ${returnElement.requestedBy?.lastNames}` : "-----" }</span>
                                        </li>
                                    </ul>
                                </div>
                                {
                                    returnElement.status === "CANCELED" ?
                                        <div className="px-2 md:px-5 space-y-3 text-ag-secondary-letter">
                                            <div>
                                                <span className="font-bold">Reason</span>
                                            </div>
                                            <div>
                                                <span>{ returnElement?.cancelReason }</span>
                                            </div>
                                        </div>
                                    : !returnElement?.productReturn ?
                                        <div className="space-y-3 text-ag-secondary-letter">
                                            <div>
                                                <span className="font-bold">Reason</span>
                                            </div>
                                            <div>
                                                <span>Tell us the reason for accepting or cancelling the order</span>
                                            </div>
                                            <div>
                                                <textarea
                                                    className="w-full p-3 border border-ag-secondary-light rounded-xl"
                                                    rows={3}
                                                    onChange={(evt) => setReasonDetail(evt.target.value)}
                                                ></textarea>
                                            </div>
                                        </div>
                                    :
                                        <div className="px-2 md:px-5 space-y-2 text-ag-secondary-letter">
                                            <div className="flex justify-start items-center space-x-5">
                                                <span className="font-semibold">Note:</span>
                                                <span>{ returnElement?.productReturn?.note }</span>
                                            </div>
                                            <div className="flex justify-start items-center space-x-5">
                                                <span className="font-semibold">Remove quantity:</span>
                                                <span>{ returnElement?.productReturn?.removeQuantity }</span>
                                            </div>
                                            <div className="flex justify-start items-center space-x-5">
                                                <span className="font-semibold">Add quantity:</span>
                                                <span>{ returnElement?.productReturn?.addQuantity }</span>
                                            </div>
                                            <div className="text-xs md:text-sm text-center w-full m-auto">
                                                <div className="flex justify-center items-center">
                                                    <span className="w-24 md:w-44 bg-ag-secondary-table px-5 py-1 font-semibold">Rack</span>
                                                    <span className="w-24 md:w-44 bg-ag-secondary-table px-5 py-1 font-semibold">Quantity</span>
                                                </div>
                                                { returnElement?.productReturn?.addRackQuantity.map((element, i) => (
                                                    <div className="flex justify-center items-center">
                                                        <span className="w-24 md:w-44 border border-zinc-300 px-5 py-1 truncate">{element?.rack?.name}</span>
                                                        <span className="w-24 md:w-44 border border-zinc-300 px-5 py-1 truncate">{ element?.quantity }</span>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                }
                                {
                                    !returnElement?.productReturn && returnElement.status !== "CANCELED" ?
                                        <div className="flex justify-center items-center px-16 space-x-5">
                                            <button
                                                type="button"
                                                className="w-full px-4 py-3 bg-red-600 text-white text-xs rounded-xl"
                                                onClick={() => handleResponse("cancel")}
                                            >
                                                Cancel the Return
                                            </button>
                                            <button
                                                type="button"
                                                className="w-full px-4 py-3 bg-green-600 text-white text-xs rounded-xl"
                                                onClick={() => handleResponse("approved")}
                                            >
                                                Approved the Return
                                            </button>
                                        </div>
                                    :
                                        <div className="pt-3 flex justify-end items-center px-0 md:px-8 space-x-5">
                                            <button
                                                type="button"
                                                className="w-full px-4 py-2 bg-ag-secondary text-white text-xs rounded-xl"
                                                onClick={() => onClose(true)}
                                            >
                                                Close
                                            </button>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="opacity-50 fixed inset-0 z-10 bg-black"></div>
        </>
    );
}

export default DetailReturns;