import { useContext, useEffect, useState } from "react";
import {
  cancelQuote,
  getQuotes,
  getQuotesPWRWT,
} from "../../../services/Quotes";
import { alertOptions, sortStringOptions } from "../../../utils/Utilities";
import { toast } from "react-toastify";
import { getWarehouses } from "../../../services/Warehouse";
import { getInsurers } from "../../../services/Insurer";
import { PermitsContext } from "../../../services/Permits";
import { changeDeliveryFee } from "../../../services/ChangeDeliveryFee";
import { FaCog } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { getInventoryAddId } from "../../../services/Inventory";
import Loader from "../../../components/loader/Loader";
import Navbar from "../../../components/navbar/Navbar";
import Sidebar from "../../../components/sidebar/Sidebar";
import Filters from "./Filters";
import TableQuotes from "./TableQuotes";
import QuoteDetail from "./QuoteDetail";
import Images from "../../../components/carrousel/Images";
import ModalInput from "../../../components/modal/ModalInput";
import InventorySpecification from "./inventorySpecification/InventorySpecification";
import PricePlinkington from "../../fragments/PricePlinkington";
import ProductEspecification from "../products/save_product/ProductEspecification";
import ModalFormProduct from "./withoutInventory/ModalFormProduct";
import ModalFormInventory from "./withoutInventory/ModalFormInventory";
import ModalCompatibleWD from "../products/ModalCompatibleWD";
import MyCartFeatures from "../../wholesaler/mycart/MyCartFeatures";
import QuoteOtherDetail from "./QuoteOtherDetail";
import ModalShipping from "./ModalShipping";
import ModalShippingRetail from "./ModalShippingRetail";
import ModalShippingPowerWindowRepair from "./ModalShippingPowerWindowRepair";
import ModalShippingWindowTint from "./ModalShippingWindowTint";
import Modal from "../../../components/modal/Modal";

const Quotes = () => {
  const navigate = useNavigate();
  const { permits, dataSession, clearContext, logOutModal, setLogOutModal } = useContext(PermitsContext);
  const [loader, setLoader] = useState(true);
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [openDropDown, setOpenDropDown] = useState(false);
  const [showModalShipping, setShowModalShipping] = useState(false);
  const [showModalShippingRetail, setShowModalShippingRetail] = useState(false);
  const [showModalShippingPowerWindowRepair, setShowModalShippingPowerWindowRepair] = useState(false);
  const [showModalShippingWindowTint, setShowModalShippingWindowTint] = useState(false);

  const [showTable, setShowTable] = useState(false);
  const [showTableSpecifications, setShowTableSpecifications] = useState(false);
  const [showFormProduct, setShowFormProduct] = useState({
    status: false,
    data: null,
  });
  const [showFormInventory, setShowFormInventory] = useState({
    status: false,
    data: null,
    type: "",
    mod: "",
  });
  const [showModal, setShowModal] = useState({ status: false, data: null });
  const [showDetail, setShowDetail] = useState({ status: false, data: null });
  const [showOtherDetail, setShowOtherDetail] = useState({
    status: false,
    data: null,
  });
  const [showPrice, setShowPrice] = useState({ status: false, data: "" });
  const [showCompatible, setShowCompatible] = useState({
    status: false,
    element: null,
  });
  const [showFeatures, setShowFeatures] = useState({
    status: false,
    element: null,
  });
  const [showImages, setShowImages] = useState({ status: false, images: [] });
  const [yearOptions, setYearOptions] = useState([]);
  const [makeOptions, setMakeOptions] = useState([]);
  const [modelOptions, setModelOptions] = useState([]);
  const [vendorsOptions, setVendorsOptions] = useState([]);
  const [warehouseOptions, setWarehouseOptions] = useState([]);
  const [insurersOptions, setInsurersOptions] = useState([]);
  const [productManual, setProductManual] = useState(null);
  const [quotes, setQuotes] = useState([]);
  const [quotesUniv, setQuotesUniv] = useState([]);
  const [page, setPage] = useState(1);
  const [pagesData, setPagesData] = useState({ total: 0, records: 0 });
  const [filterPagination, setFilterPagination] = useState({
    data: {},
    status: false,
  });

  const [paymentMethodOpts] = useState([
    { label: "Pay on my own", value: "PAY_ON_MY_WON" },
    { label: "Pay with Insurance", value: "CAR_INSURANCE" },
  ]);

  const [typeOpts] = useState([
    { label: "Order", value: "specifications" },
    { label: "By Photo", value: "photos" },
    // { label: "Power Window Repair", value: "POWER_WINDOW_REPAIR" },
    // { label: "Window Tint", value: "WINDOW_TINT" }
  ]);

  const [statusOpts] = useState([
    { label: "Approved", value: "approved" },
    { label: "Canceled", value: "canceled" },
    { label: "In process", value: "inprocess" },
  ]);

  const handleFilters = (options) => {
    let filterObject = {};
    if (
      options.year === "" &&
      options.brand === "" &&
      options.model === "" &&
      options.vendors === "" &&
      options.paymentMethod === "" &&
      options.status === "" &&
      options.type === ""
    ) {
      setPage(1);
      setFilterPagination({ ...filterPagination, data: {}, status: false });
      getData(1, {});
    } else {
      for (const key in options) {
        if (Object.hasOwnProperty.call(options, key)) {
          if (options[key] !== "") filterObject[key] = options[key];
        }
      }
      setPage(1);
      setFilterPagination({
        ...filterPagination,
        data: filterObject,
        status: true,
      });
      getData(1, filterObject);
    }
  };

  const handleFillForm = (productSelected) => {
    const foundWarehouse = warehouseOptions.find(
      (element) => element._id === productSelected?.warehouse?._id
    );
    if (foundWarehouse) {
      setShowTable(false);
      setProductManual(productSelected);
      setShowDetail({ ...showDetail, status: true });
    } else {
      toast.warning(
        "The selected warehouse is no longer available in the system",
        alertOptions
      );
    }
  };

  const handleFillFormSpecifications = (productSelected) => {
    setShowTableSpecifications(false);
    setProductManual(productSelected);
    setShowDetail({ ...showDetail, status: true });
  };

  const handleFillFormProduct = (productSelected) => {
    setShowFormProduct({ ...showFormProduct, status: false, data: null });
    setShowFormInventory({
      ...showFormInventory,
      status: true,
      data: productSelected,
      type: "add",
      mod: "quotes",
    });
  };

  const handleFillFormInventory = (productSelected) => {
    getInventoryAddId(productSelected._id).then((res) => {
      if (res.status === 200) {
        const newProductSelected = res.data;
        newProductSelected.productAvailable = false;
        setShowFormInventory({
          ...showFormInventory,
          status: false,
          data: null,
          type: "",
          mod: "",
        });
        setProductManual(newProductSelected);
        setShowDetail({ ...showDetail, status: true });
      } else {
        toast.warning(
          "The selected warehouse is no longer available in the system",
          alertOptions
        );
      }
    });
  };

  const handleFillFormInventoryUpdate = () => {
    setLoader(true);
    toast.success("Inventory product edited successfully", alertOptions);
    setShowFormInventory({
      ...showFormInventory,
      status: false,
      data: null,
      type: "",
      mod: "",
    });
    getData(page, filterPagination.data);
  };

  const handleCancel = (message) => {
    setLoader(true);
    cancelQuote(showModal.data._id, {
      status: "canceled",
      reasonCancel: message,
    })
      .then((res) => {
        if (res.status === 200) {
          getData(page, filterPagination.data);
          toast.success("Quote canceled successfully", alertOptions);
        } else {
          toast.warning(res.response.data.message, alertOptions);
          setLoader(false);
        }
        setShowModal({ ...showModal, status: false, data: null });
      })
      .catch((error) => {
        setLoader(false);
        toast.warning(error.response.data.message, alertOptions);
      });
  };

  const getOptionsSelect = (array_quotes) => {
    let array_years = [];
    let array_makes = [];
    let array_models = [];
    let array_vendors = [];
    array_quotes.forEach((element_quotes) => {
      // Cotizaciones sin producto seleccionado
      const yearFound = array_years.find(
        (element) => element.value === element_quotes?.year
      );
      const makesFound = array_makes.find(
        (element) => element.value === element_quotes?.brand
      );
      const modelsFound = array_models.find(
        (element) => element.value === element_quotes?.model
      );

      if (!yearFound && element_quotes?.year && element_quotes?.year !== "") {
        array_years.push({
          label: element_quotes?.year,
          value: element_quotes?.year,
        });
      }

      if (
        !makesFound &&
        element_quotes?.brand &&
        element_quotes?.brand !== ""
      ) {
        array_makes.push({
          label: element_quotes?.brand,
          value: element_quotes?.brand,
        });
      }

      if (
        !modelsFound &&
        element_quotes?.model &&
        element_quotes?.model !== ""
      ) {
        array_models.push({
          label: element_quotes?.model,
          value: element_quotes?.model,
        });
      }

      // Cotizaciones con producto seleccionado
      const yearFoundAux = array_years.find(
        (element) =>
          element.value ===
          element_quotes?.productInventoryAllInfo?.productInventory?.product
            ?.year
      );
      const makesFoundAux = array_makes.find(
        (element) =>
          element.value ===
          element_quotes?.productInventoryAllInfo?.productInventory?.product
            ?.brand
      );
      const modelsFoundAux = array_models.find(
        (element) =>
          element.value ===
          element_quotes?.productInventoryAllInfo?.productInventory?.product
            ?.model
      );
      const vendorsFoundAux = array_vendors.find(
        (element) => element.value === element_quotes?.selectProduct?.vendor
      );

      if (
        !yearFoundAux &&
        element_quotes?.productInventoryAllInfo?.productInventory?.product
          ?.year &&
        element_quotes?.productInventoryAllInfo?.productInventory?.product
          ?.year !== ""
      ) {
        array_years.push({
          label:
            element_quotes?.productInventoryAllInfo?.productInventory?.product
              ?.year,
          value:
            element_quotes?.productInventoryAllInfo?.productInventory?.product
              ?.year,
        });
      }

      if (
        !makesFoundAux &&
        element_quotes?.productInventoryAllInfo?.productInventory?.product
          ?.brand &&
        element_quotes?.productInventoryAllInfo?.productInventory?.product
          ?.brand !== ""
      ) {
        array_makes.push({
          label:
            element_quotes?.productInventoryAllInfo?.productInventory?.product
              ?.brand,
          value:
            element_quotes?.productInventoryAllInfo?.productInventory?.product
              ?.brand,
        });
      }

      if (
        !modelsFoundAux &&
        element_quotes?.productInventoryAllInfo?.productInventory?.product
          ?.model &&
        element_quotes?.productInventoryAllInfo?.productInventory?.product
          ?.model !== ""
      ) {
        array_models.push({
          label:
            element_quotes?.productInventoryAllInfo?.productInventory?.product
              ?.model,
          value:
            element_quotes?.productInventoryAllInfo?.productInventory?.product
              ?.model,
        });
      }

      if (
        !vendorsFoundAux &&
        element_quotes.selectProduct?.vendor &&
        element_quotes.selectProduct.vendor !== ""
      ) {
        array_vendors.push({
          label: element_quotes?.selectProduct?.vendor,
          value: element_quotes?.selectProduct?.vendor,
        });
      }
    });
    array_years = sortStringOptions(array_years);
    array_makes = sortStringOptions(array_makes);
    array_models = sortStringOptions(array_models);
    array_vendors = sortStringOptions(array_vendors);

    setYearOptions(array_years);
    setMakeOptions(array_makes);
    setModelOptions(array_models);
    setVendorsOptions(array_vendors);

    getWarehouses().then((res) => {
      if (res.status === 200 && res.data.length > 0) {
        const warehousesCurrent = res.data.filter(
          (element) => !element.deleted
        );
        setWarehouseOptions(warehousesCurrent);
      }
    });

    getInsurers().then((res) => {
      if (res.status === 200 && res.data.length > 0) {
        const insurersCurrent = res.data.filter((element) => !element.deleted);
        setInsurersOptions(insurersCurrent);
      }
    });
  };

  const handleChangeDeliveryFee = (valueCost) => {
    setLoader(true);
    changeDeliveryFee({
      userType: "WHOLESALER_USER",
      cost: valueCost,
      administrator: { _id: dataSession?._id },
    })
      .then((res) => {
        if (res.status === 200) {
          toast.success("Updated delivery fee for wholesalers", alertOptions);
          setShowModalShipping(false);
          setLoader(false);
        } else {
          toast.warning(res.response.data.message, alertOptions);
          setLoader(false);
        }
      })
      .catch((error) => {
        setLoader(false);
        toast.warning(error.response.data.message, alertOptions);
      });
  };

  const handleChangeDeliveryFeeRetail = (valueCost) => {
    setLoader(true);
    changeDeliveryFee({
      userType: "RETAIL_USER",
      cost: valueCost,
      administrator: { _id: dataSession?._id },
    })
      .then((res) => {
        if (res.status === 200) {
          toast.success("Updated delivery fee for retailers", alertOptions);
          setShowModalShippingRetail(false);
          setLoader(false);
        } else {
          toast.warning(res.response.data.message, alertOptions);
          setLoader(false);
        }
      })
      .catch((error) => {
        setLoader(false);
        toast.warning(error.response.data.message, alertOptions);
      });
  };

  const handleChangeDeliveryFeePowerWindowRepair = (valueCost) => {
    setLoader(true);
    changeDeliveryFee({
      userType: "RETAIL_USER_PWR_SERVICE",
      cost: valueCost,
      administrator: { _id: dataSession?._id },
    })
      .then((res) => {
        if (res.status === 200) {
          toast.success("Updated delivery fee for Power Window Repair", alertOptions);
          setShowModalShippingPowerWindowRepair(false);
          setLoader(false);
        } else {
          toast.warning(res.response.data.message, alertOptions);
          setLoader(false);
        }
      })
      .catch((error) => {
        setLoader(false);
        toast.warning(error.response.data.message, alertOptions);
      });
  }

  const handleChangeDeliveryFeeWindowTint = (valueCost) => {

  }

  const getData = async (pageInventory, paramsFilters) => {
    setLoader(true);

    // Quotes photos and specifications
    const quotesRes = await getQuotes(pageInventory, 20, paramsFilters);
    const quotesArr =
      quotesRes.status === 200
        ? quotesRes.data?.result.map((elem) => ({
          ...elem,
          type: elem.searchType,
        }))
        : [];

    // Quotes power window repair and window tint
    // const quotesPWRWTRes = await getQuotesPWRWT();
    // const quotesPWRWTArr = quotesPWRWTRes.status === 200 ? quotesPWRWTRes.data : [];

    // All quotes
    // const allQuotes = quotesArr.concat(quotesPWRWTArr).sort((a, b) => (new Date(b.createdAt) - new Date(a.createdAt)));
    const allQuotes = quotesArr.sort(
      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
    );

    if (allQuotes.length === 0) {
      toast.warning("Records not found", alertOptions);
    }

    // setPage(pageInventory);
    // setPagesData({...pagesData,
    //     total: Number(quotesRes.data.count)+Number(quotesPWRWTArr.length)/10,
    //     records: quotesRes.data.count+Number(quotesPWRWTArr.length)
    // });

    setPage(pageInventory);
    setPagesData({
      ...pagesData,
      total: quotesRes.data.count / 20,
      records: quotesRes.data.count,
    });
    setQuotes(allQuotes);
    setQuotesUniv(allQuotes);
    getOptionsSelect(allQuotes);

    setLoader(false);
  };

  const changePage = (pge) => {
    new Promise((resolve, reject) => {
      getData(pge, filterPagination.data);
      resolve();
    }).then(() => {
      setPage(pge);
    });
  };

  const handleModalResponse = (sts) => {
    if (sts) {
        clearContext();
        window.location.reload(true);
    }
  };

  useEffect(() => {
    getData(1, {});
  }, []);

  return (
    <>
      {loader ? <Loader /> : null}

      {showModal.status ? (
        <ModalInput
          type="text"
          forDelivery={false}
          title="Reason:"
          onResponse={handleCancel}
          onClose={() =>
            setShowModal({ ...showModal, status: false, data: null })
          }
          onBack={() =>
            setShowModal({ ...showModal, status: false, data: null })
          }
        />
      ) : null}

      { logOutModal.status && (
          <Modal
              title={logOutModal.title}
              onClose={() =>
                  setLogOutModal({ ...logOutModal, status: false, title: "", element: null })
              }
              onResponse={handleModalResponse}
          />
      )}

      {showCompatible.status ? (
        <ModalCompatibleWD
          products={showCompatible.element}
          onClose={() =>
            setShowCompatible({
              ...showCompatible,
              status: false,
              element: null,
            })
          }
        />
      ) : null}

      {showFeatures.status ? (
        <MyCartFeatures
          features={showFeatures.element}
          onClose={() =>
            setShowFeatures({ ...showFeatures, status: false, element: null })
          }
        />
      ) : null}

      {showDetail.status ? (
        <QuoteDetail
          quote={showDetail.data}
          productManual={productManual}
          warehouseOptions={warehouseOptions}
          insurersOptions={insurersOptions}
          onImage={(imagesElement) =>
            setShowImages({
              ...showImages,
              status: true,
              images: imagesElement,
            })
          }
          onLoader={(sts) => setLoader(sts)}
          onRefresh={() => {
            toast.success("Quote sended successfully", alertOptions);
            getData(page, filterPagination.data);
            setShowDetail({ ...showDetail, status: false, data: null });
          }}
          onClose={() => {
            setProductManual(null);
            setShowDetail({ ...showDetail, status: false, data: null });
          }}
          onSearch={() => {
            setShowDetail({ ...showDetail, status: false });
            setShowTableSpecifications(true);
          }}
          onPrice={(nagElement) =>
            setShowPrice({ ...showPrice, status: true, data: nagElement })
          }
          onCompatible={(prdSel) =>
            setShowCompatible({
              ...showCompatible,
              status: true,
              element: prdSel,
            })
          }
          onFeatures={(ftSel) =>
            setShowFeatures({ ...showFeatures, status: true, element: ftSel })
          }
        />
      ) : null}

      {showOtherDetail.status ? (
        <QuoteOtherDetail
          quote={showOtherDetail.data}
          onLoader={(sts) => setLoader(sts)}
          onRefresh={() => {
            toast.success("Quote sended successfully", alertOptions);
            getData(page, filterPagination.data);
            setShowOtherDetail({ ...showDetail, status: false, data: null });
          }}
          onClose={() =>
            setShowOtherDetail({
              ...showOtherDetail,
              status: false,
              data: null,
            })
          }
        />
      ) : null}

      {showImages.status ? (
        <div>
          <Images
            images={showImages.images}
            onClose={() =>
              setShowImages({ ...showImages, status: false, images: [] })
            }
          />
        </div>
      ) : null}

      {showTable ? (
        <InventorySpecification
          onSelect={handleFillForm}
          onClose={() => {
            setShowTable(false);
            setShowDetail({ ...showDetail, status: false, data: null });
          }}
          onLoader={(val) => setLoader(val)}
          onCompatible={(prdSel) =>
            setShowCompatible({
              ...showCompatible,
              status: true,
              element: prdSel,
            })
          }
        />
      ) : null}

      {showTableSpecifications ? (
        <ProductEspecification
          onSelect={handleFillFormSpecifications}
          onCompatible={(prdSel) =>
            setShowCompatible({
              ...showCompatible,
              status: true,
              element: prdSel,
            })
          }
          onClose={() => {
            setShowTableSpecifications(false);
            setShowDetail({ ...showDetail, status: false, data: null });
          }}
        />
      ) : null}

      {showFormProduct.status ? (
        <ModalFormProduct
          productSelect={showFormProduct.data}
          onLoader={(sts) => setLoader(sts)}
          onClose={() =>
            setShowFormProduct({
              ...showFormProduct,
              status: false,
              data: null,
            })
          }
          onProduct={handleFillFormProduct}
        />
      ) : null}

      {showFormInventory.status ? (
        <ModalFormInventory
          type={showFormInventory.type}
          mod={showFormInventory.mod}
          orderId={""}
          productSelect={showFormInventory.data}
          warehouses={warehouseOptions}
          warehouseCurreent={null}
          onLoader={(sts) => setLoader(sts)}
          onClose={() =>
            setShowFormInventory({
              ...showFormInventory,
              status: false,
              data: null,
              type: "",
              mod: "",
            })
          }
          onSave={handleFillFormInventory}
          onEdit={handleFillFormInventoryUpdate}
        />
      ) : null}

      {showPrice.status ? (
        <PricePlinkington
          nagSearch={showPrice.data}
          onLoader={(val) => setLoader(val)}
          onClose={() =>
            setShowPrice({ ...showPrice, status: false, data: "" })
          }
        />
      ) : null}

      {showModalShipping ? (
        <ModalShipping
          onChange={handleChangeDeliveryFee}
          onClose={() => setShowModalShipping(false)}
        />
      ) : null}

      {showModalShippingRetail ? (
        <ModalShippingRetail
          onChange={handleChangeDeliveryFeeRetail}
          onClose={() => setShowModalShippingRetail(false)}
        />
      ) : null}

      {showModalShippingPowerWindowRepair ? (
        <ModalShippingPowerWindowRepair
          onChange={handleChangeDeliveryFeePowerWindowRepair}
          onClose={() => setShowModalShippingPowerWindowRepair(false)}
        />
      ) : null}

      {showModalShippingWindowTint ? (
        <ModalShippingWindowTint
          onChange={handleChangeDeliveryFeeWindowTint}
          onClose={() => setShowModalShippingWindowTint(false)}
        />
      ) : null}

      <div>
        <div className="flex flex-row">
          <div className="w-full bg-no-repeat bg-cover fixed z-10">
            <Navbar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
          </div>
        </div>
        <div className="flex h-screen overflow-hidden">
          <div
            className={`${sidebarOpen ? "w-[100vw] md:w-80 z-20" : "w-20 z-0"}`}
          >
            <Sidebar
              sidebarOpen={sidebarOpen}
              setSidebarOpen={setSidebarOpen}
            />
          </div>
          <div className="w-full overflow-auto flex-grow mt-[100px]">
            <div className="space-y-1 px-3 py-5 md:px-8 md:py-8">

              <div className="w-full text-center relative mb-3">
                <h1 className="text-[26px] md:text-xl lg:text-[26px] font-light text-ag-secondary">Quotes</h1>
                <div class="absolute right-[5px] top-0">
                    <button
                      className="flex justify-end items-center text-white text-xs lg:text-base rounded-xl disabled:bg-zinc-200"
                      type="button"
                      onClick={() => setOpenDropDown(!openDropDown)}
                    >
                      <span className="text-ag-secondary-dark text-3xl">
                        <FaCog />
                      </span>
                    </button>
                </div>
              </div>

              <div className="grid grid-cols-md-12 ml-md-15 px-0">
                <div className="relative md:col-start-11 sm:col-span-1 md:col-span-2 xsg-mmzt-dio flex justify-end mb-1">
                  {openDropDown ? (
                    <div className="w-full absolute bg-[#939191] text-center border-2 border-[#B1E3FF] z-10 rounded-xl">
                      <ul className="py-2">
                        <li
                          className="p-3 border-b-2 border-b-[#B1E3FF] text-white text-base md:text-xs lg:text-base cursor-pointer hover:bg-zinc-500"
                          onClick={() => navigate("/insurers")}
                        >
                          Insurance rate %
                        </li>
                        <li
                          className="p-3 border-b-2 border-b-[#B1E3FF] text-white text-base md:text-xs lg:text-base cursor-pointer hover:bg-zinc-500"
                          onClick={() => {
                            setShowModalShipping(true);
                            setOpenDropDown(false);
                          }}
                        >
                          Shipping cost for wholesalers
                        </li>
                        <li
                          className="p-3 border-b-2 border-b-[#B1E3FF] text-white text-base md:text-xs lg:text-base cursor-pointer hover:bg-zinc-500"
                          onClick={() => {
                            setShowModalShippingRetail(true);
                            setOpenDropDown(false);
                          }}
                        >
                          Shipping cost for retailers
                        </li>
                        <li
                          className="p-3 border-b-2 border-b-[#B1E3FF] text-white text-base md:text-xs lg:text-base cursor-pointer hover:bg-zinc-500"
                          onClick={() => {
                            setShowModalShippingPowerWindowRepair(true);
                            setOpenDropDown(false);
                          }}
                        >
                          Shipping cost for power window repair (Retailers)
                        </li>
                        <li
                          className="p-3 text-white text-base md:text-xs lg:text-base cursor-pointer hover:bg-zinc-500"
                          onClick={() => {
                            setShowModalShippingWindowTint(true);
                            setOpenDropDown(false);
                          }}
                        >
                          Window Tint Catalog (Retailers)
                        </li>
                      </ul>
                    </div>
                  ) : null}
                </div>
              </div> 

              {/* <div className="grid gap-x-3 lg:gap-x-7 gap-y-2 md:grid-cols-5 bg-[red]">
                <div className="flex justify-end w-full">

                  <button
                    className="flex items-center justify-center w-full flex text-center bg-ag-primary-light text-white text-xs lg:text-base border 
                    border-ag-primary-light p-[7.5px] rounded-xl disabled:bg-zinc-200"
                    type="button"
                    onClick={() => navigate("/quotes/services")}
                  >
                    Services
                  </button>
                </div>
              </div> */}

              <div className="grid gap-x-3 lg:gap-x-7 gap-y-2 md:grid-cols-5">
                <div className="col-span-4">
                </div>
                <div className="md:col-span-1 col-span-5 flex items-end justify-end">
                <button
                    className="items-center justify-center w-full flex text-center bg-ag-primary-light text-white text-xs lg:text-base border 
                    border-ag-primary-light p-[7.5px] rounded-xl disabled:bg-zinc-200"
                    type="button"
                    onClick={() => navigate("/quotes/services")}
                  >
                    Services
                  </button>
                </div>
              </div>


              {/* <div class="relative flex items-center mx-4">
                <div class="flex-grow text-center text-[26px] font-light">
                  Quotes
                </div>
                <div class="absolute right-0 transform translate-x-1/2">
                    <button
                      className="flex justify-end items-center text-white text-xs lg:text-base rounded-xl disabled:bg-zinc-200"
                      type="button"
                      onClick={() => setOpenDropDown(!openDropDown)}
                    >
                      <span className="text-ag-secondary-dark text-3xl">
                        <FaCog />
                      </span>
                    </button>
                </div>
              </div> */}
              {/* <div className="grid grid-cols-12 ml-15 px-0">
                <div className="relative sm:col-start-11 md:col-start-11 sm:col-span-1 md:col-span-2 xsg-mmzt-dio flex justify-end mb-1">
                  {openDropDown ? (
                    <div className="w-full absolute bg-[#939191] mt-12 text-center border-2 border-[#B1E3FF] z-10 rounded-xl">
                      <ul className="py-2">
                        <li
                          className="p-3 border-b-2 border-b-[#B1E3FF] text-white text-base md:text-xs lg:text-base cursor-pointer hover:bg-zinc-500"
                          onClick={() => navigate("/insurers")}
                        >
                          Insurance rate %
                        </li>
                        <li
                          className="p-3 border-b-2 border-b-[#B1E3FF] text-white text-base md:text-xs lg:text-base cursor-pointer hover:bg-zinc-500"
                          onClick={() => {
                            setShowModalShipping(true);
                            setOpenDropDown(false);
                          }}
                        >
                          Shipping cost for wholesalers
                        </li>
                        <li
                          className="p-3 text-white text-base md:text-xs lg:text-base cursor-pointer hover:bg-zinc-500"
                          onClick={() => {
                            setShowModalShippingRetail(true);
                            setOpenDropDown(false);
                          }}
                        >
                          Shipping cost for retailers
                        </li>
                      </ul>
                    </div>
                  ) : null}
                </div>
              </div> */}
              {/* <div class="grid gap-x-3 lg:gap-x-7 gap-y-2 md:grid-cols-5">
                <div class="md:col-start-5">
                  <button
                    className="flex items-center justify-center w-full flex text-center bg-ag-primary-light text-white text-xs lg:text-base border 
                    border-ag-primary-light p-[7.5px] rounded-xl disabled:bg-zinc-200"
                    type="button"
                    onClick={() => navigate("/quotes/services")}
                  >
                    Services
                  </button>
                </div>
              </div> */}
              <div class="flex justify-between items-center thawing w-full">
                <div class="ml-auto"></div>
              </div>
              <div className="pt-1">
                <Filters
                  enableFilters={permits["QUOTES"]["FILTER"]}
                  onFilters={handleFilters}
                  years={yearOptions}
                  makes={makeOptions}
                  models={modelOptions}
                  vendors={vendorsOptions}
                  paymentMethodOpts={paymentMethodOpts}
                  typeOpts={typeOpts}
                  statusOpts={statusOpts}
                />
              </div>
              <div>
                <TableQuotes
                  enableSearch={permits["QUOTES"]["SEARCH"]}
                  enableCancel={permits["QUOTES"]["CANCEL"]}
                  quotes={quotes}
                  currntPg={page}
                  pagesData={pagesData}
                  onPage={(pg) => changePage(pg)}
                  onImage={(imagesElement) =>
                    setShowImages({
                      ...showImages,
                      status: true,
                      images: imagesElement,
                    })
                  }
                  onDetail={(quote) =>
                    quote?.type === "POWER_WINDOW_REPAIR" ||
                      quote?.type === "WINDOW_TINT"
                      ? setShowOtherDetail({
                        ...showOtherDetail,
                        status: true,
                        data: quote,
                      })
                      : setShowDetail({
                        ...showDetail,
                        status: true,
                        data: quote,
                      })
                  }
                  onCancel={(id) =>
                    setShowModal({ ...showModal, status: true, data: id })
                  }
                  onCompatible={(prdSel) =>
                    setShowCompatible({
                      ...showCompatible,
                      status: true,
                      element: prdSel,
                    })
                  }
                  onFeatures={(ftSel) =>
                    setShowFeatures({
                      ...showFeatures,
                      status: true,
                      element: ftSel,
                    })
                  }
                  onAddProduct={(qteInv) =>
                    setShowFormInventory({
                      ...showFormInventory,
                      status: true,
                      data: qteInv,
                      type: "update",
                      mod: "quotes",
                    })
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Quotes;
