import { createContext, useState } from "react";
import { getProductsCart } from "./Shopping";
import { getUsers } from "./Administrator";
import { getNotificationsUnreadCount, getNotificationsUnreadCountNew } from "./Notification";

export const PermitsContext = createContext();

export const PermitsProvider = ({ children }) => {
    const [dataSession, setDataSession] = useState({
        _id: "",
        _idAdmin: "",
        token: "",
        names: "",
        lastNames: "",
        email: "",
        userType: "",
        warehouse: "",
        allWarehouse: []
    });

    const [logOutModal, setLogOutModal] = useState({
        status: false,
        title: "",
        element: null,
    })

    const [numNotif, setNumNotif] = useState("");
    const [cartValue, setCartValue] = useState(0);
    const [permitsSA, setPermitsSA] = useState({
        ADMINISTRATORS: {
            VIEW: true,
            FILTER: true,
            ADD: true,
            ROLE: true,
            EDIT: true,
            DELETE: true
        },
        USERS: {
            VIEW: true,
            FILTER: true,
            ADD_WOLESALER: true,
            ADD_RETAILER: true,
            EDIT: true,
            DELETE: true,
            APPROVE_DISPPROVE: true
        },
        SUPPLIERS: {
            VIEW: true,
            FILTER: true,
            ADD: true,
            EDIT: true,
            DELETE: true
        },
        PRODUCTS: {
            VIEW: true,
            FILTER: true,
            ADD: true,
            HISTORY: true,
            EDIT: true,
            DEACTIVATE: true
        },
        INVENTORY: {
            VIEW: true,
            FILTER: true,
            ACTIONS: true,
            RECORD: true,
            EDIT: true,
            ORDER_QUERY: true
        },
        WAREHOUSE: {
            VIEW: true,
            FILTER: true,
            ADD: true,
            EDIT: true,
            REMOVE: true,
            CREATE_RACK: true,
            EDIT_RACK: true,
            DELETE_RACK: true
        },
        ORDERS: {
            VIEW: true,
            ORDERS_FILTER: true,
            ACTIONS_ORDERS: true,
            RETURNS_FILTERS: true,
            ACTIONS_RETURNS: true
        },
        SALES: {
            VIEW: true,
            FILTER: true
        },
        QUOTES: {
            VIEW: true,
            FILTER: true,
            SEARCH: true,
            CANCEL: true
        },
        REPORTS: {
            VIEW: true,
            FILTER: true,
            DOWNLOAD: true
        }
    });

    const [permits, setPermits] = useState({
        ADMINISTRATORS: {
            VIEW: false,
            FILTER: false,
            ADD: false,
            ROLE: false,
            EDIT: false,
            DELETE: false
        },
        USERS: {
            VIEW: false,
            FILTER: false,
            ADD_WOLESALER: false,
            ADD_RETAILER: false,
            EDIT: false,
            DELETE: false,
            APPROVE_DISPPROVE: false
        },
        SUPPLIERS: {
            VIEW: false,
            FILTER: false,
            ADD: false,
            EDIT: false,
            DELETE: false
        },
        PRODUCTS: {
            VIEW: false,
            FILTER: false,
            ADD: false,
            HISTORY: false,
            EDIT: false,
            DEACTIVATE: false
        },
        INVENTORY: {
            VIEW: false,
            FILTER: false,
            ACTIONS: false,
            RECORD: false,
            EDIT: false,
            ORDER_QUERY: false
        },
        WAREHOUSE: {
            VIEW: false,
            FILTER: false,
            ADD: false,
            EDIT: false,
            REMOVE: false,
            CREATE_RACK: false,
            EDIT_RACK: false,
            DELETE_RACK: false
        },
        ORDERS: {
            VIEW: false,
            ORDERS_FILTER: false,
            ACTIONS_ORDERS: false,
            RETURNS_FILTERS: false,
            ACTIONS_RETURNS: false
        },
        SALES: {
            VIEW: false,
            FILTER: false
        },
        QUOTES: {
            VIEW: false,
            FILTER: false,
            SEARCH: false,
            CANCEL: false
        },
        REPORTS: {
            VIEW: false,
            FILTER: false,
            DOWNLOAD: false
        }
    });

    const getCurrentPermits = (permitsStr, type) => {
        let permitsStructure = JSON.parse(permitsStr);
        
        const newPermits = type === "ADMIN" ? permitsSA : permits;

        for (const key in permitsStructure[0]) {
            if (Object.hasOwnProperty.call(permitsStructure[0], key)) {
                const element = permitsStructure[0][key];
                const newObjPermits = newPermits[key];

                newObjPermits['VIEW'] = true;
                for (let ip = 0; ip < element.length; ip++) {
                    newObjPermits[element[ip]] = true;
                }
            }
        }
        setPermits(newPermits);
    }

    const getCurrentCart = (idWholesaler) => {
        getProductsCart(idWholesaler).then(res => {
            if (res.status === 200) {
                setCartValue(res.data.length);
            } else {
                setCartValue(0);
            }
        });
    }

    const getNumberOfNotifications = async () => {
        let arrNotifications = 0;
        // for (let i = 0; i < dataSession?.allWarehouse?.length; i++) {
        //     const resNotif = await getNotificationsUnreadCount({ warehouse: dataSession?.allWarehouse[i]?._id });
        //     if (resNotif.status === 200) arrNotifications += Number(resNotif.data?.unreadCount ? resNotif.data?.unreadCount : 0);
        // }
        const resNotif = await getNotificationsUnreadCountNew();
        if (resNotif.status === 200) arrNotifications = Number(resNotif.data?.unreadCount ? resNotif.data?.unreadCount : 0);
        setNumNotif(arrNotifications > 99 ? "99+" : `${arrNotifications}`);
    }

    const resetPermits = () => {
        getUsers().then(res => {
            if (res.status === 200) {
                const userFound = res.data.find(adminElement => adminElement.user.id === dataSession._idUser);
                if (userFound) {
                    // Se asigna el token en el localStorage
                    localStorage.setItem("type-data", "");
                    localStorage.setItem("user-data", JSON.stringify({
                        token: dataSession.token,
                        id: userFound?.user?.id,
                        idAdmin: userFound._id,
                        idUser: userFound._id,
                        names: userFound.names,
                        lastNames: userFound.lastNames,
                        zipCode: userFound.zipCode,
                        email: userFound.user.email,
                        userType: "ADMINISTRATOR",
                        warehouse: userFound.warehouse.length > 0 ? userFound.warehouse[0]._id : "",
                        allWarehouse: userFound.warehouse,
                        permissions: userFound.role.permissions
                    }));

                    // Se almacena la información del usuario en una variable global
                    setDataSession({...dataSession,
                        _id: userFound?.user?.id,
                        _idUser: userFound.user.id,
                        _idAdmin: userFound.user.id,
                        token: dataSession.token,
                        names: userFound.names,
                        lastNames: userFound.lastNames,
                        zipCode: userFound.zipCode,
                        email: userFound.user.email,
                        userType: "ADMINISTRATOR",
                        warehouse: userFound.warehouse.length > 0 ? userFound.warehouse[0]._id : "",
                        allWarehouse: userFound.warehouse,
                        permissions: userFound.role.permissions
                    });
                }
                
                getCurrentPermits(userFound.role?.permissions, "ADMINISTRATOR");
            }
        });
    }
  
    const clearContext = () => {
        setCartValue(0);
        setDataSession({...dataSession,
            _id: "",
            _idAdmin: "",
            token: "",
            names: "",
            lastNames: "",
            email: "",
            userType: "",
            warehouse: "",
            allWarehouse: []
        });
        window.localStorage.removeItem("user-data");
        // window.localStorage.clear();
    }
  
    return (
        <PermitsContext.Provider
            value={{
                dataSession,
                setDataSession,
                cartValue,
                setCartValue,
                numNotif,
                setNumNotif,
                getNumberOfNotifications,
                permits,
                setPermits,
                permitsSA,
                setPermitsSA,
                getCurrentPermits,
                getCurrentCart,
                resetPermits,
                clearContext,
                logOutModal,
                setLogOutModal
            }}
      >
        {children}
      </PermitsContext.Provider>
    );
};