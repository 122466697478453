import { useEffect, useState } from "react";
import { FiChevronLeft } from "react-icons/fi";
import { Link, useNavigate, useParams } from "react-router-dom";
import { cancelDeliveryOrder, getOrder, organizeOrder } from "../../../../services/Orders";
import { toast } from "react-toastify";
import { alertOptions } from "../../../../utils/Utilities";
import { searchProductEspecification } from "../../../../services/Product";
import Loader from "../../../../components/loader/Loader";
import Navbar from "../../../../components/navbar/Navbar";
import Sidebar from "../../../../components/sidebar/Sidebar";
import HeaderDetails from "../details/HeaderDetails";
import TableOrganize from "./TableOrganize";
import OrganizeByRacks from "../../inventory/transfer/save_transfer/OrganizeByRacks";
import ModalCompatible from "../../products/ModalCompatible";
import MyCartFeatures from "../../../wholesaler/mycart/MyCartFeatures";

const OrganizeOrders = () => {
    const navigate = useNavigate();
    const params = useParams();
    const [loader, setLoader] = useState(true);
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const [showOrganizeRacks, setShowOrganizeRacks] = useState({ data: "", warehouse: "", status: false });
    const [showCompatible, setShowCompatible] = useState({ status: false, element: null });
    const [showFeatures, setShowFeatures] = useState({ status: false, element: null });
    const [order, setOrder] = useState({
        _id: "",
        warehouse: "",
        client: "",
        date: "",
        employee: "",
        orderType: "",
        payType: "",
        productsOrder: [],
        status: "",
        subTotal: "",
        total: "",
        ticketClover: ""
    });

    const handleRacksProducts = (respProducts, valStock) => {
        let acumProducts = 0;
        for (let i = 0; i < respProducts.racks.length; i++) {
            acumProducts += Number(respProducts.racks[i].quantity);
        }
        
        const newProductsOrder = order.productsOrder.map(prodElem => {
            if (respProducts.id === prodElem._id) {
                return {...prodElem,
                    racks: respProducts.racks,
                    isContinue: acumProducts === prodElem.quantity ? true : false
                };
            }
            return prodElem;
        });
        setOrder({...order, productsOrder: newProductsOrder});
        setShowOrganizeRacks({...showOrganizeRacks, status: false, data: "", warehouse: ""});
    }

    const handleCancel = () => {
        setLoader(true);
        cancelDeliveryOrder(params.id, { status: "attend" }).then(res => {
            if (res.status === 200) {
                toast.success("Order canceled successfully", alertOptions);
                navigate("/orders");
            } else {
                toast.warning(res.response.data.message ? res.response.data.message : "There was a problem canceling the order", alertOptions);
            }
            setLoader(false);
        }).catch(error => {
            setLoader(false);
            toast.warning(error.response.data.message, alertOptions);
        });
    }

    const handleToShip = () => {
        const organizeAllProducts = order.productsOrder.filter(element => element?.isContinue);
        if (organizeAllProducts.length === order.productsOrder.length) {
            setLoader(true);
            const body = {
                _id: params.id,
                productsInventory: order.productsOrder.map((prodOrdElem) => {
                    return ({
                        productInventory: { _id: prodOrdElem.productInventory._id },
                        organize: prodOrdElem.racks
                    });
                })
            }
            organizeOrder(params.id, body).then(res => {
                if (res.status === 200) {
                    toast.success("Has been  dispatched satisfactorily", alertOptions);
                    navigate("/orders");
                } else {
                    toast.warning(res.response.data.message ? res.response.data.message : "There was a problem organizing the order", alertOptions);
                }
                setLoader(false);
            }).catch(error => {
                setLoader(false);
                toast.warning(error.response.data.message, alertOptions);
            });
        } else {
            toast.warning("Please arrange the total quantity of the products", alertOptions);
        }
    }

    const getOrderData = async () => {
        await getOrder(params.id).then(async res => {
            if (res.status === 200) {
                let newProductsOrders = [];
                for (let i = 0; i < res.data.productsOrder.length; i++) {
                    let productsRes =  await searchProductEspecification({ nag: res.data.productsOrder[i].productInventory?.product?.nags });
                    newProductsOrders.push({...res.data.productsOrder[i],
                        compatible_vehicles: productsRes.status === 200 ? productsRes.data : []
                    });
                }

                setOrder({...order,
                    _id: res.data._id,
                    warehouse: res.data.warehouse._id,
                    client: res.data.wholesalerUser ? res.data.wholesalerUser : res.data.retailUser,
                    date: res.data.createdAt,
                    employee: res.data.employee,
                    orderType: res.data.orderType,
                    payType: res.data.payType ? res.data?.payType : res.data?.payment,
                    productsOrder: newProductsOrders,
                    status: res.data.status,
                    subTotal: res.data.subTotal,
                    total: res.data.total,
                    ticketClover: res.data?.orderIdClover ? res.data?.orderIdClover : "",
                    po: res.data?.purchase?.po ? res.data?.purchase?.po : "- - - - -"
                });
            }
            setLoader(false);
        });
    }

    useEffect(() => {
        if (params && params.id) {
            setLoader(true);
            getOrderData();
        }
    }, []);
    
    return (
        <>
            { loader ? <Loader /> : null }

            { showOrganizeRacks.status ?
                <OrganizeByRacks
                    type="order"
                    data={showOrganizeRacks.data}
                    warehouse={showOrganizeRacks.warehouse}
                    onOrganize={handleRacksProducts}
                    onClose={() => setShowOrganizeRacks({...showOrganizeRacks, status: false, data: "", warehouse: ""})}
                    onCompatible={(prdSel) => setShowCompatible({...showCompatible, status: true, element: prdSel})}
                    onLoader={(sts) => setLoader(sts)}
                />
            :
                null
            }

            {
                showCompatible.status ?
                    <ModalCompatible
                        products={showCompatible.element}
                        onClose={() => setShowCompatible({...showCompatible, status: false, element: null})}
                    />
                : null
            }

            {
                showFeatures.status ?
                    <MyCartFeatures
                        features={showFeatures.element}
                        onClose={() => setShowFeatures({...showFeatures, status: false, element: null})}
                    />
                : null
            }

            <div>
                <div className="flex flex-row">
                    <div className="w-full bg-no-repeat bg-cover fixed z-10">
                        <Navbar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                    </div>
                </div>
                <div className="flex h-screen overflow-hidden">
                    <div className={`${sidebarOpen ? "w-[100vw] md:w-80 z-20" : "w-20 z-0"}`}>
                        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                    </div>
                    <div className="w-full overflow-auto flex-grow mt-[100px]">
                        <div className="space-y-5 px-3 py-5 md:px-8 md:py-8">
                            <div className="grid grid-cols-12 px-0 md:px-5">
                                <div>
                                    <Link to="/orders">
                                        <button className="p-1 rounded-md" type="button">
                                            <span className="text-ag-secondary-dark text-3xl"><FiChevronLeft /></span>
                                        </button>
                                    </Link>
                                </div>
                                <div className="flex justify-center items-center col-span-11 text-center">
                                    <h1 className="text-[26px] md:text-xl lg:text-[26px] font-light text-ag-secondary md:mr-[8%]">
                                        Orders details
                                    </h1>
                                </div>
                            </div>
                            <div className="pt-3">
                                <HeaderDetails type="organize" orderData={order} onCancel={handleCancel} onToShip={handleToShip} />
                            </div>
                            <div className="text-center pt-3">
                                <h1 className="text-[26px] md:text-xl lg:text-[26px] font-light text-ag-secondary">Line items</h1>
                            </div>
                            <div className="overflow-x-auto">
                                <TableOrganize  
                                    items={order.productsOrder}
                                    onCompatible={(prdSel) => setShowCompatible({...showCompatible, status: true, element: prdSel})}
                                    onOrganize={(prodOrg) => setShowOrganizeRacks({...showOrganizeRacks, status: true, warehouse: order.warehouse, data: prodOrg})}
                                    onFeatures={(ftSel) => setShowFeatures({...showFeatures, status: true, element: ftSel})}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default OrganizeOrders;