import { useEffect, useState } from "react";
import Pagination from "../../../../components/pagination/Pagination";
import RowsInterchangeable from "./RowsInterchangeable";

const TableInterchangeable = ({ isRefresh, onRefresh, arrInterchanges, onNags, onCompatible, onEdit, onStatus }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(5);

    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = arrInterchanges.slice(indexOfFirstRecord, indexOfLastRecord);

    useEffect(() => {
        if (isRefresh) {
            setCurrentPage(1);
            onRefresh(false);
        }
    }, [isRefresh]);

    return (
        <div>
            <div className="overflow-auto">
                <table className="w-full text-center text-ag-secondary">
                    <thead>
                        <tr className="bg-ag-secondary-table">
                            <th className="w-[20%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 md:px-8 lg:px-2 py-3 py-4">ID Interchange</th>
                            <th className="w-[20%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 md:px-8 lg:px-2 py-3 py-4">Nags Interchanged</th>
                            <th className="w-[20%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 md:px-8 lg:px-2 py-3 py-4">Compatible Vehicles</th>
                            <th className="w-[20%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 md:px-8 lg:px-2 py-3 py-4">Description</th>
                            <th className="w-[20%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 md:px-8 lg:px-2 py-3 py-4">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            currentRecords.map((element, i) => {
                                return (
                                    <RowsInterchangeable
                                        key={i}
                                        interchange={element}
                                        onCompatible={onCompatible}
                                        onNags={onNags}
                                        onEdit={onEdit}
                                        onStatus={onStatus}
                                    />
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
            <div className="p-5 m-auto">
                <Pagination
                    currentPage={currentPage}
                    totalCount={arrInterchanges.length}
                    pageSize={recordsPerPage}
                    onPageChange={page => setCurrentPage(page)}
                />
            </div>
        </div>
    );
}

export default TableInterchangeable;