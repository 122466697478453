import { useContext, useEffect, useState } from "react";
import { deleteVendor, getVendors, saveVendor, updateVendor } from "../../../services/Vendor";
import { alertOptions, removeAccents } from "../../../utils/Utilities";
import { PermitsContext } from "../../../services/Permits";
import { toast } from "react-toastify";
import Modal from "../../../components/modal/Modal";
import Navbar from "../../../components/navbar/Navbar";
import Sidebar from "../../../components/sidebar/Sidebar";
import Filters from "./Filters";
import FormVendor from "./FormVendor";
import TableVendor from "./TableVendor";
import Loader from "../../../components/loader/Loader";

const Vendor = () => {
    const { permits, clearContext, logOutModal, setLogOutModal } = useContext(PermitsContext);

    const [loader, setLoader] = useState(false);
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const [showForm, setShowForm] = useState(false);
    const [showModal, setShowModal] = useState({ status: false, element: null });

    const [vendorData, setVendorData] = useState(null);
    const [stateOptions, setStateOptions] = useState([]);

    const [vendors, setVendors] = useState([]);
    const [vendorsUniv, setVendorsUniv] = useState([]);

    const handleFilters = (value, option) => {
        if (option === "filters") {
            let palabra = new RegExp(`${removeAccents(value)}.*`, "i");
            const vendorFound = vendorsUniv.filter(element => (
                palabra.test(`${removeAccents(element.name)}`) ||
                palabra.test(removeAccents(element.email)) ||
                palabra.test(removeAccents(element.phone)) ||
                palabra.test(removeAccents(element.address.street))
            ));
            setVendors(vendorFound);
        } else {
            if (value.location === "") {
                setVendors(vendorsUniv);
            } else {
                const vendorsFound = vendorsUniv.filter(element => element.address.state === value.location);
                setVendors(vendorsFound);
            }
        }
    }

    const getData = () => {
        getVendors().then(resGet => {
            if (resGet.status === 200 && resGet.data.length > 0) {
                setVendors(resGet.data);
                setVendorsUniv(resGet.data);
                getOptions(resGet.data);
            }
            setLoader(false);
        });
    }

    const addVendor = (vendorObject) => {
        setLoader(true);
        saveVendor(vendorObject).then(res => {
            if (res.status === 200) {
                setShowForm(false);
                getData();
                toast.success("Vendor added successfully", alertOptions);
            } else {
                setLoader(false);
                toast.warning(res.response.data.message, alertOptions);
            }
        }).catch(error => {
            setLoader(false);
            toast.warning(error.response.data.message, alertOptions);
        });
    }

    const editVendor = (id, vendorObject) => {
        setLoader(true);
        updateVendor(id, vendorObject).then((res) => {
            if (res.status === 200) {
                getData();
                setVendorData(null);
                setShowForm(false);
                toast.success("Vendor updated successfully", alertOptions);
            } else {
                setLoader(false);
                toast.warning(res.response.data.message, alertOptions);
            }
        }).catch(error => {
            setLoader(false);
            toast.warning(error.response.data.message, alertOptions);
        });
    }

    const removeVendor = (sts) => {
        if (sts) {
            setLoader(true);
            deleteVendor(showModal.element._id).then((res) => {
                if (res.status === 200) {
                    getData();
                    toast.success("Vendor deleted successfully", alertOptions);
                } else {
                    setLoader(false);
                    toast.warning(res.response.data.message, alertOptions);
                }
            }).catch(error => {
                setLoader(false);
                toast.warning(error.response.data.message, alertOptions);
            });
        }
        setShowModal({...showModal, status: false, element: null});
    }

    const getOptions = (array_vendors) => {
        let states = [];
        for (let i = 0; i < array_vendors.length; i++) {
            const statesFound = states.find(element => element.value === array_vendors[i].address.state);

            if (!statesFound) {
                states.push({ label: array_vendors[i].address.state, value: array_vendors[i].address.state });
            }
        }
        setStateOptions(states);
    }

    const handleModalResponse = (sts) => {
        if (sts) {
            clearContext();
            window.location.reload(true);
        }
    };

    useEffect(() => {
        setLoader(true);
        getData();
    }, []);

    return (
        <>
            { loader ? <Loader /> : null }

            { showModal.status ?
                <Modal
                    title="¿Are you sure you want to delete the vendor?"
                    onClose={() => {setShowModal({...showModal, status: false, element: null}) }}
                    onResponse={removeVendor}
                />
            :
                null
            }

            { logOutModal.status && (
                <Modal
                    title={logOutModal.title}
                    onClose={() =>
                        setLogOutModal({ ...logOutModal, status: false, title: "", element: null })
                    }
                    onResponse={handleModalResponse}
                />
            )}
            
            { showForm ?
                <FormVendor
                    vendorInfo={vendorData}
                    onSave={addVendor}
                    onEdit={editVendor}
                    onClose={() => {
                        setShowForm(false);
                        setVendorData(null);
                    }}
                />
            : 
                null
            }

            <div>
                <div className="flex flex-row">
                    <div className="w-full bg-no-repeat bg-cover fixed z-10">
                        <Navbar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                    </div>
                </div>
                <div className="flex h-screen overflow-hidden">
                    <div className={`${sidebarOpen ? "w-[100vw] md:w-80 z-20" : "w-20 z-0"}`}>
                        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                    </div>
                    <div className="w-full overflow-auto flex-grow mt-[100px]">
                        <div className="space-y-5 px-3 py-5 md:px-8 md:py-8">
                            <div className="text-center">
                                <h1 className="text-[26px] md:text-xl lg:text-[26px] font-light text-ag-secondary">Vendors</h1>
                            </div>
                            <div className="pt-3">
                                <Filters
                                    enableFilters={permits['SUPPLIERS']['FILTER']}
                                    enableAdd={permits['SUPPLIERS']['ADD']}
                                    stateOptions={stateOptions}
                                    onFilters={handleFilters}
                                    onClose={() => {
                                        setShowForm(true)
                                    }}
                                />
                            </div>
                            <div className="pt-5">
                                <TableVendor
                                    enableEdit={permits['SUPPLIERS']['EDIT']}
                                    enableDelete={permits['SUPPLIERS']['DELETE']}
                                    arrVendors={vendors}
                                    onDelete={(vendorElement) => {
                                        setShowModal({...showModal, status: true, element: vendorElement})
                                    }}
                                    onEdit={(vendorInfo) => {
                                        setVendorData(vendorInfo);
                                        setShowForm(true);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Vendor;