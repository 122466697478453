import { useState } from "react";

const Filters = ({ enableFilters, enableAdd, stateOptions, onFilters, onClose }) => {
    const [focusClass, setFocusClass] = useState({
        search: { placeholder: "Search Vendor", status: false },
        location: { placeholder: "Location", status: false }
    });

    const [options, setOptions] = useState({
        location: ""
    });

    const handleChange = (evt) => {
        if (evt.currentTarget.name === "search") {
            onFilters(evt.currentTarget.value, "filters");
        } else {
            if (evt.currentTarget.value === "default") {
                setOptions({...options, [evt.currentTarget.name]: ""});
            } else {
                setOptions({...options, [evt.currentTarget.name]: evt.currentTarget.value});
            }
        }
    }

    const handleSubmit = (evt) => {
        evt.preventDefault();
        onFilters(options, "options");
    }

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <div className="space-y-3">
                    <div className="grid gap-3 lg:gap-6 md:grid-cols-4">
                        <div className="md:col-span-3">
                            <input
                                className={`w-full text-ag-secondary-letter text-sm lg:text-base border border-gray-500 p-2 rounded-xl disabled:bg-zinc-200 truncate ${focusClass.search.status ? "text-left" : "text-center"}`}
                                type="text"
                                name="search"
                                placeholder={focusClass.search.placeholder}
                                onChange={handleChange}
                                disabled={!enableFilters}
                                onFocus={() => setFocusClass({...focusClass, search: { placeholder: "", status: true }})}
                                onBlur={(evt) => evt.target.value === "" ? setFocusClass({...focusClass, search: { placeholder: "Search Vendor", status: false }}) : null}
                                maxLength={60}
                            />
                        </div>
                        <div>
                            <button
                                className="w-full bg-ag-primary-light text-white text-sm lg:text-base p-2 rounded-xl disabled:bg-zinc-200"
                                type="button"
                                onClick={() => { onClose(true) }}
                                disabled={!enableAdd}
                            >
                                Create Vendor
                            </button>
                        </div>
                    </div>
                    <div className="grid gap-3 lg:gap-6 md:grid-cols-8">
                        <div className="md:col-span-2 lg:col-span-1">
                            <select
                                className={`w-full text-sm p-1 text-ag-secondary-letter border border-gray-500 rounded-xl disabled:bg-zinc-200 truncate ${focusClass.location.status ? "text-left" : "text-center"}`}
                                name="location"
                                defaultValue="default"
                                onChange={handleChange}
                                disabled={!enableFilters}
                                onFocus={() => setFocusClass({...focusClass, location: { placeholder: "", status: true }})}
                                onBlur={(evt) => evt.target.value === "default" ? setFocusClass({...focusClass, location: { placeholder: "Location", status: false }}) : null}
                            >
                                <option value="default">Location</option>
                                {
                                    stateOptions.map((element, index) => {
                                        return <option key={index} value={element.label}>{element.label}</option>;
                                    })
                                }
                            </select>
                        </div>
                        <div>
                            <button
                                className="w-full text-center text-sm p-1 text-ag-secondary-letter border border-gray-500 rounded-xl disabled:bg-zinc-200"
                                type="submit"
                                disabled={!enableFilters}
                            >
                                Filter
                            </button>
                        </div>
                        <div className="md:col-span-5 lg:col-span-6"></div>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default Filters;