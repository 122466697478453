import { useState } from "react";
import { Link } from "react-router-dom";

const Filters = ({ enableFilters, enableAdd, enableHistory, statusOptions, onFilters }) => {
    const [focusClass, setFocusClass] = useState({
        status: { placeholder: "Status", status: false },
        search: { placeholder: "Search", status: false },
        barcode1: { placeholder: "Barcode", status: false },
        nags: { placeholder: "Nags", status: false }
    });

    const [options, setOptions] = useState({
        search: "",
        status: "",
        barcode1: "",
        nags: ""
    });

    const handleChange = (evt) => {
        if (evt.currentTarget.name === "search" || evt.currentTarget.name === "barcode1" || evt.currentTarget.name === "nags") {
            setOptions ({...options, [evt.currentTarget.name]: evt.currentTarget.value});
            onFilters(evt.currentTarget.value, evt.currentTarget.name, options);
        } else {
            if (evt.currentTarget.value === "default") {
                setOptions({...options, [evt.currentTarget.name]: ""});
            } else {
                setOptions({...options, [evt.currentTarget.name]: evt.currentTarget.value});
            }
        }
    }

    const handleSubmit = (evt) => {
        evt.preventDefault();
        onFilters(options.search, "status", options);
    }

    return (
        <>
            <form onSubmit={handleSubmit}>
                <div className="grid gap-3 lg:gap-10 md:grid-cols-3">
                    <div className="space-y-2">
                        <div>
                            <select
                                className={`w-full text-ag-secondary-letter text-sm lg:text-base border border-gray-500 py-[9.5px] px-4 rounded-xl disabled:bg-zinc-200 truncate ${focusClass.status.status ? "text-left" : "text-center"}`}
                                name="status"
                                defaultValue="default"
                                onChange={handleChange}
                                disabled={!enableFilters}
                                onFocus={() => setFocusClass({...focusClass, status: { placeholder: "", status: true }})}
                                onBlur={(evt) => evt.target.value === "default" ? setFocusClass({...focusClass, status: { placeholder: "Status", status: false }}) : null}
                            >
                                <option value="default">Status</option>
                                {
                                    statusOptions.map((element, index) => {
                                        return <option key={index} value={element.value}>{element.label}</option>;
                                    })
                                }
                            </select>
                        </div>
                        <div>
                            <input
                                className={`w-full text-ag-secondary-letter text-sm lg:text-base border border-gray-500 py-2 px-4 rounded-xl disabled:bg-zinc-200 truncate ${focusClass.barcode1.status ? "text-left" : "text-center"}`}
                                type="text"
                                name="barcode1"
                                placeholder={focusClass.barcode1.placeholder}
                                onChange={handleChange}
                                disabled={!enableFilters}
                                onFocus={() => setFocusClass({...focusClass, barcode1: { placeholder: "", status: true }})}
                                onBlur={(evt) => evt.target.value === "" ? setFocusClass({...focusClass, barcode1: { placeholder: "Barcode", status: false }}) : null}
                                maxLength={30}
                            />
                        </div>
                    </div>
                    <div className="space-y-2">
                        <div>
                            <input
                                className={`w-full text-ag-secondary-letter text-sm lg:text-base border border-gray-500 py-2 px-4 rounded-xl disabled:bg-zinc-200 truncate ${focusClass.search.status ? "text-left" : "text-center"}`}
                                type="text"
                                name="search"
                                placeholder={focusClass.search.placeholder}
                                onChange={handleChange}
                                disabled={!enableFilters}
                                onFocus={() => setFocusClass({...focusClass, search: { placeholder: "", status: true }})}
                                onBlur={(evt) => evt.target.value === "" ? setFocusClass({...focusClass, search: { placeholder: "Search", status: false }}) : null}
                                maxLength={60}
                            />
                        </div>
                        <div className="grid gap-3 grid-cols-2">
                            <div>
                                <input
                                    className={`w-full text-ag-secondary-letter text-sm lg:text-base border border-gray-500 py-2 px-4 rounded-xl disabled:bg-zinc-200 truncate ${focusClass.nags.status ? "text-left" : "text-center"}`}
                                    type="text"
                                    name="nags"
                                    placeholder={focusClass.nags.placeholder}
                                    onChange={handleChange}
                                    disabled={!enableFilters}
                                    onFocus={() => setFocusClass({...focusClass, nags: { placeholder: "", status: true }})}
                                    onBlur={(evt) => evt.target.value === "" ? setFocusClass({...focusClass, nags: { placeholder: "Nags", status: false }}) : null}
                                    maxLength={15}
                                />
                            </div>
                            <div>
                                <button
                                    className="w-full text-center text-ag-secondary-letter text-sm lg:text-base border border-gray-500 p-2 rounded-xl disabled:bg-zinc-200"
                                    type="submit"
                                    disabled={!enableFilters}
                                >
                                    Filter
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="space-y-2">
                        <div>
                            <Link to={!enableAdd ? "" : "/products/element"}>
                                <button
                                    className="w-full bg-ag-primary-light text-white text-sm lg:text-base p-2 rounded-xl disabled:bg-zinc-200"
                                    type="button"
                                    disabled={!enableAdd}
                                >
                                    New product
                                </button>
                            </Link>
                        </div>
                        <div className="grid gap-3 md:grid-cols-2">
                            <Link to={!enableHistory ? "" : "/products/history"}>
                                <button
                                    className="w-full bg-ag-primary-light text-white text-sm lg:text-base p-2 rounded-xl disabled:bg-zinc-200"
                                    type="button"
                                    disabled={!enableHistory}
                                >
                                    <p className="truncate">Price History</p>
                                </button>
                            </Link>
                            <Link to={!enableFilters ? "" : "/products/interchanges"}>
                                <button
                                    className="w-full bg-ag-primary-light text-white text-sm lg:text-base p-2 rounded-xl disabled:bg-zinc-200"
                                    type="button"
                                    disabled={!enableFilters}
                                >
                                    <p className="truncate">Interchanges</p>
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
}

export default Filters;