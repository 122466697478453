import { useEffect, useState } from "react";
import { alertOptions, compareDates, formatDate, formatDateEng, formatReverseDate } from "../../../../../utils/Utilities";
import { toast } from "react-toastify";
import ReactDatePicker from "react-datepicker";

const FormTransfer = ({ headerTransferData, statusOptions, warehousesOptions, warehouseFromOptions, onHandleChange, onlyRead }) => {
    const [datesValues, setDatesValues] = useState({
        date: "",
        expectedBy: ""
    });
    
    const [addressWhf, setAddressWhf] = useState("");
    const [addressWht, setAddressWht] = useState("");

    const handleChange = (valDate, name) => {
        if (compareDates(new Date(), valDate) <= 0) {
            setDatesValues({...datesValues, [name]: valDate});
            onHandleChange(valDate, name);
        } else {
            toast.info("Cannot select a date earlier than now", alertOptions);
        }
    }

    useEffect(() => {
        if (headerTransferData && headerTransferData?.date !== "" && headerTransferData?.expectedBy !== "") {
            setDatesValues({...datesValues,
                date: formatDateEng(headerTransferData?.date),
                expectedBy: formatDateEng(headerTransferData?.expectedBy)}
            );
        }
    }, [headerTransferData]);

    useEffect(() => {
        const whtf = warehousesOptions.find(element => element._id === headerTransferData.toWarehouse);
        if (whtf) {
            setAddressWht(`${whtf.address.street}, ${whtf.address.locality}, ${whtf.address.state} ${whtf.address.postalCode}`);
        } else {
            setAddressWht("");
        }
    }, [headerTransferData.toWarehouse]);

    useEffect(() => {
        const whff = warehouseFromOptions.find(element => element._id === headerTransferData.fromWarehouse);
        if (whff) {
            setAddressWhf(`${whff.address.street}, ${whff.address.locality}, ${whff.address.state} ${whff.address.postalCode}`);
        } else {
            setAddressWhf("");
        }
    }, [headerTransferData.fromWarehouse]);

    return (
        <>
            <div className="pb-8 grid md:gap-3 md:grid-cols-2 xl:grid-cols-4 space-y-3 md:space-y-0 border-b-2 border-dashed border-ag-secondary-light">
                <div className="flex justify-center items-center space-x-5 md:space-x-5">
                    <div className="w-[48%] md:w-[20%] text-right md:text-center">
                        <label className="text-ag-secondary-dark text-xs lg:text-base">STATUS</label>
                    </div>
                    <div className="w-[52%] md:w-[80%]">
                        <select
                            className="w-full py-2 px-5 text-ag-secondary-letter text-xs lg:text-base border border-ag-secondary-light rounded-xl disabled:bg-zinc-100"
                            name="status"
                            defaultValue="default"
                            onChange={(evt) => onHandleChange(evt.target.value, evt.target.name)}
                            value={headerTransferData.status}
                            disabled={onlyRead}
                        >
                            <option value="default"></option>
                            {
                                statusOptions.map((element, index) => {
                                    return <option key={index} value={element.value}>{element.label}</option>;
                                })
                            }
                        </select>
                    </div>
                </div>
                <div className="flex justify-center items-center space-x-5 md:space-x-1">
                    <div className="w-[48%] md:w-[57%] text-right md:text-center">
                        <label className="text-ag-secondary-dark text-xs lg:text-base">TRANSFER NUMBER</label>
                    </div>
                    <div className="w-[52%] md:w-[43%]">
                        <input
                            className="w-full p-2 text-ag-secondary-letter text-xs lg:text-base border border-ag-secondary-light rounded-xl disabled:bg-zinc-100"
                            type="text"
                            name="number"
                            onChange={(evt) => onHandleChange(evt.target.value, evt.target.name)}
                            value={headerTransferData._id ? `#TF-${String(headerTransferData._id).substring(String(headerTransferData._id).length - 5).toLocaleUpperCase()}` : "-----"}
                            disabled="true"
                        />
                    </div>
                </div>
                <div className="flex justify-center items-center space-x-5 md:space-x-1">
                    <div className="w-[50%] md:w-[20%] text-right md:text-center">
                        <label className="text-ag-secondary-dark text-xs lg:text-base">DATE</label>
                    </div>
                    <div className="w-[50%] md:w-[80%]">
                        <div className="customDatePickerWidth">
                            <ReactDatePicker
                                className="w-full py-2 px-5 text-ag-secondary-letter text-xs lg:text-base border border-ag-secondary-light rounded-xl disabled:bg-zinc-100"
                                placeholderText={onlyRead ? formatDateEng(headerTransferData?.date) : ""}
                                name="date"
                                selected={headerTransferData?.date !== "" ? new Date(headerTransferData?.date).setMinutes(new Date(headerTransferData?.date).getMinutes() + new Date(headerTransferData?.date).getTimezoneOffset()) : null}
                                onChange={(valDate) => handleChange(valDate, "date")}
                                disabled={onlyRead}
                                minDate={new Date()}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex justify-center items-center space-x-5 md:space-x-1">
                    <div className="w-[50%] md:w-[40%] text-right md:text-center">
                        <label className="text-ag-secondary-dark text-xs lg:text-base">EXPECTED BY</label>
                    </div>
                    <div className="w-[50%] md:w-[60%]">
                        <div className="customDatePickerWidth">
                            <ReactDatePicker
                                className="w-full py-2 px-5 text-ag-secondary-letter text-xs lg:text-base border border-ag-secondary-light rounded-xl disabled:bg-zinc-100"
                                placeholderText={onlyRead ? formatDateEng(headerTransferData?.expectedBy) : ""}
                                name="expectedBy"
                                selected={headerTransferData?.expectedBy !== "" ? new Date(headerTransferData?.expectedBy).setMinutes(new Date(headerTransferData?.expectedBy).getMinutes() + new Date(headerTransferData?.expectedBy).getTimezoneOffset()) : null}
                                onChange={(valDate) => handleChange(valDate, "expectedBy")}
                                disabled={onlyRead}
                                minDate={new Date()}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="grid gap-8 grid-cols-2 py-8 border-b-2 border-dashed border-ag-secondary-light">
                <div className="space-y-3">
                    <div>
                        <label className="text-xs lg:text-base">FROM</label>
                    </div>
                    <div>
                        <select
                            className="w-full p-2 text-ag-secondary-letter text-xs lg:text-base border border-ag-secondary-light rounded-xl disabled:bg-zinc-100 truncate"
                            name="fromWarehouse"
                            defaultValue="default"
                            onChange={(evt) => onHandleChange(evt.target.value, evt.target.name)}
                            value={headerTransferData.fromWarehouse}
                            disabled={onlyRead}
                        >
                            <option value="default"></option>
                            {
                                warehouseFromOptions.map((element, index) => {
                                    return <option key={index} value={element._id}>{element.name}</option>;
                                })
                            }
                        </select>
                    </div>
                    <div>
                        <input
                            className="w-full p-2 text-ag-secondary-letter text-xs lg:text-base border border-ag-secondary-light rounded-xl disabled:bg-zinc-100 truncate"
                            id="whFromAdress"
                            type="text"
                            name="whFromAdress"
                            value={addressWhf}
                            disabled={true}
                        />
                    </div>
                    <div>
                        <label className="text-xs lg:text-base">FEES</label>
                    </div>
                    <div>
                        <input
                            className="w-full p-2 text-ag-secondary-letter text-xs lg:text-base border border-ag-secondary-light rounded-xl disabled:bg-zinc-100 truncate"
                            type="number"
                            name="fees"
                            onChange={(evt) => onHandleChange(evt.target.value, evt.target.name)}
                            value={headerTransferData.fees}
                            disabled={onlyRead}
                        />
                    </div>
                </div>
                <div className="space-y-3">
                    <div>
                        <label className="text-xs lg:text-base">SHIP TO</label>
                    </div>
                    <div>
                        <select
                            className="w-full p-2 text-ag-secondary-letter text-xs lg:text-base border border-ag-secondary-light rounded-xl disabled:bg-zinc-100 truncate"
                            name="toWarehouse"
                            defaultValue="default"
                            onChange={(evt) => onHandleChange(evt.target.value, evt.target.name)}
                            value={headerTransferData.toWarehouse}
                            disabled={onlyRead}
                        >
                            <option value="default"></option>
                            {
                                warehousesOptions.map((element, index) => {
                                    return <option key={index} value={element._id}>{element.name}</option>;
                                })
                            }
                        </select>
                    </div>
                    <div>
                        <input
                            className="w-full p-2 text-ag-secondary-letter text-xs lg:text-base border border-ag-secondary-light rounded-xl disabled:bg-zinc-100 truncate"
                            type="text"
                            id="whShiptoAdress"
                            name="whShiptoAdress"
                            value={addressWht}
                            disabled={true}
                        />
                    </div>
                    <div>
                        <label className="text-xs lg:text-base">FREIGHT</label>
                    </div>
                    <div>
                        <input
                            className="w-full p-2 text-ag-secondary-letter text-xs lg:text-base border border-ag-secondary-light rounded-xl disabled:bg-zinc-100 truncate"
                            type="number"
                            name="freight"
                            onChange={(evt) => onHandleChange(evt.target.value, evt.target.name)}
                            value={headerTransferData.freight}
                            disabled={onlyRead}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export default FormTransfer;