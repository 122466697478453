import { BsFillBellFill } from "react-icons/bs";
import { MdMenuOpen, MdRequestQuote } from "react-icons/md";
import { FaUserCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { PermitsContext } from "../../services/Permits";
import { IoMdTime } from "react-icons/io";
import Logo from "../../assets/images/logo_a.png";
import Notifications from "../notifications/Notifications";
// import { useEffect, useState } from "react";
// import { getQuotes } from "../../services/Quotes";
// import LanguageIcon from "../../assets/icons/languaje_icon.png";
// import { IoMdTime } from "react-icons/io";

const Navbar = ({ sidebarOpen, setSidebarOpen }) => {
    const navigate = useNavigate();
    const { dataSession, permits, numNotif, getNumberOfNotifications } = useContext(PermitsContext);
    const [showNotif, setShowNotif] = useState(false);

    // const [numQuotes, setNumQuotes] = useState("");
    // const [isEnable, setIsEnable] = useState(false);

    // useEffect(() => {
    //     getQuotes().then(res => {
    //         if (res.status === 200 && res.data.length > 0) {
    //             const quotesPending = res.data.filter(ele => ele.status === "inprocess");
    //             setNumQuotes(quotesPending.length > 99 ? "99+" : quotesPending.length);
    //             setIsEnable(true);
    //         }
    //     });
    // }, []);

    useEffect(() => {
        // if (dataSession?.allWarehouse && dataSession?.allWarehouse?.length > 0) getNumberOfNotifications();
        getNumberOfNotifications();
    }, [dataSession]);

    useEffect(() => {
        // if (dataSession?.allWarehouse && dataSession?.allWarehouse?.length > 0) {
        //     setInterval(() => getNumberOfNotifications(), [900000]);
        // }
        setInterval(() => getNumberOfNotifications(), [900000]);
    }, [dataSession]);

    return (
        <>
            { showNotif ? <Notifications onClose={() => setShowNotif(false)} /> : null }

            <div className="relative fixed">
                <div className="bg-ag-secondary-light">
                    <div className={`mx-auto px-[25px] ${ !sidebarOpen ? "py-[6px]" : "py-[32px]" }`}>
                        <div className="flex justify-between items-center">
                            {
                                !sidebarOpen ?
                                    <div className="flex justify-between items-center">
                                        <div className="">
                                            <button aria-expanded={sidebarOpen} onClick={(e) => { setSidebarOpen(!sidebarOpen); }}>
                                                <span className="text-2xl md:text-3xl text-white"><MdMenuOpen /></span>
                                            </button>
                                        </div>
                                        <div className="pl-5">
                                            <img className="h-auto w-[130px] md:w-[220px] m-auto" src={Logo} />
                                        </div>
                                    </div>
                                :
                                <div className="pl-[270px]">
                                    <button aria-expanded={sidebarOpen} onClick={(e) => { setSidebarOpen(!sidebarOpen); }}>
                                        <span className="text-2xl md:text-3xl text-white"><MdMenuOpen /></span>
                                    </button>
                                </div>
                            }
                            <div className="flex items-center justify-end space-x-3 md:space-x-7 pr-0 md:pr-10">
                                {/* <button type="button">
                                    <span className="text-2xl md:text-3xl text-white">
                                        <img className="w-[30px] md:w-[40px] m-auto" src={LanguageIcon} />
                                    </span>
                                </button> */}
                                <button type="button" onClick={() => navigate("/profile")}>
                                    <span className="text-2xl md:text-3xl text-white"><FaUserCircle /></span>
                                </button>
                                {
                                    permits['QUOTES']['VIEW'] ?
                                        <button type="button" onClick={() => navigate("/quotes")}>
                                            <span className="text-2xl md:text-3xl text-white"><MdRequestQuote /></span>
                                            {/* {
                                                numQuotes !== "" || numQuotes > 0 ?
                                                    <div className="absolute top-7 right-28 mr-0 bg-red-500 rounded-xl">
                                                        <span className="flex justify-center items-center px-[6px] py-[2px] text-white text-[9px]">{ numQuotes }</span>
                                                    </div>
                                                :
                                                    <div className="absolute top-7 right-28 mr-0 bg-zinc-500 rounded-full">
                                                        <span className="flex justify-center items-center px-[3px] py-[3px] text-white text-[18px]"><IoMdTime /></span>
                                                    </div>
                                            } */}
                                        </button>
                                    : null
                                }
                                <button className="relative" type="button" onClick={() => setShowNotif(true)}>
                                    <span className="text-2xl md:text-3xl text-white"><BsFillBellFill /></span>
                                    {
                                        numNotif !== "" || numNotif > 0 ?
                                            <div className="absolute top-[-6px] right-[-14px] mr-0 bg-red-500 rounded-xl">
                                                <span className="flex justify-center items-center px-[6px] py-[2px] text-white text-[9px]">{ numNotif }</span>
                                            </div>
                                        :
                                            <div className="absolute top-[-6px] right-[-14px] mr-0 bg-zinc-500 rounded-full">
                                                <span className="flex justify-center items-center px-[3px] py-[3px] text-white text-[18px]"><IoMdTime /></span>
                                            </div>
                                    }
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Navbar;