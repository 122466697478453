import { useContext, useEffect, useState } from "react";
import { PermitsContext } from "../../../services/Permits";
import { toast } from "react-toastify";
import { alertOptions, getAllCategories, getAllFeaturesWC } from "../../../utils/Utilities";
import { emptyProductCart, saveProductCart } from "../../../services/Shopping";
import { useNavigate } from "react-router-dom";
import { cancelPurcharse, finishPurcharse, getPurcharses, sendNote } from "../../../services/Purcharse";
import { getProductEspecification, getProductEspecificationByNag } from "../../../services/Product";
import Loader from "../../../components/loader/Loader";
import NavbarClients from "../../../components/navbar/NavbarClients";
import SidebarClients from "../../../components/sidebar/SidebarClients";
import OrderCard from "./OrderCard";
import OrdersNote from "./OrdersNote";
import OrderDetail from "./OrderDetail";
import OrderMsg from "./OrderMsg";
import ModalCompatible from "../../admin/products/ModalCompatible";

const Orders = () => {
    const navigate = useNavigate();
    const { dataSession, getCurrentCart } = useContext(PermitsContext);
    const [loader, setLoader] = useState(false);
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const [showForm, setShowForm] = useState({ status: false, data: null });
    const [showMsg, setShowMsg] = useState({ status: false, data: null, type: "" });
    const [showDetail, setShowDetail] = useState({ status: false, data: [] });
    const [showCompatible, setShowCompatible] = useState({ status: false, element: null });
    const [orders, setOrders] = useState([]);

    const getData = async () => {
        await getPurcharses(dataSession._id).then(async res => {
            if (res.status === 200) {
                const featuresAux = getAllFeaturesWC();
                const categoriesAux = getAllCategories();

                let newArrOrders = [];
                for (let i = 0; i < res.data.length; i++) {
                    const productSpecificationRes = await getProductEspecificationByNag(res.data[i]?.purchasedProducts[0]?.productInventory?.product?.nags);
                    const productSpecificationVal = productSpecificationRes?.status === 200 ? productSpecificationRes.data : null;

                    let distinctFeatures = [];
                    const newCategories = categoriesAux.map(catAux => {
                        let newFeaturesAux = [];
                        const featuresFilters = featuresAux.filter(featAux => featAux.category === catAux.label);
                        for (let i = 0; i < featuresFilters.length; i++) {
                            if (
                                String(productSpecificationVal.ad_info_a).toLowerCase().includes(String(featuresFilters[i].value).toLowerCase()) === true ||
                                ((productSpecificationVal.hud === "Y" && featuresFilters[i].value === "HUD (Head up display)") ||
                                (productSpecificationVal.antena === "Y" && featuresFilters[i].value === "Antenna"))
                            ) {
                                const foundFeatures = distinctFeatures.find(distFeatElem => distFeatElem.value === featuresFilters[i].value);;
                                if (!foundFeatures) distinctFeatures.push(featuresFilters[i]);
                                newFeaturesAux.push(featuresFilters[i]);
                            }
                        }

                        if (newFeaturesAux.length > 0) return {...catAux, features: newFeaturesAux, status: true}
                        return catAux;
                    });

                    if (res.data[i].status === "FINISHED") {
                        newArrOrders.push({...res.data[i],
                            status: "finished",
                            features: productSpecificationVal !== null ? productSpecificationVal.ad_info_a : "",
                            category: newCategories
                        });
                    } else if (res.data[i].status === "CANCELED") {
                        newArrOrders.push({...res.data[i],
                            status: "canceled",
                            features: productSpecificationVal !== null ? productSpecificationVal.ad_info_a : "",
                            category: newCategories
                        });
                    } else {
                        newArrOrders.push({...res.data[i],
                            status: res.data[i]?.order?.status,
                            features: productSpecificationVal !== null ? productSpecificationVal.ad_info_a : "",
                            category: newCategories
                        });
                    }
                }

                const array_sort = newArrOrders.sort(function(a,b){
                    return new Date(b.createdAt) - new Date(a.createdAt);
                });

                setOrders(array_sort);
            }
            setLoader(false);
        });
    }

    const handleCancel = (idOrder) => {
        setShowMsg({...showMsg, status: false, data: null, type: ""});
        setLoader(true);
        cancelPurcharse(dataSession._id, idOrder, { _id: idOrder, status: "canceled" }).then(res => {
            if (res.status === 200) {
                toast.success("Order canceled successfully", alertOptions);
                getData();
            } else {
                toast.warning(res.response.data.message, alertOptions);
            }
            setLoader(false);
        }).catch(error => {
            setLoader(false);
            toast.warning(error.response.data.message, alertOptions);
        });
    }

    const handlePurchase = async (arrObjCart) => {
        setLoader(true);
        
        let valAcum = 0;
        for (let i = 0; i < arrObjCart.length; i++) {
            valAcum += Number(arrObjCart[i].quantity);
            await saveProductCart(dataSession._id, arrObjCart[i]);
        }
        setLoader(false);
        getCurrentCart(dataSession._id);
        navigate("/wholesaler/mycart");
    }

    const handleMark = (idOrder) => {
        setShowMsg({...showMsg, status: false, data: null, type: ""});
        setLoader(true);
        finishPurcharse(dataSession._id, idOrder).then(res => {
            if (res.status === 200) {
                toast.success("Order finished successfully", alertOptions);
                getData();
            } else {
                toast.warning(res.response.data.message, alertOptions);
            }
            setLoader(false);
        }).catch(error => {
            setLoader(false);
            toast.warning(error.response.data.message, alertOptions);
        });
    }

    const handleNote = (noteObject, idPurchase) => {
        setLoader(true);
        setShowForm({...showForm, status: false, data: null});
        sendNote(dataSession._id, idPurchase, noteObject).then(res => {
            if (res.status === 200) {
                toast.success("Note sended successfully", alertOptions);
                getData();
            } else {
                toast.warning(res.response.data.message, alertOptions);
            }
            setLoader(false);
        }).catch(error => {
            setLoader(false);
            toast.warning(error.response.data.message, alertOptions);
        });
    }

    useEffect(() => {
        if (dataSession._id !== null) {
            setLoader(true);
            getData();
        }
    }, [dataSession._id]);

    return (
        <>
            { loader ? <Loader /> : null }

            {
                showForm.status ?
                    <OrdersNote
                        email={dataSession.email}
                        onSave={(noteObject) => handleNote(noteObject, showForm.data)}
                        onClose={() => setShowForm({...showForm, status: false, data: null})}
                    />
                : null
            }

            {
                showDetail.status ?
                    <OrderDetail
                        products={showDetail.data}
                        onCompatible={(prdSel) => setShowCompatible({...showCompatible, status: true, element: prdSel})}
                        onClose={() => setShowDetail({...showDetail, status: false, data: null})}
                    />
                : null
            }

            {
                showMsg.status ?
                    <OrderMsg
                        type={showMsg.type}
                        onClose={() => setShowMsg({...showMsg, status: false, data: null, type: ""})}
                        onMark={() => handleMark(showMsg.data)}
                        onCancel={() => handleCancel(showMsg.data)}
                    />
                : null
            }

            {
                showCompatible.status ?
                    <ModalCompatible
                        products={showCompatible.element}
                        onClose={() => setShowCompatible({...showCompatible, status: false, element: null})}
                    />
                : null
            }
            
            <div>
                <div className="flex flex-row">
                    <div className="w-full bg-no-repeat bg-cover fixed z-10">
                        <NavbarClients type="wholesaler" sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                    </div>
                </div>
                <div className="flex h-screen overflow-hidden">
                    <div className={`${sidebarOpen ? "w-[100vw] md:w-80 z-20" : "w-20 z-0"}`}>
                        <SidebarClients type="wholesaler" sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                    </div>
                    <div className="w-full overflow-auto flex-grow mt-[100px]">
                        <div className="space-y-5 px-3 py-5 md:px-8 md:py-8">
                            <div className="text-center">
                                <h1 className="text-[26px] md:text-xl lg:text-[26px] font-light text-ag-secondary">
                                    My Orders
                                </h1>
                            </div>
                            <div className="pt-3 space-y-5">
                                {
                                    orders.length > 0 ? orders.map((element, i) => (
                                        <OrderCard
                                            key={i}
                                            order={element}
                                            onDetail={(prds) => setShowDetail({...showDetail, status: true, data: prds})}
                                            onShowForm={(idOrder) => setShowForm({...showForm, status: true, data: idOrder})}
                                            onMark={(idOrder) => setShowMsg({...showMsg, status: true, data: idOrder, type: "mark"})}
                                            onPurchase={handlePurchase}
                                            onCancel={(idOrder) => setShowMsg({...showMsg, status: true, data: idOrder, type: "cancel"})}
                                            sidebarOpen={sidebarOpen}
                                            onCompatible={(prdSel) => setShowCompatible({...showCompatible, status: true, element: prdSel})}
                                        />
                                    )) : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Orders;