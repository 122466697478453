import { useEffect, useState } from "react";
import { FiChevronLeft } from "react-icons/fi";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { alertOptions, removeAccents } from "../../../../utils/Utilities";
import { getInterchangeables, saveInterchangeable, updateInterchangeable } from "../../../../services/Interchangeable";
import { getProductEspecificationByNag, getProducts } from "../../../../services/Product";
import Loader from "../../../../components/loader/Loader";
import Navbar from "../../../../components/navbar/Navbar";
import Sidebar from "../../../../components/sidebar/Sidebar";
import TableInterchangeable from "./TableInterchangeable";
import Filters from "./Filters";
import FormInterchangeable from "./FormInterchangeable";
import ModalCompatible from "../ModalCompatible";
import ModalNags from "../ModalNags";

const Interchangeable = () => {
    const [loader, setLoader] = useState(false);
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const [isRefresh, setIsRefresh] = useState(false);
    const [isFilters, setIsFilters] = useState(false);
    const [showForm, setShowForm] = useState({ status: false, data: null});
    const [showCompatible, setShowCompatible] = useState({ status: false, element: null });
    const [showNags, setShowNags] = useState({ status: false, element: null, id: "" });
    const [interchanges, setInterchanges] = useState([]);
    const [interchangesUniv, setInterchangesUniv] = useState([]);
    const [productsOpts, setProductsOpts] = useState([]);

    const handleFilters = (opts, stsUpdt) => {
        setIsRefresh(stsUpdt);
        setIsFilters(false);
        if (opts.search === "" && opts.interchange === "" && opts.nags === "") {
            setInterchanges(interchangesUniv);
        } else {
            let interchangesFound = interchangesUniv;

            if (opts.interchange !== "") {
                let palabra = new RegExp(`${removeAccents(opts.interchange)}.*`, "i");
                interchangesFound = interchangesFound.filter(element => palabra.test(element?.index));
            }

            if (opts.nags !== "") {
                let palabra = new RegExp(`${removeAccents(opts.nags)}.*`, "i");
                interchangesFound = interchangesFound.filter(element => {
                    const foundNag = element.interchangeableNags.filter(elemAux => palabra.test(elemAux));
                    if (foundNag.length > 0) return element;
                });
            }

            if (opts.search !== "") {
                let palabra = new RegExp(`${removeAccents(opts.search)}.*`, "i");
                interchangesFound = interchangesFound.filter(element => {
                    const foundNag = element.interchangeableNags.filter(elemAux => palabra.test(elemAux));
                    const foundInterchange = element.compatible_vehicles.filter(elemFound => (
                        palabra.test(elemFound?.year) ||
                        palabra.test(removeAccents(elemFound?.model)) ||
                        palabra.test(removeAccents(elemFound?.brand)) ||
                        palabra.test(removeAccents(elemFound?.type_car)) ||
                        palabra.test(removeAccents(elemFound?.type_glass)) ||
                        palabra.test(removeAccents(
                            `${String(elemFound?.year).replace(/\s+/g, '').split(',').length > 1 ? `${String(elemFound?.year).replace(/\s+/g, '').split(',').sort()[0]} - ${String(elemFound?.year).replace(/\s+/g, '').split(',').sort()[Number(String(elemFound?.year).replace(/\s+/g, '').split(',').length-1)]}` : elemFound?.year}` +
                            ` ${elemFound?.brand} ${elemFound?.model} ${elemFound?.type_car}`
                        ))
                    ));
                    if (foundNag.length > 0 || foundInterchange.length > 0 || palabra.test(removeAccents(element?.description)) || palabra.test(element?.index)) {
                        return element;
                    }
                });
            }

            setInterchanges(interchangesFound);
        }
    }

    const getData = (stsRefresh) => {
        getInterchangeables().then(res => {
            if (res.status === 200) {
                let count = 0;
                const newInterchanges = res.data.map(elem => {
                    count += 1;
                    return {...elem, index: count};
                });
                setIsRefresh(stsRefresh);
                setIsFilters(!stsRefresh);
                setInterchanges(newInterchanges);
                setInterchangesUniv(newInterchanges);
            } else {
                setInterchanges([]);
                setInterchangesUniv([]);
            }
            setLoader(false);
            getOptions();
        }).catch(error => {
            setLoader(false);
            setInterchanges([]);
            setInterchangesUniv([]);
            toast.warning(error.response.data.message, alertOptions);
        });
    }

    const getOptions = () => {
        getProducts().then(res => {
            if (res.status === 200) {
                setProductsOpts(res.data);
            } else {
                setProductsOpts([]);
            }
        }).catch(() => setProductsOpts());
    }

    const handleCompatible = async (arrNags) => {
        const modelCompatible = { nags: arrNags[0], compatible_vehicles: arrNags };
        setShowCompatible({...showCompatible, status: true, element: modelCompatible});
    }

    const handleSave = (interchangeModel) => {
        setLoader(true);
        saveInterchangeable(interchangeModel).then(res => {
            if (res.status === 200) {
                toast.success("Interchange added successfully", alertOptions);
                setShowForm({...showForm, status: false, data: null});
                getData(true);
            } else {
                toast.warning(res.response.data.message, alertOptions);
            }
            setLoader(false);
        }).catch(error => {
            setLoader(false);
            toast.warning(error.response.data.message, alertOptions);
        });
    }

    const handleEdit = (interchangeModel, stsRefresh) => {
        setLoader(true);
        updateInterchangeable(interchangeModel._id, interchangeModel).then(res => {
            if (res.status === 200) {
                toast.success("Interchange updated successfully", alertOptions);
                setShowForm({...showForm, status: false, data: null});
                getData(stsRefresh);
            } else {
                toast.warning(res.response.data.message, alertOptions);
            }
            setLoader(false);
        }).catch(error => {
            setLoader(false);
            toast.warning(error.response.data.message, alertOptions);
        });
    }

    useEffect(() => {
        setLoader(true);
        getData(true);
    }, []);

    return (
        <>
            { loader ? <Loader /> : null }

            {
                showForm.status ?
                    <FormInterchangeable
                        productsOpts={productsOpts}
                        interchangeInfo={showForm.data}
                        onSave={handleSave}
                        onEdit={handleEdit}
                        onClose={() => setShowForm({...showForm, status: false, data: null})}
                        onCompatible={handleCompatible}
                    />
                : null
            }

            {
                showCompatible.status ?
                    <ModalCompatible
                        products={showCompatible.element}
                        onClose={() => setShowCompatible({...showCompatible, status: false, element: null})}
                    />
                : null
            }

            {
                showNags.status ?
                    <ModalNags
                        nags={showNags.element}
                        id={showNags.id}
                        onClose={() => setShowNags({...showNags, status: false, element: null, id: ""})}
                    />
                : null
            }

            <div>
                <div className="flex flex-row">
                    <div className="w-full bg-no-repeat bg-cover fixed z-10">
                        <Navbar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                    </div>
                </div>
                <div className="flex h-screen overflow-hidden">
                    <div className={`${sidebarOpen ? "w-[100vw] md:w-80 z-20" : "w-20 z-0"}`}>
                        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                    </div>
                    <div className="w-full overflow-auto flex-grow mt-[100px]">
                        <div className="space-y-5 px-3 py-5 md:px-8 md:py-8">
                            <div className="grid grid-cols-4 md:grid-cols-12 px-0 md:px-5">
                                <div>
                                    <Link to="/products">
                                        <button className="p-1 rounded-md" type="button">
                                            <span className="text-ag-secondary-dark text-3xl"><FiChevronLeft /></span>
                                        </button>
                                    </Link>
                                </div>
                                <div className="flex justify-center items-center col-span-2 md:col-span-11 text-center">
                                    <h1 className="text-[26px] md:text-xl lg:text-[26px] font-light text-ag-secondary md:mr-[8%]">
                                        Nags Interchanges
                                    </h1>
                                </div>
                                <div></div>
                            </div>
                            <div className="pt-3">
                                <Filters
                                    isFilters={isFilters}
                                    onFilters={handleFilters}
                                    onShowForm={() => setShowForm({...showForm, status: true, data: null})}
                                />
                            </div>
                            <div className="pt-3">
                                <TableInterchangeable
                                    isRefresh={isRefresh}
                                    onRefresh={setIsRefresh}
                                    arrInterchanges={interchanges}
                                    onCompatible={(prdSel) => setShowCompatible({...showCompatible, status: true, element: prdSel})}
                                    onNags={(prdSel, idNag) => setShowNags({...showNags, status: true, element: prdSel, id: idNag})}
                                    onEdit={(ngSel) => setShowForm({...showForm, status: true, data: ngSel})}
                                    onStatus={handleEdit}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Interchangeable;