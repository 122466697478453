import { IoMdImage } from "react-icons/io";

const WishListCard = ({ element, onAttend, onCompatible }) => {
    return (
        <div className="px-5 py-2 border border-zinc-400 rounded-xl">
            <div className="flex-wrap md:flex-nowrap flex justify-between items-center space-x-3">
                <div className="flex justify-center items-center">
                    <span className="text-ag-secondary-letter text-[50px]">
                        <IoMdImage />
                    </span>
                </div>
                <div className="w-full space-y-3">
                    <div className="text-left">
                        <div>
                            <p className="text-ag-secondary-letter text-sm">
                                {element?.nag}
                            </p>
                        </div>
                        <div>
                            {
                                element.compatible_vehicles.map((prodComp, i) => {
                                    if (i < 1) {
                                        return (
                                            <p className="text-ag-secondary-letter text-sm truncate" key={i} title={
                                                `${String(prodComp?.year).replace(/\s+/g, '').split(',').length > 1 ? `${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[0]} - ${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[Number(String(prodComp?.year).replace(/\s+/g, '').split(',').length - 1)]}` : prodComp?.year} ` +
                                                `${prodComp?.brand} ` +
                                                `${prodComp?.model} ` +
                                                `${prodComp?.type_car}`
                                            }>
                                                {String(prodComp?.year).replace(/\s+/g, '').split(',').length > 1 ? `${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[0]} - ${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[Number(String(prodComp?.year).replace(/\s+/g, '').split(',').length - 1)]}` : prodComp?.year}{' '}
                                                {prodComp?.brand}{' '}
                                                {prodComp?.model}{' '}
                                                {prodComp?.type_car}
                                            </p>
                                        )
                                    }
                                })
                            }
                            {
                                element.compatible_vehicles.length > 1 ?
                                    <p className="truncate text-ag-secondary-letter text-sm font-semibold cursor-pointer" onClick={() => onCompatible(element)}>
                                        See compatible vehicles +
                                    </p>
                                    : null
                            }
                        </div>
                    </div>
                </div>
                <div>
                    {/* <button className="px-5 py-2 bg-green-600 text-white text-xs rounded-xl" type="button" onClick={() => onAttend(element.productSpecification?._id)}>
                        Attend
                    </button> */}
                    <button className="px-5 py-2 bg-green-600 text-white text-xs rounded-xl" type="button" onClick={() => onAttend(element?.nag)}>
                        Attend
                    </button>
                    <div className="pt-2 flex justify-center items-center space-x-3">
                        <p className="text-ag-secondary-letter text-xs font-semibold">Times:</p>
                        <p className="text-ag-secondary-letter text-xs">{element?.count}</p>
                    </div>
                </div>
            </div>
            {/* <div className="pt-1">
                <span className="text-ag-secondary-letter text-xs italic">
                    { element?.productSpecification?.ad_info_a ? element?.productSpecification?.ad_info_a : "Without features..." }
                </span>
            </div> */}
        </div>
    );
}

export default WishListCard;