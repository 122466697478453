import { useState } from "react";
import { IoIosArrowUp, IoMdImage } from "react-icons/io";
import { formatDateWordEng } from "../../../utils/Utilities";
import { getInventoryAddId } from "../../../services/Inventory";

const OrderCard = ({ order, onDetail, onShowForm, onMark, onPurchase, onCancel, sidebarOpen, onCompatible }) => {
    const [showDetail, setShowDetail] = useState(false);

    const createCart = async () => {
        let arrProducts = [];
        for (let i = 0; i < order?.purchasedProducts.length; i++) {
            const whRes = await getInventoryAddId(order?.purchasedProducts[i].productInventory?._id);
            const whValue = whRes.status === 200 ? whRes.data?.warehouse?._id : "";
            arrProducts.push({
                warehouse: { _id: whValue },
                productInventory: { _id: order?.purchasedProducts[i]?.productInventory?._id },
                quantity: order?.purchasedProducts[i]?.quantity,
                price: order?.purchasedProducts[i]?.price
            });
        }
        onPurchase(arrProducts);
    }

    return (
        <div className="border border-ag-secondary rounded-xl">
            <div className="flex justify-between items-center bg-ag-secondary-light rounded-t-xl">
                <div className="px-5 py-2 text-white">
                    <span className="text-sm">{ formatDateWordEng(order?.createdAt) }</span>
                </div>
                <div className="px-5 md:px-10 py-2 bg-ag-secondary text-white rounded-t-xl cursor-pointer" onClick={() => createCart()}>
                    <span className="text-sm">Buy Again</span>
                </div>
            </div>
            <div className="lg:flex lg:justify-between lg:items-end">
                <div className="flex justify-center items-center border border-ag-secondary m-3">
                    {
                        order?.purchasedProducts[0]?.productInventory?.product?.productImages.length > 0 ?
                            <div className="w-[190px] h-[120px] flex justify-center items-center p-1">
                                <img
                                    className="w-full h-full object-contain"
                                    src={order?.purchasedProducts[0]?.productInventory?.product?.productImages[0]}
                                />
                            </div>
                        :
                            <span className="px-16 py-8 text-[60px] text-ag-secondary-letter">
                                <IoMdImage />
                            </span>
                    }
                </div>
                <div className="w-full space-y-0 px-5 lg:px-0 py-5">
                    <div>
                        <div className="cursor-pointer flex items-center space-x-2" onClick={() => onDetail(order?.purchasedProducts)}>
                            <p className="text-base">
                                { order ? order?.purchasedProducts[0]?.productInventory?.product?.nags : null }
                            </p>
                            {
                                order?.purchasedProducts?.length > 1 ?
                                    <span className="text-ag-secondary-letter text-xs italic">{' ('}Click to show more products{')'}</span>
                                : null
                            }
                        </div>
                        {
                            order?.purchasedProducts[0]?.productInventory?.product.compatible_vehicles.map((prodComp, i) => {
                                if (i < 1) {
                                    return (
                                        <p className="text-base cursor-pointer" onClick={() => onDetail(order?.purchasedProducts)} key={i} title={
                                            `${String(prodComp?.year).replace(/\s+/g, '').split(',').length > 1 ? `${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[0]} - ${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[Number(String(prodComp?.year).replace(/\s+/g, '').split(',').length-1)]}` : prodComp?.year} ` +
                                            `${prodComp?.brand} ` +
                                            `${prodComp?.model} ` +
                                            `${prodComp?.type_car}`
                                        }>
                                            { String(prodComp?.year).replace(/\s+/g, '').split(',').length > 1 ? `${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[0]} - ${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[Number(String(prodComp?.year).replace(/\s+/g, '').split(',').length-1)]}` : prodComp?.year }{' '}
                                            { prodComp?.brand }{' '}
                                            { prodComp?.model }{' '}
                                            { prodComp?.type_car }
                                        </p>
                                    )
                                }
                            })
                        }
                        {
                            order?.purchasedProducts[0]?.productInventory?.product.compatible_vehicles.length > 1 ?
                                <p className="font-semibold cursor-pointer" onClick={() => onCompatible(order?.purchasedProducts[0]?.productInventory?.product)}>
                                    See compatible vehicles +
                                </p>
                            : null
                        }
                    </div>
                    <div className="flex justify-start items-center space-x-3">
                        <div className="flex justify-center lg:justify-start items-center pt-2 space-x-2">
                            <span className="text-ag-secondary-letter text-xs italic">{ order?.features }</span>
                        </div>
                    </div>
                    {
                        order?.status ?
                            <div className="pt-3 font-semibold">
                                <p className="text-base">
                                    {
                                        `${order?.status === "pending" ? "Waiting"
                                    :
                                        order?.status === "inprocess" ? "In process"
                                    :
                                        order?.status === "paid" ? "Delivered"
                                    :    
                                        `${ order?.status[0].toUpperCase() + order?.status.substring(1)}`} on 
                                            ${ formatDateWordEng(order?.order?.updatedAt) }`
                                    }
                                </p>
                            </div>
                        : null
                    }
                </div>
                {
                    showDetail ?
                        (order?.status === "canceled" || order?.status === "finished" || (order?.payment?.userAddress && order?.status === "inprocess")) ? null : (order?.status !== "delivered" && order?.status !== "paid") ?
                            <div className="w-[90%] lg:w-[30%] space-y-3 py-5 mx-3">
                                <button className="w-full bg-ag-primary-light text-white text-lg px-3 py-2 rounded-xl" type="button" onClick={() => onCancel(order?._id)}>
                                    Cancel
                                </button>
                            </div>
                        :
                            <div className="w-[90%] lg:w-[30%] space-y-3 py-5 mx-3">
                                <button className="w-full bg-green-600 text-white text-lg px-3 py-2 rounded-xl" type="button" onClick={() => onMark(order?._id)}>
                                    Mark done
                                </button>
                                {
                                    !order?.note ?
                                        <button className="w-full bg-ag-secondary text-white text-lg px-3 py-2 rounded-xl" type="button" onClick={() => onShowForm(order?._id)}>
                                            Note
                                        </button>
                                    : null
                                }
                            </div>
                    :
                        <div className={`w-full ${sidebarOpen ? "lg:w-[260px] xl:w-[220px]" : "lg:w-[220px] xl:w-[200px]" }`}>
                            <button className="w-full bg-ag-secondary text-white text-lg px-5 py-3 rounded-r-xl" type="button" onClick={() => setShowDetail(true)}>
                                See detail
                            </button>
                        </div>
                }
            </div>
            {
                showDetail ?
                    <div className>
                        <div className="px-3">
                            <p className="text-ag-secondary-letter text-xs">We deliver your package to the address:</p>
                            {
                                order?.payment?.userAddress ?
                                    <>
                                        <p className="truncate text-ag-secondary-letter text-xs">
                                            { order?.payment?.userAddress?.street }, { order?.payment?.userAddress?.city },{' '}
                                            { order?.payment?.userAddress?.suburb }, { order?.payment?.userAddress?.postalCode }
                                        </p>
                                        <p className="truncate text-ag-secondary-letter text-xs">
                                            { order?.payment?.userAddress?.references }
                                        </p>
                                    </>
                                :
                                    <>
                                        <p className="text-ag-secondary-letter text-xs">
                                            { order?.payment?.warehouseAddress?.street }, { order?.payment?.warehouseAddress?.city },{' '}
                                            { order?.payment?.warehouseAddress?.suburb }, { order?.payment?.warehouseAddress?.postalCode }
                                        </p>
                                        <p className="text-ag-secondary-letter text-xs">
                                            { order?.payment?.warehouseAddress?.references }
                                        </p>
                                    </>
                            }
                        </div>
                        <div className="px-3 pt-5">
                            <p className="text-ag-secondary-letter text-xs">
                                Order number: { String(order?.order?._id).substring(String(order?.order?._id).length - 5).toLocaleUpperCase() }
                            </p>
                        </div>
                        <div className="px-10 pt-3">
                            <div className={
                                `${order?.status === "pending" ? "w-[3%]" : order?.status === "attend" ? "w-1/3" : 
                                order?.status === "inprocess" ? "w-2/3" : (order?.status === "delivered" || order?.status === "finished") ? 
                                "w-full" : "" } ${order?.status === "canceled" ? "bg-ag-primary-light" : order?.status === "finished" ?
                                "bg-blue-500" : "bg-green-600"} p-3`
                            }></div>
                        </div>
                        <div className="flex justify-between space-x-3 p-3">
                            <p className="text-ag-secondary-letter text-xs">Waiting</p>
                            <p className="text-ag-secondary-letter text-xs">Attended</p>
                            <p className="text-ag-secondary-letter text-xs">In process</p>
                            <p className="text-ag-secondary-letter text-xs">Delivered</p>
                        </div>
                        {
                            showDetail && (order.status === "paid" || order.status === "delivered") ?
                                <div className="px-5 pt-3 text-right">
                                    <span className="text-ag-secondary-letter text-xs italic">
                                        *** If you wish to return the product, please contact the branch.
                                    </span>
                                </div>
                            : null
                        }
                        <div className="flex justify-center py-2 bg-ag-secondary-light text-white rounded-b-xl cursor-pointer" onClick={() => setShowDetail(false)}>
                            <span className="text-2xl font-semibold"><IoIosArrowUp /></span>
                        </div>
                    </div>
                : null
            }
        </div>
    );
}

export default OrderCard;