import { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getVendors } from "../../../../services/Vendor";
import { validateProducts } from "../../../../utils/Validators";
import { getAllFeaturesWC } from "../../../../utils/Utilities";
import { PermitsContext } from "../../../../services/Permits";
import { getProduct, saveProduct, updateProduct, searchProductEspecification, getCompatiblesVehicles } from "../../../../services/Product";
import { searchInterchangrablesByNag } from "../../../../services/Interchangeable";
import { alertOptions, formatDate, getHoursMinutesByDay } from "../../../../utils/Utilities";
import { AiFillCloseCircle } from "react-icons/ai";
import { IoMdImage, IoMdSearch } from "react-icons/io";
import { fileUpload } from "../../../../services/File";
import { toast } from "react-toastify";
import ProductEspecification from "./ProductEspecification";
import Loader from "../../../../components/loader/Loader";
import IconPlinkintong from "../../../../assets/icons/logo_plinkintong_green.png";
import PricePlinkington from "../../../fragments/PricePlinkington";
import TableCompatibleVehicles from "./TableCompatibleVehicles";
import TableInterchanges from "./TableInterchanges";
import ModalCompatibleWD from "../ModalCompatibleWD";


const FormProductContent = ({ isModal, productSelect, onLoader, onProduct }) => {
    const navigate = useNavigate();
    const params = useParams();
    const { permits, dataSession } = useContext(PermitsContext);

    const [loader, setLoader] = useState(false);
    const [showTable, setShowTable] = useState(false);
    const [showPrice, setShowPrice] = useState(false);
    const [showCompatible, setShowCompatible] = useState({ status: false, element: null });
    const [canEditBarcode, setCanEditBarcode] = useState(false);
    const [serviceTime, setServiceTime] = useState({ hours: "00", minutes: "00" });
    const [dataUpdate, setDataUpdate] = useState({ by: "", lastUpdate: "" });
    const [vendorsStr, setVendorsStr] = useState("");
    const [vendorOptions, setVendorOptions] = useState([]);
    const [hoursOptions, setHoursOptions] = useState([]);
    const [minutesOptions, setMinutesOptions] = useState([]);
    const [compatibleVehicles, setCompatibleVehicles] = useState([]);
    const [interchanges, setInterchanges] = useState([]);

    const [product, setProduct] = useState({
        id: "",
        status: true,
        name: "",
        nags: "",
        price: "",
        serviceTime: "",
        barcode1: "",
        barcode2: "",
        totalQuantity: "",
        by: "",
        vendors: [],
        features: "",
        description: "",
        note: "",
        productImages: []
    });

    const [imageUpload, setImageUpload] = useState([
        { name: "", file: null },
        { name: "", file: null },
        { name: "", file: null },
        { name: "", file: null },
        { name: "", file: null },
        { name: "", file: null },
        { name: "", file: null },
        { name: "", file: null },
        { name: "", file: null },
        { name: "", file: null },
        { name: "", file: null },
        { name: "", file: null }
    ]);

    const clearData = () => {
        setProduct({...product,
            id: "",
            status: true,
            name: "",
            nags: "",
            year: "",
            price: "",
            serviceTime: "",
            barcode1: "",
            barcode2: "",
            totalQuantity: "",
            by: "",
            vendors: [],
            features: "",
            description: "",
            note: "",
            productImages: []
        });

        setImageUpload([
            { name: "", file: null },
            { name: "", file: null },
            { name: "", file: null },
            { name: "", file: null },
            { name: "", file: null },
            { name: "", file: null },
            { name: "", file: null },
            { name: "", file: null },
            { name: "", file: null },
            { name: "", file: null },
            { name: "", file: null },
            { name: "", file: null }
        ]);

        const newVendorsOptions = vendorOptions.map((voe) => {
            return { label: voe.label, value: voe.value, selected: false}
        });
        setVendorOptions(newVendorsOptions);
    }

    const deleteArrImage= (index) => {
        const newImageUpload = imageUpload.map((imgEle, i) => {
            if (index === i) {
                return { name: "", file: null }
            } else {
                return { name: imgEle.name, file: imgEle.file }
            }
        });
        setImageUpload(newImageUpload);
        document.getElementById(`productImages_${index}`).value = "";
    }

    const handleUpload = (evt, index) => {
        if (evt.currentTarget.files[0].type !== "image/png" && evt.currentTarget.files[0].type !== "image/jpg" && evt.currentTarget.files[0].type !== "image/jpeg") {
            toast.warning("It is not possible to upload a file other than png, jpg or jpeg", alertOptions);
        } else {
            const file = evt.currentTarget.files;
            if (file !== null) {
                const newImageUpload = imageUpload.map((imgEle, i) => {
                    if (index === i) {
                        return { name: file[0].name, file: file[0] }
                    } else {
                        return { name: imgEle.name, file: imgEle.file }
                    }
                });
                setImageUpload(newImageUpload);
            }
        }
    }

    const getAllFeatures = (productEspecifications) => {
        let features = `${productEspecifications.antena === "Y" ? "Antena, " : ""}` +
        `${productEspecifications.encapsulated === "Y" ? "Encapsulated, " : ""}` +
        `${productEspecifications.heated === "Y" ? "Heated, " : ""}` +
        `${productEspecifications.hud === "Y" ? "HUD, " : ""}` +
        `${productEspecifications.slider === "Y" ? "Slider, " : ""}` +
        `${productEspecifications.solar === "Y" ? "Solar, " : ""}`;
        const featuresAux = getAllFeaturesWC();
        let distinctFeatures = [];
        for (let i = 0; i < featuresAux.length; i++) {
            if ((productEspecifications.ad_info_a).toLowerCase().includes(String(featuresAux[i].value).toLowerCase()) === true) {
                const foundFeatures = distinctFeatures.find(distFeatElem => distFeatElem === featuresAux[i].value);
                if (!foundFeatures) distinctFeatures.push(featuresAux[i].value);
            }
        }
        distinctFeatures.forEach(element => {
            features = features + element + ', ';
        });
        
        let featuresFinal= features.trim();
        if (featuresFinal.length > 0) {
            featuresFinal=featuresFinal.slice(0, featuresFinal.length-1)
        }
        return featuresFinal
    }

    const handleFillForm = async (prdSel) => {
        setShowTable(false);

        const valueDecimal = String(prdSel.labor_time).split('.').pop();
        const valueComplete = String(Math.trunc(prdSel.labor_time));

        setServiceTime({
            hours: valueComplete.length === 1 ? `0${valueComplete}` : valueComplete,
            minutes: valueDecimal.length === 1 ? `${valueDecimal}0` : valueDecimal
        });



        const yearSelected = prdSel.year.split(",");
        yearSelected.sort((a, b) => b - a);
        const numbers = yearSelected.map(numero => parseInt(numero.trim()));
        const bigger  = Math.max(...numbers);
        const minor = Math.min(...numbers);
        let AllFeatures = getAllFeatures(prdSel);
        await getInterchanges(prdSel.nag);
        setProduct({...product,
            name: '(' + minor + ' - ' + bigger  + ') ' + prdSel.brand + ' ' + prdSel.model + ' ' + prdSel.type_car + ' ' + prdSel.type_glass,
            nags: prdSel.nag,
            price: prdSel.price,
            description: `${prdSel.color} (${prdSel.width}x${prdSel.height})` +
            `${prdSel.antena === "Y" ? " • Antena " : ""}` +
            `${prdSel.encapsulated === "Y" ? " • Encapsulated " : ""}` +
            `${prdSel.heated === "Y" ? " • Heated " : ""}` +
            `${prdSel.hud === "Y" ? " • HUD " : ""}` +
            `${prdSel.slider === "Y" ? " • Slider " : ""}` +
            `${prdSel.solar === "Y" ? " • Solar " : ""}` +
            `${prdSel.ad_info_a}`,
            features: AllFeatures
        });

        try {
            const foundCompProd = await searchProductEspecification({ nag: prdSel.nag, excludePriceZero: false });
            if (foundCompProd.status === 200) setCompatibleVehicles(foundCompProd.data);
        } catch (error) {
            setCompatibleVehicles([]);
        }
    }

    const handleChange = (evt) => {
        if (evt.currentTarget.name === "totalQuantity") {
            const pattern = new RegExp(/^[0-9\s]+$/g);
            if (pattern.test(evt.currentTarget.value) || evt.currentTarget.value === "") {
                setProduct({...product, [evt.currentTarget.name]: evt.currentTarget.value});
            }
        } else if (evt.currentTarget.name === "price") {
            const pattern = new RegExp(/^\d*\.?\d*$/);
            if (pattern.test(evt.currentTarget.value) || evt.currentTarget.value === "") {
                setProduct({...product, [evt.currentTarget.name]: evt.currentTarget.value});
            }
        } else {
            if (evt.currentTarget.value === "default") {
                setProduct({...product, [evt.currentTarget.name]: ""});
            } else {
                setProduct({...product, [evt.currentTarget.name]: evt.currentTarget.value});
            }
        }
    }
    const handleSubmitModal = (e) => {
        e.preventDefault();
 
        const foundVendors = vendorOptions.filter(vendorElement => vendorElement.selected);
        if (
            product.name !== "" && product.nags !== "" && product.price !== "" && product.barcode1 !== "" && product.barcode2 !== "" &&
            product.glassType !== "" && product.totalQuantity !== "" && product.description !== "" &&
            (serviceTime.hours !== "00" || serviceTime.minutes !== "00") && foundVendors.length > 0
        ) {
            let vendorsValid = [];
            for (let i = 0; i < vendorOptions.length; i++) {
                if (vendorOptions[i].selected) vendorsValid.push({ _id: vendorOptions[i].value });
            }
 
            const productModel = {
                status: product.status,
                name: product.name,
                nags: product.nags,
                price: product.price,
                serviceTime: `${serviceTime.hours}.${serviceTime.minutes}`,
                barcode1: product.barcode1,
                barcode2: product.barcode2,
                glassType: product.glassType,
                totalQuantity: product.totalQuantity,
                by: { _id: dataSession._id },
                vendors: vendorsValid,
                features: product.features,
                description: product.description,
                note: product.note,
                productImages: product.productImages
            }
 
            const result = validateProducts(productModel);
            if (result.status) {
                onLoader(true);
                saveProduct(productModel).then(res => {
                    if (res.status === 200) {
                        onLoader(false);
                        onProduct({...res.data, whCurrent: productSelect?.whCurrent});
                    } else {
                        onLoader(false);
                        toast.warning(res.response.data.message, alertOptions);
                    }
                }).catch(error => {
                    onLoader(false);
                    toast.warning(error.response.data.message, alertOptions);
                });
            } else {
                toast.warning(result.msg, alertOptions);
            }
        } else {
            toast.info("Please fill in all the fields of the form", alertOptions);
        }
    }

    const handleSubmit = async (evt) => {
        evt.preventDefault();
        const foundVendors = vendorOptions.filter(vendorElement => vendorElement.selected);
        if (
            product.name !== "" && product.nags !== "" && product.price !== "" && product.barcode1 !== "" && 
            product.barcode2 !== "" && product.totalQuantity !== "" && product.description !== "" && 
            (serviceTime.hours !== "00" || serviceTime.minutes !== "00") && foundVendors.length > 0
        ) {
            setLoader(true);
            let vendorsValid = [];
            for (let i = 0; i < vendorOptions.length; i++) {
                if (vendorOptions[i].selected) {
                    vendorsValid.push({ _id: vendorOptions[i].value });
                }
            }

            const productModel = {
                status: product.status,
                name: product.name,
                nags: product.nags,
                price: product.price,
                serviceTime: `${serviceTime.hours}.${serviceTime.minutes}`,
                barcode1: product.barcode1,
                barcode2: product.barcode2,
                totalQuantity: product.totalQuantity,
                vendors: vendorsValid,
                features: product.features,
                description: product.description,
                note: product.note,
                by: { _id: dataSession._id },
                productImages: product.productImages
            }

            const result = validateProducts(productModel);
            if (result.status) {
                // Upload file
                if (
                    imageUpload[0].name !== "" || imageUpload[1].name !== "" || imageUpload[2].name !== "" ||
                    imageUpload[3].name !== "" || imageUpload[4].name !== "" || imageUpload[5].name !== "" ||
                    imageUpload[6].name !== "" || imageUpload[7].name !== "" || imageUpload[8].name !== "" ||
                    imageUpload[9].name !== "" || imageUpload[10].name !== "" || imageUpload[11].name !== ""
                ) {
                    let productImagesValid = [];
                    for (let i = 0; i < imageUpload.length; i++) {
                        if (imageUpload[i].file !== null && imageUpload[i].name !== "") {
                            let bodyFormData = new FormData();
                            bodyFormData.append('file', imageUpload[i].file);
                            const valueUpload = await fileUpload(bodyFormData).then(res => {
                                if (res.status === 200) return res.data;
                                return "";
                            });
                            productImagesValid.push(valueUpload);
                        } else if (imageUpload[i].name !== "") {
                            productImagesValid.push(imageUpload[i].name);
                        }
                    }
                    productModel.productImages = productImagesValid;
                }

                if (params && params.id) {
                    productModel._id = params.id;
                    updateProduct(productModel._id, productModel).then(res => {
                        if (res.status === 200) {
                            toast.success("Product updated successfully", alertOptions);
                            setLoader(false);
                            clearData();
                            navigate("/products");
                        } else {
                            setLoader(false);
                            toast.warning(res.response.data.message, alertOptions);
                        }
                    }).catch(error => {
                        setLoader(false);
                        toast.warning(error.response.data.message, alertOptions);
                    });
                } else {
                    saveProduct(productModel).then(res => {
                        if (res.status === 200) {
                            toast.success("Product added successfully", alertOptions);
                            setLoader(false);
                            clearData();
                            navigate("/products");
                        } else {
                            setLoader(false);
                            toast.warning(res.response.data.message, alertOptions);
                        }
                    }).catch(error => {
                        setLoader(false);
                        toast.warning(error.response.data.message, alertOptions);
                    });
                }
            } else {
                setLoader(false);
                toast.warning(result.msg, alertOptions);
            }
        } else {
            const checkStr = `
                ${product.name === "" ? " • Name" : ""} ${product.nags === "" ? " • Nags" : ""} 
                ${product.price === "" ? " • Price" : ""} ${product.barcode1 === "" ? " • Barcode 1" : ""}
                ${product.barcode2 === "" ? " • Barcode 2" : ""} ${product.glassType === "" ? " • Glass type" : ""} 
                ${product.totalQuantity === "" ? " • Total quantity" : ""}
                ${product.description === "" ? " • Description" : ""} ${foundVendors.length === 0 ? " • Vendors" : ""}
                ${(serviceTime.hours === "00" || serviceTime.minutes === "00") ? " • Service time" : ""}
            `;
            toast.info(`Please fill in all the fields of the form. Review the following fields: ${checkStr}`, alertOptions);
        }
    }

    const handleChangeVendors = (evt) => {
        const newOptions = vendorOptions.map((element, indn) => {
            if (element.value === evt.currentTarget.value) {
                return  { value: element.value, label: element.label, selected: !element.selected }
            }
            return element;
        });

        setVendorsStr(newOptions.filter(elem => elem?.selected).map(elem => elem.label));
        setVendorOptions(newOptions);
    }

    const getOptions = (array_vendors, vendors_current) => {
        let vendors = [];
        for (let i = 0; i < array_vendors.length; i++) {
            const vendorFound = vendors.find(element => element._id === array_vendors[i]._id);
            const vendorCurrentFound = vendors_current.find(element => element._id === array_vendors[i]._id);

            if (!vendorFound) {
                vendors.push({
                    label: array_vendors[i].name,
                    value: array_vendors[i]._id,
                    selected: vendorCurrentFound ? true : false
                });
            }
        }
        setVendorsStr(vendors.filter(elem => elem?.selected).map(elem => elem.label));
        setVendorOptions(vendors);
    }

    const getInterchanges = (nagProduct) => {
        searchInterchangrablesByNag(nagProduct).then(res => {
            if (res.status === 200) {
                setInterchanges(res.data);
            } else {
                setInterchanges([]);
            }
        }).catch(() => setInterchanges([]));
    }

    const handlePrice = () => {
        if (product.nags !== "") {
            setShowPrice(true)
        } else {
            toast.warning("Please select a nag first", alertOptions);
        }
    }

    const processProductSpecification = (dataFound) => {
        if (!dataFound) return;
    
        const valueDecimal = String(dataFound.labor_time).split('.').pop();
        const valueComplete = String(Math.trunc(dataFound.labor_time));
    
        const hours = valueComplete.length === 1 ? `0${valueComplete}` : valueComplete;
        const minutes = valueDecimal.length === 1 ? `${valueDecimal}0` : valueDecimal;
    
        const features = getAllFeatures(dataFound);
        const yearSelected = dataFound.year.split(",");
        yearSelected.sort((a, b) => b - a);
        const numbers = yearSelected.map(numero => parseInt(numero.trim()));
        const bigger  = Math.max(...numbers);
        const minor = Math.min(...numbers);
        setServiceTime({ hours, minutes });
        setProduct({
            ...product,
            name: '(' + minor + ' - ' + bigger  + ') ' + dataFound.brand + ' ' + dataFound.model + ' ' + dataFound.type_car + ' ' + dataFound.type_glass,
            description: `${dataFound.color} (${dataFound.width}x${dataFound.height})` +
            `${dataFound.antena === "Y" ? " • Antena " : ""}` +
            `${dataFound.encapsulated === "Y" ? " • Encapsulated " : ""}` +
            `${dataFound.heated === "Y" ? " • Heated " : ""}` +
            `${dataFound.hud === "Y" ? " • HUD " : ""}` +
            `${dataFound.slider === "Y" ? " • Slider " : ""}` +
            `${dataFound.solar === "Y" ? " • Solar " : ""}` +
            `${dataFound.ad_info_a}`,
            nags: dataFound.nag,
            price: dataFound.price,
            glassType: dataFound.type_glass,
            features
        });
    };

    const initializeTimeOptions = () => {
        const resultTimeOptions = getHoursMinutesByDay(1);
        setHoursOptions(resultTimeOptions[0]);
        setMinutesOptions(resultTimeOptions[1]);
    };
    
    const fetchProductSpecification = () => {
        if (productSelect) {
            searchProductEspecification({ nag: productSelect.nag }).then(resPE => {
                const dataFound = resPE.status === 200 ? resPE.data[0] : null;
                processProductSpecification(dataFound);
            });
        }
    };
    
    const fetchProductData = async (id) => {
        try {
            const res = await getProduct(id);
            if (res.status === 200) {
                setServiceTime({
                    hours: String(res.data?.serviceTime).substring(0, 2),
                    minutes: String(res.data?.serviceTime).substring(3, 5)
                });
    
                const responseCompatibleVehicles = await getCompatiblesVehicles(res.data.nags);
                setCompatibleVehicles(responseCompatibleVehicles.data);
                setCanEditBarcode(true);
                setProduct({
                    ...product,
                    id: res.data._id,
                    status: res.data.status,
                    name: res.data.name,
                    nags: res.data.nags,
                    price: res.data.price,
                    serviceTime: "",
                    barcode1: res.data.barcode1,
                    barcode2: res.data.barcode2,
                    totalQuantity: res.data.totalQuantity,
                    by: res.data.by,
                    vendors: [],
                    features: res.data.features,
                    description: res.data.description,
                    note: res.data.note,
                    productImages: res.data.productImages,
                });
    
                const newImageUpload = [...imageUpload];
                res.data.productImages.forEach((image, i) => {
                    if (image !== "") {
                        newImageUpload[i].name = image;
                    }
                });
                setImageUpload(newImageUpload);
    
                setDataUpdate({
                    ...dataUpdate,
                    by: res.data.by ? `${res.data.by.names} ${res.data.by.lastNames}` : `${dataSession.names} ${dataSession.lastNames}`,
                    lastUpdate: formatDate(res.data.lastUpdate)
                });
    
                getInterchanges(res.data.nags);
                const resVen = await getVendors();
                if (resVen.status === 200 && resVen.data.length > 0) {
                    getOptions(resVen.data, res.data.vendors);
                } else {
                    setVendorOptions([]);
                }
                setLoader(false);
            }
        } catch (error) {
        }
    };
    
    const initializeProductData = () => {
        if (permits['PRODUCTS']['ADD'] || permits['PRODUCTS']['EDIT']) {
            setLoader(true);
            if (params && params.id) {
                fetchProductData(params.id);
            } else {
                setDataUpdate({
                    ...dataUpdate,
                    by: `${dataSession.names} ${dataSession.lastNames}`,
                    lastUpdate: "-----"
                });
    
                getVendors().then(res => {
                    if (res.status === 200 && res.data.length > 0) {
                        getOptions(res.data, []);
                    } else {
                        setVendorOptions([]);
                    }
                    setLoader(false);
                });
            }
        } else {
            navigate("/products");
        }
    };
    
    useEffect(() => {
        initializeTimeOptions();
        fetchProductSpecification();
        initializeProductData();
    }, []);
    
    

    const buttons = () => {
        if (isModal) {
            return (
               <button className="mt-4 col-end-2 w-full bg-green-600 text-white text-xs lg:text-base p-3 rounded-xl" type="button" onClick={handleSubmitModal}>
                    Save
                </button>
            );
        }

        return (
            <div className="space-y-[14px]">
                <div>
                    <button className="w-full bg-green-600 text-white text-xs lg:text-base p-3 rounded-xl" type="button" onClick={handleSubmit}>
                        Save
                    </button>
                </div>
                <div>
                    <Link to="/inventory">
                        <button className="w-full bg-ag-secondary text-white text-xs lg:text-base p-3 rounded-xl" type="button">
                            See inventory
                        </button>
                    </Link>
                </div>
                <div>
                    <Link to={!permits['PRODUCTS']['HISTORY'] ? "" : "/products/history"}>
                        <button className="w-full bg-ag-secondary text-white text-xs lg:text-base p-3 rounded-xl disabled:bg-zinc-200" type="button" disabled={!permits['PRODUCTS']['HISTORY']}>
                            Price History
                        </button>
                    </Link>
                </div>
                <div>
                    <Link to="/products">
                        <button className="w-full bg-ag-secondary text-white text-xs lg:text-base p-3 rounded-xl" type="button">
                            Back
                        </button>
                    </Link>
                </div>
                <div>
                    <button
                        className="w-full bg-[#199B5D] text-white text-xs lg:text-base p-[11px] rounded-xl"
                        type="button"
                        onClick={handlePrice}
                    >
                        <img className="w-32 m-auto" src={IconPlinkintong} alt='' />
                    </button>
                </div>
            </div>
        );
    }

    const renderBottomInfo =()=>{
        if (isModal) {
            return null;
        }

        return(
            <div className="md:flex md:justify-between md:items-center p-5 md:p-3 space-y-2 md:space-y-0">
                <div className="text-ag-secondary">
                    <span className="text-sm font-bold">Last update: </span>
                    <span className="text-sm">{ dataUpdate.lastUpdate }</span>
                </div>
                <div className="text-ag-secondary">
                    <span className="text-sm font-bold">By: </span>
                    <span className="text-sm">{ dataUpdate.by }</span>
                </div>
            </div>
        );
    }

    const renderFormContent = () => {
        return (
            <>
                { buttons() }
                <div className="mt-2 md:mt-0 md:order-first md:col-span-4">
                    <form onSubmit={handleSubmit}>
                        <div className="space-y-3">
                        <div className="w-full flex justify-center items-center">
                                <span className="w-[30%] md:flex lg:w-[22%] xl:w-[18%] bg-ag-secondary text-xs xl:text-base text-white text-center border border-gray-500 py-3 rounded-l-xl flex justify-center items-center">
                                    Specifications
                                </span>
                                <div className="w-[70%] lg:w-[78%] xl:w-[82%] flex items-center border border-gray-500 rounded-r-xl">
                                    <input
                                        className="w-[80%] lg:w-[90%] xl:w-[95%] text-ag-secondary-letter p-2 xl:p-3 disabled:bg-zinc-200"
                                        type="text"
                                        name="productSpecification"
                                        disabled={true}
                                    />
                                    <button
                                        className="w-[20%] lg:w-[10%] xl:w-[5%] text-2xl bg-ag-secondary text-white text-center px-2 py-2 xl:py-3 rounded-r-xl flex justify-center items-center"
                                        type="button"
                                        onClick={() => setShowTable(true)}
                                    >
                                        <IoMdSearch />
                                    </button>
                                </div>
                        </div>
                            <div className="w-full flex justify-center items-center">
                                <span
                                    className="w-[30%] lg:w-[22%] xl:w-[18%] bg-ag-secondary text-xs xl:text-base text-white text-center border border-gray-500 py-3 rounded-l-xl"
                                >
                                    Product name
                                </span>
                                <input
                                    className="w-[70%] lg:w-[78%] xl:w-[82%] text-ag-secondary-letter border border-gray-500 p-2 xl:p-3 rounded-r-xl truncate"
                                    type="text"
                                    name="name"
                                    onChange={handleChange}
                                    value={product.name}
                                />
                            </div>
                            <div className="w-full flex justify-center items-center">
                                <span
                                    className="w-[30%] lg:w-[22%] xl:w-[18%] bg-ag-secondary text-xs xl:text-base text-white text-center border border-gray-500 py-3 rounded-l-xl"
                                >
                                    Nags
                                </span>
                                <input
                                    className="w-[70%] lg:w-[78%] xl:w-[82%] text-ag-secondary-letter border border-gray-500 p-2 xl:p-3 rounded-r-xl truncate"
                                    type="text"
                                    name="nags"
                                    onChange={handleChange}
                                    value={product.nags}
                                    maxLength={40}
                                />
                            </div>
                            <div className="grid gap-3 lg:grid-cols-2">
                                <div className="w-full flex justify-center items-center">
                                    <span
                                        className="w-[30%] lg:w-[45%] xl:w-[37%] bg-ag-secondary text-xs xl:text-base text-white text-center border border-gray-500 py-3 rounded-l-xl"
                                    >
                                        Nags Price
                                    </span>
                                    <input
                                        className="w-[70%] lg:w-[55%] xl:w-[63%] text-ag-secondary-letter border border-gray-500 p-2 xl:p-3 rounded-r-xl truncate"
                                        type="text"
                                        name="price"
                                        onChange={handleChange}
                                        value={product.price}
                                        maxLength={12}
                                    />
                                </div>
                                <div className="w-full flex justify-center items-center">
                                    <span
                                        className="w-[30%] lg:w-[45%] xl:w-[37%] bg-ag-secondary text-xs xl:text-base text-white text-center border border-gray-500 py-3 rounded-l-xl"
                                    >
                                        Barcode 1
                                    </span>
                                    <input
                                        className="w-[70%] lg:w-[55%] xl:w-[63%] text-ag-secondary-letter border border-gray-500 p-2 xl:p-3 rounded-r-xl truncate disabled:bg-zinc-200"
                                        type="text"
                                        name="barcode1"
                                        onChange={handleChange}
                                        value={product.barcode1}
                                        disabled={canEditBarcode}
                                        maxLength={40}
                                    />
                                </div>
                            </div>
                            <div className="grid gap-3 lg:grid-cols-2">
                                <div className="w-full flex justify-center items-center">
                                    <span
                                        className="w-[30%] lg:w-[45%] xl:w-[37%] bg-ag-secondary text-xs xl:text-base text-white text-center border border-gray-500 py-3 rounded-l-xl"
                                    >
                                        Total quantity
                                    </span>
                                    <input
                                        className="w-[70%] lg:w-[55%] xl:w-[63%] text-ag-secondary-letter border border-gray-500 p-2 xl:p-3 rounded-r-xl truncate"
                                        type="text"
                                        name="totalQuantity"
                                        onChange={handleChange}
                                        value={product.totalQuantity}
                                        maxLength={12}
                                    />
                                </div>
                                <div className="w-full flex justify-center items-center">
                                    <span
                                        className="w-[30%] lg:w-[45%] xl:w-[37%] bg-ag-secondary text-xs xl:text-base text-white text-center border border-gray-500 py-3 rounded-l-xl"
                                    >
                                        Barcode 2
                                    </span>
                                    <input
                                        className="w-[70%] lg:w-[55%] xl:w-[63%] text-ag-secondary-letter border border-gray-500 p-2 xl:p-3 rounded-r-xl truncate"
                                        type="text"
                                        name="barcode2"
                                        onChange={handleChange}
                                        value={product.barcode2}
                                        maxLength={40}
                                    />
                                </div>
                            </div>
                            <div className="w-full flex justify-center items-center">
                                <span
                                    className="w-[30%] lg:w-[22%] xl:w-[18%] bg-ag-secondary text-xs lg:text-base text-white text-center border border-gray-500 py-[36px] rounded-l-xl"
                                >
                                    Description
                                </span>
                                {/* <input
                                    className="w-[70%] lg:w-[78%] xl:w-[82%] text-ag-secondary-letter border border-gray-500 p-2 xl:p-3 rounded-r-xl truncate"
                                    type="text"
                                    name="description"
                                    onChange={handleChange}
                                    value={product.description}
                                    maxLength={300}
                                /> */}
                                <textarea
                                    className="w-[70%] lg:w-[78%] xl:w-[82%] text-ag-secondary-letter border border-gray-500 p-2 xl:p-3 rounded-r-xl"
                                    name="description"
                                    onChange={handleChange}
                                    value={product.description}
                                    rows={3}
                                />
                            </div>
                            <div className="w-full flex justify-center items-center">
                                <span
                                    className="w-[30%] lg:w-[22%] xl:w-[18%] bg-ag-secondary text-xs xl:text-base text-white text-center border border-gray-500 py-[36px] rounded-l-xl"
                                >
                                    Features
                                </span>
                                {/* <input
                                    className="w-[70%] lg:w-[78%] xl:w-[82%] text-ag-secondary-letter border border-gray-500 p-2 xl:p-3 rounded-r-xl truncate"
                                    type="text"
                                    name="features"
                                    onChange={handleChange}
                                    value={product.features}
                                    maxLength={300}
                                /> */}
                                <textarea
                                     className="w-[70%] lg:w-[78%] xl:w-[82%] text-ag-secondary-letter border border-gray-500 p-2 xl:p-3 rounded-r-xl"
                                    name="features"
                                    onChange={handleChange}
                                    value={product.features}
                                    rows={3}
                                />
                            </div>
                            <div className="w-full flex justify-center items-center">
                                <span
                                    className="w-[30%] lg:w-[22%] xl:w-[18%] bg-ag-secondary text-xs xl:text-base text-white text-center border border-gray-500 py-3 rounded-l-xl"
                                >
                                    Vendors
                                </span>
                                <select
                                    className="w-[70%] lg:w-[78%] xl:w-[82%] text-ag-secondary-letter border border-gray-500 p-2 xl:p-3 rounded-r-xl truncate"
                                    name="vendors"
                                    defaultValue="default"
                                    onChange={handleChangeVendors}
                                    value="default"
                                >
                                    <option value="default">
                                        { 
                                            vendorsStr.length > 0 ? vendorsStr.map((elem, i) => {
                                                if (vendorsStr.length === (i+1)) {
                                                    return (<span>{ elem }</span>)
                                                } else {
                                                    return (<span>{ elem }, </span>)
                                                }
                                            }) : ""
                                        }
                                    </option>
                                    {
                                        vendorOptions.map((element, index) => {
                                            return (
                                                <option className="flex space-x-2" key={index} value={element.value}
                                                >
                                                    { element.label }{ element.selected ? ' (✓)' : '' }
                                                </option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className="w-full flex justify-center items-center">
                                <span
                                    className="w-[30%] lg:w-[22%] xl:w-[18%] bg-ag-secondary text-xs xl:text-base text-white text-center border border-gray-500 py-3 rounded-l-xl"
                                >
                                    Hour labor
                                </span>
                                <div className="flex items-center w-[70%] lg:w-[78%] xl:w-[82%] text-ag-secondary-letter border border-gray-500 p-2 xl:p-[11px] rounded-r-xl truncate">
                                    <select
                                        name="hour"
                                        className="px-1 text-center appearance-none"
                                        onChange={(evt) => setServiceTime({...serviceTime, hours: evt.target.value})}
                                        value={serviceTime.hours}
                                    >
                                        {
                                            hoursOptions.map((element, index) => {
                                                return <option key={index} value={element.value}>{element.label}</option>;
                                            })
                                        }
                                    </select>
                                    <label>.</label>
                                    <select
                                        name="minutes"
                                        className="px-1 text-center appearance-none"
                                        onChange={(evt) => setServiceTime({...serviceTime, minutes: evt.target.value})}
                                        value={serviceTime.minutes}
                                    >
                                        {
                                            minutesOptions.map((element, index) => {
                                                return <option key={index} value={element.value}>{element.label}</option>;
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="w-full flex justify-center items-start">
                                <span
                                    className="w-[30%] lg:w-[22%] xl:w-[18%] bg-ag-secondary text-xs xl:text-base text-white text-center border border-gray-500 py-3 rounded-l-xl"
                                >
                                    Product images
                                </span>
                                <div className="w-[70%] lg:w-[78%] xl:w-[82%] text-ag-secondary-letter border border-gray-500 p-2 xl:p-3 rounded-r-xl">
                                    <div className="pl-3 grid grid-cols-2 lg:grid-cols-4 xl:grid-cols-6">
                                        <label className="w-full">
                                            <div className="w-[100px] p-2 m-auto">
                                                <span className="w-full text-[70px] text-ag-secondary"><IoMdImage /></span>
                                                <div>
                                                    { imageUpload[0].name ?
                                                        <div className="flex pt-2">
                                                            <span
                                                                className="w-full text-left text-[10px] text-ag-secondary truncate"
                                                            >
                                                                {imageUpload[0].name}
                                                            </span>
                                                            <button className="text-red-500 text-sm" type="button" onClick={() => deleteArrImage(0)}>
                                                                <AiFillCloseCircle />
                                                            </button>
                                                        </div>
                                                        :
                                                        <div>
                                                            <span className="w-full text-left text-[10px] text-ag-secondary truncate">
                                                                Upload image...
                                                            </span>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            <input id="productImages_0" type="file" className="absolute w-[100px] top-[0px] opacity-0" name="productImages" accept=".png, .jpg, .jpeg" onChange={(evt) => handleUpload(evt, 0)} />
                                        </label>
                                        <label className="w-full">
                                            <div className="w-[100px] p-2 m-auto">
                                                <span className="w-full text-[70px] text-ag-secondary"><IoMdImage /></span>
                                                <div>
                                                    { imageUpload[1].name ?
                                                        <div className="flex pt-2">
                                                            <span
                                                                className="w-full text-left text-[10px] text-ag-secondary truncate"
                                                            >
                                                                {imageUpload[1].name}
                                                            </span>
                                                            <button className="text-red-500 text-sm" type="button" onClick={() => deleteArrImage(1)}>
                                                                <AiFillCloseCircle />
                                                            </button>
                                                        </div>
                                                        :
                                                        <div>
                                                            <span className="w-full text-left text-[10px] text-ag-secondary truncate">
                                                                Upload image...
                                                            </span>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            <input id="productImages_1" type="file" className="absolute w-[100px] top-[0px] opacity-0" name="productImages" accept=".png, .jpg, .jpeg" onChange={(evt) => handleUpload(evt, 1)} />
                                        </label>
                                        <label className="w-full">
                                            <div className="w-[100px] p-2 m-auto">
                                                <span className="w-full text-[70px] text-ag-secondary"><IoMdImage /></span>
                                                <div>
                                                    { imageUpload[2].name ?
                                                        <div className="flex pt-2">
                                                            <span
                                                                className="w-full text-left text-[10px] text-ag-secondary truncate"
                                                            >
                                                                {imageUpload[2].name}
                                                            </span>
                                                            <button className="text-red-500 text-sm" type="button" onClick={() => deleteArrImage(2)}>
                                                                <AiFillCloseCircle />
                                                            </button>
                                                        </div>
                                                        :
                                                        <div>
                                                            <span className="w-full text-left text-[10px] text-ag-secondary truncate">
                                                                Upload image...
                                                            </span>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            <input id="productImages_2" type="file" className="absolute w-[100px] top-[0px] opacity-0" name="productImages" accept=".png, .jpg, .jpeg" onChange={(evt) => handleUpload(evt, 2)} />
                                        </label>
                                        <label className="w-full">
                                            <div className="w-[100px] p-2 m-auto">
                                                <span className="w-full text-[70px] text-ag-secondary"><IoMdImage /></span>
                                                <div>
                                                    { imageUpload[3].name ?
                                                        <div className="flex pt-2">
                                                            <span
                                                                className="w-full text-left text-[10px] text-ag-secondary truncate"
                                                            >
                                                                {imageUpload[3].name}
                                                            </span>
                                                            <button className="text-red-500 text-sm" type="button" onClick={() => deleteArrImage(3)}>
                                                                <AiFillCloseCircle />
                                                            </button>
                                                        </div>
                                                        :
                                                        <div>
                                                            <span className="w-full text-left text-[10px] text-ag-secondary truncate">
                                                                Upload image...
                                                            </span>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            <input id="productImages_3" type="file" className="absolute w-[100px] top-[0px] opacity-0" name="productImages" accept=".png, .jpg, .jpeg" onChange={(evt) => handleUpload(evt, 3)} />
                                        </label>
                                        <label className="w-full">
                                            <div className="w-[100px] p-2 m-auto">
                                                <span className="w-full text-[70px] text-ag-secondary"><IoMdImage /></span>
                                                <div>
                                                    { imageUpload[4].name ?
                                                        <div className="flex pt-2">
                                                            <span
                                                                className="w-full text-left text-[10px] text-ag-secondary truncate"
                                                            >
                                                                {imageUpload[4].name}
                                                            </span>
                                                            <button className="text-red-500 text-sm" type="button" onClick={() => deleteArrImage(4)}>
                                                                <AiFillCloseCircle />
                                                            </button>
                                                        </div>
                                                        :
                                                        <div>
                                                            <span className="w-full text-left text-[10px] text-ag-secondary truncate">
                                                                Upload image...
                                                            </span>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            <input id="productImages_4" type="file" className="absolute w-[100px] top-[0px] opacity-0" name="productImages" accept=".png, .jpg, .jpeg" onChange={(evt) => handleUpload(evt, 4)} />
                                        </label>
                                        <label className="w-full">
                                            <div className="w-[100px] p-2 m-auto">
                                                <span className="w-full text-[70px] text-ag-secondary"><IoMdImage /></span>
                                                <div>
                                                    { imageUpload[5].name ?
                                                        <div className="flex pt-2">
                                                            <span
                                                                className="w-full text-left text-[10px] text-ag-secondary truncate"
                                                            >
                                                                {imageUpload[5].name}
                                                            </span>
                                                            <button className="text-red-500 text-sm" type="button" onClick={() => deleteArrImage(5)}>
                                                                <AiFillCloseCircle />
                                                            </button>
                                                        </div>
                                                        :
                                                        <div>
                                                            <span className="w-full text-left text-[10px] text-ag-secondary truncate">
                                                                Upload image...
                                                            </span>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            <input id="productImages_5" type="file" className="absolute w-[100px] top-[0px] opacity-0" name="productImages" accept=".png, .jpg, .jpeg" onChange={(evt) => handleUpload(evt, 5)} />
                                        </label>
                                        <label className="w-full">
                                            <div className="w-[100px] p-2 m-auto">
                                                <span className="w-full text-[70px] text-ag-secondary"><IoMdImage /></span>
                                                <div>
                                                    { imageUpload[6].name ?
                                                        <div className="flex pt-2">
                                                            <span
                                                                className="w-full text-left text-[10px] text-ag-secondary truncate"
                                                            >
                                                                {imageUpload[6].name}
                                                            </span>
                                                            <button className="text-red-500 text-sm" type="button" onClick={() => deleteArrImage(6)}>
                                                                <AiFillCloseCircle />
                                                            </button>
                                                        </div>
                                                        :
                                                        <div>
                                                            <span className="w-full text-left text-[10px] text-ag-secondary truncate">
                                                                Upload image...
                                                            </span>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            <input id="productImages_6" type="file" className="absolute w-[100px] top-[0px] opacity-0" name="productImages" accept=".png, .jpg, .jpeg" onChange={(evt) => handleUpload(evt, 6)} />
                                        </label>
                                        <label className="w-full">
                                            <div className="w-[100px] p-2 m-auto">
                                                <span className="w-full text-[70px] text-ag-secondary"><IoMdImage /></span>
                                                <div>
                                                    { imageUpload[7].name ?
                                                        <div className="flex pt-2">
                                                            <span
                                                                className="w-full text-left text-[10px] text-ag-secondary truncate"
                                                            >
                                                                {imageUpload[7].name}
                                                            </span>
                                                            <button className="text-red-500 text-sm" type="button" onClick={() => deleteArrImage(7)}>
                                                                <AiFillCloseCircle />
                                                            </button>
                                                        </div>
                                                        :
                                                        <div>
                                                            <span className="w-full text-left text-[10px] text-ag-secondary truncate">
                                                                Upload image...
                                                            </span>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            <input id="productImages_7" type="file" className="absolute w-[100px] top-[0px] opacity-0" name="productImages" accept=".png, .jpg, .jpeg" onChange={(evt) => handleUpload(evt, 7)} />
                                        </label>
                                        <label className="w-full">
                                            <div className="w-[100px] p-2 m-auto">
                                                <span className="w-full text-[70px] text-ag-secondary"><IoMdImage /></span>
                                                <div>
                                                    { imageUpload[8].name ?
                                                        <div className="flex pt-2">
                                                            <span
                                                                className="w-full text-left text-[10px] text-ag-secondary truncate"
                                                            >
                                                                {imageUpload[8].name}
                                                            </span>
                                                            <button className="text-red-500 text-sm" type="button" onClick={() => deleteArrImage(8)}>
                                                                <AiFillCloseCircle />
                                                            </button>
                                                        </div>
                                                        :
                                                        <div>
                                                            <span className="w-full text-left text-[10px] text-ag-secondary truncate">
                                                                Upload image...
                                                            </span>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            <input id="productImages_8" type="file" className="absolute w-[100px] top-[0px] opacity-0" name="productImages" accept=".png, .jpg, .jpeg" onChange={(evt) => handleUpload(evt, 8)} />
                                        </label>
                                        <label className="w-full">
                                            <div className="w-[100px] p-2 m-auto">
                                                <span className="w-full text-[70px] text-ag-secondary"><IoMdImage /></span>
                                                <div>
                                                    { imageUpload[9].name ?
                                                        <div className="flex pt-2">
                                                            <span
                                                                className="w-full text-left text-[10px] text-ag-secondary truncate"
                                                            >
                                                                {imageUpload[9].name}
                                                            </span>
                                                            <button className="text-red-500 text-sm" type="button" onClick={() => deleteArrImage(9)}>
                                                                <AiFillCloseCircle />
                                                            </button>
                                                        </div>
                                                        :
                                                        <div>
                                                            <span className="w-full text-left text-[10px] text-ag-secondary truncate">
                                                                Upload image...
                                                            </span>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            <input id="productImages_9" type="file" className="absolute w-[100px] top-[0px] opacity-0" name="productImages" accept=".png, .jpg, .jpeg" onChange={(evt) => handleUpload(evt, 9)} />
                                        </label>
                                        <label className="w-full">
                                            <div className="w-[100px] p-2 m-auto">
                                                <span className="w-full text-[70px] text-ag-secondary"><IoMdImage /></span>
                                                <div>
                                                    { imageUpload[10].name ?
                                                        <div className="flex pt-2">
                                                            <span
                                                                className="w-full text-left text-[10px] text-ag-secondary truncate"
                                                            >
                                                                {imageUpload[10].name}
                                                            </span>
                                                            <button className="text-red-500 text-sm" type="button" onClick={() => deleteArrImage(10)}>
                                                                <AiFillCloseCircle />
                                                            </button>
                                                        </div>
                                                        :
                                                        <div>
                                                            <span className="w-full text-left text-[10px] text-ag-secondary truncate">
                                                                Upload image...
                                                            </span>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            <input id="productImages_10" type="file" className="absolute w-[100px] top-[0px] opacity-0" name="productImages" accept=".png, .jpg, .jpeg" onChange={(evt) => handleUpload(evt, 10)} />
                                        </label>
                                        <label className="w-full">
                                            <div className="w-[100px] p-2 m-auto">
                                                <span className="w-full text-[70px] text-ag-secondary"><IoMdImage /></span>
                                                <div>
                                                    { imageUpload[11].name ?
                                                        <div className="flex pt-2">
                                                            <span
                                                                className="w-full text-left text-[10px] text-ag-secondary truncate"
                                                            >
                                                                {imageUpload[11].name}
                                                            </span>
                                                            <button className="text-red-500 text-sm" type="button" onClick={() => deleteArrImage(11)}>
                                                                <AiFillCloseCircle />
                                                            </button>
                                                        </div>
                                                        :
                                                        <div>
                                                            <span className="w-full text-left text-[10px] text-ag-secondary truncate">
                                                                Upload image...
                                                            </span>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            <input id="productImages_11" type="file" className="absolute w-[100px] top-[0px] opacity-0" name="productImages" accept=".png, .jpg, .jpeg" onChange={(evt) => handleUpload(evt, 11)} />
                                        </label>
                                    </div>
                                </div>
                                {/* <input
                                    className="w-[70%] md:w-[78%] lg:w-[82%] text-ag-secondary-letter border border-gray-500 p-3 rounded-r-xl"
                                    type="text"
                                /> */}
                            </div>
                        </div>
                       
                        { renderBottomInfo() }            
                    </form>
                    
                </div>
            </>
        )
}  

    const renderForm = () => {
        if (isModal) {
            return (
              <div className="grid gap-0 md:grid-cols-1">
                { renderFormContent() }
              </div>
            );
          }
    
          return (
            <div className="grid gap-3 md:grid-cols-5">    
                { renderFormContent() }
            </div>
          );
    }

    const renderContent = () => {
        if (isModal) {
            return(
                <div className="w-full h-full flex-grow "> 
                    <div className="space-y-1 py-1 px-5 md:px-2 lg:px-5">
                        <div className="col-span-4 text-center">
                            
                        </div>
                        <div className="pt-3 pb-5 space-y-2">
                            { renderForm() }
                        </div>
                    </div>
                </div>
            )
        }

        return(
            <div className="overflow-auto w-full flex-grow mt-[100px]"> 
                <div className="space-y-5 py-8 px-5 md:px-10 lg:px-16">
                    <div className="col-span-4 text-center">
                        <h1 className="text-[26px] md:text-xl lg:text-[26px] font-light text-ag-secondary">New/Edit Product</h1>
                    </div>

                    <div className="pt-3 pb-10 space-y-5">
                        { renderForm() }

                        {
                            compatibleVehicles.length > 0 ?
                                <div className="pt-5 border-t border-ag-secondary">
                                    <div className="w-full text-center">
                                        <p className="text-[26px] md:text-xl lg:text-[26px] font-light text-ag-secondary">Compatible vehicles</p>
                                    </div>
                                    <div className="pt-8">
                                        <TableCompatibleVehicles arrCompVeh={compatibleVehicles} />
                                    </div>
                                </div>
                            : null
                        }

                        {
                            interchanges.length > 0 ?
                                <div className="py-5 border-t border-ag-secondary">
                                    <div className="w-full text-center">
                                        <p className="text-[26px] md:text-xl lg:text-[26px] font-light text-ag-secondary">Compatible vehicles by interchanges</p>
                                    </div>
                                    <div className="pt-8">
                                        <TableInterchanges arrInter={interchanges} />
                                    </div>
                                </div>
                            : null
                        }
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            { loader ? <Loader /> : null }

            {
                showCompatible.status ?
                    <ModalCompatibleWD
                        products={showCompatible.element}
                        onClose={() => setShowCompatible({...showCompatible, status: false, element: null})}
                    />
                : null
            }

            {
                showTable ?
                    <ProductEspecification
                        onClose={() => setShowTable(false)}
                        onSelect={handleFillForm}
                        onCompatible={(prdSel) => setShowCompatible({...showCompatible, status: true, element: prdSel})}
                    />
                : null
            }

            {
                showPrice ?
                    <PricePlinkington
                        nagSearch={product.nags}
                        onClose={() => setShowPrice(false)}
                        onLoader={(val) => setLoader(val)}
                    />
                : null
            }

            { renderContent() }
        </>
    );
    
}

export default FormProductContent;