import { useContext, useEffect, useState } from "react";
import { IoMdCloseCircle } from "react-icons/io";
import { BiChevronUp, BiChevronDown } from "react-icons/bi";
import { PermitsContext } from "../../services/Permits";
import { changeReadNotification, getNotifications } from "../../services/Notification";
import { BsFillBellFill } from "react-icons/bs";
import { toast } from "react-toastify";
import { alertOptions, formatDateWordEng, formatHours, parseTextTitleNotifications } from "../../utils/Utilities";
import { useNavigate } from "react-router-dom";
import iconReaded from "../../assets/icons/notificationReaded.svg";

const typeNotif = {
    NEW_ORDER: { text: "New Order" },
    NEW_QUOTE: { text: "New Quote" }
}

const Notifications = ({ onClose }) => {
    const navigate = useNavigate();
    const { dataSession } = useContext(PermitsContext);
    const [isLoader, setIsLoader] = useState(false);
    const [dropDownSel, setDropDownSel] = useState("");
    const [openDropDown, setOpenDropDown] = useState(false);
    const [notifs, setNotifs] = useState([]);
    const [notifsUniv, setNotifsUniv] = useState([]);


    const [data, setData] = useState([])
    const [filteredData, setFilteredData] = useState([])
    const [allNotifications, setAllNotifications] = useState([])

    const [filter, setFilter] = useState('');

    // const getData = async (filterSelected) => {
    //     let arrNotifications = [];
    //     for (let i = 0; i < dataSession?.allWarehouse?.length; i++) {
    //         const resNotif = await getNotifications({ warehouse: dataSession?.allWarehouse[i]?._id });
    //         if (resNotif.status === 200 && resNotif.data.length > 0) arrNotifications = arrNotifications.concat(resNotif.data);
    //     }

    //     const arrayUniqueById = [...new Map(arrNotifications.map(item =>[item._id, item])).values()];
    //     const foundNotif = arrayUniqueById.filter(elem => !elem?.isRead);

    //     setNotifs(foundNotif.sort((a, b) => (new Date(b.createdAt) - new Date(a.createdAt))));
    //     setNotifsUniv(arrayUniqueById.sort((a, b) => (new Date(b.createdAt) - new Date(a.createdAt))));
    //     setDropDownSel(filterSelected);
    //     setIsLoader(false);
    // }
    const getData = async (filterSelected) => {
        setIsLoader(true);
        const res = await getNotifications();
        if (res.status === 200) {
            res.data.sort((a, b) => (new Date(b.createdAt) - new Date(a.createdAt)));
            setData(res.data);
            setFilteredData(res.data);
            setAllNotifications(res.data);
        }
        setIsLoader(false);
    }

    const handleFilters = (typeFilter) => {
        setOpenDropDown(false);
        let allNotifications = [...filteredData]
        switch (typeFilter) {
            case "read":
                // setNotifs(notifsUniv.filter(elem => elem?.isRead));
                // setDropDownSel("Read");
                setFilteredData(allNotifications.filter(elem => elem?.isRead));
                setDropDownSel("Read");
                break;
            case "not_read":
                // setNotifs(notifsUniv.filter(elem => !elem?.isRead));
                // setDropDownSel("Not read");
                setFilteredData(allNotifications.filter(elem => !elem?.isRead));
                setDropDownSel("Not read");
                break;
            case "new_orders":
                // setNotifs(notifsUniv.filter(elem => elem?.type === "NEW_ORDER"));
                // setDropDownSel("New Orders");
                setFilteredData(allNotifications.filter(elem => elem?.type === "NEW_ORDER"))
                setDropDownSel("New Orders");
                break;
            case "new_quotes":
                // setNotifs(notifsUniv.filter(elem => elem?.type === "NEW_QUOTE"));
                // setDropDownSel("New Quotes");
                setFilteredData(allNotifications.filter(elem => elem?.type === "NEW_QUOTE"))
                setDropDownSel("New Quotes");
                break;
            default:
                // setNotifs(notifsUniv);
                // setDropDownSel("All");
                setFilteredData(allNotifications)
                setDropDownSel("All");
                break;
        }
    }

    const readNotif = (idNotif) => {
        changeReadNotification(idNotif, { esRead: true }).then(res => {
            if (res.status === 200) {
                let arrafilteredData = [...filteredData]
                let index = arrafilteredData.findIndex((noti) => noti._id.toString() === idNotif);
                console.log(index)
                if (index !== -1) {
                    arrafilteredData[index].esRead = true;
                    // setFilteredData(arrafilteredData)

                    let act = document.getElementById(idNotif);
                    act?.classList.replace("bg-ag-primary-dark", "bg-[#FFB2B2]");

                    let act2 = document.getElementById(idNotif + '_');
                    act2?.classList.replace('text-white', 'text-[#727272]')

                    console.log(act2)
                   document.getElementById(idNotif + '-').disabled = true;

                    let act4 = document.getElementById(idNotif + '*');
                    act4?.classList.replace('text-white', 'text-[#727272]')
                    console.log(act4)

                    let act5 = document.getElementById(idNotif + '*_');
                    console.log(act5)
                    act5?.classList.replace('text-white', 'text-[#727272]')

                    let act6 = document.getElementById(idNotif + '*-');
                    console.log(act6)
                    act6?.classList.replace('text-white', 'text-[#727272]')

                    let act7 = document.getElementById(idNotif + '**');
                    console.log(act7)
                    act7?.classList.replace('text-white', 'text-[#727272]')

                    let act8 = document.getElementById(idNotif + '-_');
                    console.log(act8)
                    act8?.classList.replace('text-white', 'text-[#727272]')

                } else if (index === -1) {
                    toast.warning("An error occurred while updating the notification", alertOptions);
                }
            } else {
                toast.warning("An error occurred while updating the notification", alertOptions);
            }
        }).catch(() => setIsLoader(false));
    }

    useEffect(() => {
        // getData("Not read");
        getData('All');
    }, [dataSession]);


    useEffect(() => {
        // getData("Not read");
        console.log("cambio")
    }, [filteredData]);

    useEffect(() => {
        setOpenDropDown(false);
        let newNotifications = [...allNotifications]
        if (filter === 'read') {
            newNotifications = newNotifications.filter(elem => elem?.isRead)
            setFilteredData(newNotifications);
            setDropDownSel("Read");
        } else if (filter === 'not_read') {
            newNotifications = newNotifications.filter(elem => !elem?.isRead)
            setFilteredData(newNotifications);
            setDropDownSel("Not read");
        } else if (filter === 'new_orders') {
            newNotifications = newNotifications.filter(elem => elem?.type === "NEW_ORDER")
            setFilteredData(newNotifications);
            setDropDownSel("New Orders");
        } else if (filter === 'new_quotes') {
            newNotifications = newNotifications.filter(elem => elem?.type === "NEW_QUOTE")
            setFilteredData(newNotifications);
            setDropDownSel("New Quotes");
        } else {
            setFilteredData(newNotifications)
            setDropDownSel("All");
        }
    }, [filter])

    const handleDetail = (type, order, quotePWRWT, quotePhotoSpecifications) => {
        if (type === 'NEW_ORDER') {
            // if (order?.orderType === 'QUOTES') {
            //     console.log('order - quotes')
            // } else if (order?.orderType === 'POWER_WINDOW_REPAIR') {
            //     console.log('order - POWER_WINDOW_REPAIR')
            // } else if (order?.orderType === 'WINDOW_TINT') {
            //     console.log('order - WINDOW_TINT')
            // }
            navigate(`/orders/detail/${order?._id}`);
            window.location.reload(true);
        } else if (type === 'NEW_QUOTE') {
            if (quotePWRWT?.type !== undefined) {
                if (quotePWRWT?.type === 'POWER_WINDOW_REPAIR' || quotePWRWT?.type === 'WINDOW_TINT') {
                    navigate(`/quotes/services`);
                    window.location.reload(true);
                }
                // else if (quotePWRWT?.type === 'WINDOW_TINT') {
                //     console.log('quote - WINDOW_TINT')
                // }
            } else {
                if (quotePhotoSpecifications?.searchType === 'photos' || quotePhotoSpecifications?.searchType === 'specifications') {
                    navigate(`/quotes`);
                    window.location.reload(true);
                }
                //  else if (quotePhotoSpecifications?.searchType === 'specifications') {
                //     console.log('quote - specifications')
                // }
            }

        }

        // navigate(`/orders/detail/${id}`);
    }



    return (
        <>
            <div className="block">
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                    <div className="w-[320px] md:w-[400px]">
                        <div className="p-2 border-0 rounded-lg shadow-lg flex-col w-full bg-white outline-none focus:outline-none">
                            <div className="w-full flex items-center justify-end rounded text-center">
                                <div className="w-[95%] px-3 text-left">
                                    <p className="text-lg font-semibold">Notifications</p>
                                </div>
                                <div className="w-[5%]">
                                    <button
                                        className="text-ag-secondary float-right text-2xl leading-none outline-none focus:outline-none"
                                        onClick={() => onClose(false)}
                                    >
                                        <IoMdCloseCircle />
                                    </button>
                                </div>
                            </div>
                            <div className="relative px-5 py-3 flex-auto">
                                {
                                    isLoader ?
                                        <span>Loading, please wait...</span>
                                        :
                                        <>
                                            <div className="w-full flex justify-end items-center pb-3">
                                                <button
                                                    className="flex justify-center items-center text-xs rounded-xl"
                                                    type="button"
                                                    onClick={() => setOpenDropDown(!openDropDown)}
                                                >
                                                    <span>{dropDownSel}</span>
                                                    <span className="text-lg">
                                                        {openDropDown ? <BiChevronUp /> : <BiChevronDown />}
                                                    </span>
                                                </button>
                                                {
                                                    openDropDown ?
                                                        <div className="absolute bg-white mt-[160px] text-center border border-[#FF5F52] z-10 rounded-b-xl">
                                                            <ul className="py-2">
                                                                <li className={`px-3 py-1 text-xs hover:bg-zinc-200 cursor-pointer`} onClick={() => setFilter("all")}>All</li>
                                                                <li className={`px-3 py-1 text-xs hover:bg-zinc-200 cursor-pointer`} onClick={() => setFilter("read")}>Read</li>
                                                                <li className={`px-3 py-1 text-xs hover:bg-zinc-200 cursor-pointer`} onClick={() => setFilter("not_read")}>Not read</li>
                                                                <li className={`px-3 py-1 text-xs hover:bg-zinc-200 cursor-pointer`} onClick={() => setFilter("new_orders")}>New Orders</li>
                                                                <li className={`px-3 py-1 text-xs hover:bg-zinc-200 cursor-pointer`} onClick={() => setFilter("new_quotes")}>New Quotes</li>
                                                                {/* <li className={`px-3 py-1 text-xs hover:bg-zinc-200 cursor-pointer`} onClick={() => handleFilters("new_quotes")}>New Quotes</li> */}
                                                            </ul>
                                                        </div>
                                                        : null
                                                }
                                            </div>
                                            <div className="h-[400px] overflow-y-auto space-y-2 py-3 px-1">
                                                {/* {
                                                    notifs.length > 0 ? notifs.map((notif, i) => (
                                                        <>
                                                            <p className="text-[11px] font-semibold">{formatDateWordEng(notif?.createdAt)}{' - '}{formatHours(notif?.createdAt)}</p>
                                                            <div className={`${notif?.isRead ? "bg-ag-primary-light" : "bg-ag-primary-dark"} w-full flex justify-between items-center px-5 py-2 rounded-xl`} key={i}>
                                                                <div className="space-y-2 cursor-pointer" onClick={() => navigate("/quotes")}>
                                                                    <div>
                                                                        <p className="text-white font-semibold">
                                                                            {typeNotif[notif?.type].text}
                                                                        </p>
                                                                    </div>
                                                                    <div>
                                                                        {
                                                                            notif?.quoteWindowRepair?.type === "POWER_WINDOW_REPAIR" ?
                                                                                <p className="text-xs text-white">Power Window Repair</p>
                                                                                : notif?.quoteWindowRepair?.type === "WINDOW_TINT" ?
                                                                                    <p className="text-xs text-white">Window Tint</p>
                                                                                    : notif?.order?.orderType === "WINDOW_TINT" ?
                                                                                        <p className="text-xs text-white">Window Tint</p>
                                                                                        : notif?.order?.orderType === "POWER_WINDOW_REPAIR" ?
                                                                                            <p className="text-xs text-white">Power Window Repair</p>
                                                                                            : <p className="text-xs text-white">Order - {notif?.description !== "" ? notif?.description : "By Quote"}</p>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <button className="disabled:opacity-50" type="button" disabled={notif?.isRead} onClick={() => readNotif(notif?._id)}>
                                                                        <span className="text-2xl text-white"><BsFillBellFill /></span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )) : <p className="text-xs italic">Records not found...</p>
                                                } */}

                                                {

                                                    filteredData.length === 0 ?
                                                        <p className="text-xs italic">Records not found...</p>
                                                        :
                                                        filteredData.map((notif, i) => (
                                                            <>
                                                                <p className="text-[16px] font-semibold">{formatDateWordEng(notif?.createdAt)}{' - '}{formatHours(notif?.createdAt)}</p>
                                                                <div id={notif?._id} className={`${notif?.isRead ? "bg-[#FFB2B2]" : "bg-ag-primary-dark"} w-full flex justify-between items-center px-5 py-2 rounded-xl`} key={i}>
                                                                    <div className="w-[85%] space-y-2 cursor-pointer"
                                                                        //  onClick={() => navigate("/quotes")}
                                                                        // onClick={() => handleDetail(notif?._id, notif?.type, notif?.order?.orderType, notif?.quoteWindowRepair?.type, notif?.quote?.searchType)}
                                                                        onClick={() => handleDetail(notif?.type, notif?.order, notif?.quoteWindowRepair, notif?.quote)}
                                                                    >
                                                                        <div>
                                                                            <p id={notif?._id + '_'} className={`${notif?.isRead ? "text-[#727272]" : "text-white"} text-[16px] font-bold change-color`}>
                                                                                {parseTextTitleNotifications(notif?.type)}
                                                                            </p>
                                                                        </div>
                                                                        <div>


                                                                            {

                                                                                notif?.quoteWindowRepair?.type === "POWER_WINDOW_REPAIR" ?
                                                                                    <p id={notif?._id + '*'} className={`${notif?.isRead ? "text-[#727272]" : "text-white"} text-[12px] font-semibold change-color`}>Power Window Repair</p>
                                                                                    : notif?.quoteWindowRepair?.type === "WINDOW_TINT" ?
                                                                                        <p id={notif?._id + '**'} className={`${notif?.isRead ? "text-[#727272]" : "text-white"} text-[12px] font-semibold change-color`}>Window Tint</p>
                                                                                        : notif?.order?.orderType === "WINDOW_TINT" ?
                                                                                            <p id={notif?._id + '*-'} className={`${notif?.isRead ? "text-[#727272]" : "text-white"} text-[12px] font-semibold`}>Window Tint</p>
                                                                                            : notif?.order?.orderType === "POWER_WINDOW_REPAIR" ?
                                                                                                <p id={notif?._id + '*_'} className={`${notif?.isRead ? "text-[#727272]" : "text-white"} text-[12px] font-semibold`}>Power Window Repair</p>
                                                                                                : <p id={notif?._id + '_-'} className={`${notif?.isRead ? "text-[#727272]" : "text-white"} text-[12px] font-semibold`}>Order - {notif?.description !== "" ? notif?.description : "By Quote"}</p>

                                                                            }

                                                                        </div>
                                                                    </div>
                                                                    <div className="w-[15%]">
                                                                        <button id={notif?._id + '-'} className="disabled:opacity-50" type="button" disabled={notif?.isRead} onClick={() => readNotif(notif?._id)}>
                                                                            {
                                                                                notif?.isRead ?
                                                                                    <img src={iconReaded} alt="Readed"></img>
                                                                                    :
                                                                                    <span className="text-2xl text-white"><BsFillBellFill /></span>
                                                                            }

                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        ))

                                                }

                                            </div>
                                        </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
        </>
    );
}

export default Notifications;