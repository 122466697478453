import { useState } from "react";
import RowsStocktakeProducts from "./RowsStocktakeProducts";
import Pagination from "../../../../../components/pagination/Pagination";

const TableStocktakeProducts = ({ products, disabledFields, onProduct, onAccept, onDelete, onCompatible }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(6);

    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = products.slice(indexOfFirstRecord, indexOfLastRecord);
    
    return (
        <div>
            <table className="w-full text-center text-ag-secondary">
                <thead>
                    <tr className="bg-ag-secondary-table">
                        <th className="w-[20%] border-[0.5px] border-ag-secondary-light text-xs font-normal px-20 lg:px-2 py-4">Products</th>
                        <th className="w-[15%] border-[0.5px] border-ag-secondary-light text-xs font-normal px-20 lg:px-2 py-4">Rack</th>
                        <th className="w-[15%] border-[0.5px] border-ag-secondary-light text-xs font-normal px-10 lg:px-2 py-4">Expected</th>
                        <th className="w-[15%] border-[0.5px] border-ag-secondary-light text-xs font-normal px-10 lg:px-2 py-4">Actual</th>
                        <th className="w-[15%] border-[0.5px] border-ag-secondary-light text-xs font-normal px-6 lg:px-2 py-4">Discrepancy</th>
                        <th className="w-[15%] border-[0.5px] border-ag-secondary-light text-xs font-normal px-16 lg:px-2 py-4">Notes</th>
                        <th className="w-[5%] border-[0.5px] border-ag-secondary-light text-xs font-normal px-5 lg:px-2 py-4">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        currentRecords.map((element, i) => {
                            return (
                                <RowsStocktakeProducts
                                    key={i}
                                    product={element}
                                    disabledFields={disabledFields}
                                    onProduct={onProduct}
                                    onAccept={onAccept}
                                    onDelete={onDelete}
                                    onCompatible={onCompatible}
                                />
                            )
                        })
                    }
                </tbody>
            </table>
            <div className="p-5 m-auto">
                <Pagination
                    currentPage={currentPage}
                    totalCount={products.length}
                    pageSize={recordsPerPage}
                    onPageChange={page => setCurrentPage(page)}
                />
            </div>
        </div>
    );
}

export default TableStocktakeProducts;