import { useEffect, useState } from "react";
import { IoMdCloseCircle } from "react-icons/io";
import { toast } from "react-toastify";
import { alertOptions } from "../../../../utils/Utilities";
import TableNags from "./TableNags";

const FormInterchangeable = ({ productsOpts, interchangeInfo, onClose, onSave, onEdit, onCompatible }) => {
    const [nagsToEdit, setNagsToEdit] = useState([]);
    const [dataInterchanges, setDataInterchanges] = useState({
        id: "",
        description: "",
        nags: []
    });

    const handleCanEdit = (nag, sts) => {
        if (sts) {
            setNagsToEdit([...nagsToEdit, nag]);
        } else {
            const newNags = nagsToEdit.filter(elem => elem !== nag);
            setNagsToEdit(newNags);
        }
    }

    const handleAdd = (nagSelected) => {
        const nagFound = dataInterchanges.nags.find(elem => elem === nagSelected);
        if (!nagFound) {
            setDataInterchanges({...dataInterchanges, nags: [...dataInterchanges.nags, nagSelected]});
        } else {
            toast.info("The nag selected is already in the list", alertOptions);
        }
    }

    const handleEdit = (idxNg, nagSelected) => {
        const foundNag = dataInterchanges.nags.find(elem => elem === nagSelected);
        if (!foundNag) {
            const newArrNags = dataInterchanges.nags.map((elem, i) => {
                if (i === idxNg) return nagSelected;
                return elem;
            });
            setDataInterchanges({...dataInterchanges, nags: newArrNags});
        } else {
            toast.info("The nag selected is already in the list", alertOptions);
        }
    }

    const handleDelete = (nagSelected) => {
        const newArrNags = dataInterchanges.nags.filter(elem => elem !== nagSelected);
        setDataInterchanges({...dataInterchanges, nags: newArrNags});
    }

    const handleChange = (evt) => {
        setDataInterchanges({...dataInterchanges, [evt.target.name]: evt.target.value});
    }

    const handleSubmit = (evt) => {
        evt.preventDefault();
        if (dataInterchanges.nags.length > 0) {
            if (nagsToEdit.length === 0) {
                let interchangeModel = {
                    description: dataInterchanges.description,
                    interchangeableNags: dataInterchanges.nags
                }
                if (interchangeInfo === null) {
                    onSave(interchangeModel);
                } else {
                    interchangeModel._id = interchangeInfo._id;
                    onEdit(interchangeModel, true);
                }
            } else {
                toast.info("Please finish editing the table nags", alertOptions);
            }
        } else {
            toast.info("Please fill out all form fields", alertOptions);
        }
    }

    useEffect(() => {
        if (interchangeInfo !== null) {
            setDataInterchanges({...dataInterchanges,
                id: interchangeInfo.index,
                description: interchangeInfo.description,
                nags: interchangeInfo.interchangeableNags
            });
        }
    }, [interchangeInfo]);

    return (
        <>
            <div className="block">
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-30 outline-none focus:outline-none">
                    <div className="w-[320px] md:w-[700px] lg:w-[950px] ">
                        <div className="p-5 border-0 rounded-lg shadow-lg flex-col w-full bg-white outline-none focus:outline-none">
                            <div className="w-full flex items-center justify-center rounded text-center">
                                <div className="w-[95%] ">
                                    <span className="text-xl text-ag-secondary font-bold md:ml-[5%]">
                                        Nags Interchange
                                    </span>
                                </div>
                                <div className="w-[5%]">
                                    <button
                                        className="text-ag-secondary float-right text-3xl leading-none outline-none focus:outline-none"
                                        onClick={() => onClose(false)}
                                    >
                                        <IoMdCloseCircle />
                                    </button>
                                </div>
                            </div>
                            <div className="relative px-2 md:px-5 pb-2 pt-5 flex-auto">
                                <form className="space-y-10" onSubmit={handleSubmit}>
                                    <div className="space-y-3">
                                        <div className="grid gap-3 md:grid-cols-2">
                                            <div className="w-full flex justify-center items-center">
                                                <span
                                                    className="w-[40%] bg-ag-secondary-dark text-xs xl:text-base text-white text-center border border-gray-500 py-3 rounded-l-xl"
                                                >
                                                    ID Interchange
                                                </span>
                                                <input
                                                    className="w-[60%] text-ag-secondary-letter border border-gray-500 p-2 xl:p-3 rounded-r-xl disabled:bg-zinc-200 truncate"
                                                    type="text"
                                                    name="id"
                                                    value={dataInterchanges.id}
                                                    onChange={handleChange}
                                                    disabled={true}
                                                    maxLength={60}
                                                />
                                            </div>
                                            <div className="w-full flex justify-center items-center">
                                                <span
                                                    className="w-[40%] bg-ag-secondary-dark text-xs xl:text-base text-white text-center border border-gray-500 py-3 rounded-l-xl"
                                                >
                                                    Description
                                                </span>
                                                <input
                                                    className="w-[60%] text-ag-secondary-letter border border-gray-500 p-2 xl:p-3 rounded-r-xl disabled:bg-zinc-200 truncate"
                                                    type="text"
                                                    name="description"
                                                    onChange={handleChange}
                                                    value={dataInterchanges.description}
                                                    maxLength={60}
                                                />
                                            </div>
                                        </div>
                                        <div className="flex justify-center items-center">
                                            <button
                                                className="w-full md:w-[60%] lg:w-[50%] px-5 py-3 bg-ag-secondary-dark text-white text-xs xl:text-base border border-gray-500 rounded-xl truncate"
                                                style={{ opacity: interchangeInfo ? 1 : 0.5 }}
                                                type="button"
                                                disabled={interchangeInfo ? false : true}
                                                onClick={() => onCompatible(dataInterchanges.nags)}
                                            >
                                                See compatible vehicles
                                            </button>
                                        </div>
                                    </div>
                                    <div className="max-h-[250px] border-t border-ag-secondary overflow-auto">
                                        <div className="md:flex md:justify-center md:items-center py-6 space-y-3 md:space-y-0">
                                            <div className="w-full text-center px-0">
                                                <h1 className="text-[26px] md:text-xl lg:text-[26px] font-light text-ag-secondary">Compatible Vehicles</h1>
                                            </div>
                                        </div>
                                        <div className="px-5 md:px-20 pb-5 md:pb-5">
                                            <TableNags
                                                arrNags={dataInterchanges.nags}
                                                productsOpts={productsOpts}
                                                onAdd={handleAdd}
                                                onEdit={handleEdit}
                                                onDelete={handleDelete}
                                                onCanEdit={handleCanEdit}
                                            />
                                        </div>
                                        <div className="px-0 md:px-20 pt-5 md:flex md:justify-center md:items-center md:space-x-3 space-y-3 md:space-y-0">
                                            <div className="w-full md:w-[30%] lg:w-[25%]">
                                                <button className="w-full bg-ag-primary-light text-white p-2 rounded-xl" type="button" onClick={() => onClose(false)}>
                                                    Cancel
                                                </button>
                                            </div>
                                            <div className="w-full md:w-[30%] lg:w-[25%]">
                                                <button className="w-full bg-green-600 text-white p-2 rounded-xl" type="submit">
                                                    Apply
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="opacity-50 fixed inset-0 z-20 bg-black"></div>
        </>
    );
}

export default FormInterchangeable;