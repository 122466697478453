import { useEffect, useState } from "react";
import { IoMdCloseCircle } from "react-icons/io";
import { toast } from "react-toastify";
import { alertOptions } from "../../../../utils/Utilities";
import { validateInventoryAdd } from "../../../../utils/Validators";
import { getWarehouse } from "../../../../services/Warehouse";
import { saveInventoryAdd, updateInventoryAddId } from "../../../../services/Inventory";
import { updateAvailability } from "../../../../services/Quotes";
import { inventoryOrder } from "../../../../services/Orders";
import TableInventory from "./TableInventory";

const ModalFormInventory = ({ type, mod, orderId, productSelect, warehouses, warehouseCurrent, onLoader, onClose, onSave, onEdit }) => {
    const [sumStock, setSumStock] = useState(0);
    const [racksOptions, setRacksOptions] = useState([]);
    const [racksToEdit, setRacksToEdit] = useState([]);
    const [warehousesAv, setWarehousesAv] = useState([]);
    const [inventory, setInventory] = useState({
        _id: "",
        status: "active",
        warehouse: "",
        product: "",
        year: "",
        brand: "",
        model: "",
        nags: "",
        cost: "",
        sellerPrice: 0,
        resellerPrice: 0,
        maxStock: "",
        minStock: "",
        stock: "",
        racks: []
    });

    const handleCanEdit = (rckElem, sts) => {
        if (sts) {
            const newRacks = racksToEdit.filter(elem => elem === rckElem?._id || elem === rckElem?.rack?._id);
            if (!newRacks || newRacks.length === 0) setRacksToEdit([...racksToEdit, rckElem?._id ? rckElem?._id : rckElem?.rack._id]);
        } else {
            const newRacks = racksToEdit.filter(elem => elem !== rckElem?._id && elem !== rckElem?.rack?._id);
            setRacksToEdit(newRacks);
        }
    }

    const addRack = (rackElement) => {
        if (rackElement._id === "" || (rackElement.quantity === 0 || rackElement.quantity === "")) {
            toast.warning("Please select a rack and quantity to add", alertOptions);
        } else {
            let racksAux = inventory.racks;
            const foundRack = racksAux.find(rckElmnt => rckElmnt._id === rackElement._id);
            if (!foundRack) {
                racksAux.push(rackElement);
                setInventory({...inventory, racks: racksAux});
                setSumStock(Number(sumStock) + Number(rackElement.quantity));
            } else {
                toast.warning("The selected rack is already added", alertOptions);
            }
        }
    }

    const editRack = (rackIndex, rackElement) => {
        let sumStockAux = 0;
        const racksAux = inventory.racks.map((rckElmnt, i) => {
            if (i === rackIndex) {
                sumStockAux += Number(rackElement.quantity);
                return { _id: rackElement._id, quantity: rackElement.quantity};
            }
            sumStockAux += Number(rckElmnt.quantity);
            return rckElmnt;
        });
        setSumStock(sumStockAux);
        setInventory({...inventory, racks: racksAux});
    }

    const deleteRack = (idRack) => {
        let sumStockAux = 0;
        const racksAux = inventory.racks.filter(rackElement => {
            if (rackElement._id !== idRack) {
                sumStockAux += Number(rackElement.quantity);
                return rackElement;
            }
        });
        setSumStock(sumStockAux);
        setInventory({...inventory, racks: racksAux});
    }

    const handleChange = (evt) => {
        if (evt.currentTarget.name === "warehouse") {
            setInventory({...inventory, warehouse: evt.currentTarget.value});
            getWarehouse(evt.currentTarget.value).then(res => {
                if (res.status === 200) {
                    setRacksOptions(res.data.racks);
                }
            });
        } else {
            setInventory({...inventory, [evt.currentTarget.name]: evt.currentTarget.value});
        }
    }

    const handleSubmit = (evt) => {
        evt.preventDefault();
        if (
            inventory.warehouse !== "" && inventory.product !== "" && inventory.cost !== "" && inventory.maxStock !== "" && 
            inventory.minStock !== "" && inventory.racks.length > 0
        ) {
            // Se verifica que no exista ningún rack repetido antes de agregar/editar
            let rackRepeat = false;
            let rack_array = [];
            for (let i = 0; i < inventory?.racks.length; i++) {
                const foundRack = rack_array.find(element => element._id === inventory.racks[i]._id);
                if (foundRack) {
                    rackRepeat = true;
                    break;
                } else {
                    rack_array.push(inventory.racks[i]);
                }
            }

            if (!rackRepeat) {
                if (sumStock <= inventory.maxStock) {
                    let arrRackFormat = [];
                    for (let i = 0; i < inventory.racks.length; i++) {
                        arrRackFormat.push({ _id: inventory.racks[i]._id, quantity: Number(inventory.racks[i].quantity) });
                    }
                    const inventoryModel = {
                        warehouse: { _id: inventory.warehouse },
                        product: { _id: inventory.product },
                        status: inventory.status,
                        maxStock: inventory.maxStock,
                        minStock: inventory.minStock,
                        cost: inventory.cost,
                        //sellerPrice: inventory.sellerPrice,
                        //resellerPrice: inventory.resellerPrice,
                        racks: arrRackFormat
                    }
                    const result = validateInventoryAdd(inventoryModel);
                    if (result.status) {
                        if (type === "add") {
                            onLoader(true);
                            saveInventoryAdd(inventoryModel).then(res => {
                                if (res.status === 200) {
                                    if (inventoryModel.racks.length > 0) {
                                        inventoryModel._id = res.data._id;
                                        if (inventoryModel.racks.length > 0) {
                                            if (mod === "quotes") {
                                                updateAvailability(productSelect._id, { productAvailable: true }).then((resAux) => {
                                                    if (resAux.status === 200) onEdit(true);
                                                }).catch(error => {
                                                    onLoader(false);
                                                    toast.warning(error.response.data.message, alertOptions);
                                                });
                                            } else {
                                                inventoryOrder(orderId, { productInventory: { _id: inventoryModel._id }}).then((resAux) => {
                                                    if (resAux.status === 200) onEdit(true);
                                                }).catch((error) => {
                                                    onLoader(false);
                                                    toast.warning(error.response.data.message, alertOptions);
                                                });
                                            }
                                        } else {
                                            onEdit(true);
                                        }
                                    } else {
                                        onSave(res.data);
                                    }
                                } else {
                                    onLoader(false);
                                    toast.warning(res.response.data.message, alertOptions);
                                }
                            }).catch(error => {
                                onLoader(false);
                                toast.warning(error.response.data.message, alertOptions);
                            });
                        } else {
                            onLoader(true);
                            inventoryModel._id = inventory._id;
                            updateInventoryAddId(inventory._id, inventoryModel).then(res => {
                                if (res.status === 200) {
                                    if (inventoryModel.racks.length > 0) {
                                        if (mod === "quotes") {
                                            updateAvailability(productSelect._id, { productAvailable: true }).then((resAux) => {
                                                if (resAux.status === 200) onEdit(true);
                                            }).catch((errorhd) => {
                                                toast.warning(errorhd.response.data.message, alertOptions)
                                                onLoader(false);
                                            });
                                        } else {
                                            inventoryOrder(orderId, { productInventory: { _id: inventoryModel._id }}).then((resAux) => {
                                                if (resAux.status === 200) onEdit(true);
                                            }).catch((errorhd) => {
                                                toast.warning(errorhd.response.data.message, alertOptions)
                                                onLoader(false);
                                            });
                                        }
                                    } else {
                                        onEdit(true);
                                    }
                                } else {
                                    onLoader(false);
                                    toast.warning(res.response.data.message, alertOptions)
                                }
                            }).catch(error => {
                                onLoader(false);
                                toast.warning(error.response.data.message, alertOptions);
                            });
                        }
                    } else {
                        toast.warning(result.msg, alertOptions);
                    }
                } else {
                    toast.warning("You cannot add more products than the established maximum in stock", alertOptions);
                }
            } else {
                toast.warning("Please, do not duplicate the racks of the table", alertOptions);
            }
        } else {
            toast.info("Please fill in all the fields of the form", alertOptions);
        }
    }

    useEffect(() => {
        if (productSelect !== null) {
            if (type === "add") {
                setInventory({...inventory,
                    product: productSelect._id,
                    year: productSelect.year,
                    brand: productSelect.brand,
                    model: productSelect.model,
                    nags: productSelect.nags,
                    cost: productSelect.price,
                    warehouse: warehouseCurrent._id
                });
            } else {
                getWarehouse(productSelect?.productInventoryAllInfo?.warehouse?._id).then(resWare => {
                    if (resWare.status === 200) {
                        if (resWare.status === 200) {
                            setRacksOptions(resWare.data.racks);
                        }
        
                        let racksAux = [];
                        if (productSelect?.productInventoryAllInfo?.racks.length > 0) {
                            racksAux = productSelect?.productInventoryAllInfo?.racks.map((element, i) => {
                                return { _id: element.rack._id, quantity: element.quantity }
                            });
                        }

                        setInventory({...inventory,
                            _id: productSelect?.productInventoryAllInfo?.productInventory?._id,
                            status: productSelect?.productInventoryAllInfo?.productInventory?.status,
                            warehouse: productSelect?.productInventoryAllInfo?.warehouse?._id,
                            product: productSelect?.productInventoryAllInfo?.productInventory?.product?._id,
                            year: productSelect?.productInventoryAllInfo?.productInventory?.product?.year,
                            brand: productSelect?.productInventoryAllInfo?.productInventory?.product?.brand,
                            model: productSelect?.productInventoryAllInfo?.productInventory?.product?.model,
                            nags: productSelect?.productInventoryAllInfo?.productInventory?.product?.nags,
                            cost: productSelect?.productInventoryAllInfo?.productInventory?.cost,
                           // sellerPrice: productSelect?.productInventoryAllInfo?.productInventory?.sellerPrice,
                           // resellerPrice: productSelect?.productInventoryAllInfo?.productInventory?.resellerPrice,
                            maxStock: productSelect?.productInventoryAllInfo?.productInventory?.maxStock,
                            minStock: productSelect?.productInventoryAllInfo?.productInventory?.minStock,
                            stock: "",
                            racks: racksAux
                        });
                    }
                });
            }

            if (mod === "order") {
                const warehousesFound = warehouses.filter(elem => elem?._id === warehouseCurrent?._id);
                setWarehousesAv(warehousesFound.length === 0 ? warehouses : warehousesFound);
            } else {
                setWarehousesAv(warehouses);
            }
        }
    }, [productSelect]);

    return (
        <>
            <div className="block">
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-30 outline-none focus:outline-none">
                    <div className="w-[350px] md:w-[700px] lg:w-[800px]">
                        <div className="px-2 md:px-5 py-5 border-0 rounded-lg shadow-lg flex-col w-full bg-white outline-none focus:outline-none">
                            <div className="w-full flex items-center justify-center rounded text-center">
                                <div className="w-[95%]">
                                    <span className="text-xl text-ag-secondary font-bold">
                                        { type === "add" ? "Add" : "Edit" } inventory
                                    </span>
                                </div>
                                <div className="w-[5%]">
                                    <button
                                        className="text-ag-secondary float-right text-3xl leading-none outline-none focus:outline-none"
                                        onClick={() => onClose(false)}
                                    >
                                        <IoMdCloseCircle />
                                    </button>
                                </div>
                            </div>
                            <div className="px-6 pt-3 md:pt-5 h-[450px] overflow-y-auto">
                                <form className="space-y-10" onSubmit={handleSubmit}>
                                    <div className="grid gap-3 md:grid-cols-2">
                                        <div className="w-full flex justify-center items-center">
                                            <span
                                                className="w-[40%] lg:w-[30%] bg-ag-secondary-dark text-xs text-white text-center border border-gray-500 py-3 rounded-l-xl"
                                            >
                                                Warehouse
                                            </span>
                                            <select
                                                className="w-[60%] lg:w-[70%] text-ag-secondary-letter border border-gray-500 p-2 rounded-r-xl truncate"
                                                id="select-inventoryAdd-warehouse"
                                                name="warehouse"
                                                onChange={handleChange}
                                                value={inventory.warehouse}
                                                disabled={type === "add" ? false : true}
                                            >
                                                <option value="default"></option>
                                                {
                                                    warehousesAv.map((element, index) => {
                                                        return <option key={index} value={element._id}>{element.name}</option>;
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <div className="w-full flex justify-center items-center">
                                            <span
                                                className="w-[40%] lg:w-[30%] bg-ag-secondary-dark text-xs text-white text-center border border-gray-500 py-3 rounded-l-xl"
                                            >
                                                Nags Price
                                            </span>
                                            <input
                                                className="w-[60%] lg:w-[70%] text-ag-secondary-letter border border-gray-500 p-2 rounded-r-xl truncate disabled:opacity-75"
                                                type="text"
                                                name="cost"
                                                value={inventory.cost}
                                                onChange={handleChange}
                                                maxLength={12}
                                                disabled={type === "add" ? false : true}
                                            />
                                        </div>
                                        {/* <div className="w-full flex justify-center items-center">
                                            <span
                                                className="w-[40%] lg:w-[30%] bg-ag-secondary-dark text-xs text-white text-center border border-gray-500 py-3 rounded-l-xl"
                                            >
                                                Seller Price
                                            </span>
                                            <input
                                                className="w-[60%] lg:w-[70%] text-ag-secondary-letter border border-gray-500 p-2 rounded-r-xl truncate disabled:opacity-75"
                                                type="text"
                                                name="sellerPrice"
                                                value={inventory.sellerPrice}
                                                onChange={handleChange}
                                                maxLength={12}
                                                disabled={type === "add" ? false : true}
                                            />
                                        </div> */}
                                        {/* <div className="w-full flex justify-center items-center">
                                            <span
                                                className="w-[40%] lg:w-[30%] bg-ag-secondary-dark text-xs text-white text-center border border-gray-500 py-3 rounded-l-xl"
                                            >
                                                Reseller Price
                                            </span>
                                            <input
                                                className="w-[60%] lg:w-[70%] text-ag-secondary-letter border border-gray-500 p-2 rounded-r-xl truncate disabled:opacity-75"
                                                type="text"
                                                name="resellerPrice"
                                                value={inventory.resellerPrice}
                                                onChange={handleChange}
                                                maxLength={12}
                                                disabled={type === "add" ? false : true}
                                            />
                                        </div> */}
                                        <div className="w-full flex justify-center items-center">
                                            <span
                                                className="w-[40%] lg:w-[30%] bg-ag-secondary-dark text-xs text-white text-center border border-gray-500 py-3 rounded-l-xl"
                                            >
                                                Maximum Stock
                                            </span>
                                            <input
                                                className="w-[60%] lg:w-[70%] text-ag-secondary-letter border border-gray-500 p-2 rounded-r-xl truncate"
                                                type="text"
                                                name="maxStock"
                                                value={inventory.maxStock}
                                                onChange={handleChange}
                                                maxLength={12}
                                            />
                                        </div>
                                        <div className="w-full flex justify-center items-center">
                                            <span
                                                className="w-[40%] lg:w-[30%] bg-ag-secondary-dark text-xs text-white text-center border border-gray-500 py-3 rounded-l-xl"
                                            >
                                                Minimum Stock
                                            </span>
                                            <input
                                                className="w-[60%] lg:w-[70%] text-ag-secondary-letter border border-gray-500 p-2 rounded-r-xl truncate"
                                                type="text"
                                                name="minStock"
                                                value={inventory.minStock}
                                                onChange={handleChange}
                                                maxLength={12}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <div className="md:flex md:justify-center md:items-center border-t border-ag-secondary py-6 space-y-3 md:space-y-0">
                                            <div className="w-full md:w-[66%] text-center md:text-right px-0 md:px-[10%] pb-5 md:pb-0">
                                                <h1 className="text-2xl md:text-xl font-light text-ag-secondary">Organize by racks</h1>
                                            </div>
                                            <div className="w-full md:w-[34%]">
                                                <button
                                                    className="w-full bg-ag-primary text-white text-xs p-2 rounded-xl disabled:bg-zinc-200"
                                                    type="submit"
                                                >
                                                    Save
                                                </button>
                                            </div>
                                        </div>
                                        <div className="px-5 md:px-20 py-5 md:py-5 border border-ag-secondary-light rounded-lg">
                                            <TableInventory
                                                type="transfer"
                                                maxStock={inventory.maxStock}
                                                sumStock={sumStock}
                                                racksCurrent={inventory.racks}
                                                racks={racksOptions}
                                                onSave={addRack}
                                                onEdit={editRack}
                                                onDelete={deleteRack}
                                                onCanEdit={handleCanEdit}
                                            />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="opacity-50 fixed inset-0 z-20 bg-black"></div>
        </>
    );
}

export default ModalFormInventory;