import { FiChevronLeft } from "react-icons/fi";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { getQuotePWRWT, shippingOptionQuote } from "../../../../services/Quotes";
import { getWarehouses } from "../../../../services/Warehouse";
import { toast } from "react-toastify";
import { alertOptions } from "../../../../utils/Utilities";
import NewAddress from "../../../fragments/NewAddress";
import Navbar from "../../../../components/navbar/Navbar";
import Sidebar from "../../../../components/sidebar/Sidebar";
import Loader from "../../../../components/loader/Loader";
import FormShippingMethod from "./FormShippingMethod";
import InformationQuote from "../InformationQuote";

const Shipping = () => {
    const navigate = useNavigate();
    const params = useParams();
    const [loader, setLoader] = useState(true);
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const [quote, setQuote] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [isRefreshAddress, setIsRefreshAddress] = useState(false);
    const [disabledButton, setDisabledButton] = useState(false);

    const getData = () => {
        getQuotePWRWT(params?.id).then(res => {
            if (res.status === 200) {
                setQuote(res.data);
                setLoader(false);
            } else {
                toast.warning(res.response.data.message, alertOptions);
                setLoader(false);
            }
        }).catch((error) => {
            toast.error(error.response.data.message, alertOptions);
            setLoader(false);
        });
    }

    const handleShippingMethod = (shippingObject) => {
        if (shippingObject.shippingMethod === "SHIP") {
            getWarehouses().then(res => {
                if (res.status === 200) {
                    const warehousesAvailable = res.data.filter(element => !element?.deleted);
                    const foundWarehouse = warehousesAvailable[Math.floor(Math.random() * warehousesAvailable.length)];
                    setQuote({...quote,
                        shippingMethod: shippingObject.shippingMethod,
                        costShipping: shippingObject.costShipping,
                        fromWarehouse: { _id: foundWarehouse?._id },
                        userAddress: { _id: shippingObject.id },
                        address: shippingObject.address
                    });
                }
            });
        } else {
            setQuote({...quote,
                shippingMethod: shippingObject.shippingMethod,
                costShipping: shippingObject.costShipping,
                fromWarehouse: { _id: shippingObject.idWarehouse },
                warehouseAddress: { _id: shippingObject.id },
                address: shippingObject.address
            });
        }
    }

    const handleContinue = () => {
        if (quote.shippingMethod !== "" && quote.costShipping >= 0) {
            setLoader(true);
            const body = {
                shippingMethod: quote.shippingMethod,
                costShipping: quote.costShipping,
                fromWarehouse: quote.fromWarehouse
            }

            if (quote.shippingMethod === "SHIP") {
                body.userAddress = quote.userAddress;
            } else {
                body.warehouseAddress = quote.warehouseAddress;
            }

            shippingOptionQuote(params.id, body).then(res => {
                if (res.status === 200) {
                    setLoader(false);
                    navigate(`/quotes/${params.id}/schedule`, { state: quote });
                } else {
                    setLoader(false);
                    toast.warning(res.response.data.message, alertOptions);
                }
            }).catch(error => {
                setLoader(false);
                toast.warning(error.response.data.message, alertOptions);
            });
        } else {
            toast.warning("Please select the shipping method and the address of the appointment", alertOptions);
        }
    }

    const handleResult = () => {
        setShowModal(false);
        setLoader(false);
        setIsRefreshAddress(true);
        toast.success("Address saved successfully", alertOptions);
    }

    useEffect(() => {
        if (params && params.id) getData();
    }, []);

    return (
        <>
            { loader ? <Loader /> : null }

            {
                showModal ?
                    <NewAddress
                        idUser={quote !== null ? quote?.retailUser?._id : ""}
                        typeUser="retail"
                        onClose={() => setShowModal(false)}
                        onLoader={(sts) => setLoader(sts)}
                        onResult={() => handleResult()}
                    />
                : null
            }

            <div>
                <div className="flex flex-row">
                    <div className="w-full bg-no-repeat bg-cover fixed z-10">
                        <Navbar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                    </div>
                </div>
                <div className="flex h-screen overflow-hidden">
                    <div className={`${sidebarOpen ? "w-[100vw] md:w-80 z-20" : "w-20 z-0"}`}>
                        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                    </div>
                    <div className="w-full overflow-auto flex-grow mt-[100px]">
                        <div className="space-y-5 px-3 py-5 md:px-8 md:py-8">
                            <div className="grid grid-cols-12 px-0 md:px-5">
                                <div>
                                    <Link to="/quotes">
                                        <button className="p-1 rounded-md" type="button">
                                            <span className="text-ag-secondary-dark text-3xl"><FiChevronLeft /></span>
                                        </button>
                                    </Link>
                                </div>
                                <div className="flex justify-center items-center col-span-11 text-center">
                                    <h1 className="text-[26px] md:text-xl lg:text-[26px] font-light text-ag-secondary md:mr-[8%]">
                                        Mobile Service
                                    </h1>
                                </div>
                            </div>
                            <div className="pt-3 lg:flex lg:justify-center lg:items-start lg:space-x-10 space-y-5 lg:space-y-0">
                                <div className="w-full lg:w-[65%]">
                                    <FormShippingMethod
                                        idRetail={quote !== null ? quote?.retailUser?._id : ""}
                                        blockMobileService={quote?.type === "WINDOW_TINT" ? true : false}
                                        onModal={() => setShowModal(true)}
                                        onMethod={handleShippingMethod}
                                        onRefreshAddress={isRefreshAddress}
                                        onResponseRefreshAddress={(sts) => setIsRefreshAddress(sts)}
                                    />
                                </div>
                                <div className="w-full lg:w-[35%]">
                                    <InformationQuote quote={quote} onContinue={handleContinue} disabledBtn={disabledButton} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Shipping;