import { useEffect, useState } from "react";
import { RiBankCard2Line } from "react-icons/ri";
import { AiFillStar } from "react-icons/ai";
import { IoMdCar } from "react-icons/io";
import { getPaymentMethods } from "../../../../services/WholesalerPaymentMethod";
import IconCash from "../../../../assets/icons/icon_cash_black.svg";
import IconVisa from "../../../../assets/images/visa_image.png";
import IconMasterCard from "../../../../assets/images/master_card_icon.png";
import IconAmericanExpress from "../../../../assets/images/american_express_icon.png";
import IconDiscover from "../../../../assets/images/discover_icon.png";

const creditCards = [null, null, null, IconAmericanExpress, IconVisa, IconMasterCard, IconDiscover];

const PaymentForm = ({ idWholesaler, onModal, onPayment, onCreditData, onRefreshCreditCard, onResponseRefreshCreditCard }) => {
    const [showMethod, setShowMethod] = useState({ view1: false, view2: false, view3: false });
    const [payments, setPayments] = useState([]);

    const refreshCreditCard = () => {
        new Promise((resolve, reject) => {
            getPaymentMethods(idWholesaler).then(res => {
                if (res.status === 200 && res.data.length > 0) {
                    let currentCard = null;
                    const newCards = res.data.map((element, i) => {
                        if (element.selected) {
                            currentCard = {...element, status: true};
                            return {...element, status: true}
                        }
                        return element;
                    });

                    setPayments(newCards);
                    resolve([currentCard, newCards]);
                }
            });
        }).then((responseCards) => {
            if (responseCards[0] !== null) onCreditData(responseCards[0]);
        });
    }

    const handlePayment = (paymentType) => {
        if (paymentType === "CREDIT_CARD") {
            onPayment(paymentType);
            setShowMethod({...showMethod, view1: true, view2: false, view3: false});
            refreshCreditCard();
        } else if (paymentType === "CASH") {
            onPayment(paymentType);
            setShowMethod({...showMethod, view1: false, view2: true, view3: false});
        } else {
            onPayment(paymentType);
            setShowMethod({...showMethod, view1: false, view2: false, view3: true});
        }
    }

    const handleCreditData = (creditData, arrCards) => {
        onCreditData(creditData);
        const newPayments = arrCards.map((cardElement) => {
            if (cardElement._id === creditData._id) return {...cardElement, status: true}
            return {...cardElement, status: false}
        });
        setPayments(newPayments);
    }

    useEffect(() => {
        if (onRefreshCreditCard) {
            refreshCreditCard();
            onResponseRefreshCreditCard(false);
        }
    }, [onRefreshCreditCard]);

    return (
        <div>
            <div className="space-y-3">
                <div className={`w-full p-4 border rounded-xl ${showMethod.view1 ? "border-ag-primary" : "border-ag-secondary-light"}`}>
                    <div className="flex justify-start items-center space-x-10 px-3">
                        <div>
                            <input
                                className="w-4 h-4 accent-ag-primary cursor-pointer"
                                type="radio"
                                name="payment_quote_retail"
                                onClick={() => handlePayment("CREDIT_CARD")}
                            />
                        </div>
                        <div className="flex items-center space-x-5">
                            <span className="text-xl"><RiBankCard2Line /></span>
                            <span>Credit Card</span>
                        </div>
                    </div>
                    {
                        showMethod.view1 ?
                            <div className="mt-3 pt-3 space-y-5 border-t border-ag-secondary-light">
                                <div className="pt-1 px-16 text-ag-secondary-letter">
                                    <span className="px-1">My cards</span>
                                </div>
                                {
                                    payments.map((paymentElement, i) => (
                                        <div className={`flex justify-center items-center px-3 py-2 border rounded-xl ${paymentElement?.status ? "border-ag-primary" : "border-ag-secondary-light"}`} key={i}>
                                            <div className="w-[7%]">
                                                <input
                                                    className="w-4 h-4 accent-ag-primary cursor-pointer"
                                                    type="radio"
                                                    name="payment_method_card_retail"
                                                    onChange={() => handleCreditData(paymentElement, payments)}
                                                    checked={paymentElement?.status}
                                                />
                                            </div>
                                            <div className="w-[93%] relative flex justify-center items-center space-x-3 md:space-x-5 mr-0 md:mr-14 text-ag-secondary-letter">
                                                <div className="w-8 md:w-14 h-6 md:h-10 flex justify-center items-center border border-ag-secondary-light rounded-lg p-1 md:p-2">
                                                    <img className="w-auto h-auto" src={paymentElement.cardNumber ? creditCards[String(paymentElement.cardNumber).substring(0, 1)] : null} />
                                                </div>
                                                <div>
                                                    <ul>
                                                        <li className="text-base md:text-xl font-light"><p className="truncate w-40 lg:w-48 xl:w-60 m-auto">{ paymentElement.nameofOwner }</p></li>
                                                        <li className="text-sm md:text-lg">**** **** **** { String(paymentElement.cardNumber).substring(12, 16) }</li>
                                                    </ul>
                                                </div>
                                                {
                                                    paymentElement?.status ?
                                                        <span className="absolute right-0 text-3xl text-ag-primary-dark">
                                                            <AiFillStar />
                                                        </span>
                                                    : null
                                                }
                                            </div>
                                        </div>
                                    ))
                                }
                                <div className="px-8 md:px-14">
                                    <button className="text-ag-secondary-letter" type="button" onClick={() => onModal(true)}>
                                        + Save new credit card
                                    </button>
                                </div>
                            </div>
                        : null
                    }
                </div>
                <div className={`w-full p-4 border rounded-xl ${showMethod.view2 ? "border-ag-primary" : "border-ag-secondary-light"}`}>
                    <div className="flex justify-start items-center space-x-10 px-3">
                        <div>
                            <input
                                className="w-4 h-4 accent-ag-primary cursor-pointer"
                                type="radio"
                                name="payment_quote_retail"
                                onClick={() => handlePayment("CASH")}
                            />
                        </div>
                        <div className="flex items-center space-x-5">
                            <img className="w-5 h-5" src={IconCash} />
                            <span>Cash</span>
                        </div>
                    </div>
                </div>
                <div className={`w-full p-4 border rounded-xl ${showMethod.view3 ? "border-ag-primary" : "border-ag-secondary-light"}`}>
                    <div className="flex justify-start items-center space-x-10 px-3">
                        <div>
                            <input
                                className="w-4 h-4 accent-ag-primary cursor-pointer"
                                type="radio"
                                name="payment_quote_retail"
                                onClick={() => handlePayment("CREDIT")}
                            />
                        </div>
                        <div className="flex items-center space-x-5">
                            <span className="text-xl"><IoMdCar /></span>
                            <span>In Store Credit</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PaymentForm;