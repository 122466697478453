import FormProductContent from "../../products/save_product/FormProductContent";

const ModalFormProduct = ({ productSelect, onClose, onLoader, onProduct }) => {
  return (
    <>
      <div className="fixed inset-0 z-30 flex items-center justify-center bg-black bg-opacity-50">
        <div className="relative rounded-md w-[350px] md:w-[700px] lg:w-[800px] bg-white shadow-lg">
          <div className="max-h-[80vh] px-2 md:px-5 py-5 border-0 rounded-md flex flex-col w-full bg-white">
            <button
              onClick={() => onClose(false)}
              className="absolute right-[9px] top-19 text-ag-secondary float-right text-3xl leading-none outline-none focus:outline-none"
            >
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M256 48C140.559 48 48 140.559 48 256c0 115.436 92.559 208 208 208 115.435 0 208-92.564 208-208 0-115.441-92.564-208-208-208zm104.002 282.881l-29.12 29.117L256 285.117l-74.881 74.881-29.121-29.117L226.881 256l-74.883-74.881 29.121-29.116L256 226.881l74.881-74.878 29.12 29.116L285.119 256l74.883 74.881z"></path>
              </svg>
            </button>
            <h1 className="pb-[1px] text-center text-[26px] md:text-xl lg:text-[26px] font-bold text-ag-secondary">
                 Add Product
            </h1>

            <div className="overflow-auto max-h-[80vh] md:px-1 border-0 rounded-md flex flex-col w-full bg-white">
                <FormProductContent isModal productSelect={productSelect} onProduct={onProduct} onLoader={onLoader} />
            </div>
          </div>
        </div>  
      </div>

      <div className="opacity-50 fixed inset-0 z-20 bg-black"></div>
    </>
  );
};

export default ModalFormProduct;
