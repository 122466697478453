import { useEffect, useState } from "react";
import { IoMdCloseCircle } from "react-icons/io";
import { toast } from "react-toastify";
import { alertOptions } from "../../../utils/Utilities";
import { validateVendor } from "../../../utils/Validators";

const FormVendor = ({ vendorInfo, onSave, onEdit, onClose }) => {
    const [focusClass, setFocusClass] = useState({
        name: { placeholder: "Name", status: false },
        email: { placeholder: "Email", status: false },
        phone: { placeholder: "Phone number", status: false },
        postalCode: { placeholder: "Postal code", status: false },
        state: { placeholder: "State", status: false },
        locality: { placeholder: "Locality", status: false },
        street: { placeholder: "Street", status: false },
    });

    const [vendor, setVendor] = useState({
        _id: "",
        idAddress: "",
        name: "",
        midName: "",
        lastName: "",
        email: "",
        phone: "",
        postalCode: "",
        state: "",
        locality: "",
        street: ""
    });

    const handleChange = (evt) => {
        if (evt.currentTarget.name === "lastName" || evt.currentTarget.name === "name") {
            const pattern = new RegExp(/^[A-Za-z\s]+$/g);
            if (pattern.test(evt.currentTarget.value) || evt.currentTarget.value === "") {
                setVendor({...vendor, [evt.currentTarget.name]: evt.currentTarget.value});
            }
        } else if (evt.currentTarget.name === "phone" || evt.currentTarget.name === "postalCode") {
            const pattern = new RegExp(/^[0-9\s]+$/g);
            if (pattern.test(evt.currentTarget.value) || evt.currentTarget.value === "") {
                setVendor({...vendor, [evt.currentTarget.name]: evt.currentTarget.value});
            }
        } else {
            setVendor({...vendor, [evt.currentTarget.name]: evt.currentTarget.value});
        }
    }

    const handleSubmit = (evt) => {
        evt.preventDefault();
        if (
            vendor.name !== "" && vendor.email !== "" && vendor.phone !== "" && vendor.postalCode !== "" && vendor.state !== "" &&
            vendor.locality !== "" && vendor.street !== ""
        ) {

            let vendorModel = {
                name: vendor.name,
                lastName: vendor.lastName,
                email: vendor.email,
                phone: vendor.phone,
                address: {
                    postalCode: vendor.postalCode,
                    state: vendor.state,
                    locality: vendor.locality,
                    street: vendor.street
                }
            }

            const result = validateVendor(vendorModel);
            if (result.status) {
                if (vendorInfo !== null) {
                    vendorModel._id = vendor._id;
                    onEdit(vendorInfo._id, vendorModel);
                } else {
                    onSave(vendorModel);
                }
            } else {
                toast.warning(result.msg, alertOptions);
            }
        } else {
            toast.info("Please fill in all the fields of the form", alertOptions);
        }
    }

    useEffect(() => {
        if (vendorInfo !== null) {
            setVendor({...vendor,
                _id: vendorInfo._id ? vendorInfo._id : "",
                idAddress: vendorInfo.address ? vendorInfo.address : "",
                name: vendorInfo.name ? vendorInfo.name : "",
                midName: vendorInfo.midName ? vendorInfo.midName : "",
                lastName: vendorInfo.lastName ? vendorInfo.lastName : "",
                email: vendorInfo.email ? vendorInfo.email : "",
                phone: vendorInfo.phone ? vendorInfo.phone : "",
                postalCode: vendorInfo.address.postalCode ? vendorInfo.address.postalCode : "",
                state: vendorInfo.address.state ? vendorInfo.address.state : "",
                locality: vendorInfo.address.locality ? vendorInfo.address.locality : "",
                street: vendorInfo.address.street ? vendorInfo.address.street : ""
            });

            setFocusClass({...focusClass,
                name: { placeholder: "Name", status: vendorInfo.name !== "" ? true : false },
                email: { placeholder: "Email", status: vendorInfo.email !== "" ? true : false },
                phone: { placeholder: "Phone number", status: vendorInfo.phone !== "" ? true : false },
                postalCode: { placeholder: "Postal code", status: vendorInfo.address.postalCode !== "" ? true : false },
                state: { placeholder: "State", status: vendorInfo.address.state !== "" ? true : false },
                locality: { placeholder: "Locality", status: vendorInfo.address.locality !== "" ? true : false },
                street: { placeholder: "Street", status: vendorInfo.address.street !== "" ? true : false }
            });
        }
    }, [vendorInfo]);

    return (
        <>
            <div className="block">
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-30 outline-none focus:outline-none">
                    <div className="w-[320px] md:w-[700px] lg:w-[800px]">
                        <div className="p-5 border-0 rounded-lg shadow-lg flex-col w-full bg-white outline-none focus:outline-none">
                            <div className="w-full flex items-center justify-center rounded text-center">
                                <div className="w-[95%] ">
                                    <span className="text-xl text-ag-secondary font-bold md:ml-[5%]">
                                        Create / Edit Vendor
                                    </span>
                                </div>
                                <div className="w-[5%]">
                                    <button
                                        className="text-ag-secondary float-right text-3xl leading-none outline-none focus:outline-none"
                                        onClick={() => onClose(false)}
                                    >
                                        <IoMdCloseCircle />
                                    </button>
                                </div>
                            </div>
                            <div className="relative px-2 md:px-5 pb-2 pt-5 flex-auto">
                                <form onSubmit={handleSubmit}>
                                    <div className="space-y-3">
                                        <div className="grid gap-3 grid-cols-2 rows-2">
                                            <div className="col-span-2">
                                                <input
                                                    className={`w-full text-ag-secondary-letter border border-gray-500 p-2 rounded-xl truncate ${focusClass.name.status ? "text-left" : "text-center"}`}
                                                    type="text"
                                                    name="name"
                                                    placeholder={focusClass.name.placeholder}
                                                    onChange={handleChange}
                                                    value={vendor.name}
                                                    onFocus={() => setFocusClass({...focusClass, name: { placeholder: "", status: true }})}
                                                    onBlur={(evt) => evt.target.value === "" ? setFocusClass({...focusClass, name: { placeholder: "Name", status: false }}) : null}
                                                    maxLength={40}
                                                />
                                            </div>
                                            <div>
                                                <input
                                                    className={`w-full text-ag-secondary-letter border border-gray-500 p-2 rounded-xl truncate ${focusClass.email.status ? "text-left" : "text-center"}`}
                                                    type="text"
                                                    name="email"
                                                    placeholder={focusClass.email.placeholder}
                                                    onChange={handleChange}
                                                    value={vendor.email}
                                                    onFocus={() => setFocusClass({...focusClass, email: { placeholder: "", status: true }})}
                                                    onBlur={(evt) => evt.target.value === "" ? setFocusClass({...focusClass, email: { placeholder: "Email", status: false }}) : null}
                                                    maxLength={40}
                                                />
                                            </div>
                                            <div>
                                                <input
                                                    className={`w-full text-ag-secondary-letter border border-gray-500 p-2 rounded-xl truncate ${focusClass.phone.status ? "text-left" : "text-center"}`}
                                                    type="text"
                                                    name="phone"
                                                    placeholder={focusClass.phone.placeholder}
                                                    onChange={handleChange}
                                                    value={vendor.phone}
                                                    onFocus={() => setFocusClass({...focusClass, phone: { placeholder: "", status: true }})}
                                                    onBlur={(evt) => evt.target.value === "" ? setFocusClass({...focusClass, phone: { placeholder: "Phone number", status: false }}) : null}
                                                    maxLength={10}
                                                />
                                            </div>
                                            <div>
                                                <input
                                                    className={`w-full text-ag-secondary-letter border border-gray-500 p-2 rounded-xl truncate ${focusClass.street.status ? "text-left" : "text-center"}`}
                                                    type="text"
                                                    name="street"
                                                    placeholder={focusClass.street.placeholder}
                                                    onChange={handleChange}
                                                    value={vendor.street}
                                                    onFocus={() => setFocusClass({...focusClass, street: { placeholder: "", status: true }})}
                                                    onBlur={(evt) => evt.target.value === "" ? setFocusClass({...focusClass, street: { placeholder: "Street", status: false }}) : null}
                                                    maxLength={40}
                                                />
                                            </div>
                                            <div>
                                                <input
                                                    className={`w-full text-ag-secondary-letter border border-gray-500 p-2 rounded-xl truncate ${focusClass.locality.status ? "text-left" : "text-center"}`}
                                                    type="text"
                                                    name="locality"
                                                    placeholder={focusClass.locality.placeholder}
                                                    onChange={handleChange}
                                                    value={vendor.locality}
                                                    onFocus={() => setFocusClass({...focusClass, locality: { placeholder: "", status: true }})}
                                                    onBlur={(evt) => evt.target.value === "" ? setFocusClass({...focusClass, locality: { placeholder: "Locality", status: false }}) : null}
                                                    maxLength={40}
                                                />
                                            </div>
                                            <div>
                                                <input
                                                    className={`w-full text-ag-secondary-letter border border-gray-500 p-2 rounded-xl truncate ${focusClass.state.status ? "text-left" : "text-center"}`}
                                                    type="text"
                                                    name="state"
                                                    placeholder={focusClass.state.placeholder}
                                                    onChange={handleChange}
                                                    value={vendor.state}
                                                    onFocus={() => setFocusClass({...focusClass, state: { placeholder: "", status: true }})}
                                                    onBlur={(evt) => evt.target.value === "" ? setFocusClass({...focusClass, state: { placeholder: "State", status: false }}) : null}
                                                    maxLength={40}
                                                />
                                            </div>
                                            <div>
                                                <input
                                                    className={`w-full text-ag-secondary-letter border border-gray-500 p-2 rounded-xl truncate ${focusClass.postalCode.status ? "text-left" : "text-center"}`}
                                                    type="text"
                                                    name="postalCode"
                                                    placeholder={focusClass.postalCode.placeholder}
                                                    onChange={handleChange}
                                                    value={vendor.postalCode}
                                                    onFocus={() => setFocusClass({...focusClass, postalCode: { placeholder: "", status: true }})}
                                                    onBlur={(evt) => evt.target.value === "" ? setFocusClass({...focusClass, postalCode: { placeholder: "Postal code", status: false }}) : null}
                                                    maxLength={5}
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full md:w-[60%] m-auto">
                                            <button
                                                className="w-full bg-ag-primary-light text-white p-2 rounded-xl"
                                                type="submit"
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="opacity-50 fixed inset-0 z-20 bg-black"></div>
        </>
    );
}

export default FormVendor;