import { FiChevronLeft } from "react-icons/fi";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { savePaymentQuote, scheduleQuote } from "../../../../services/Quotes";
import { alertOptions, formatReverseDate } from "../../../../utils/Utilities";
import InformationQuote from "../InformationQuote";
import ScheduleQuote from "./ScheduleQuote";
import Navbar from "../../../../components/navbar/Navbar";
import Sidebar from "../../../../components/sidebar/Sidebar";
import Loader from "../../../../components/loader/Loader";

const Schedule = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [loader, setLoader] = useState(true);
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const [quote, setQuote] = useState(null);

    const [disabledButton, setDisabledButton] = useState(false);

    const handleSchedule = () => {
        if (quote.day && quote.hour) {
            setLoader(true);
            const body = {
                installationType: quote?.shippingMethod === "SHIP" ? "HOMESERVICE" : "WAREHOUSESERVICE",
                date: `${formatReverseDate(quote.day)}T${quote.hour}:00`,
                warehouse: quote?.fromWarehouse,
                sameDay: quote?.sameDay ? true : false
            }

            scheduleQuote(quote._id, body).then(res => {
                if (res.status === 200) {
                    const bodyQuote = {...body,
                        names: quote?.retailUser?.names,
                        lastNames: quote?.retailUser?.lastNames,
                        email: quote?.retailUser?.user?.email,
                        phoneNumber: quote?.retailUser?.phoneNumber,
                        total: Number(quote.price) + Number(quote.costShipping),
                        installationType: quote?.shippingMethod === "SHIP" ? "HOMESERVICE" : "WAREHOUSESERVICE",
                        date: `${formatReverseDate(quote.day)}T${quote.hour}:00`,
                        shippingMethod: quote.shippingMethod,
                        warehouse: quote.fromWarehouse,
                        costShipping: quote.costShipping,
                        paymentMethod: quote.paymentMethod
                    }

                    // Shipping method
                    if (quote.shippingMethod === "SHIP") {
                        bodyQuote.userAddress = quote.userAddress;
                    } else {
                        bodyQuote.warehouseAddress = quote.warehouseAddress;
                        bodyQuote.fromWarehouse = quote.fromWarehouse;
                    }

                    savePaymentQuote(quote._id, bodyQuote).then(resQuote => {
                        if (resQuote.status === 200) {
                            setLoader(false);
                            navigate(`/appointments`);
                            setDisabledButton(true)
                        } else {
                            setLoader(false);
                            toast.warning(resQuote.response.data.message, alertOptions);
                        }
                    }).catch((error) => {
                        setLoader(false);
                        toast.error(error.response.data.message, alertOptions);
                    });
                } else {
                    setLoader(false);
                    toast.warning(res.response.data.message, alertOptions);
                }
            }).catch(error => {
                setLoader(false);
                toast.warning(error.response.data.message, alertOptions);
            });
        } else {
            toast.warning("Please select the date and time of the appointment", alertOptions);
        }
    }

    const handleDay = (daySelected) => {
        setQuote({...quote, day: daySelected, hour: null});
    }

    useEffect(() => {
        if (location.state) {
            setLoader(true);
            setQuote(location.state);
            setLoader(false);
        }
    }, []);

    return (
        <>
            { loader ? <Loader /> : null }

            <div>
                <div className="flex flex-row">
                    <div className="w-full bg-no-repeat bg-cover fixed z-10">
                        <Navbar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                    </div>
                </div>
                <div className="flex h-screen overflow-hidden">
                    <div className={`${sidebarOpen ? "w-[100vw] md:w-80 z-20" : "w-20 z-0"}`}>
                        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                    </div>
                    <div className="w-full overflow-auto flex-grow mt-[100px]">
                        <div className="space-y-5 px-3 py-5 md:px-8 md:py-8">
                            <div className="grid grid-cols-12 px-0 md:px-5">
                                <div>
                                    <button className="p-1 rounded-md" type="button" onClick={() => navigate(-1)}>
                                        <span className="text-ag-secondary-dark text-3xl"><FiChevronLeft /></span>
                                    </button>
                                </div>
                                <div className="flex justify-center items-center col-span-11 text-center">
                                    <h1 className="text-[26px] md:text-xl lg:text-[26px] font-light text-ag-secondary md:mr-[8%]">
                                        Schedule your appointment
                                    </h1>
                                </div>
                            </div>
                            <div className="pt-3 lg:flex lg:justify-center lg:items-start lg:space-x-10 space-y-5 lg:space-y-0">
                                <div className="w-full lg:w-[65%]">
                                    <ScheduleQuote
                                        shippingMethod={quote?.shippingMethod}
                                        warehouseID={quote?.fromWarehouse?._id}
                                        dayValue={quote?.day}
                                        onDay={(daySelected) => handleDay(daySelected)}
                                        onHour={(hourSelected) => hourSelected !== null ? setQuote({...quote, hour: hourSelected}) : setQuote({...quote, hour: null})}
                                        onSunday={(sndVal, daySel) => setQuote({...quote, day: daySel})}
                                    />
                                </div>
                                <div className="w-full lg:w-[35%]">
                                    <InformationQuote quote={quote} onContinue={handleSchedule} disabledBtn={disabledButton} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Schedule;