import { FaCheckCircle } from "react-icons/fa";
import { formatCurrency, formatDateWordEng } from "../../../../utils/Utilities";
import { ReactComponent as IconTicket } from "../../../../assets/icons/ticket.svg";
import { AiFillEdit } from "react-icons/ai";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { HiArrowRight } from "react-icons/hi";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { PermitsContext } from "../../../../services/Permits";

const statusColor = {
    inprocess: "bg-yellow-300",
    canceled: "bg-red-600",
    draft: "bg-blue-600",
    completed: "bg-green-500"
}

const arrStatus = {
    canceled: "Canceled",
    completed: "Completed",
    draft: "Draft",
    inprocess: "In Process"
};

const RowsTransfer = ({ transfer, onCanceled }) => {
    const { dataSession } = useContext(PermitsContext);

    return (
        <tr>
            <td className="w-[7%] border-[0.5px] border-ag-secondary-light text-xs font-normal p-2">
                <div className="space-y-1">
                    <span>{ arrStatus[transfer.status] }</span>
                    <div className={`w-3 h-3 rounded-full m-auto ${statusColor[transfer.status]}`}></div>
                </div>
            </td>
            <td className="w-[8%] border-[0.5px] border-ag-secondary-light text-xs font-normal p-2">{ formatDateWordEng(transfer.createdAt) }</td>
            <td className="w-[12%] border-[0.5px] border-ag-secondary-light text-xs font-normal p-2">
                <p className="truncate w-28 m-auto">{`${transfer.cratedBy.names} ${transfer.cratedBy.lastNames}`}</p>
            </td>
            <td className="w-[8%] border-[0.5px] border-ag-secondary-light text-xs font-normal p-2">T-{String(transfer._id).substring(String(transfer._id).length - 5).toLocaleUpperCase()}</td>
            <td className="w-[19%] border-[0.5px] border-ag-secondary-light text-xs font-normal p-2">
                <div className="flex justify-center items-center space-x-3">
                    <p className="truncate w-24 m-auto" title={transfer?.fromWarehouse ? transfer?.fromWarehouse?.name : "Warehouse not found"}>
                        { transfer?.fromWarehouse ? transfer?.fromWarehouse?.name : "Warehouse not found" }
                    </p>
                    <span className="text-xl"><HiArrowRight /></span>
                    <p className="truncate w-24 m-auto" title={transfer?.toWarehouse ? transfer?.toWarehouse?.name : "Warehouse not found"}>
                        { transfer?.toWarehouse ? transfer?.toWarehouse?.name : "Warehouse not found" }
                    </p>
                </div>
            </td>
            <td className="w-[8%] border-[0.5px] border-ag-secondary-light text-xs font-normal p-2">{ transfer.totalQuantity }</td>
            <td className="w-[8%] border-[0.5px] border-ag-secondary-light text-xs font-normal p-2">{ formatCurrency(transfer.subTotal) }</td>
            <td className="w-[8%] border-[0.5px] border-ag-secondary-light text-xs font-normal p-2">{ formatDateWordEng(transfer.lastUpdate) }</td>
            <td className="w-[12%] border-[0.5px] border-ag-secondary-light text-xs font-normal p-2">
                <p className="truncate w-28 m-auto">{`${transfer.lastUpdateBy.names} ${transfer.lastUpdateBy.lastNames}`}</p>
            </td>
            <td className="w-[10%] border-[0.5px] border-ag-secondary-light text-xs font-normal p-2">
                {
                    transfer.status === "inprocess" ?
                        <div className="w-full flex justify-center items-center space-x-3">
                            {
                                (transfer?.canEdit) || dataSession?.userType === "ADMIN" ?
                                    <Link to={`/inventory/transfer/confirm/${transfer._id}`}>
                                        <button type="button">
                                            <span className="text-green-600 text-3xl"><FaCheckCircle /></span>
                                        </button>
                                    </Link>
                                :
                                    <Link to={`/inventory/transfer/show/${transfer._id}`}>
                                        <button type="button">
                                            <IconTicket />
                                        </button>
                                    </Link>
                            }
                            <button type="button" onClick={() => onCanceled(transfer._id)}>
                                <span className="text-red-600 text-4xl"><IoMdCloseCircleOutline /></span>
                            </button>
                        </div>
                    : transfer.status === "draft" ?
                        <div className="w-full flex justify-center items-center space-x-3">
                            <Link to={`/inventory/transfer/element/${transfer._id}`}>
                                <button type="button">
                                    <span className="text-green-700 text-4xl"><AiFillEdit /></span>
                                </button>
                            </Link>
                            <button type="button" onClick={() => onCanceled(transfer._id)}>
                                <span className="text-red-600 text-4xl"><IoMdCloseCircleOutline /></span>
                            </button>
                        </div>
                    :
                        <div className="w-full flex justify-center items-center space-x-3">
                            <Link to={`/inventory/transfer/show/${transfer._id}`}>
                                <button type="button">
                                    <IconTicket />
                                </button>
                            </Link>
                        </div>
                }
            </td>
        </tr>
    );
}

export default RowsTransfer;