import { useEffect, useState } from "react";
import { BsFillCheckCircleFill, BsInfoCircleFill } from "react-icons/bs";
import { toast } from "react-toastify";
import { alertOptions } from "../../../../utils/Utilities";
import FeaturesByProductOrder from "../../../wholesaler/mycart/FeaturesByProductOrder";

const RowsDelivery = ({ item, onItem, onCheck, onReturn, onCompatible, onFeatures }) => {
    const [values, setValues] = useState({ real: 0, note: "" });

    const handleChange = (evt) => {
        if (evt.target.name === "real") {
            if (!isNaN(evt.target.value) && evt.target.value >= 0) {
                setValues({...values, real: evt.target.value});
                onItem(evt, item);
            } else {
                toast.warning("Please check that the real field is a numeric value", alertOptions);
            }
        } else {
            setValues({...values, note: evt.target.value});
            onItem(evt, item);
        }
    }

    // const handleReturn = () => {
    //     if ((Number(item?.quantity) - Number(item?.real)) !== 0 && item?.real) {
    //         if (item?.note && item?.note !== "") {
    //             const returnModel = {
    //                 reason: item?.note,
    //                 quantity: (Number(item?.quantity) - Number(item?.real)),
    //                 price: item?.price,
    //                 total: (Number(item?.quantity) - Number(item?.real)) * item?.price,
    //                 productOrder: { _id: item?._id },
    //                 photos: []
    //             }
    //             onReturn(returnModel, item?.real, Number(item?.quantity) - Number(item?.real));
    //         } else {
    //             toast.warning("Please enter the reason for the return in the note field", alertOptions);
    //         }
    //     }
    // }

    useEffect(() => {
        if (item?.note) setValues({...values, note: item.note});
    }, [item?.note]);

    useEffect(() => {
        if (item?.real) setValues({...values, real: item.real});
    }, [item?.real]);

    return (
        <tr>
            <td className="w-[35%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-3">
                {
                    item?.type !== "POWER_WINDOW_REPAIR" && item?.type !== "WINDOW_TINT" ?
                        <div className="flex justify-center items-center space-x-3">
                            {
                                item.compatible_vehicles.map((prodComp, i) => {
                                    if (i === 0) {
                                        return (
                                            <div>
                                                <p className="w-40 m-auto" key={i} title={
                                                    `${prodComp?.nag} ` +
                                                    `(${String(prodComp?.year).replace(/\s+/g, '').split(',').length > 1 ? `${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[0]} - ${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[Number(String(prodComp?.year).replace(/\s+/g, '').split(',').length-1)]}` : prodComp?.year})`
                                                }>
                                                    { prodComp?.nag }{' ('}
                                                    { String(prodComp?.year).replace(/\s+/g, '').split(',').length > 1 ? `${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[0]} - ${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[Number(String(prodComp?.year).replace(/\s+/g, '').split(',').length-1)]}` : prodComp?.year }{') '}
                                                </p>
                                                <p className="w-40 m-auto" key={i} title={
                                                    `${prodComp?.brand} ` +
                                                    `${prodComp?.model} ` +
                                                    `${prodComp?.type_car}`
                                                }>
                                                    { prodComp?.brand }{' '}
                                                    { prodComp?.model }{' '}
                                                    { prodComp?.type_car }
                                                </p>
                                            </div>
                                        )
                                    }
                                })
                            }
                            <div className="pt-1 pl-2">
                                <div>
                                    {
                                        item.compatible_vehicles.length > 1 ?
                                            <p className="truncate w-40 m-auto font-semibold cursor-pointer" onClick={() => onCompatible(item)}>
                                                See compatible vehicles +
                                            </p>
                                        : null
                                    }
                                </div>
                            </div>
                        </div>
                    :
                        <p>{ `${ item?.year } ${item?.brand} ${item?.model} ${item?.typeGlass}` }</p>
                }
            </td>
            <td className="w-[14%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-6">
                <FeaturesByProductOrder features={item} />
            </td>
            <td className="w-[10%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-3">
                { item?.type !== "POWER_WINDOW_REPAIR" && item?.type !== "WINDOW_TINT" ? item.quantity : 1 }
            </td>
            <td className="w-[15%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-3">
                {
                    item?.type !== "POWER_WINDOW_REPAIR" && item?.type !== "WINDOW_TINT" ?
                        <input
                            className="w-full h-12 text-center border border-ag-secondary-light rounded-md"
                            name="real"
                            type="number"
                            onChange={(evt) => handleChange(evt)}
                            defaultValue={item?.real}
                            value={values.real}
                        />
                    : "---"
                }
            </td>
            <td className="w-[15%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-3">
                {
                    item?.type !== "POWER_WINDOW_REPAIR" && item?.type !== "WINDOW_TINT" ?
                        <p className="flex items-center justify-center w-full h-12 text-center border border-ag-secondary-light rounded-md">
                            { item.real ? item.quantity - item?.real : "" }
                        </p>
                    : "---"
                }
            </td>
            <td className="w-[15%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-3">
                {
                    item?.type !== "POWER_WINDOW_REPAIR" && item?.type !== "WINDOW_TINT" ?
                        <input
                            className="w-full h-12 text-center border border-ag-secondary-light rounded-md"
                            name="note"
                            type="text"
                            onChange={(evt) => handleChange(evt)}
                            defaultValue={item?.note}
                        />
                    : "---"
                }
            </td>
            <td className="w-[10%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-3">
                <div className="space-y-3">
                    {
                        item?.type !== "POWER_WINDOW_REPAIR" && item?.type !== "WINDOW_TINT" ?
                            <div className={`flex justify-center items-center space-x-1 cursor-pointer ${ item?.check && item?.qtyDefault === item?.real ? "text-ag-secondary-light" : "text-green-500" }`} onClick={() => onCheck(item)}>
                                <span className="text-lg"><BsFillCheckCircleFill /></span>
                                <span className="text-xs">Check</span>
                            </div>
                        : "---"
                    }
                    {/* <div className={`flex justify-center items-center space-x-1 ${ ((Number(item?.quantity) - Number(item?.real)) === 0 || !item?.real) ? "text-ag-secondary-light" : "text-red-500 cursor-pointer"}`} onClick={() => handleReturn()}>
                        <span className="text-lg"><AiFillCloseSquare /></span>
                        <span className="text-xs">Report Incident</span>
                    </div> */}
                </div>
            </td>
        </tr>
    );
}

export default RowsDelivery;