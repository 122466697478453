import { Route, Routes } from "react-router-dom";
import { PermitsProvider } from "../services/Permits";
import PrivateRoute from "./PrivateRoute";

import LoginA from "../views/auth/admin/Login";
import LoginW from "../views/auth/wholesaler/Login";
import RecoverPassword from "../views/auth/admin/RecoverPassword";
import ChangePassword from "../views/auth/admin/ChangePassword";
import RegisterW from "../views/auth/wholesaler/Register";

import Profile from "../views/admin/profile/Profile";
import Admin from "../views/admin/Admin";
import Administrators from "../views/admin/administrators/Administrators";
import Roles from "../views/admin/administrators/roles/Roles";
import FormRoles from "../views/admin/administrators/roles/FormRoles";
import Users from "../views/admin/users/Users";
import Vendor from "../views/admin/vendors/Vendor";
import History from "../views/admin/users/history/History";
import Wholesaler from "../views/admin/users/wholesaler/Wholesaler";
import Products from "../views/admin/products/Products";
import FormProducts from "../views/admin/products/save_product/FormProducts";
import PriceHistory from "../views/admin/products/price_history/PriceHistory";
import Warehouse from "../views/admin/warehouses/Warehouse";
import Inventory from "../views/admin/inventory/Inventory";
import SaveInventory from "../views/admin/inventory/save_product/SaveInventory";
import Stocktake from "../views/admin/inventory/stocktake/Stocktake";
import Transfer from "../views/admin/inventory/transfer/Transfer";
import SaveStocktake from "../views/admin/inventory/stocktake/save_stocktake/SaveStocktake";
import SaveTransfer from "../views/admin/inventory/transfer/save_transfer/SaveTransfer";
import ConfirmTransfer from "../views/admin/inventory/transfer/save_transfer/ConfirmTransfer";
import ShowTransfer from "../views/admin/inventory/transfer/save_transfer/ShowTransfer";
import Orders from "../views/admin/orders/Orders";
import DetailsOrders from "../views/admin/orders/details/DetailsOrders";
import DeliveryOrders from "../views/admin/orders/delivery/DeliveryOrders";
import OrganizeOrders from "../views/admin/orders/organize/OrganizeOrders";
import Quotes from "../views/admin/quotes/Quotes";
import QuotesShipping from "../views/admin/quotes/shipping/Shipping";
import QuotesSchedule from "../views/admin/quotes/schedule/Schedule";
import Appointments from "../views/admin/quotes/appointments/Appointments";
import ProcessOrders from "../views/admin/orders/process/ProcessOrders";
import Returns from "../views/admin/returns/Returns";
import Sales from "../views/admin/sales/Sales";
import Reports from "../views/admin/reports/Reports";
import Approved from "../views/admin/returns/approved/Approved";
import GraphicalView from "../views/admin/reports/graphicalView/GraphicalView";

import Reseller from "../views/wholesaler/Wholesaler";
import ResultWholesaler from "../views/wholesaler/search_result/result/SearchResult";
import MyCartWholesaler from "../views/wholesaler/mycart/MyCart";
import ProfileWholesaler from "../views/wholesaler/profile/Profile";
import AdressesWholesaler from "../views/wholesaler/adresses/Adresses";
import PaymentWholesaler from "../views/wholesaler/payment/Payment";
import SearchDetailWholesaler from "../views/wholesaler/search_result/detail/SearchDetail";
import AboutUsWholesaler from "../views/wholesaler/about_us/AboutUs";
import Insurers from "../views/admin/quotes/insurers/Insurers";
import MyCartShipping from "../views/wholesaler/mycart/shipping/MyCartShipping";
import MyCartPayment from "../views/wholesaler/mycart/payment/MyCartPayment";
import OrdersWholesaler from "../views/wholesaler/orders/Orders";
import WishList from "../views/admin/wishList/WishList";
import Interchangeable from "../views/admin/products/interchangeable/Interchangeable";
import DiscountList from "../views/wholesaler/search_result/result/discounts/DiscountList";
import QuotesPWRWT from "../views/admin/quotes/QuotesPWRWT";

const RoutesApp = () => {
    return (
        <PermitsProvider>
            <Routes>
                {/* ------------------------------ Quote free ------------------------------ */}
                <Route path="/recoverPassword" element={<RecoverPassword />} />
                <Route path="/recover/password/:user/:code" element={<ChangePassword />} />

                {/* ------------------------------ Admin ------------------------------ */}
                <Route path="/" element={<LoginA />} />

                {/* ------------------------------ Wholesaler ------------------------------ */}
                <Route path="/wholesaler/" element={<LoginW />} />
                <Route path="/wholesaler/register" element={<RegisterW />} />


                {/* ------------------------------ PRIVATE ROUTES ------------------------------ */}
                <Route element={<PrivateRoute />}>
                    <Route path="/home" element={<Admin />} />
                    <Route path="/profile" element={<Profile />} />

                    {/* Admin => Administrators */}
                    <Route path="/administrators" element={<Administrators />} />
                    <Route path="/administrators/roles" element={<Roles />} />
                    <Route path="/administrators/roles/element" element={<FormRoles />} />
                    <Route path="/administrators/roles/element/:id" element={<FormRoles editRole />} />

                    {/* Admin => Users */}
                    <Route path="/users" element={<Users />} />
                    <Route path="/users/wholesaler" element={<Wholesaler />} />
                    <Route path="/users/history/:id" element={<History />} />

                    {/* Admin => Vendors */}
                    <Route path="/vendors" element={<Vendor />} />

                    {/* Admin => Products */}
                    <Route path="/products" element={<Products />} />
                    <Route path="/products/element" element={<FormProducts />} />
                    <Route path="/products/element/:id" element={<FormProducts editProduct />} />
                    <Route path="/products/history" element={<PriceHistory />} />
                    <Route path="/products/interchanges" element={<Interchangeable />} />

                    {/* Admin => Warehouses */}
                    <Route path="/warehouses" element={<Warehouse />} />

                    {/* Admin => Inventory */}
                    <Route path="/inventory" element={<Inventory />} />
                    <Route path="/inventory/element" element={<SaveInventory />} />
                    <Route path="/inventory/element/:id" element={<SaveInventory />} />
                    <Route path="/inventory/stocktake" element={<Stocktake />} />
                    <Route path="/inventory/stocktake/element" element={<SaveStocktake />} />
                    <Route path="/inventory/stocktake/element/:id" element={<SaveStocktake editStocktake />} />
                    <Route path="/inventory/transfer" element={<Transfer />} />
                    <Route path="/inventory/transfer/element" element={<SaveTransfer />} />
                    <Route path="/inventory/transfer/element/:id" element={<SaveTransfer editTransfer />} />
                    <Route path="/inventory/transfer/confirm/:id" element={<ConfirmTransfer />} />
                    <Route path="/inventory/transfer/show/:id" element={<ShowTransfer />} />

                    {/* Admin => Returns */}
                    <Route path="/returns" element={<Returns />} />
                    <Route path="/returns/approved" element={<Approved />} />

                    {/* Admin => Orders */}
                    <Route path="/orders" element={<Orders />} />
                    <Route path="/orders/detail/:id" element={<DetailsOrders />} />
                    <Route path="/orders/delivery/:id" element={<DeliveryOrders />} />
                    <Route path="/orders/organize/:id" element={<OrganizeOrders />} />
                    <Route path="/orders/in_care" element={<ProcessOrders />} />

                    {/* Admin => Quotes */}
                    <Route path="/quotes" element={<Quotes />} />
                    <Route path="/appointments" element={<Appointments />} />
                    <Route path="/insurers" element={<Insurers />} />
                    <Route path="/quotes/services" element={<QuotesPWRWT />} />

                    {/* Admin => Quotes => Shipping */}
                    <Route path="/quotes/:id/shipping" element={<QuotesShipping />} />
                    <Route path="/quotes/:id/schedule" element={<QuotesSchedule />} />

                    {/* Admin => Wish List */}
                    <Route path="/wishList" element={<WishList />} />

                    {/* Admin => Sales */}
                    <Route path="/sales" element={<Sales />} />

                    {/* Admin => Reports */}
                    <Route path="/reports" element={<Reports />} />
                    <Route path="/reports/graphics" element={<GraphicalView />} />



                    {/* ------------------------------ Wholesaler ------------------------------ */}
                    <Route path="/wholesaler/home" element={<Reseller />} />
                    <Route path="/wholesaler/home/search" element={<ResultWholesaler />} />
                    <Route path="/wholesaler/profile" element={<ProfileWholesaler />} />
                    <Route path="/wholesaler/adresses" element={<AdressesWholesaler />} />
                    <Route path="/wholesaler/methodsPayment" element={<PaymentWholesaler />} />
                    <Route path="/wholesaler/search/:id" element={<SearchDetailWholesaler />} />

                    <Route path="/wholesaler/home/discounts" element={<DiscountList />} />

                    {/* Wholesaler => About Us */}
                    <Route path="/wholesaler/about_us" element={<AboutUsWholesaler />} />

                    {/* Wholesaler => My Cart */}
                    <Route path="/wholesaler/mycart" element={<MyCartWholesaler />} />
                    <Route path="/wholesaler/mycart/shipping" element={<MyCartShipping />} />
                    <Route path="/wholesaler/mycart/payment" element={<MyCartPayment />} />

                    {/* Wholesaler => My Orders */}
                    <Route path="/wholesaler/orders" element={<OrdersWholesaler />} />
                </Route>
            </Routes>
        </PermitsProvider>
    );
}

export default RoutesApp;