import { useContext, useEffect, useState } from "react";
import { PermitsContext } from "../../../services/Permits";
import { alertOptions, formatReverseDate, removeAccents } from "../../../utils/Utilities";
import { getReturns, cancelReturn, approvedReturn } from "../../../services/Returns";
import { getWholesalerUsers } from "../../../services/Wholesaler";
import { getWarehouses } from "../../../services/Warehouse";
import { BiSearch } from "react-icons/bi";
import { toast } from "react-toastify";
import { getRetailUsers } from "../../../services/Retail";
import Navbar from "../../../components/navbar/Navbar";
import Sidebar from "../../../components/sidebar/Sidebar";
import Filters from "./Filters";
import Loader from "../../../components/loader/Loader";
import TableReturns from "./TableReturns";
import Modal from "../../../components/modal/Modal";
import DetailReturns from "./DetailReturns";

const Returns = () => {
    const { permits, dataSession, clearContext, logOutModal, setLogOutModal } = useContext(PermitsContext);
    const [loader, setLoader] = useState(true);
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const [showModal, setShowModal] = useState({ status: false, type: "", message: "", data: null });
    const [showDetail, setShowDetail] = useState({ status: false, data: null });
    const [returns, setReturns] = useState([]);
    const [returnsUniv, setReturnsUniv] = useState([]);
    const [searchWord, setSearchWord] = useState(null);
    const [warehouseOptions, setWarehouseOptions] = useState([]);
    const [clientOptions, setClientOptions] = useState([]);
    const [statusOptions] = useState([
        { label: "Canceled", value: "CANCELED" },
        { label: "In process", value: "INPROCESS" }
    ]);

    const [focusClass, setFocusClass] = useState({
        search: { placeholder: "Search", status: false }
    });

    const handleFilters = (wordFilter, options) => {
        if (wordFilter === "" && options.warehouse === "" && options.status === "" && options.client === "" && options.dateStart === "" && options.dateEnd === "") {
            setReturns(returnsUniv);
        } else {
            let returnsFound = returnsUniv;

            if (wordFilter !== "") {
                let palabra = new RegExp(`${removeAccents(wordFilter)}.*`, "i");
                returnsFound = returnsFound.filter(element => {
                    let palabraStatus = new RegExp(`${removeAccents(String(wordFilter).replace(/\s+/g, ''))}.*`, "i");
                    if (
                        palabraStatus.test(removeAccents(element.status)) ||
                        palabra.test(removeAccents(element.reason)) ||
                        palabra.test(removeAccents(element.productOrder.productInventory.product.nags)) ||
                        palabra.test(removeAccents(`${element?.wholesaler?.names} ${element?.wholesaler?.lastNames}`)) ||
                        palabra.test(removeAccents(`${element?.employee?.names} ${element?.employee?.lastNames}`)) ||
                        palabra.test(element.total) ||
                        palabra.test(removeAccents(element?.warehouse?.name))
                    ) {
                        return element;
                    }
                });
            }

            if (options.warehouse !== "") {
                returnsFound = returnsFound.filter(element => (element.warehouse?._id === options.warehouse));
            }

            if (options.status !== "") {
                returnsFound = returnsFound.filter(element => (element.status === options.status));
            }

            if (options.client !== "") {
                returnsFound = returnsFound.filter(element => (element.wholesaler?._id === options.client));
            }

            if ((options.dateStart !== "" && options.dateEnd === "") || (options.dateStart === "" && options.dateEnd !== "")) {
                toast.info("Please enter both dates to perform the filter", alertOptions);
                returnsFound = [];
            } else if (options.dateStart !== "" && options.dateEnd !== "") {
                if (formatReverseDate(options.dateStart) > formatReverseDate(options.dateEnd)) {
                    toast.info("The start date is greater than the end date", alertOptions);
                    returnsFound = [];
                } else {
                    returnsFound = returnsFound.filter(element => (
                        (formatReverseDate(element.createdAt) >= formatReverseDate(options.dateStart)) && 
                        (formatReverseDate(element.createdAt) <= formatReverseDate(options.dateEnd))
                    ));
                }
            }

            setReturns(returnsFound);
        }
    }

    const getData = () => {
        getReturns().then(res => {
            if (res.data && res.data.length > 0) {
                let returnsFound = [];
                if (dataSession.userType === "ADMIN") {
                    returnsFound = res.data.filter(ele => 
                        ele.status !== "CANCELED" && ele.status !== "APPROVED" && ele.status !== "REMOVED" && ele.status !== "ADDED"
                    );
                } else {
                    returnsFound = res.data.filter(ele => {
                        const foundWH = dataSession.allWarehouse.find(elem => ele?.warehouse._id === elem._id);
                        if (ele.status !== "CANCELED" && ele.status !== "APPROVED" && ele.status !== "REMOVED" && ele.status !== "ADDED" && foundWH) return ele;
                    });
                }

                const array_sort = returnsFound.sort(function(a,b){
                    return new Date(b.createdAt) - new Date(a.createdAt);
                });
                
                setReturns(array_sort);
                setReturnsUniv(array_sort);
            }
            setLoader(false);
        });
    }

    const handleResponse = () => {
        if (showModal.type === "cancel") {
            handleCancel();
        } else {
            handleAccept();
        }
    }

    const handleCancel = () => {
        setLoader(true);
        const body = {
            _id: showModal.data._id,
            employee: { _id: dataSession._id },
            status: "CANCELED",
            cancelReason: showModal.data.reason
        }
        cancelReturn(showModal.data._id, body).then(res => {
            if (res.status === 200) {
                getData();
                setLoader(false);
                setShowModal({...showModal, status: false, type: "", message: "", data: null});
                toast.success("Return canceled successfully", alertOptions);
            } else {
                setLoader(false);
                toast.warning(res.response.data.message, alertOptions);
            }
        }).catch(error => {
            setLoader(false);
            toast.warning(error.response.data.message, alertOptions);
        });
    }

    const handleAccept = () => {
        setLoader(true);
        setShowModal({...showModal, status: false, type: "", message: "", data: null});
        const body = {
            _id: showModal.data._id,
            employee: { _id: dataSession._id },
            status: "APPROVED",
            returnMoney: showModal.data.total
        }
        approvedReturn(showModal.data._id, body).then(res => {
            if (res.status === 200) {
                getData();
                setLoader(false);
                toast.success("Return approved successfully", alertOptions);
            } else {
                setLoader(false);
                toast.warning(res.response.data.message, alertOptions);
            }
        }).catch(error => {
            setLoader(false);
            toast.warning(error.response.data.message, alertOptions);
        });
    }

    const handleModalResponse = (sts) => {
        if (sts) {
            clearContext();
            window.location.reload(true);
        }
    };

    useEffect(() => {
        getData();
    }, [dataSession]);

    useEffect(() => {
        let array_clients = [];
        getWholesalerUsers().then(resWU => {
            if (resWU.status === 200 && resWU.data.length > 0) {
                const clientsFound = resWU.data.filter(element => !element.deleted);
                array_clients = array_clients.concat(clientsFound);
            }
            getRetailUsers().then(resRU => {
                if (resRU.status === 200 && resRU.data.length > 0) {
                    const clientsFoundAux = resRU.data.filter(element => !element.deleted);
                    array_clients = array_clients.concat(clientsFoundAux);
                }

                const sortUser = array_clients.sort(function(a, b) {
                    return a?.user?.identifier - b?.user?.identifier;
                });

                setClientOptions(sortUser);
            });
        });

        getWarehouses().then(res => {
            if (res.status === 200 && res.data.length > 0) {
                const warehousesCurrent = res.data.filter(element => !element.deleted);
                setWarehouseOptions(warehousesCurrent);
            }
        });
    }, []);

    return (
        <>
            { loader ? <Loader /> : null }

            {
                showModal.status ?
                    <Modal
                        title={showModal.message}
                        onClose={() => setShowModal({...showModal, status: false, type: "", message: "", element: null})}
                        onResponse={handleResponse}
                    />
                : null
            }

            { logOutModal.status && (
                <Modal
                    title={logOutModal.title}
                    onClose={() =>
                        setLogOutModal({ ...logOutModal, status: false, title: "", element: null })
                    }
                    onResponse={handleModalResponse}
                />
            )}

            {
                showDetail.status ?
                    <DetailReturns
                        returnElement={showDetail.data}
                        onClose={() => setShowDetail({...showDetail, status: false, data: null})}
                        onCancel={(returnElement) => {
                            setShowDetail({...showDetail, status: false, data: null});
                            setShowModal({...showModal,
                                status: true,
                                type: "cancel",
                                message: "Are you sure you want to cancel the return?",
                                data: returnElement
                            });
                        }}
                        onApproved={(returnElement) => {
                            setShowDetail({...showDetail, status: false, data: null});
                            setShowModal({...showModal,
                                status: true,
                                type: "approved",
                                message: "Are you sure you want to accept the return?",
                                data: returnElement
                            });
                        }}
                    />
                : null
            }

            <div>
                <div className="flex flex-row">
                    <div className="w-full bg-no-repeat bg-cover fixed z-10">
                        <Navbar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                    </div>
                </div>
                <div className="flex h-screen overflow-hidden">
                    <div className={`${sidebarOpen ? "w-[100vw] md:w-80 z-20" : "w-20 z-0"}`}>
                        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                    </div>
                    <div className="w-full overflow-auto flex-grow mt-[100px]">
                        <div className="space-y-3 px-3 py-5 md:px-8 md:py-8">
                            <div className="grid grid-flow-col md:grid-cols-5 px-0">
                                <div className="pr-6 hidden lg:block">
                                    <div className="flex justify-center items-center border border-gray-500 rounded-xl">
                                        <input
                                            className={`w-full flex justify-center items-center space-x-3 text-ag-secondary-letter text-xs lg:text-sm px-2 py-2 rounded-xl disabled:bg-zinc-200 truncate ${ focusClass.search.status ? "text-left" : "text-center" }`}
                                            type="text"
                                            name="search"
                                            placeholder={focusClass.search.placeholder}
                                            disabled={!permits['ORDERS']['RETURNS_FILTERS']}
                                            onChange={(evt) => setSearchWord({ currentTarget: { name: evt.target.name, value: evt.target.value } })}
                                            onFocus={() => setFocusClass({...focusClass, search: { placeholder: "", status: true }})}
                                            onBlur={(evt) => evt.target.value === "" ? setFocusClass({...focusClass, search: { placeholder: "Search", status: false }}) : null}
                                            maxLength={60}
                                        />
                                        <div 
                                            className="border-none p-[10px] rounded-md disabled:bg-zinc-200"
                                        >
                                            <span className="text-ag-secondary-letter text-xl"><BiSearch /></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex justify-center items-center md:col-span-5 lg:grid-cols-4 text-center">
                                    <h1 className="text-[26px] md:text-xl lg:text-[26px] font-light text-ag-secondary lg:mr-[25%]">
                                        Returns
                                    </h1>
                                </div>
                            </div>
                            <div className="">
                                <Filters
                                    enableFilters={permits['ORDERS']['RETURNS_FILTERS']}
                                    typeView="primary"
                                    warehouses={warehouseOptions}
                                    status={statusOptions}
                                    clients={clientOptions}
                                    wordFilter={searchWord}
                                    onFilters={handleFilters}
                                />
                            </div>
                            <div className="pt-3">
                                <TableReturns
                                    enableActions={permits['ORDERS']['ACTIONS_RETURNS']}
                                    returns={returns}
                                    onDetail={(returnElement) => setShowDetail({...showDetail, status: true, data: returnElement})}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Returns;