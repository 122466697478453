import { useEffect, useState } from "react";
import { AiFillCloseCircle, AiFillPlusCircle } from "react-icons/ai";
import { IoMdImage } from "react-icons/io";

const RowsProducts = ({ type, product, onAdd, onRemove, onCompatible }) => {
    const [vendorsStr, setVendorsStr] = useState("");

    useEffect(() => {
        if (product !== null) {
            const newVendors = product.productInventory.product.vendors.map((vendor) => vendor?.name);
            setVendorsStr(newVendors.join(', '));
        }
    }, [product]);

    return (
        <tr>
            <td className="w-full md:w-[50%] border-[0.5px] border-ag-secondary-light text-sm font-normal p-1">
                <div className="grid gap-3 grid-cols-8">
                    <div className="col-span-4 border-r border-ag-secondary">
                        <div className="flex-wrap md:flex-nowrap flex justify-center items-center space-x-3">
                            <div className="w-[20%] flex justify-center items-center">
                                { product.productInventory.product?.productImages[0] ?
                                    <div className="w-[45px] h-[45px] flex justify-center items-center border border-ag-secondary-light rounded-lg p-1">
                                        <img className="w-full h-full object-contain" src={product.productInventory.product.productImages[0]} />
                                    </div>
                                :
                                    <span className="text-4xl"><IoMdImage /></span>
                                }
                            </div>
                            <div className="w-[80%] text-left text-[10px]">
                                <div className="space-y-[-6px]">
                                    <div>
                                        <p style={{ textWrap: 'balance' }} title={`${ product.productInventory.product.nags} ${product.productInventory.product?.name }`}>
                                            { product.productInventory.product.nags } { product.productInventory.product?.name }
                                        </p>
                                    </div>
                                    <div className="space-y-[-6px]">
                                        {
                                            product?.productInventory?.product?.compatible_vehicles.map((prodComp, i) => {
                                                if (i < 1) {
                                                    return (
                                                        <p className="truncate w-16 md:w-20 xl:w-32" key={i} title={
                                                            `${String(prodComp?.year).replace(/\s+/g, '').split(',').length > 1 ? `${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[0]} - ${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[Number(String(prodComp?.year).replace(/\s+/g, '').split(',').length-1)]}` : prodComp?.year} ` +
                                                            `${prodComp?.brand} ` +
                                                            `${prodComp?.model} ` +
                                                            `${prodComp?.type_car}`
                                                        }>
                                                            { String(prodComp?.year).replace(/\s+/g, '').split(',').length > 1 ? `${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[0]} - ${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[Number(String(prodComp?.year).replace(/\s+/g, '').split(',').length-1)]}` : prodComp?.year }{' '}
                                                            { prodComp?.brand }{' '}
                                                            { prodComp?.model }{' '}
                                                            { prodComp?.type_car }
                                                        </p>
                                                    )
                                                }
                                            })
                                        }
                                        {
                                            product?.productInventory?.product?.compatible_vehicles.length > 1 ?
                                                <p className="truncate w-16 md:w-20 xl:w-32 font-semibold cursor-pointer" onClick={() => onCompatible(product?.productInventory?.product)}>
                                                    See compatible vehicles +
                                                </p>
                                            : null
                                        }
                                    </div>
                                </div>
                                <div className="pt-2 space-y-[-6px]">
                                    <div className="flex justify-start items-start space-x-1">
                                        <span className="font-bold">Vendors:</span>
                                        <p className="truncate w-24" title={vendorsStr}>{ vendorsStr }</p>
                                    </div>
                                    <div className="space-y-[-6px]">
                                        <span className="font-bold">Rack: </span>
                                        <span className="">{product.rack?.name}</span> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-3 border-r border-ag-secondary flex justify-center items-center">
                        <div className="w-full p-1 truncate">
                            <span className="text-[10px]">{ product.productInventory.product.description }</span>
                        </div>
                    </div>
                    <div className="flex justify-center items-center">
                        {
                            type === "available" ?
                                <button type="button" onClick={() => onAdd(product._id_rackAndProductRelation)}>
                                    <span className="text-4xl">
                                        <AiFillPlusCircle />
                                    </span>
                                </button>
                            :
                                <button type="button" onClick={() => onRemove(product._id_rackAndProductRelation)}>
                                    <span className="text-4xl text-red-700">
                                        <AiFillCloseCircle />
                                    </span>
                                </button>
                        }
                    </div>
                </div>
            </td>
        </tr>
    );
}

export default RowsProducts;