import { _delete, _get, _post, _put } from "./http";

export const getVendor = (id) => _get(`/vendors/${id}`);
 
export const getVendors = () => _get(`/vendors`);

export const saveVendor = (vendorObject) => _post(`/vendors`, vendorObject);

export const updateVendor = (id, vendorObject) => _put(`/vendors/${id}`, vendorObject);

export const deleteVendor = (id) => _delete(`/vendors/${id}`);