import { useContext, useEffect, useState } from "react";
import { FiChevronLeft } from "react-icons/fi";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getProducts } from "../../../../services/Product";
import { getWarehouses } from "../../../../services/Warehouse";
import { getInventoryAddId } from "../../../../services/Inventory";
import { saveInventoryAdd, updateInventoryAddId } from "../../../../services/Inventory";
import { toast } from "react-toastify";
import { alertOptions } from "../../../../utils/Utilities";
import { PermitsContext } from "../../../../services/Permits";
import FormInventory from "./FormInventory";
import Navbar from "../../../../components/navbar/Navbar";
import Sidebar from "../../../../components/sidebar/Sidebar";
import Loader from "../../../../components/loader/Loader";
import ModalCompatible from "../../products/ModalCompatible";

const SaveInventory = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const { dataSession } = useContext(PermitsContext);
    const [loader, setLoader] = useState(false);
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const [showCompatible, setShowCompatible] = useState({ status: false, element: null });
    const [inventoryData, setInventoryData] = useState(null);
    const [productsOptions, setProductsOptions] = useState([]);
    const [warehouseOptions, setWarehouseOptions] = useState([]);

    const handleSave = async (inventoryModel) => {
        try {
            setLoader(true);
            const res = await saveInventoryAdd(inventoryModel);
            setLoader(false);
            if (res.status === 200) {
                toast.success("Product added successfully", alertOptions);
                navigate("/inventory");
            } else {
                toast.warning(res.response.data.message, alertOptions);
            }
        } catch (error) {
            setLoader(false);
            toast.warning(error.response.data.message, alertOptions);
        }
    };

    const handleEdit = async (inventoryModel) => {
        try {
            setLoader(true);
            const res = await updateInventoryAddId(inventoryModel._id, inventoryModel);
            setLoader(false);
            if (res.status === 200) {
                toast.success("Product updated successfully", alertOptions);
                navigate("/inventory");
            } else {
                toast.warning(res.response.data.message, alertOptions);
            }
        } catch (error) {
            setLoader(false);
            toast.warning(error.response.data.message, alertOptions);
        }
    };

    const fetchInventoryData = async () => {
        if (id) {
            setLoader(true);
            const res = await getInventoryAddId(id);
            if (res.status === 200) {
                setInventoryData(res.data);
            }
            setLoader(false);
        }
    };

    const fetchWarehouses = async () => {
        const res = await getWarehouses();
        if (res.status === 200 && res.data.length > 0) {
            const warehouses = dataSession.userType === "ADMIN"
                ? res.data.filter((element) => !element.deleted)
                : res.data.filter((element) => !element.deleted && dataSession.allWarehouse.some((elem) => element?._id === elem._id));
            setWarehouseOptions(warehouses);
        } else {
            setWarehouseOptions([]);
        }
    };

    const fetchProducts = async () => {
        const res = await getProducts();
        setProductsOptions(res.status === 200 && res.data.length > 0 ? res.data : []);
    };

    useEffect(() => {
        fetchInventoryData();
        fetchWarehouses();
        fetchProducts();
    }, []);

    return (
        <>
            {loader && <Loader />}

            {showCompatible.status && (
                <ModalCompatible
                    products={showCompatible.element}
                    onClose={() => setShowCompatible({ ...showCompatible, status: false, element: null })}
                />
            )}

            <div>
                <div className="flex flex-row">
                    <div className="w-full bg-no-repeat bg-cover fixed z-10">
                        <Navbar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                    </div>
                </div>
                <div className="flex h-screen overflow-hidden">
                    <div className={`${sidebarOpen ? "w-[100vw] md:w-80 z-20" : "w-20 z-0"}`}>
                        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                    </div>
                    <div className="w-full overflow-auto flex-grow mt-[100px]">
                        <div className="space-y-5 px-3 py-5 md:px-8 md:py-8">
                            <div className="grid grid-cols-12 px-0 md:px-5">
                                <div>
                                    <Link to="/inventory">
                                        <button className="p-1 rounded-md" type="button">
                                            <span className="text-ag-secondary-dark text-2xl md:text-3xl"><FiChevronLeft /></span>
                                        </button>
                                    </Link>
                                </div>
                                <div className="flex justify-center items-center col-span-11 text-center">
                                    <h1 className="text-[26px] md:text-xl lg:text-[26px] font-light text-ag-secondary md:mr-[8%]">
                                      Add/Edit Product
                                    </h1>
                                </div>
                            </div>
                            <div className="py-3">
                                <FormInventory
                                    inventoryData={inventoryData}
                                    warehouses={warehouseOptions}
                                    products={productsOptions}
                                    onEdit={handleEdit}
                                    onSave={handleSave}
                                    onCompatible={(prdSel) => setShowCompatible({ ...showCompatible, status: true, element: prdSel })}
                                    onLoader={setLoader}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SaveInventory;
