import { useEffect, useState } from "react";
import { IoMdCloseCircle } from "react-icons/io";
import { toast } from "react-toastify";
import { alertOptions } from "../../../utils/Utilities";

const OrdersNote = ({ email, onSave, onClose }) => {
    const [noteData, setNoteData] = useState({
        email: "",
        description: ""
    });

    const handleChange = (evt) => {
        setNoteData({...noteData, [evt.target.name]: evt.target.value});
    }

    const handleSubmit = (evt) => {
        evt.preventDefault();
        if (noteData.email !== "" && noteData.description !== "") {
            onSave(noteData);
        } else {
            toast.info("Please fill in all the fields of the forms", alertOptions);
        }
    }

    useEffect(() => {
        if (email !== null) setNoteData({...noteData, email: email, description: ""});
    }, [email]);

    return (
        <>
            <div className="block">
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-40 outline-none focus:outline-none">
                    <div className="w-[320px] md:w-[600px]">
                        <div className="p-5 border-0 rounded-lg shadow-lg flex-col w-full bg-white outline-none focus:outline-none">
                            <div className="w-full flex items-center justify-center rounded text-center">
                                <div className="w-[95%]">
                                    <span className="text-xl text-ag-secondary font-bold pl-[5%]">
                                        Note
                                    </span>
                                </div>
                                <div className="w-[5%]">
                                    <button
                                        className="text-ag-secondary float-right text-3xl leading-none outline-none focus:outline-none"
                                        onClick={() => onClose(false)}
                                    >
                                        <IoMdCloseCircle />
                                    </button>
                                </div>
                            </div>
                            <div className="px-2 md:px-5 pb-0 pt-5">
                                <form onSubmit={handleSubmit}>
                                    <div className="space-y-3">
                                        <div>
                                            <input
                                                className="w-full px-3 py-2 text-center border border-ag-secondary rounded-xl disabled:bg-zinc-200"
                                                type="text"
                                                name="email"
                                                placeholder="Email"
                                                onChange={handleChange}
                                                value={noteData.email}
                                                disabled={true}
                                            />
                                        </div>
                                        <div>
                                            <input
                                                className="w-full h-32 px-3 py-2 text-center border border-ag-secondary rounded-xl"
                                                type="text"
                                                name="description"
                                                placeholder="Description"
                                                onChange={handleChange}
                                                value={noteData.description}
                                                rows={3}
                                            />
                                        </div>
                                        <div>
                                            <button className="w-full px-3 py-2 bg-ag-primary-light text-center text-white rounded-xl">
                                                Continue
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="opacity-50 fixed inset-0 z-30 bg-black"></div>
        </>
    );
}

export default OrdersNote;