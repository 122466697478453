import { useState } from "react";
import { useEffect } from "react";
import { IoMdCloseCircle } from "react-icons/io";
import { getAllAvailableProducts, getWarehouse } from "../../../services/Warehouse";
import { removeAccents } from "../../../utils/Utilities";
import RowsProducts from "./stocktake/save_stocktake/RowsProducts";

const ProductsAvailable = ({ typeView, IdWarehouse, currentProducts, onProducts, onClose, onCompatible }) => {
    const [nameWarehouse, setNameWarehouse] = useState("");
    const [racksOptions, setRacksOptions] = useState([]);
    const [productsAvailable, setProductsAvailable] = useState([]);
    const [productsAvailableUniv, setProductsAvailableUniv] = useState([]);
    const [productsStocktake, setProductsStocktake] = useState([]);
    const [productsStocktakeUniv, setProductsStocktakeUniv] = useState([]);
    const [dataFilters, setDataFilters] = useState({
        products_available: "",
        products_rack_first: "",
        products_stocktake: "",
        products_rack_second: ""
    });

    const handleFiltersFirst = (evt) => {
        if (
            (evt.target.name === "products_available" && evt.target.value === "" && dataFilters.products_rack_first === "default") ||
            (evt.target.name === "products_rack_first" && evt.target.value === "default" && dataFilters.products_available === "")) {
            setProductsAvailable(productsAvailableUniv);
            setDataFilters({...dataFilters, products_available: "", products_rack_first: ""});
        } else {
            let productsCurrentFound = productsAvailableUniv;
            if (evt.target.name === "products_available") {
                let palabra = new RegExp(`${removeAccents(evt.target.value)}.*`, "i");
                productsCurrentFound = productsCurrentFound.filter(element => (
                    palabra.test(removeAccents(element.productInventory.product?.nags)) ||
                    palabra.test(removeAccents(element.productInventory.product?.name)) ||
                    palabra.test(removeAccents(element.productInventory.product?.model)) ||
                    palabra.test(removeAccents(element.productInventory.product?.brand)) ||
                    palabra.test(removeAccents(element.productInventory.product?.year)) ||
                    palabra.test(removeAccents(element.productInventory.product?.glassType)) ||
                    palabra.test(removeAccents(element.productInventory.product?.type_car)) ||
                    palabra.test(removeAccents(element.productInventory.product?.description)) ||
                    palabra.test(element.productInventory.product?.price) ||
                    palabra.test(removeAccents(element.productInventory.product?.barcode1)) ||
                    palabra.test(removeAccents(element.productInventory.product?.barcode2)) ||
                    palabra.test(element.productInventory.product?.totalQuantity)
                ));
                setDataFilters({...dataFilters, products_available: evt.target.value});
            } else if (dataFilters.products_available !== "") {
                let palabra = new RegExp(`${removeAccents(dataFilters.products_available)}.*`, "i");
                productsCurrentFound = productsCurrentFound.filter(element => (
                    palabra.test(removeAccents(element.productInventory.product?.nags)) ||
                    palabra.test(removeAccents(element.productInventory.product?.name)) ||
                    palabra.test(removeAccents(element.productInventory.product?.model)) ||
                    palabra.test(removeAccents(element.productInventory.product?.brand)) ||
                    palabra.test(removeAccents(element.productInventory.product?.year)) ||
                    palabra.test(removeAccents(element.productInventory.product?.glassType)) ||
                    palabra.test(removeAccents(element.productInventory.product?.type_car)) ||
                    palabra.test(removeAccents(element.productInventory.product?.description)) ||
                    palabra.test(element.productInventory.product?.price) ||
                    palabra.test(removeAccents(element.productInventory.product?.barcode1)) ||
                    palabra.test(removeAccents(element.productInventory.product?.barcode2)) ||
                    palabra.test(element.productInventory.product?.totalQuantity)
                ));
            }

            if (evt.target.name === "products_rack_first" && evt.target.value !== "default") {
                productsCurrentFound = productsCurrentFound.filter(element => element.rack._id === evt.target.value);
                setDataFilters({...dataFilters, products_rack_first: evt.target.value});
            } else if (evt.target.name === "products_rack_first" && evt.target.value === "default") {
                setDataFilters({...dataFilters, products_rack_first: ""});
            } else if (dataFilters.products_rack_first !== "") {
                productsCurrentFound = productsCurrentFound.filter(element => element.rack._id === dataFilters.products_rack_first);
            }

            setProductsAvailable(productsCurrentFound);
        }
    }

    const handleFiltersSecond = (evt) => {
        if (
            (evt.target.name === "products_stocktake" && evt.target.value === "" && dataFilters.products_rack_second === "default") ||
            (evt.target.name === "products_rack_second" && evt.target.value === "default" && dataFilters.products_stocktake === "")) {
            setProductsStocktake(productsStocktakeUniv);
            setDataFilters({...dataFilters, products_stocktake: "", products_rack_second: ""});
        } else {
            let productsCurrentFound = productsStocktakeUniv;
            if (evt.target.name === "products_stocktake") {
                let palabra = new RegExp(`${removeAccents(evt.target.value)}.*`, "i");
                productsCurrentFound = productsCurrentFound.filter(element => (
                    palabra.test(removeAccents(element.productInventory.product?.nags)) ||
                    palabra.test(removeAccents(element.productInventory.product?.name)) ||
                    palabra.test(removeAccents(element.productInventory.product?.model)) ||
                    palabra.test(removeAccents(element.productInventory.product?.brand)) ||
                    palabra.test(removeAccents(element.productInventory.product?.year)) ||
                    palabra.test(removeAccents(element.productInventory.product?.glassType)) ||
                    palabra.test(removeAccents(element.productInventory.product?.type_car)) ||
                    palabra.test(removeAccents(element.productInventory.product.description)) ||
                    palabra.test(element.productInventory.product?.price) ||
                    palabra.test(removeAccents(element.productInventory.product?.barcode1)) ||
                    palabra.test(removeAccents(element.productInventory.product?.barcode2)) ||
                    palabra.test(element.productInventory.product?.totalQuantity)
                ));
                setDataFilters({...dataFilters, products_stocktake: evt.target.value});
            } else if (dataFilters.products_available !== "") {
                let palabra = new RegExp(`${removeAccents(dataFilters.products_stocktake)}.*`, "i");
                productsCurrentFound = productsCurrentFound.filter(element => (
                    palabra.test(removeAccents(element.productInventory.product?.nags)) ||
                    palabra.test(removeAccents(element.productInventory.product?.name)) ||
                    palabra.test(removeAccents(element.productInventory.product?.model)) ||
                    palabra.test(removeAccents(element.productInventory.product?.brand)) ||
                    palabra.test(removeAccents(element.productInventory.product?.year)) ||
                    palabra.test(removeAccents(element.productInventory.product?.glassType)) ||
                    palabra.test(removeAccents(element.productInventory.product?.type_car)) ||
                    palabra.test(removeAccents(element.productInventory.product?.description)) ||
                    palabra.test(element.productInventory.product?.price) ||
                    palabra.test(removeAccents(element.productInventory.product?.barcode1)) ||
                    palabra.test(removeAccents(element.productInventory.product?.barcode2)) ||
                    palabra.test(element.productInventory.product?.totalQuantity)
                ));
            }

            if (evt.target.name === "products_rack_second" && evt.target.value !== "default") {
                productsCurrentFound = productsCurrentFound.filter(element => element.rack._id === evt.target.value);
                setDataFilters({...dataFilters, products_rack_second: evt.target.value});
            } else if (evt.target.name === "products_rack_second" && evt.target.value === "default") {
                setDataFilters({...dataFilters, products_rack_second: ""});
            } else if (dataFilters.products_rack_second !== "") {
                productsCurrentFound = productsCurrentFound.filter(element => element.rack._id === dataFilters.products_rack_second);
            }

            setProductsStocktake(productsCurrentFound);
        }
    }

    const addProduct = (id) => {
        const newProductsAvailable = productsAvailableUniv.filter(element => element._id_rackAndProductRelation !== id); 

        const newProductsStocktake = productsStocktakeUniv;
        const productFound = productsAvailableUniv.find(element => element._id_rackAndProductRelation === id);
        if (productFound) {
            newProductsStocktake.push(productFound);
        }

        setProductsAvailable(newProductsAvailable);
        setProductsStocktake(newProductsStocktake);
        setProductsAvailableUniv(newProductsAvailable);
        setProductsStocktakeUniv(newProductsStocktake);
    }

    const addProducts = () => {
        const newProductsStocktake = productsStocktakeUniv;
        const newProductsAvailable = [];
        productsAvailableUniv.forEach(element => {
            if (dataFilters.products_rack_first !== "") {
                if (element.rack._id === dataFilters.products_rack_first) newProductsStocktake.push(element);
                else newProductsAvailable.push(element);
            } else {
                newProductsStocktake.push(element);
            }
        });
        setProductsAvailable(newProductsAvailable);
        setProductsStocktake(newProductsStocktake);
        setProductsAvailableUniv(newProductsAvailable);
        setProductsStocktakeUniv(newProductsStocktake);
        setDataFilters({...dataFilters, products_rack_first: "" });
    }

    const removeProduct = (id) => {
        const newProductsAvailable = productsAvailableUniv;
        const productFound = productsStocktakeUniv.find(element => element._id_rackAndProductRelation === id);
        if (productFound) newProductsAvailable.push(productFound);
        const newProductsStocktake = productsStocktakeUniv.filter(element => element._id_rackAndProductRelation !== id);

        setProductsAvailable(newProductsAvailable);
        setProductsStocktake(newProductsStocktake);
        setProductsAvailableUniv(newProductsAvailable);
        setProductsStocktakeUniv(newProductsStocktake);
    }

    const removeProducts = () => {
        const newProductsAvailable = productsAvailableUniv;
        const newProductsStocktake = [];
        productsStocktakeUniv.forEach(element => {
            if (dataFilters.products_rack_second !== "") {
                if (element.rack._id === dataFilters.products_rack_second) newProductsAvailable.push(element);
                else newProductsStocktake.push(element);
            } else {
                newProductsAvailable.push(element);
            }
        });
        setProductsAvailable(newProductsAvailable);
        setProductsStocktake(newProductsStocktake);
        setProductsAvailableUniv(newProductsAvailable);
        setProductsStocktakeUniv(newProductsStocktake);
        setDataFilters({...dataFilters,products_rack_second: "" });
    }

    const handleSubmit = (evt) => {
        evt.preventDefault();
        onProducts(productsStocktake);
    }

    useEffect(() => {
        getAllAvailableProducts(IdWarehouse).then(res => {
            if (res.status === 200 && res.data.allAvailableProducts && res.data.allAvailableProducts.length > 0) {
                if (currentProducts.length === 0) {
                    setProductsAvailable(res.data.allAvailableProducts);
                    setProductsAvailableUniv(res.data.allAvailableProducts);
                } else {
                    let prodsAvailable = [];
                    let prodsStocktake = [];
                    for (let i = 0; i < res.data.allAvailableProducts.length; i++) {
                        const prodFound = currentProducts.find(element => {
                            if (typeView === "transfer") {
                                if (
                                    `${element._id}${element.rack._id}` === `${res.data.allAvailableProducts[i].productInventory._id}${res.data.allAvailableProducts[i].rack._id}` ||
                                    `${element._id}${element.rack}` === `${res.data.allAvailableProducts[i].productInventory._id}${res.data.allAvailableProducts[i].rack._id}` ||
                                    `${element?.productInventory?._id}${element.rack._id}` === `${res.data.allAvailableProducts[i].productInventory._id}${res.data.allAvailableProducts[i].rack._id}`
                                ) {
                                    return element;
                                }
                            } else {
                                if (`${element._id}${element.rack}` === `${res.data.allAvailableProducts[i].productInventory._id}${res.data.allAvailableProducts[i].rack._id}`) {
                                    return element;
                                }
                            }
                        });
                        if (prodFound) {
                            prodsStocktake.push(res.data.allAvailableProducts[i]);
                        } else {
                            prodsAvailable.push(res.data.allAvailableProducts[i]);
                        }
                    }
                    setProductsAvailable(prodsAvailable);
                    setProductsStocktake(prodsStocktake);
                    setProductsAvailableUniv(prodsAvailable);
                    setProductsStocktakeUniv(prodsStocktake);
                }
            }
        })
    }, []);

    useEffect(() => {
        getWarehouse(IdWarehouse).then(res => {
            if (res.status === 200) {
                setNameWarehouse(res.data.name);
                setRacksOptions(res.data.racks);
            }
        });
    }, []);

    return (
        <>
            <div className="block">
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-40 outline-none focus:outline-none">
                    <div className="w-[320px] md:w-[750px] lg:w-[950px] h-[550px] md:h-auto">
                        <div className="p-5 border-0 rounded-lg shadow-lg flex-col w-full bg-white outline-none focus:outline-none">
                            <div className="w-full flex items-center justify-center rounded text-center">
                                <div className="w-[95%]">
                                    <span className="text-xl text-ag-secondary">
                                        Products available at warehouse:
                                    </span>
                                    {' '}
                                    <span className="text-xl text-red-700 font-bold">
                                        { nameWarehouse }
                                    </span>
                                </div>
                                <div className="w-[5%]">
                                    <button
                                        className="text-ag-secondary float-right text-3xl leading-none outline-none focus:outline-none"
                                        onClick={() => onClose(false)}
                                    >
                                        <IoMdCloseCircle />
                                    </button>
                                </div>
                            </div>
                            <div className="relative px-2 md:px-5 pb-2 pt-5 flex-auto">
                                <form onSubmit={handleSubmit}>
                                    <div className="grid md:grid-cols-2">
                                        <div className="h-[300px] overflow-auto">
                                            <table className="w-full text-center text-ag-secondary">
                                                <thead>
                                                    <tr className="bg-gray-300">
                                                        <th className="w-full md:w-[50%] border-[0.5px] border-ag-secondary-light text-sm font-normal px-4 py-2 space-y-2">
                                                            <div>
                                                                <h1>All available products</h1>
                                                            </div>
                                                            <div className="grid gap-3 md:grid-cols-2">
                                                                <div>
                                                                    <input
                                                                        className="w-full text-ag-secondary-letter border border-gray-500 p-1 rounded-md"
                                                                        type="text"
                                                                        name="products_available"
                                                                        placeholder="Filter products..."
                                                                        onChange={handleFiltersFirst}
                                                                    />
                                                                </div>
                                                                <div>
                                                                    <select
                                                                        className="w-full text-ag-secondary-letter border border-gray-500 p-1 rounded-md"
                                                                        name="products_rack_first"
                                                                        onChange={handleFiltersFirst}
                                                                        value={dataFilters.products_rack_first}
                                                                    >
                                                                        <option value="default">Rack</option>
                                                                        {
                                                                            racksOptions.map((element, index) => {
                                                                                return <option key={index} value={element._id}>{element.name}</option>;
                                                                            })
                                                                        }
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <button
                                                                    className="font-semibold text-[10px] text-blue-700"
                                                                    type="button"
                                                                    onClick={addProducts}
                                                                >
                                                                    Add all products to { typeView } {`(${productsAvailable.length} products)`}
                                                                </button>
                                                            </div>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        productsAvailable.map((element, i) => {
                                                            return (
                                                                <RowsProducts
                                                                    key={i}
                                                                    type="available"
                                                                    product={element}
                                                                    onAdd={addProduct}
                                                                    onCompatible={onCompatible}
                                                                />
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="h-[300px] overflow-auto">
                                            <table className="w-full text-center text-ag-secondary">
                                                <thead>
                                                    <tr className="bg-gray-300">
                                                        <th className="w-full md:w-[50%] border-[0.5px] border-ag-secondary-light text-sm font-normal px-4 py-2 space-y-2">
                                                            <div>
                                                                <h1>Products for { typeView }</h1>
                                                            </div>
                                                            <div className="grid gap-3 md:grid-cols-2">
                                                                <div>
                                                                    <input
                                                                        className="w-full text-ag-secondary-letter border border-gray-500 p-1 rounded-md"
                                                                        type="text"
                                                                        name="products_stocktake"
                                                                        placeholder="Filter products..."
                                                                        onChange={handleFiltersSecond}
                                                                    />
                                                                </div>
                                                                <div>
                                                                    <select
                                                                        className="w-full text-ag-secondary-letter border border-gray-500 p-1 rounded-md"
                                                                        name="products_rack_second"
                                                                        onChange={handleFiltersSecond}
                                                                        value={dataFilters.products_rack_second}
                                                                    >
                                                                        <option value="default">Rack</option>
                                                                        {
                                                                            racksOptions.map((element, index) => {
                                                                                return <option key={index} value={element._id}>{element.name}</option>;
                                                                            })
                                                                        }
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <button
                                                                    className="font-semibold text-[10px] text-red-700"
                                                                    type="button"
                                                                    onClick={removeProducts}
                                                                >
                                                                    Remove all variants from { typeView } {`(${productsStocktake.length})`}
                                                                </button>
                                                            </div>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        productsStocktake.map((element, i) => {
                                                            return (
                                                                <RowsProducts
                                                                    key={i}
                                                                    type="stocktake"
                                                                    product={element}
                                                                    onRemove={removeProduct}
                                                                    onCompatible={onCompatible}
                                                                />
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div> 
                                    </div>
                                    <div className="w-full md:flex md:justify-center md:items-center md:space-x-5 space-y-3 md:space-y-0 pt-5">
                                        <div className="w-full md:w-[30%] lg:w-[25%]">
                                            <button className="w-full bg-ag-primary-light text-white p-2 rounded-xl" type="button" onClick={() => onClose(false)}>
                                                Cancel
                                            </button>
                                        </div>
                                        <div className="w-full md:w-[30%] lg:w-[25%]">
                                            <button className="w-full bg-green-600 text-white p-2 rounded-xl" type="submit">
                                                Apply
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="opacity-50 fixed inset-0 z-30 bg-black"></div>
        </>
    );
}

export default ProductsAvailable;