import { toast } from "react-toastify";
import { alertOptions, formatCurrency } from "../../../../utils/Utilities";

const RowsInventorySpecification = ({ product, onSelect, onCompatible }) => {
    const handleSelect = () => {
        if (product?.totalQuantity === 0) {
            toast.warning("The selected product is not available in stock", alertOptions);
        } else {
            onSelect(true);
        }
    }

    return (
        <tr className="hover:bg-zinc-200 hover:cursor-pointer">
            <td className="w-[15%] border-[0.5px] border-ag-secondary-light text-[10px] font-normal p-1" onClick={handleSelect}>
                <p className="truncate w-28 m-auto">{ product.warehouse?.name }</p>
            </td>
            <td className="w-[10%] border-[0.5px] border-ag-secondary-light text-[10px] font-normal p-1" onClick={handleSelect}>
                <p className="truncate w-28 m-auto">{ product.productInventory.product.nags }</p>
            </td>
            <td className="w-[15%] border-[0.5px] border-ag-secondary-light text-[10px] font-normal p-1" onClick={handleSelect}>
                <p className="truncate w-28 m-auto">{ product.productInventory.product.name }</p>
            </td>
            <td className="w-[25%] border-[0.5px] border-ag-secondary-light text-[10px] font-normal p-1">
                {
                    product.productInventory.product.compatible_vehicles.map((prodComp, i) => {
                        if (i <= 2) {
                            return (
                                <p className="truncate w-60 m-auto" key={i} title={
                                    `${String(prodComp?.year).replace(/\s+/g, '').split(',').length > 1 ? `${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[0]} - ${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[Number(String(prodComp?.year).replace(/\s+/g, '').split(',').length-1)]}` : prodComp?.year} ` +
                                    `${prodComp?.brand} ` +
                                    `${prodComp?.model} ` +
                                    `${prodComp?.type_car}`
                                }>
                                    { String(prodComp?.year).replace(/\s+/g, '').split(',').length > 1 ? `${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[0]} - ${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[Number(String(prodComp?.year).replace(/\s+/g, '').split(',').length-1)]}` : prodComp?.year }{' '}
                                    { prodComp?.brand }{' '}
                                    { prodComp?.model }{' '}
                                    { prodComp?.type_car }
                                </p>
                            )
                        }
                    })
                }
                {
                    product.productInventory.product.compatible_vehicles.length > 3 ?
                        <p className="truncate w-60 font-semibold m-auto cursor-pointer" onClick={() => onCompatible(product.productInventory.product)}>
                            See compatible vehicles +
                        </p>
                    : null
                }
            </td>
            <td className="w-[15%] border-[0.5px] border-ag-secondary-light text-[10px] font-normal p-1" onClick={handleSelect}>
                <p className="truncate w-20 m-auto">{ product.productInventory.product.barcode1 }</p>
                <p className="truncate w-20 m-auto">{ product.productInventory.product.barcode2 }</p>
            </td>
            <td className="w-[10%] border-[0.5px] border-ag-secondary-light text-[10px] font-normal p-1" onClick={handleSelect}>
                {/* <p className="truncate w-28 m-auto">{ product.productInventory.product.price }</p> */}
                <p className="truncate w-28 m-auto">{ formatCurrency(product.productInventory.cost)}</p>
            </td>
            <td className="w-[10%] border-[0.5px] border-ag-secondary-light text-[10px] font-normal p-1" onClick={handleSelect}>
                <p className="truncate w-28 m-auto">{ product.totalQuantity }</p>
            </td>
        </tr>
    );
}

export default RowsInventorySpecification;