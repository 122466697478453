import { useEffect, useState } from "react";
import { IoMdCloseCircle } from "react-icons/io";
import { toast } from "react-toastify";
import { alertOptions, removeAccents } from "../../../utils/Utilities";
import { BiSearch } from "react-icons/bi";
import TableProducts from "./TableProducts";

const FormRack = ({ enableEditR, enableDeleteR, rackInfo, products, onSave, onEdit, onDelete, onModal, onClose, onCompatible }) => {
    const [rack, setRack] = useState({
        _id: "",
        name: "",
        productsRack: []
    });

    const [rackProducts, setRackProducts] = useState([]);
    const [rackProductsUniv, setRackProductsUniv] = useState([]);

    const handleFilters = (wordFilter) => {
        if (wordFilter === "") {
            setRackProducts(rackProductsUniv);
        } else {
            let palabra = new RegExp(`${removeAccents(wordFilter)}.*`, "i");
            const productsFound = rackProductsUniv.filter(element => (
                palabra.test(`${removeAccents(element?.productInventory?.product?.nags)}`) ||
                palabra.test(`${removeAccents(element?.productInventory?.product?.name)}`) ||
                palabra.test(`${removeAccents(element?.productInventory?.product?.year)}`) ||
                palabra.test(`${removeAccents(element?.productInventory?.product?.model)}`) ||
                palabra.test(`${removeAccents(element?.productInventory?.product?.brand)}`)

            ));
            setRackProducts(productsFound);
        }
    }

    const handleChange = (evt) => {
        setRack({...rack, [evt.currentTarget.name]: evt.currentTarget.value});
    }

    const handleSubmit = (evt) => {
        evt.preventDefault();
        if (rack.name) {
            if (String(rack.name).trim().length > 0) {
                let rackModel = {
                    name: rack.name,
                    productsRack: rackProducts
                }
    
                if (rackInfo !== null) {
                    rackModel._id = rack._id;
                    onEdit(rackInfo._id, rackModel);
                } else {
                    onSave(rackModel);
                }
            } else {
                toast.info("You cannot save the name of a rack as an empty field", alertOptions);
            }
        } else {
            toast.info("Please fill in all the fields of the form", alertOptions);
        }
    }

    useEffect(() => {
        if (rackInfo !== null) {
            setRack({...rack,
                _id: rackInfo._id,
                name: rackInfo.name
            });
        }
    }, [rackInfo]);

    useEffect(() => {
        setRackProducts(products);
        setRackProductsUniv(products);
    }, [products]);

    return (
        <>
            <div className="block">
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-40 outline-none focus:outline-none">
                    <div className="w-[320px] md:w-[700px] lg:w-[800px]">
                        <div className="p-5 border-0 rounded-lg shadow-lg flex-col w-full bg-white outline-none focus:outline-none">
                            <div className="w-full flex items-center justify-center rounded text-center">
                                <div className="w-[95%]">
                                    <span className="text-xl text-ag-secondary font-bold md:ml-[5%]">
                                        { rackInfo !== null ? "Edit Rack" : "Create Rack" }
                                    </span>
                                </div>
                                <div className="w-[5%]">
                                    <button
                                        className="text-ag-secondary float-right text-3xl leading-none outline-none focus:outline-none"
                                        onClick={() => onClose(false)}
                                    >
                                        <IoMdCloseCircle />
                                    </button>
                                </div>
                            </div>
                            <div className="relative px-2 md:px-5 pb-2 pt-5 flex-auto">
                                <form onSubmit={handleSubmit}>
                                    <div className="space-y-3">
                                        <div className="md:flex md:justify-center md:items-center md:space-x-3 space-y-3 md:space-y-0">
                                            <div className="w-full md:w-[65%] m-auto">
                                                <div className="w-full flex justify-center items-center">
                                                    <span
                                                        className="w-[30%] md:w-[31%] bg-ag-secondary text-white text-xs lg:text-sm text-center border border-gray-500 py-3 rounded-l-xl"
                                                    >
                                                        Name
                                                    </span>
                                                    <input
                                                        className="w-[70%] md:w-[69%] text-ag-secondary-letter text-xs lg:text-sm border border-gray-500 p-3 rounded-r-xl disabled:bg-zinc-200"
                                                        type="text"
                                                        name="name"
                                                        onChange={handleChange}
                                                        disabled={!enableEditR}
                                                        value={rack.name}
                                                    />
                                                </div>
                                            </div>
                                            <div className="w-full md:w-[35%] m-auto">
                                                <button
                                                    className="w-full bg-ag-secondary text-white text-xs lg:text-sm border border-gray-500 py-3 rounded-xl disabled:bg-zinc-200"
                                                    type="button"
                                                    disabled={!enableEditR}
                                                    onClick={() => onModal(null, "create")}
                                                >
                                                    Add product
                                                </button>
                                            </div>
                                        </div>
                                        <div className="md:flex md:justify-between md:items-center space-y-3 md:space-y-0 md:pt-3">
                                            <div className="w-full md:w-[65%] px-3">
                                                <span className="text-xs lg:text-sm">RACK PRODUCTS</span>
                                            </div>
                                            <div className="w-full md:w-[35%]">
                                                <div className="flex justify-center items-center border border-gray-500 rounded-xl">
                                                    <input
                                                        className="w-full text-center text-ag-secondary-letter text-xs lg:text-sm border-none p-2 rounded-xl disabled:bg-zinc-200"
                                                        type="text"
                                                        name="search"
                                                        placeholder="Search product"
                                                        onChange={(evt) => handleFilters(evt.currentTarget.value)}
                                                    />
                                                    <div 
                                                        className="bg-ag-primary-dark text-xs lg:text-sm border-none p-[10px] lg:p-[12px] rounded-xl"
                                                    >
                                                        <span className="text-white text-xl"><BiSearch /></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="overflow-auto h-[274px]">
                                            <TableProducts
                                                enableEditR={enableEditR}
                                                products={rackProducts}
                                                productSelected={null}
                                                avaiable={false}
                                                onEdit={(productElement) => onModal(productElement, "edit")}
                                                onDelete={(productElement) => onModal(productElement, "delete")}
                                                onCompatible={onCompatible}
                                            />
                                        </div>
                                        <div className="flex justify-center items-center space-x-8 w-full md:w-[60%] m-auto">
                                            { rackInfo !== null ?
                                                <button
                                                    className="w-full bg-ag-primary-light text-white text-xs lg:text-sm px-2 py-3 rounded-xl disabled:bg-zinc-200"
                                                    type="button"
                                                    disabled={!enableDeleteR}
                                                    onClick={() => onDelete(rack._id)}
                                                >
                                                    Delete
                                                </button>
                                            :
                                                <button
                                                    className="w-full bg-ag-primary-light text-white text-xs lg:text-sm px-2 py-3 rounded-xl"
                                                    type="button"
                                                    onClick={() => onClose(false)}
                                                >
                                                    Cancel
                                                </button>
                                            }
                                            <button
                                                className="w-full bg-green-600 text-white text-xs lg:text-sm px-2 py-3 rounded-xl disabled:bg-zinc-200"
                                                type="submit"
                                                disabled={!enableEditR}
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="opacity-50 fixed inset-0 z-10 bg-black"></div>
        </>
    );
}

export default FormRack;