import { useContext, useState, useEffect } from "react";
import { toast } from "react-toastify";
import { alertOptions } from "../../../utils/Utilities";
import { PermitsContext } from "../../../services/Permits";
import { saveWholesalerUser } from "../../../services/Wholesaler";
import { saveRetailUser } from "../../../services/Retail";
import { saveSale } from "../../../services/Sales";
import Loader from "../../../components/loader/Loader";
import Modal from "../../../components/modal/Modal";
import Navbar from "../../../components/navbar/Navbar";
import Sidebar from "../../../components/sidebar/Sidebar";
import InventorySpecification from "../quotes/inventorySpecification/InventorySpecification";
import FormSales from "./FormSales";
import SelectClient from "./SelectClient";
import FormUser from "../users/FormUser";
import ModalCompatible from "../products/ModalCompatible";

const Sales = () => {
    const { permits, clearContext, logOutModal, setLogOutModal } = useContext(PermitsContext);
    const [loader, setLoader] = useState(false);
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const [cleanForm, setCleanForm] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showModalClients, setShowModalClients] = useState(false);
    const [showModalProducts, setShowModalProducts] = useState(false);
    const [showModalConfirm, setShowModalConfirm] = useState({ status: false, type: "", title: "", data: null});
    const [showCompatible, setShowCompatible] = useState({ status: false, element: null });
    const [clientCurrent, setClientCurrent] = useState(null);
    const [productsCurrent, setProductsCurrent] = useState([]);

    const handleClient = (clientSelect) => {
        setClientCurrent(clientSelect);
        setShowModalClients(false);
    }

    const handleProduct = (productSelect) => {
        setProductsCurrent(productSelect);
        setShowModalProducts(false);
    }

    const handleCustomer = (userObject, type) => {
        setLoader(true);
        if (type === "wsu") {
            saveWholesalerUser(userObject).then(res => {
                if (res.status === 200) {
                    setShowModal(false);
                    toast.success("User added successfully", alertOptions);
                } else {
                    toast.warning(res.response.data.message, alertOptions);
                }
                setLoader(false);
            }).catch(error => {
                setLoader(false);
                toast.warning(error.response.data.message, alertOptions);
            });
        } else {
            saveRetailUser(userObject).then(res => {
                if (res.status === 200) {
                    setShowModal(false);
                    toast.success("User added successfully", alertOptions);
                } else {
                    toast.warning(res.response.data.message, alertOptions);
                }
                setLoader(false);
            }).catch(error => {
                setLoader(false);
                toast.warning(error.response.data.message, alertOptions);
            });
        }
    }

    const handleModal = () => {
        if (showModalConfirm.type === "cancel") {
            handleCancel();
        } else {
            handlePay(showModalConfirm.data);
        }
    }

    const handleCancel = () => {
        setShowModalConfirm({...showModalConfirm, status: false, type: "", title: "", data: null});
        setClientCurrent(null);
        setProductsCurrent([]);
        setCleanForm(true);
    }

    const handlePay = (dataSale) => {
        setShowModalConfirm({...showModalConfirm, status: false, type: "", title: "", data: null});
        setLoader(true);
        saveSale(dataSale).then(res => {
            if (res.status === 200) {
                setClientCurrent(null);
                setProductsCurrent([]);
                setCleanForm(true);
                toast.success("Sale added successfully", alertOptions);
            } else {
                toast.warning(res.response.data.message, alertOptions);
            }
            setLoader(false);
        }).catch(error => {
            setLoader(false);
            setShowModalConfirm({...showModalConfirm, status: false, type: "", title: "", data: null});
            toast.warning(error.response.data.message, alertOptions);
        });
    }

    const handleModalResponse = (sts) => {
        if (sts) {
            clearContext();
            window.location.reload(true);
        }
    };

    return (
        <>
            { loader ? <Loader /> : null }

            { showModal ?
                <FormUser
                    enableAddRetail={permits['USERS']['ADD_RETAILER']}
                    enableAddWholesaler={permits['USERS']['ADD_WOLESALER']}
                    userType="wholesaler"
                    userInfo={null}
                    onSave={handleCustomer}
                    onClose={() => setShowModal(false)}
                />
            : 
                null
            }

            { logOutModal.status && (
                <Modal
                    title={logOutModal.title}
                    onClose={() =>
                        setLogOutModal({ ...logOutModal, status: false, title: "", element: null })
                    }
                    onResponse={handleModalResponse}
                />
            )}

            {
                showModalClients ?
                    <SelectClient
                        clientCurrent={handleClient}
                        onLoader={(val) => setLoader(val)}
                        onClose={() => setShowModalClients(false)}
                    />
                : null
            }

            {
                showModalProducts ?
                    <InventorySpecification
                        onSelect={handleProduct}
                        onClose={() => setShowModalProducts(false)}
                        onLoader={(val) => setLoader(val)}
                        onCompatible={(prdSel) => setShowCompatible({...showCompatible, status: true, element: prdSel})}
                    />
                : null
            }

            { showModalConfirm.status ?
                <Modal
                    title={showModalConfirm.title}
                    onClose={() => setShowModalConfirm({...showModalConfirm, status: false, type: "", title: "", data: null})}
                    onResponse={handleModal}
                />
            :
                null
            }

            {
                showCompatible.status ?
                    <ModalCompatible
                        products={showCompatible.element}
                        onClose={() => setShowCompatible({...showCompatible, status: false, element: null})}
                    />
                : null
            }

            <div>
                <div className="flex flex-row">
                    <div className="w-full bg-no-repeat bg-cover fixed z-10">
                        <Navbar type="retail" sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                    </div>
                </div>
                <div className="flex h-screen overflow-hidden">
                    <div className={`${sidebarOpen ? "w-[100vw] md:w-80 z-20" : "w-20 z-0"}`}>
                        <Sidebar type="retail" sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                    </div>
                    <div className="w-full overflow-auto flex-grow mt-16 md:mt-[100px]">
                        <div className="space-y-5 px-3 py-5 md:px-8 md:py-8">
                            <div className="text-center">
                                <h1 className="text-[26px] md:text-xl lg:text-[26px] font-light text-ag-secondary">
                                    Sales
                                </h1>
                            </div>
                            <div className="pt-5">
                                <FormSales
                                    clientCurrent={clientCurrent}
                                    productsCurrent={productsCurrent}
                                    isClean={cleanForm}
                                    onClean={() => setCleanForm(false)}
                                    onShow={() => setShowModal(true)}
                                    onCancel={() => setShowModalConfirm({...showModalConfirm,
                                        status: true,
                                        type: "cancel",
                                        title: "¿Are you sure you want to cancel the sale?",
                                        data: null
                                    })}
                                    onPay={(payModel) => setShowModalConfirm({...showModalConfirm,
                                        status: true,
                                        type: "pay",
                                        title: "¿Are you sure you want to confirm the sale?",
                                        data: payModel
                                    })}
                                    onShowClients={() => setShowModalClients(true)}
                                    onShowProducts={() => setShowModalProducts(true)}
                                    onLoader={(val) => setLoader(val)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Sales;