import { useContext, useEffect, useState } from "react";
import { PermitsContext } from "../../../../services/Permits";
import { useLocation, useNavigate } from "react-router-dom";
import { getWarehouses } from "../../../../services/Warehouse";
import { shippingOptionCartWholesaler } from "../../../../services/Purcharse";
import { toast } from "react-toastify";
import { alertOptions } from "../../../../utils/Utilities";
import Loader from "../../../../components/loader/Loader";
import NavbarClients from "../../../../components/navbar/NavbarClients";
import SidebarClients from "../../../../components/sidebar/SidebarClients";
import NewAddress from "../../../fragments/NewAddress";
import ShippingForm from "./ShippingForm";
import MyCartInformation from "../MyCartInformation";

const MyCartShipping = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { dataSession } = useContext(PermitsContext);
    const [loader, setLoader] = useState(false);
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [isRefreshAddress, setIsRefreshAddress] = useState(false);
    const [carts, setCarts] = useState(null);

    const handleShippingMethod = async (shippingObject) => {
        let warehousesOptions = [];
        if (shippingObject.shippingMethod === "PICK_UP") {
            const warehousesRes = await getWarehouses();
            warehousesOptions = warehousesRes.status === 200 ? warehousesRes.data : [];
        }
        const newCarts = carts.map(element => {
            if (shippingObject.shippingMethod === "SHIP") {
                return {...element,
                    shippingMethod: shippingObject.shippingMethod,
                    costShipping: shippingObject.costShipping,
                    fromWarehouse: { _id: element?.idWarehouse },
                    userAddress: { _id: shippingObject.id },
                    address: shippingObject.address
                }
            } else {
                const warehouseFound = warehousesOptions.find(elemWH => elemWH._id === element?.idWarehouse);
                return {...element,
                    shippingMethod: shippingObject.shippingMethod,
                    costShipping: shippingObject.costShipping,
                    fromWarehouse: { _id: element?.idWarehouse },
                    warehouseAddress: { _id: element?.addressWarehouse },
                    address: warehouseFound ? warehouseFound.address : shippingObject.address
                }
            }
        });
        setCarts(newCarts);
    }

    const handleShippingMethodClean = () => {
        const newCarts = carts.map(element => {
           return {
                products: element?.products,
                po: element?.po,
                idWarehouse: element?.idWarehouse,
                addressWarehouse: element?.addressWarehouse,
                nameWarehouse: element?.nameWarehouse
           }
        });
        setCarts(newCarts);
    }

    const handleResult = () => {
        setShowModal(false);
        setLoader(false);
        setIsRefreshAddress(true);
        toast.success("Address saved successfully", alertOptions);
    }

    const handleContinue = async () => {
        if (carts[0].shippingMethod !== "" && carts[0].costShipping >= 0) {
            setLoader(true);
            let cartsSended = 0;
            for (let i = 0; i < carts.length; i++) {
                const body = {
                    shippingMethod: carts[i].shippingMethod,
                    costShipping: carts[i].costShipping,
                    fromWarehouse: carts[i].fromWarehouse
                }

                if (carts[i].shippingMethod === "SHIP") {
                    body.userAddress = carts[i].userAddress;
                } else {
                    body.warehouseAddress = carts[i].warehouseAddress;
                }

                await shippingOptionCartWholesaler(dataSession._id, body).then(res => {
                    if (res.status === 200) cartsSended += 1;
                }).catch(() => {});
            }

            setLoader(false);
            if (cartsSended === carts.length) {
                navigate(`/wholesaler/mycart/payment`, { state: carts });
            } else {
                toast.error("There was a problem assigning the shipping method", alertOptions);
            }
        } else {
            toast.warning("Please select the shipping method and the address", alertOptions);
        }
    }

    useEffect(() => {
        setLoader(true);
        if (location?.state) setCarts(location.state);
        setLoader(false);
    }, []);
    return (
        <>
            { loader ? <Loader /> : null }

            {
                showModal ?
                    <NewAddress
                        idUser={dataSession._id}
                        typeUser="wholesaler"
                        onClose={() => setShowModal(false)}
                        onLoader={(sts) => setLoader(sts)}
                        onResult={() => handleResult()}
                    />
                : null
            }
            
            <div>
                <div className="flex flex-row">
                    <div className="w-full bg-no-repeat bg-cover fixed z-10">
                        <NavbarClients type="wholesaler" sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                    </div>
                </div>
                <div className="flex h-screen overflow-hidden">
                    <div className={`${sidebarOpen ? "w-[100vw] md:w-80 z-20" : "w-20 z-0"}`}>
                        <SidebarClients type="wholesaler" sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                    </div>
                    <div className="w-full overflow-auto flex-grow mt-[100px]">
                        <div className="space-y-5 px-3 py-5 md:px-8 md:py-8">
                            <div className="text-center">
                                <h1 className="text-[26px] md:text-xl lg:text-[26px] font-light text-ag-secondary">
                                    Shipping method
                                </h1>
                            </div>
                            <div className="pt-3 lg:flex lg:justify-center lg:items-start lg:space-x-10 space-y-5 lg:space-y-0">
                                <div className="w-full lg:w-[65%]">
                                    <ShippingForm
                                        idWholesaler={dataSession._id}
                                        carts={carts}
                                        onModal={() => setShowModal(true)}
                                        onMethod={handleShippingMethod}
                                        onRefreshAddress={isRefreshAddress}
                                        onResponseRefreshAddress={(sts) => setIsRefreshAddress(sts)}
                                        onCleanAddress={handleShippingMethodClean}
                                    />
                                </div>
                                <div className="w-full lg:w-[35%]">
                                    <MyCartInformation cart={carts} onContinue={() => handleContinue()} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default MyCartShipping;