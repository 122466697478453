import { useEffect, useState } from "react";
import RowsInventorySpecification from "./RowsInventorySpecification";
import Pagination from "../../../../components/pagination/Pagination";

const TableInventorySpecification = ({ arrProducts, currntPg, pagesData, onPage, onSelect, onCompatible }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(9);

    useEffect(() => {
        setCurrentPage(currntPg);
    }, [currntPg]);

    return (
        <div>
            <div className="overflow-auto h-[280px] md:h-[300px]">
                <table className="w-screen text-center text-ag-secondary">
                    <thead>
                        <tr className="bg-gray-300">
                            <th className="w-[15%] border-[0.5px] border-ag-secondary-light text-xs font-normal px-2 py-4 md:py-2">Warehouse</th>
                            <th className="w-[10%] border-[0.5px] border-ag-secondary-light text-xs font-normal px-2 py-4 md:py-2">Nag/ID Accesories</th>
                            <th className="w-[15%] border-[0.5px] border-ag-secondary-light text-xs font-normal px-2 py-4 md:py-2">Name</th>
                            <th className="w-[25%] border-[0.5px] border-ag-secondary-light text-xs font-normal px-2 py-4 md:py-2">Compatible Vehicles</th>
                            <th className="w-[15%] border-[0.5px] border-ag-secondary-light text-xs font-normal px-2 py-4 md:py-2">Barcodes</th>
                            <th className="w-[10%] border-[0.5px] border-ag-secondary-light text-xs font-normal px-2 py-4 md:py-2">Sales Price</th>
                            <th className="w-[10%] border-[0.5px] border-ag-secondary-light text-xs font-normal px-2 py-4 md:py-2">Stock</th>
                        </tr>
                    </thead>
                    <tbody>
                        { arrProducts.map((element, i) => (
                            <RowsInventorySpecification
                                key={i}
                                product={element}
                                onSelect={() => onSelect(element)}
                                onCompatible={onCompatible}
                            />
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="px-5 pt-5 pb-2 m-auto">
                <Pagination
                    currentPage={currentPage}
                    totalCount={pagesData.records}
                    pageSize={recordsPerPage}
                    onPageChange={page => onPage(page)}
                />
            </div>
        </div>
    );
}

export default TableInventorySpecification;