import { useContext, useEffect, useState } from "react";
import { PermitsContext } from "../../../services/Permits";
import { getUser, updateUser } from "../../../services/Administrator";
import { toast } from "react-toastify";
import { alertOptions } from "../../../utils/Utilities";
import Loader from "../../../components/loader/Loader";
import ProfileView from "../../fragments/profile/ProfileView";
import Navbar from "../../../components/navbar/Navbar";
import Sidebar from "../../../components/sidebar/Sidebar";
import Modal from "../../../components/modal/Modal";

const Profile = () => {
    const { dataSession, clearContext, logOutModal, setLogOutModal } = useContext(PermitsContext);
    const [loader, setLoader] = useState(false);
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const [isSuccess, setIsSuccess] = useState(false);
    const [profile, setProfile] = useState(null);

    const getData = () => {
        getUser(dataSession._id).then(res => {
            if (res.status === 200 && !Array.isArray(res.data)) setProfile(res.data);
            setLoader(false);
        });
    }

    const handleUpdate = (profileModel) => {
        setLoader(true);
        const newProfileModel = {
            _id: profile?._id,
            names: profileModel?.names,
            lastNames: profileModel?.lastNames,
            email: profileModel?.email,
            phoneNumber: profileModel?.phoneNumber,
            warehouse: profile?.warehouse,
            role: { _id: profile?.role?._id },
            image: profileModel?.image
        }
        updateUser(profile?._id, newProfileModel).then(res => {
            if (res.status === 200) {
                getData();
                toast.success("Profile updated successfully", alertOptions);
                setIsSuccess(true);
            } else {
                toast.warning(res.response.data.message, alertOptions);
            }
            setLoader(false);
        }).catch(error => {
            setLoader(false);
            toast.warning(error.response.data.message, alertOptions);
        });
    }

    const handleModalResponse = (sts) => {
        if (sts) {
            clearContext();
            window.location.reload(true);
        }
      };

    useEffect(() => {
        setLoader(true);
        getData();
    }, [dataSession]);

    return (
        <>
            { loader ? <Loader /> : null }

            { logOutModal.status && (
                <Modal
                    title={logOutModal.title}
                    onClose={() =>
                        setLogOutModal({ ...logOutModal, status: false, title: "", element: null })
                    }
                    onResponse={handleModalResponse}
                />
            )}

            <div>
                <div className="flex flex-row">
                    <div className="w-full bg-no-repeat bg-cover fixed z-10">
                        <Navbar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                    </div>
                </div>
                <div className="flex h-screen overflow-hidden">
                    <div className={`${sidebarOpen ? "w-[100vw] md:w-80 z-20" : "w-20 z-0"}`}>
                        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                    </div>
                    <div className="w-full overflow-auto flex-grow mt-[100px]">
                        <div className="space-y-5 px-3 py-5 md:px-8 md:py-8">
                            <div className="text-center">
                                <h1 className="text-[26px] md:text-xl lg:text-[26px] font-light text-ag-secondary">Profile</h1>
                            </div>
                            <div className="pt-3">
                                <ProfileView
                                    type="admin"
                                    information={profile}
                                    isSuccess={isSuccess}
                                    onSuccess={setIsSuccess}
                                    onUpdate={handleUpdate}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Profile;