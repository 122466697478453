import { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getRoles, saveRole, updateRole } from "../../../../services/Role";
import { getUsers, updateUser } from "../../../../services/Administrator";
import { FiChevronLeft } from "react-icons/fi";
import { toast } from "react-toastify";
import { alertOptions } from "../../../../utils/Utilities";
import { PermitsContext } from "../../../../services/Permits";
import Navbar from "../../../../components/navbar/Navbar";
import Sidebar from "../../../../components/sidebar/Sidebar";
import Loader from "../../../../components/loader/Loader";

const FormRoles = () => {
    const navigate = useNavigate();
    const params = useParams();
    const { resetPermits } = useContext(PermitsContext);
    const [loader, setLoader] = useState(false);
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const [isModifiable, setIsModifiable] = useState(false);
    const [roleDefault, setRoleDefault] = useState("");
    const [nameOptions, setNameOptions] = useState([]);
    const [sectionsAux, setSectionsAux] = useState({});
    const [usersUniv, setUsersUniv] = useState([]);

    const [focusClass, setFocusClass] = useState({
        name: { placeholder: "Write the role name here", status: false }
    });

    const [role, setRole] = useState({
        name: "",
        users: []
    });

    const handleChange = (evt) => {
        setRole({...role, [evt.currentTarget.name]: evt.currentTarget.value});
    }

    const handleChangeHeader = (evt) => {
        if (document.getElementById(evt.target.name).checked) {
            if (!sectionsAux.hasOwnProperty(evt.target.name)) {
                let newSectionObj = sectionsAux;
                newSectionObj[evt.target.name] = [];
                setSectionsAux(newSectionObj);
            }
        } else {
            if (sectionsAux.hasOwnProperty(evt.target.name)) {
                let newSectionObj = sectionsAux;

                for (let i = 0; i < newSectionObj[evt.target.name].length; i++) {
                    document.getElementById(`${evt.target.name}-${newSectionObj[evt.target.name][i]}`).checked = false;
                }

                delete newSectionObj[evt.target.name];
                setSectionsAux(newSectionObj);
            }
        }
    }

    const handleChangeSection = (evt, type, permit) => {
        let newSectionObj = sectionsAux;
        if (!sectionsAux.hasOwnProperty(type)) {
            newSectionObj[type] = [];
            document.getElementById(type).checked = true;
        }

        if (evt.target.checked){
            const foundPermit = newSectionObj[type].find(permitElement => permitElement === permit);
            if (!foundPermit) {
                newSectionObj[type].push(permit);
            }
        } else {
            const foundPermits = newSectionObj[type].filter((permitElement) => permitElement !== permit);
            newSectionObj[type] = foundPermits;
        }
        setSectionsAux(newSectionObj);
    }

    const handleChangeEmployees = (evt) => {
        let newArrUsers = role.users;
        const newOptions = nameOptions.map((element, indn) => {
            if (element.value === evt.currentTarget.value) {
                const foundUser = newArrUsers.find(userElement => (userElement.no === element.user._id));
                if (!foundUser) {
                    newArrUsers.push({ no: element.user._id, name: element.label, user: element.user });
                } else {
                    newArrUsers = newArrUsers.filter(userElement => (userElement.no !== element.user._id));
                }

                return  {
                    no: element.no,
                    value: element.value,
                    label: element.label,
                    selected: !element.selected,
                    isDefault: element.isDefault,
                    user: element.user
                }
            }
            return element;
        });
        setNameOptions(newOptions);
        setRole({...role, users: newArrUsers});
    }

    const handleSubmit = (evt) => {
        evt.preventDefault();
        if (role.name !== "") {
            if (Object.entries(sectionsAux).length > 0) {
                setLoader(true);
                const newRoleModel = {
                    name: role.name,
                    permissions: [sectionsAux],
                }
                if (params && params.id) {
                    newRoleModel._id = params.id;
                    updateRole(params.id, newRoleModel).then((res) => {
                        if (res.status === 200) {
                            for (let i = 0; i < role.users.length; i++) {
                                let userModel = {
                                    _id: role.users[i].user._id ? role.users[i].user._id : "",
                                    names: role.users[i].user.names ? role.users[i].user.names : "",
                                    lastNames: role.users[i].user.lastNames ? role.users[i].user.lastNames : "",
                                    email: role.users[i].user.user.email ? role.users[i].user.user.email : "",
                                    phoneNumber: role.users[i].user.phoneNumber ? role.users[i].user.phoneNumber : "",
                                    warehouse: role.users[i].user.warehouse ? role.users[i].user.warehouse : "",
                                    role: {
                                        _id: params.id
                                    }
                                }

                                updateUser(role.users[i].user._id, userModel).then(resUpd => {});
                            }

                            // Los usuarios que se deseleccionaron, se les asigna el rol predeterminado
                            const usersRoleDefault = nameOptions.filter(urElement => (!urElement.selected && urElement.isDefault));
                            if (usersRoleDefault && usersRoleDefault.length > 0) {
                                for (let iurd = 0; iurd < usersRoleDefault.length; iurd++) {
                                    let userModel = {
                                        _id: usersRoleDefault[iurd].user._id ? usersRoleDefault[iurd].user._id : "",
                                        names: usersRoleDefault[iurd].user.names ? usersRoleDefault[iurd].user.names : "",
                                        lastNames: usersRoleDefault[iurd].user.lastNames ? usersRoleDefault[iurd].user.lastNames : "",
                                        email: usersRoleDefault[iurd].user.user.email ? usersRoleDefault[iurd].user.user.email : "",
                                        phoneNumber: usersRoleDefault[iurd].user.phoneNumber ? usersRoleDefault[iurd].user.phoneNumber : "",
                                        warehouse: usersRoleDefault[iurd].user.warehouse ? usersRoleDefault[iurd].user.warehouse : "",
                                        role: {
                                            _id: roleDefault
                                        }
                                    }
                                    updateUser(userModel._id, userModel).then(resUpd => {});
                                }
                            }

                            resetPermits();
                            setLoader(false);
                            toast.success("Role updated successfully", alertOptions);
                            navigate("/administrators/roles");
                        } else {
                            setLoader(false);
                            toast.warning(res.response.data.message, alertOptions);
                        }
                    }).catch(error => {
                        setLoader(false);
                        toast.warning(error.response.data.message, alertOptions);
                    });
                } else {
                    saveRole(newRoleModel).then(res => {
                        if (res.status === 200) {
                            for (let i = 0; i < role.users.length; i++) {
                                let userModel = {
                                    _id: role.users[i].user._id ? role.users[i].user._id : "",
                                    names: role.users[i].user.names ? role.users[i].user.names : "",
                                    lastNames: role.users[i].user.lastNames ? role.users[i].user.lastNames : "",
                                    email: role.users[i].user.email ? role.users[i].user.email : "",
                                    phoneNumber: role.users[i].user.phoneNumber ? role.users[i].user.phoneNumber : "",
                                    warehouse: role.users[i].user.warehouse ? role.users[i].user.warehouse : "",
                                    role: {
                                        _id: res.data._id
                                    }
                                }
                                updateUser(role.users[i].user._id, userModel).then(resUpd => {});
                            }

                            resetPermits();
                            setLoader(false);
                            toast.success("Role added successfully", alertOptions);
                            navigate("/administrators/roles");
                        } else {
                            setLoader(false);
                            toast.warning(res.response.data.message, alertOptions);
                        }
                    }).catch(error => {
                        setLoader(false);
                        toast.warning(error.response.data.message, alertOptions);
                    });
                }
            } else {
                toast.warning("You cannot save a role without any permissions assigned", alertOptions);
            }
        } else {
            toast.info("Please fill in all the fields of the form", alertOptions);
        }
    }

    const getOptions = (roleName, array_users) => {
        let names = [];
        let usersCurrent = [];
        for (let i = 0; i < array_users.length; i++) {
            const nameFound = names.find(element => element._id === array_users[i]._id);
            let userAssigned = false;
            if (params && params.id && array_users[i].role?._id === params.id) userAssigned = true;

            if (!nameFound) {
                names.push({
                    no: array_users[i]._id,
                    user: array_users[i],
                    label: `${array_users[i].names} ${array_users[i].lastNames}`,
                    value: `${array_users[i].names} ${array_users[i].lastNames}`,
                    selected: userAssigned,
                    isDefault: userAssigned
                });

                if (userAssigned) {
                    usersCurrent.push({
                        no: array_users[i]._id,
                        name: `${array_users[i].names} ${array_users[i].lastNames}`,
                        user: array_users[i]
                    });
                }
            }
        }
        setRole({...role, name: roleName, users: usersCurrent});
        setUsersUniv(usersCurrent);
        setFocusClass({...focusClass, name: { placeholder: "Write the role name here", status: roleName !== "" ? true : false }});
        setNameOptions(names);
    }

    useEffect(() => {
        setLoader(true);
        getRoles().then(res => {
            if (res.status === 200 && res.data.length > 0) {
                if (params && params.id) {
                    // Se almacena el ID del rol predeterminado
                    const roleDefaultFound = res.data.find(roleElement => roleElement?.name === "DEFAULT ROLE");
                    if (roleDefaultFound) setRoleDefault(roleDefaultFound._id);
                    
                    // Se obtiene la informacion del rol actual
                    const roleCurrentFound = res.data.find(roleElement => roleElement?._id === params.id);
                    if (roleCurrentFound) {
                        if (roleCurrentFound?.name === "DEFAULT ROLE") setIsModifiable(true);

                        let dataPermits = JSON.parse(roleCurrentFound.permissions);
                        setSectionsAux(dataPermits[0]);

                        for (const key in dataPermits[0]) {
                            if (Object.hasOwnProperty.call(dataPermits[0], key)) {
                                document.getElementById(key).checked = true;

                                for (let i = 0; i < dataPermits[0][key].length; i++) {
                                    const permitFound = document.getElementById(`${key}-${dataPermits[0][key][i]}`);
                                    if (permitFound) permitFound.checked = true;
                                }
                            }
                        }

                        getUsers().then(resUsers => {
                            const usersFound = resUsers.data.filter(element => !element.deleted);
                            getOptions(roleCurrentFound?.name, usersFound);
                        });
                    }
                    setLoader(false);
                } else {
                    getUsers().then(resUsers => {
                        setLoader(false);
                        const usersFound = resUsers.data.filter(element => !element.deleted);
                        getOptions("", usersFound);
                    });
                }
            }
        }).catch(() => {
            setLoader(false);
        });
    }, []);

    return (
        <>
            { loader ? <Loader /> : null }

            <div>
                <div className="flex flex-row">
                    <div className="w-full bg-no-repeat bg-cover fixed z-10">
                        <Navbar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                    </div>
                </div>
                <div className="flex h-screen overflow-hidden">
                    <div className={`${sidebarOpen ? "w-[100vw] md:w-80 z-20" : "w-20 z-0"}`}>
                        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                    </div>
                    <div className="w-full overflow-auto flex-grow mt-[100px]">
                        <div className="space-y-5 py-8 px-5 md:px-16">
                            <div className="grid gap-3 md:gap-5 grid-cols-2 md:grid-cols-6">
                                <div>
                                    <Link to="/administrators/roles">
                                        <button className="p-1 rounded-xl" type="button">
                                            <span className="text-ag-secondary-dark text-3xl"><FiChevronLeft /></span>
                                        </button>
                                    </Link>
                                </div>
                                <div className="col-span-3 text-center">
                                    <h1 className="text-[26px] md:text-xl lg:text-[26px] font-light text-ag-secondary">
                                        New Role / Edit Role
                                    </h1>
                                </div>
                                <div>
                                    <Link to="/administrators/roles">
                                        <button
                                            className="w-full bg-ag-secondary-light text-white p-2 rounded-xl"
                                            type="button"
                                        >
                                            Cancel
                                        </button>
                                    </Link>
                                </div>
                                <div>
                                    <button
                                        className="w-full bg-ag-primary-light text-white p-2 rounded-xl"
                                        type="button"
                                        onClick={handleSubmit}
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                            <div className="pt-3 space-y-5">
                                <div className="md:flex md:justify-center md:items-center md:space-x-10 space-y-5 md:space-y-0 p-3 border border-ag-primary rounded-lg">
                                    <div className="w-full space-y-2">
                                        <div className="text-center">
                                            <h1 className="text-base text-ag-secondary font-bold">Role name*</h1>
                                        </div>
                                        <div>
                                            <input
                                                className={`w-full text-ag-secondary-letter border border-gray-500 p-2 truncate rounded-xl disabled:bg-zinc-200 ${focusClass.name.status ? "text-left" : "text-center"}`}
                                                name="name"
                                                type="text"
                                                placeholder={focusClass.name.placeholder}
                                                onChange={handleChange}
                                                value={role.name}
                                                disabled={isModifiable}
                                                onFocus={() => setFocusClass({...focusClass, name: { placeholder: "", status: true }})}
                                                onBlur={(evt) => evt.target.value === "" ? setFocusClass({...focusClass, name: { placeholder: "Write the role name here", status: false }}) : null}
                                                maxLength={50}
                                            />
                                        </div>
                                    </div>
                                    <div className="w-full space-y-2">
                                        <div className="text-center">
                                            <h1 className="text-base text-ag-secondary font-bold">Select your employees</h1>
                                        </div>
                                        <div>
                                            <select
                                                className="w-full text-center text-ag-secondary-letter border border-gray-500 p-2 rounded-xl"
                                                name="users"
                                                value="default"
                                                onChange={handleChangeEmployees}
                                            >
                                                <option value="default">Select</option>
                                                {
                                                    nameOptions.map((optionName, k) => {
                                                        return (
                                                            <option className="flex space-x-2" key={k} value={optionName.label}
                                                            >
                                                                { optionName.label }{ optionName.selected ? ' (✓)' : '' }
                                                            </option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="px-3 py-5 border border-ag-secondary rounded-lg">
                                    <div className="w-[95%] pt-0 pb-5 text-center border-b border-ag-secondary m-auto">
                                        <h1 className="text-[26px] md:text-xl lg:text-[26px] font-light text-ag-secondary">Sections</h1>
                                    </div>

                                    {/* ADMINISTRATOR PERMISSION */}
                                    <div className="w-[95%] py-5 border-b border-ag-secondary m-auto">
                                        <div>
                                            <label className="inline-flex relative items-center cursor-pointer">
                                                <input
                                                    type="checkbox"
                                                    id="ADMINISTRATORS"
                                                    name="ADMINISTRATORS"
                                                    className="sr-only peer"
                                                    onChange={handleChangeHeader}
                                                />
                                                <div className="w-8 h-4 bg-ag-secondary rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[6px] after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-3 after:w-3 after:transition-all peer-checked:bg-ag-primary z-[-1]"></div>
                                                <span className="ml-3 text-base text-ag-secondary font-bold">Administrators</span>
                                            </label>
                                        </div>
                                        <div className="px-11 py-5">
                                            <p className="text-base text-ag-secondary">Administrator permissions</p>
                                        </div>
                                        <div className="lg:flex lg:justify-start lg:items-center lg:space-x-5 lg:space-y-0 space-y-2">
                                            <div className="flex items-center space-x-2">
                                                <input
                                                    type="checkbox"
                                                    id="ADMINISTRATORS-FILTER"
                                                    className="w-4 h-4 accent-ag-primary rounded"
                                                    onChange={(evt) => handleChangeSection(evt, "ADMINISTRATORS", "FILTER")}
                                                />
                                                <label className="text-base text-ag-secondary">Admin Filters</label>
                                            </div>
                                            <div className="flex items-center space-x-2">
                                                <input
                                                    type="checkbox"
                                                    id="ADMINISTRATORS-ADD"
                                                    className="w-4 h-4 accent-ag-primary rounded"
                                                    onChange={(evt) => handleChangeSection(evt, "ADMINISTRATORS", "ADD")}    
                                                />
                                                <label className="text-base text-ag-secondary">New record</label>
                                            </div>
                                            <div className="flex items-center space-x-2">
                                                <input
                                                    type="checkbox"
                                                    id="ADMINISTRATORS-ROLE"
                                                    className="w-4 h-4 accent-ag-primary rounded"
                                                    onChange={(evt) => handleChangeSection(evt, "ADMINISTRATORS", "ROLE")}
                                                />
                                                <label className="text-base text-ag-secondary">Role</label>
                                            </div>
                                            <div className="flex items-center space-x-2">
                                                <input
                                                    type="checkbox"
                                                    id="ADMINISTRATORS-EDIT"
                                                    className="w-4 h-4 accent-ag-primary rounded"
                                                    onChange={(evt) => handleChangeSection(evt, "ADMINISTRATORS", "EDIT")}
                                                />
                                                <label className="text-base text-ag-secondary">Edit</label>
                                            </div>
                                            <div className="flex items-center space-x-2">
                                                <input
                                                    type="checkbox"
                                                    id="ADMINISTRATORS-DELETE"
                                                    className="w-4 h-4 accent-ag-primary rounded"
                                                    onChange={(evt) => handleChangeSection(evt, "ADMINISTRATORS", "DELETE")}
                                                />
                                                <label className="text-base text-ag-secondary">Delete</label>
                                            </div>
                                        </div>
                                    </div>

                                    {/* USER PERMISSIONS */}
                                    <div className="w-[95%] py-5 border-b border-ag-secondary m-auto">
                                        <div>
                                            <label className="inline-flex relative items-center cursor-pointer">
                                                <input
                                                    type="checkbox"
                                                    id="USERS"
                                                    name="USERS"
                                                    className="sr-only peer"
                                                    onChange={handleChangeHeader}
                                                />
                                                <div className="w-8 h-4 bg-ag-secondary rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[6px] after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-3 after:w-3 after:transition-all peer-checked:bg-ag-primary z-[-1]"></div>
                                                <span className="ml-3 text-base text-ag-secondary font-bold">Users</span>
                                            </label>
                                        </div>
                                        <div className="px-11 py-5">
                                            <p className="text-base text-ag-secondary">User permissions</p>
                                        </div>
                                        <div className="lg:flex lg:justify-start lg:items-center lg:space-x-5 lg:space-y-0 space-y-2">
                                            <div className="flex items-center space-x-2">
                                                <input
                                                    type="checkbox"
                                                    id="USERS-FILTER"
                                                    className="w-4 h-4 accent-ag-primary rounded"
                                                    onChange={(evt) => handleChangeSection(evt, "USERS", "FILTER")}
                                                />
                                                <label className="text-base text-ag-secondary">User Filters</label>
                                            </div>
                                            <div className="flex items-center space-x-2">
                                                <input
                                                    type="checkbox"
                                                    id="USERS-ADD_WOLESALER"
                                                    className="w-4 h-4 accent-ag-primary rounded"
                                                    onChange={(evt) => handleChangeSection(evt, "USERS", "ADD_WOLESALER")}
                                                />
                                                <label className="text-base text-ag-secondary">Add Wholesaler</label>
                                            </div>
                                            <div className="flex items-center space-x-2">
                                                <input
                                                    type="checkbox"
                                                    id="USERS-ADD_RETAILER"
                                                    className="w-4 h-4 accent-ag-primary rounded"
                                                    onChange={(evt) => handleChangeSection(evt, "USERS", "ADD_RETAILER")}
                                                />
                                                <label className="text-base text-ag-secondary">Add Retailer</label>
                                            </div>
                                            <div className="flex items-center space-x-2">
                                                <input
                                                    type="checkbox"
                                                    id="USERS-EDIT"
                                                    className="w-4 h-4 accent-ag-primary rounded"
                                                    onChange={(evt) => handleChangeSection(evt, "USERS", "EDIT")}
                                                />
                                                <label className="text-base text-ag-secondary">Edit</label>
                                            </div>
                                            <div className="flex items-center space-x-2">
                                                <input
                                                    type="checkbox"
                                                    id="USERS-DELETE"
                                                    className="w-4 h-4 accent-ag-primary rounded"
                                                    onChange={(evt) => handleChangeSection(evt, "USERS", "DELETE")}
                                                />
                                                <label className="text-base text-ag-secondary">Delete</label>
                                            </div>
                                            <div className="flex items-center space-x-2">
                                                <input
                                                    type="checkbox"
                                                    id="USERS-APPROVE_DISPPROVE"
                                                    className="w-4 h-4 accent-ag-primary rounded"
                                                    onChange={(evt) => handleChangeSection(evt, "USERS", "APPROVE_DISPPROVE")}
                                                />
                                                <label className="text-base text-ag-secondary">Approve / Disapprove</label>
                                            </div>
                                        </div>
                                    </div>

                                    {/* SUPPLIER PERMISSION */}
                                    <div className="w-[95%] py-5 border-b border-ag-secondary m-auto">
                                        <div>
                                            <label className="inline-flex relative items-center cursor-pointer">
                                                <input
                                                    type="checkbox"
                                                    id="SUPPLIERS"
                                                    name="SUPPLIERS"
                                                    className="sr-only peer"
                                                    onChange={handleChangeHeader}
                                                />
                                                <div className="w-8 h-4 bg-ag-secondary rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[6px] after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-3 after:w-3 after:transition-all peer-checked:bg-ag-primary z-[-1]"></div>
                                                <span className="ml-3 text-base text-ag-secondary font-bold">Supplier</span>
                                            </label>
                                        </div>
                                        <div className="px-11 py-5">
                                            <p className="text-base text-ag-secondary">Supplier permissions</p>
                                        </div>
                                        <div className="lg:flex lg:justify-start lg:items-center lg:space-x-5 lg:space-y-0 space-y-2">
                                            <div className="flex items-center space-x-2">
                                                <input
                                                    type="checkbox"
                                                    id="SUPPLIERS-FILTER"
                                                    className="w-4 h-4 accent-ag-primary rounded"
                                                    onChange={(evt) => handleChangeSection(evt, "SUPPLIERS", "FILTER")}
                                                />
                                                <label className="text-base text-ag-secondary">Supplier Filters</label>
                                            </div>
                                            <div className="flex items-center space-x-2">
                                                <input
                                                    type="checkbox"
                                                    id="SUPPLIERS-ADD"
                                                    className="w-4 h-4 accent-ag-primary rounded"
                                                    onChange={(evt) => handleChangeSection(evt, "SUPPLIERS", "ADD")}
                                                />
                                                <label className="text-base text-ag-secondary">New supplier</label>
                                            </div>
                                            <div className="flex items-center space-x-2">
                                                <input
                                                    type="checkbox"
                                                    id="SUPPLIERS-EDIT"
                                                    className="w-4 h-4 accent-ag-primary rounded"
                                                    onChange={(evt) => handleChangeSection(evt, "SUPPLIERS", "EDIT")}
                                                />
                                                <label className="text-base text-ag-secondary">Edit supplier</label>
                                            </div>
                                            <div className="flex items-center space-x-2">
                                                <input
                                                    type="checkbox"
                                                    id="SUPPLIERS-DELETE"
                                                    className="w-4 h-4 accent-ag-primary rounded"
                                                    onChange={(evt) => handleChangeSection(evt, "SUPPLIERS", "DELETE")}
                                                />
                                                <label className="text-base text-ag-secondary">Delete supplier</label>
                                            </div>
                                        </div>
                                    </div>

                                    {/* PRODUCTS PERMISSION */}
                                    <div className="w-[95%] py-5 border-b border-ag-secondary m-auto">
                                        <div>
                                            <label className="inline-flex relative items-center cursor-pointer">
                                                <input
                                                    type="checkbox"
                                                    id="PRODUCTS"
                                                    name="PRODUCTS"
                                                    className="sr-only peer"
                                                    onChange={handleChangeHeader}
                                                />
                                                <div className="w-8 h-4 bg-ag-secondary rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[6px] after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-3 after:w-3 after:transition-all peer-checked:bg-ag-primary z-[-1]"></div>
                                                <span className="ml-3 text-base text-ag-secondary font-bold">Products</span>
                                            </label>
                                        </div>
                                        <div className="px-11 py-5">
                                            <p className="text-base text-ag-secondary">Products permissions</p>
                                        </div>
                                        <div className="lg:flex lg:justify-start lg:items-center lg:space-x-5 lg:space-y-0 space-y-2">
                                            <div className="flex items-center space-x-2">
                                                <input
                                                    type="checkbox"
                                                    id="PRODUCTS-FILTER"
                                                    className="w-4 h-4 accent-ag-primary rounded"
                                                    onChange={(evt) => handleChangeSection(evt, "PRODUCTS", "FILTER")}
                                                />
                                                <label className="text-base text-ag-secondary">Products Filters</label>
                                            </div>
                                            <div className="flex items-center space-x-2">
                                                <input
                                                    type="checkbox"
                                                    id="PRODUCTS-ADD"
                                                    className="w-4 h-4 accent-ag-primary rounded"
                                                    onChange={(evt) => handleChangeSection(evt, "PRODUCTS", "ADD")}
                                                />
                                                <label className="text-base text-ag-secondary">New product</label>
                                            </div>
                                            <div className="flex items-center space-x-2">
                                                <input
                                                    type="checkbox"
                                                    id="PRODUCTS-HISTORY"
                                                    className="w-4 h-4 accent-ag-primary rounded"
                                                    onChange={(evt) => handleChangeSection(evt, "PRODUCTS", "HISTORY")}
                                                />
                                                <label className="text-base text-ag-secondary">Price history</label>
                                            </div>
                                            <div className="flex items-center space-x-2">
                                                <input
                                                    type="checkbox"
                                                    id="PRODUCTS-EDIT"
                                                    className="w-4 h-4 accent-ag-primary rounded"
                                                    onChange={(evt) => handleChangeSection(evt, "PRODUCTS", "EDIT")}
                                                />
                                                <label className="text-base text-ag-secondary">Edit</label>
                                            </div>
                                            <div className="flex items-center space-x-2">
                                                <input
                                                    type="checkbox"
                                                    id="PRODUCTS-DEACTIVATE"
                                                    className="w-4 h-4 accent-ag-primary rounded"
                                                    onChange={(evt) => handleChangeSection(evt, "PRODUCTS", "DEACTIVATE")}
                                                />
                                                <label className="text-base text-ag-secondary">Deactivate</label>
                                            </div>
                                        </div>
                                    </div>

                                    {/* PROVIDER PERMITS */}
                                    <div className="w-[95%] py-5 border-b border-ag-secondary m-auto">
                                        <div>
                                            <label className="inline-flex relative items-center cursor-pointer">
                                                <input
                                                    type="checkbox"
                                                    id="INVENTORY"
                                                    name="INVENTORY"
                                                    className="sr-only peer"
                                                    onChange={handleChangeHeader}
                                                />
                                                <div className="w-8 h-4 bg-ag-secondary rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[6px] after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-3 after:w-3 after:transition-all peer-checked:bg-ag-primary z-[-1]"></div>
                                                <span className="ml-3 text-base text-ag-secondary font-bold">Inventory</span>
                                            </label>
                                        </div>
                                        <div className="px-11 py-5">
                                            <p className="text-base text-ag-secondary">Provider permits</p>
                                        </div>
                                        <div className="lg:flex lg:justify-start lg:items-center lg:space-x-5 lg:space-y-0 space-y-2">
                                            <div className="flex items-center space-x-2">
                                                <input
                                                    type="checkbox"
                                                    id="INVENTORY-FILTER"
                                                    className="w-4 h-4 accent-ag-primary rounded"
                                                    onChange={(evt) => handleChangeSection(evt, "INVENTORY", "FILTER")}
                                                />
                                                <label className="text-base text-ag-secondary">Inventory Filters</label>
                                            </div>
                                            <div className="flex items-center space-x-2">
                                                <input
                                                    type="checkbox"
                                                    id="INVENTORY-ACTIONS"
                                                    className="w-4 h-4 accent-ag-primary rounded"
                                                    onChange={(evt) => handleChangeSection(evt, "INVENTORY", "ACTIONS")}
                                                />
                                                <label className="text-base text-ag-secondary">Actions</label>
                                            </div>
                                            <div className="flex items-center space-x-2">
                                                <input
                                                    type="checkbox"
                                                    id="INVENTORY-EDIT"
                                                    className="w-4 h-4 accent-ag-primary rounded"
                                                    onChange={(evt) => handleChangeSection(evt, "INVENTORY", "EDIT")}
                                                />
                                                <label className="text-base text-ag-secondary">Edit</label>
                                            </div>
                                            <div className="flex items-center space-x-2">
                                                <input
                                                    type="checkbox"
                                                    id="INVENTORY-RECORD"
                                                    className="w-4 h-4 accent-ag-primary rounded"
                                                    onChange={(evt) => handleChangeSection(evt, "INVENTORY", "RECORD")}
                                                />
                                                <label className="text-base text-ag-secondary">Record</label>
                                            </div>
                                            <div className="flex items-center space-x-2">
                                                <input
                                                    type="checkbox"
                                                    id="INVENTORY-ORDER_QUERY"
                                                    className="w-4 h-4 accent-ag-primary rounded"
                                                    onChange={(evt) => handleChangeSection(evt, "INVENTORY", "ORDER_QUERY")}
                                                />
                                                <label className="text-base text-ag-secondary">Order Query</label>
                                            </div>
                                        </div>
                                    </div>

                                    {/* WAREHOUSE PERMITS */}
                                    <div className="w-[95%] py-5 border-b border-ag-secondary m-auto">
                                        <div>
                                            <label className="inline-flex relative items-center cursor-pointer">
                                                <input
                                                    type="checkbox"
                                                    id="WAREHOUSE"
                                                    name="WAREHOUSE"
                                                    className="sr-only peer"
                                                    onChange={handleChangeHeader}
                                                />
                                                <div className="w-8 h-4 bg-ag-secondary rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[6px] after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-3 after:w-3 after:transition-all peer-checked:bg-ag-primary z-[-1]"></div>
                                                <span className="ml-3 text-base text-ag-secondary font-bold">Warehouse</span>
                                            </label>
                                        </div>
                                        <div className="px-11 py-5">
                                            <p className="text-base text-ag-secondary">Warehouse permits</p>
                                        </div>
                                        <div className="lg:flex lg:justify-start lg:items-center lg:space-x-5 lg:space-y-0 space-y-2">
                                            <div className="flex items-center space-x-2">
                                                <input
                                                    type="checkbox"
                                                    id="WAREHOUSE-FILTER"
                                                    className="w-4 h-4 accent-ag-primary rounded"
                                                    onChange={(evt) => handleChangeSection(evt, "WAREHOUSE", "FILTER")}    
                                                />
                                                <label className="text-base text-ag-secondary">Warehouse Filters</label>
                                            </div>
                                            <div className="flex items-center space-x-2">
                                                <input
                                                    type="checkbox"
                                                    id="WAREHOUSE-ADD"
                                                    className="w-4 h-4 accent-ag-primary rounded"
                                                    onChange={(evt) => handleChangeSection(evt, "WAREHOUSE", "ADD")}    
                                                />
                                                <label className="text-base text-ag-secondary">Create Warehouse</label>
                                            </div>
                                            <div className="flex items-center space-x-2">
                                                <input
                                                    type="checkbox"
                                                    id="WAREHOUSE-EDIT"
                                                    className="w-4 h-4 accent-ag-primary rounded"
                                                    onChange={(evt) => handleChangeSection(evt, "WAREHOUSE", "EDIT")}    
                                                />
                                                <label className="text-base text-ag-secondary">Edit W.</label>
                                            </div>
                                            <div className="flex items-center space-x-2">
                                                <input
                                                    type="checkbox"
                                                    id="WAREHOUSE-REMOVE"
                                                    className="w-4 h-4 accent-ag-primary rounded"
                                                    onChange={(evt) => handleChangeSection(evt, "WAREHOUSE", "REMOVE")}    
                                                />
                                                <label className="text-base text-ag-secondary">Delete W.</label>
                                            </div>
                                            <div className="flex items-center space-x-2">
                                                <input
                                                    type="checkbox"
                                                    id="WAREHOUSE-CREATE_RACK"
                                                    className="w-4 h-4 accent-ag-primary rounded"
                                                    onChange={(evt) => handleChangeSection(evt, "WAREHOUSE", "CREATE_RACK")}    
                                                />
                                                <label className="text-base text-ag-secondary">Create R.</label>
                                            </div>
                                            <div className="flex items-center space-x-2">
                                                <input
                                                    type="checkbox"
                                                    id="WAREHOUSE-EDIT_RACK"
                                                    className="w-4 h-4 accent-ag-primary rounded"
                                                    onChange={(evt) => handleChangeSection(evt, "WAREHOUSE", "EDIT_RACK")}    
                                                />
                                                <label className="text-base text-ag-secondary">Edit R.</label>
                                            </div>
                                            <div className="flex items-center space-x-2">
                                                <input
                                                    type="checkbox"
                                                    id="WAREHOUSE-DELET_RACK"
                                                    className="w-4 h-4 accent-ag-primary rounded"
                                                    onChange={(evt) => handleChangeSection(evt, "WAREHOUSE", "DELET_RACK")}    
                                                />
                                                <label className="text-base text-ag-secondary">Delete R.</label>
                                            </div>
                                        </div>
                                    </div>

                                    {/* ORDERS PERMITS */}
                                    <div className="w-[95%] py-5 border-b border-ag-secondary m-auto">
                                        <div>
                                            <label className="inline-flex relative items-center cursor-pointer">
                                                <input
                                                    type="checkbox"
                                                    id="ORDERS"
                                                    name="ORDERS"
                                                    className="sr-only peer"
                                                    onChange={handleChangeHeader}
                                                />
                                                <div className="w-8 h-4 bg-ag-secondary rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[6px] after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-3 after:w-3 after:transition-all peer-checked:bg-ag-primary z-[-1]"></div>
                                                <span className="ml-3 text-base text-ag-secondary font-bold">Orders and Returns</span>
                                            </label>
                                        </div>
                                        <div className="px-11 py-5">
                                            <p className="text-base text-ag-secondary">Orders permits</p>
                                        </div>
                                        <div className="lg:flex lg:justify-start lg:items-center lg:space-x-5 lg:space-y-0 space-y-2">
                                            <div className="flex items-center space-x-2">
                                                <input
                                                    type="checkbox"
                                                    id="ORDERS-ORDERS_FILTER"
                                                    className="w-4 h-4 accent-ag-primary rounded"
                                                    onChange={(evt) => handleChangeSection(evt, "ORDERS", "ORDERS_FILTER")}
                                                />
                                                <label className="text-base text-ag-secondary">Orders Filters</label>
                                            </div>
                                            <div className="flex items-center space-x-2">
                                                <input
                                                    type="checkbox"
                                                    id="ORDERS-ACTIONS_ORDERS"
                                                    className="w-4 h-4 accent-ag-primary rounded"
                                                    onChange={(evt) => handleChangeSection(evt, "ORDERS", "ACTIONS_ORDERS")}
                                                />
                                                <label className="text-base text-ag-secondary">Actions Orders</label>
                                            </div>
                                            <div className="flex items-center space-x-2">
                                                <input
                                                    type="checkbox"
                                                    id="ORDERS-RETURNS_FILTERS"
                                                    className="w-4 h-4 accent-ag-primary rounded"
                                                    onChange={(evt) => handleChangeSection(evt, "ORDERS", "RETURNS_FILTERS")}
                                                />
                                                <label className="text-base text-ag-secondary">Returns Filters</label>
                                            </div>
                                            <div className="flex items-center space-x-2">
                                                <input
                                                    type="checkbox"
                                                    id="ORDERS-ACTIONS_RETURNS"
                                                    className="w-4 h-4 accent-ag-primary rounded"
                                                    onChange={(evt) => handleChangeSection(evt, "ORDERS", "ACTIONS_RETURNS")}
                                                />
                                                <label className="text-base text-ag-secondary">Actions returns</label>
                                            </div>
                                        </div>
                                    </div>

                                    {/* SALES PERMITS */}
                                    <div className="w-[95%] py-5 border-b border-ag-secondary m-auto">
                                        <div>
                                            <label className="inline-flex relative items-center cursor-pointer">
                                                <input
                                                    type="checkbox"
                                                    id="SALES"
                                                    name="SALES"
                                                    className="sr-only peer"
                                                    onChange={handleChangeHeader}
                                                />
                                                <div className="w-8 h-4 bg-ag-secondary rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[6px] after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-3 after:w-3 after:transition-all peer-checked:bg-ag-primary z-[-1]"></div>
                                                <span className="ml-3 text-base text-ag-secondary font-bold">Sales</span>
                                            </label>
                                        </div>
                                        <div className="px-11 py-5">
                                            <p className="text-base text-ag-secondary">Sales permits</p>
                                        </div>
                                        <div className="lg:flex lg:justify-start lg:items-center lg:space-x-5 lg:space-y-0 space-y-2">
                                            <div className="flex items-center space-x-2">
                                                <input
                                                    type="checkbox"
                                                    id="SALES-FILTER"
                                                    className="w-4 h-4 accent-ag-primary rounded"
                                                    onChange={(evt) => handleChangeSection(evt, "SALES", "FILTER")}
                                                />
                                                <label className="text-base text-ag-secondary">Sales Filters</label>
                                            </div>
                                        </div>
                                    </div>

                                    {/* QUOTES PERMITS */}
                                    <div className="w-[95%] py-5 border-b border-ag-secondary m-auto">
                                        <div>
                                            <label className="inline-flex relative items-center cursor-pointer">
                                                <input
                                                    type="checkbox"
                                                    id="QUOTES"
                                                    name="QUOTES"
                                                    className="sr-only peer"
                                                    onChange={handleChangeHeader}
                                                />
                                                <div className="w-8 h-4 bg-ag-secondary rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[6px] after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-3 after:w-3 after:transition-all peer-checked:bg-ag-primary z-[-1]"></div>
                                                <span className="ml-3 text-base text-ag-secondary font-bold">Quotes</span>
                                            </label>
                                        </div>
                                        <div className="px-11 py-5">
                                            <p className="text-base text-ag-secondary">Quotes permits</p>
                                        </div>
                                        <div className="lg:flex lg:justify-start lg:items-center lg:space-x-5 lg:space-y-0 space-y-2">
                                            <div className="flex items-center space-x-2">
                                                <input
                                                    type="checkbox"
                                                    id="QUOTES-FILTER"
                                                    className="w-4 h-4 accent-ag-primary rounded"
                                                    onChange={(evt) => handleChangeSection(evt, "QUOTES", "FILTER")}
                                                />
                                                <label className="text-base text-ag-secondary">Quotes Filters</label>
                                            </div>
                                            <div className="flex items-center space-x-2">
                                                <input
                                                    type="checkbox"
                                                    id="QUOTES-SEARCH"
                                                    className="w-4 h-4 accent-ag-primary rounded"
                                                    onChange={(evt) => handleChangeSection(evt, "QUOTES", "SEARCH")}
                                                />
                                                <label className="text-base text-ag-secondary">Search</label>
                                            </div>
                                            <div className="flex items-center space-x-2">
                                                <input
                                                    type="checkbox"
                                                    id="QUOTES-CANCEL"
                                                    className="w-4 h-4 accent-ag-primary rounded"
                                                    onChange={(evt) => handleChangeSection(evt, "QUOTES", "CANCEL")}
                                                />
                                                <label className="text-base text-ag-secondary">Cancel</label>
                                            </div>
                                        </div>
                                    </div>

                                    {/* REPORTS PERMITS */}
                                    <div className="w-[95%] py-5 border-b border-ag-secondary m-auto">
                                        <div>
                                            <label className="inline-flex relative items-center cursor-pointer">
                                                <input
                                                    type="checkbox"
                                                    id="REPORTS"
                                                    name="REPORTS"
                                                    className="sr-only peer"
                                                    onChange={handleChangeHeader}
                                                />
                                                <div className="w-8 h-4 bg-ag-secondary rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[6px] after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-3 after:w-3 after:transition-all peer-checked:bg-ag-primary z-[-1]"></div>
                                                <span className="ml-3 text-base text-ag-secondary font-bold">Reports</span>
                                            </label>
                                        </div>
                                        <div className="px-11 py-5">
                                            <p className="text-base text-ag-secondary">Reports permits</p>
                                        </div>
                                        <div className="lg:flex lg:justify-start lg:items-center lg:space-x-5 lg:space-y-0 space-y-2">
                                            <div className="flex items-center space-x-2">
                                                <input
                                                    type="checkbox"
                                                    id="REPORTS-FILTER"
                                                    className="w-4 h-4 accent-ag-primary rounded"
                                                    onChange={(evt) => handleChangeSection(evt, "REPORTS", "FILTER")}
                                                />
                                                <label className="text-base text-ag-secondary">Reports Filters</label>
                                            </div>
                                            <div className="flex items-center space-x-2">
                                                <input
                                                    type="checkbox"
                                                    id="REPORTS-DOWNLOAD"
                                                    className="w-4 h-4 accent-ag-primary rounded"
                                                    onChange={(evt) => handleChangeSection(evt, "REPORTS", "DOWNLOAD")}
                                                />
                                                <label className="text-base text-ag-secondary">Download reports</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default FormRoles;