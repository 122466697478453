import { useContext, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { CSVLink } from "react-csv";
import { FiChevronLeft } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { getWarehouses } from "../../../../services/Warehouse";
import { getWholesalerUsers } from "../../../../services/Wholesaler";
import { getGeneralDate, searchProduct, searchProductWithLimits } from "../../../../services/Reports";
import { alertOptions, formatDateWordEng, formatReverseDate, formatYear } from "../../../../utils/Utilities";
import { getDataChart, getRowsGVCSV, headersProducts } from "../../../../utils/ExcelData";
import { PermitsContext } from "../../../../services/Permits";
import Navbar from "../../../../components/navbar/Navbar";
import Sidebar from "../../../../components/sidebar/Sidebar";
import BarsGraphics from "./BarsGraphic";
import Filters from "./Filters";
import HeaderData from "./HeaderData";
import TableGraphics from "./TableGraphics";
import Loader from "../../../../components/loader/Loader";
import InventorySpecification from "../../quotes/inventorySpecification/InventorySpecification";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import ModalCompatible from "../../products/ModalCompatible";

const GraphicalView = () => {
    const navigate = useNavigate();
    const csvLinkGV = useRef();
    const { permits, dataSession } = useContext(PermitsContext)
    const [loader, setLoader] = useState(false);
    const [sidebarOpen, setSidebarOpen] = useState(true);
    
    const [rows, setRows] = useState([]);
    const [nameCSV, setNameCSV] = useState("");

    const [showModalProducts, setShowModalProducts] = useState(false);
    const [showCompatible, setShowCompatible] = useState({ status: false, element: null });
    const [toPrint, setToPrint] = useState(false);
    const [includeGraph, setIncludeGraph] = useState(false);

    const [totalYear, setTotalYear] = useState(0);
    const [yearCurrent, setYearCurrent] = useState({ first: "-----", last: "-----"});
    const [warehouseOptions, setWarehouseOptions] = useState([]);
    const [yearOptions, setYearOptions] = useState([]);
    const [monthOptions] = useState([
        { label: "January", value: 1 },
        { label: "February", value: 2 },
        { label: "March", value: 3 },
        { label: "April", value: 4 },
        { label: "May", value: 5 },
        { label: "June", value: 6 },
        { label: "July", value: 7 },
        { label: "August", value: 8 },
        { label: "September", value: 9 },
        { label: "October", value: 10 },
        { label: "November", value: 11 },
        { label: "December", value: 12 },
    ]);

    const [productSelected, setProductSelected] = useState("");
    const [showByProduct, setShowByProduct] = useState(false);
    const [productsData, setProductsData] = useState([]);
    const [generalData, setGeneralData] = useState({
        totalTransactions: 0,
        totalSales: 0,
        totalReturns: 0,
        totalCredits: 0,
        totalSalesCredits: 0,
        total: 0
    });

    const getOptions = () => {
        let arrYears = [{ label: 2020, value: 2020 }];
        const numLaps = new Date().getFullYear() - 2020;
        for (let i = 1; i <= numLaps; i++) arrYears.push({ label: 2020+i, value: 2020+i });
        setYearOptions(arrYears);

        getWarehouses().then(res => {
            if (res.status === 200 && res.data.length > 0) {
                if (dataSession.userType === "ADMIN") {
                    const warehousesCurrent = res.data.filter(element => !element.deleted);
                    setWarehouseOptions(warehousesCurrent);
                } else {
                    const warehousesCurrent = res.data.filter(element => {
                        const foundWH = dataSession.allWarehouse.find(elem => element?._id === elem._id);
                        if (!element?.deleted && foundWH) return element;
                    });
                    setWarehouseOptions(warehousesCurrent);
                }
            }
        });
    }

    const handleProduct = (resProd) => {
        setProductSelected(resProd?.productInventory?.product?.nags);
        setShowModalProducts(false);
    }

    const getGeneralData = async (paramsObject) => {
        if (paramsObject?.year) {
            setYearCurrent({...yearCurrent, first: paramsObject?.year, last: paramsObject?.year});
        } else {
            setYearCurrent({...yearCurrent,
                first: paramsObject?.startDate ? formatYear(paramsObject?.startDate) : "-----",
                last: paramsObject?.lastDate ? formatYear(paramsObject?.lastDate) : "-----"
            });
        }

        let totalWCredits = 0;
        const wholesalerRes = await getWholesalerUsers();
        const wholesalerVal = wholesalerRes.status === 200 ? wholesalerRes.data : [];
        for (let i = 0; i < wholesalerVal.length; i++) {
            totalWCredits += Number(wholesalerVal[i]?.availableCredit);
        }

        await getGeneralDate(paramsObject).then(res => {
            if (res.status === 200) {
                setGeneralData({...generalData,
                    totalTransactions: res.data?.totalTransactions,
                    totalSales: res.data?.totalSales,
                    totalReturns: res.data?.totalReturns,
                    totalCredits: totalWCredits,
                    totalSalesCredits: res.data?.total_payWithstoreCredit ? res.data?.total_payWithstoreCredit : 0,
                    total: res.data.total
                });
            } else {
                toast.warning("Search without information", alertOptions);
            }
        });

        let totalYearObj = { year: new Date().getFullYear() };
        await getGeneralDate(totalYearObj).then(res => {
            if (res.status === 200) {
                setTotalYear(Number(res.data.total) - Number(res.data.totalSubtract) - Number(res.data?.total_payWithstoreCredit ? res.data?.total_payWithstoreCredit : 0));
            }
        })
    }

    const getProductData = (paramsObject) => {
        setLoader(true);
        if (paramsObject.startDate && paramsObject.startDate !== "") {
            paramsObject.startDate = formatReverseDate(paramsObject.startDate);
            paramsObject.endDate = formatReverseDate(paramsObject.endDate);
        }
        searchProduct(paramsObject).then(res => {
            if (res.status === 200) {
                new Promise((resolve, reject) => {
                    setProductsData(getDataChart(res.data, paramsObject));
                    resolve();
                }).then(() => {
                    setTimeout(() => {
                        setShowByProduct(true);
                        setLoader(false);
                    }, 2000);
                });
            } else {
                toast.warning("Search without information", alertOptions);
                setLoader(false);
            }
        }).catch(() => setLoader(false));
    }

    const getDataWithFilters = (paramsObject) => {
        setLoader(true);
        if (paramsObject.startDate && paramsObject.startDate !== "") {
            paramsObject.startDate = formatReverseDate(paramsObject.startDate);
            paramsObject.endDate = formatReverseDate(paramsObject.endDate);
        }
        searchProductWithLimits(paramsObject).then(res => {
            if (res.status === 200) {
                new Promise((resolve, reject) => {
                    setProductsData(getDataChart(res.data, paramsObject));
                    resolve();
                }).then(() => {
                    setTimeout(() => {
                        setShowByProduct(true);
                        setLoader(false);
                    }, 2000);
                });
            } else {
                toast.warning("Search without information", alertOptions);
                setLoader(false);
            }
        }).catch(() => {});
    }

    const exportPDF = () => {
        if (productsData.productsData.length > 0) {
            setLoader(true);
            new Promise((resolve, reject) => {
                setToPrint(true);
                setTimeout(() => {
                    resolve();
                }, 1000);
            }).then(() => {
                let input = document.getElementById('divToPrint');
                let realSize = document.getElementById('divToPrint').clientWidth;
                
                html2canvas(input, { scrollX: 0, scrollY: 0 }).then((canvas) => {
                    const imgData = canvas.toDataURL('image/png', 1.0);
                    const pdf = new jsPDF({
                        orientation: productsData.productsData.length > 10 && includeGraph ? 'portrait' : productsData.productsData.length > 15 && !includeGraph ? 'portrait' : 'landscape',
                        unit: 'px',
                        format: [input.clientWidth, input.clientHeight]
                    });

                    const width = pdf.internal.pageSize.getWidth();
                    const height = pdf.internal.pageSize.getHeight();
                    pdf.addImage(imgData, 'jpeg', 100, 0, width-200, height);
                    pdf.save(`Graphical_view ${formatDateWordEng(new Date())}`);

                    setToPrint(false);
                    setLoader(false);
                }).then(() => {
                    document.getElementById('divToPrint').style.width = `${realSize}px`;
                    document.getElementById('divToPrint').style.height = "auto";
                    document.getElementById('divToPrint').style.minHeight = "auto";
                });
            });
        } else {
            toast.info("There are no records to download the PDF file", alertOptions);
        }
    }

    const exportCSV = () => {
        if (productsData.productsData.length > 0) {
            new Promise((resolve, reject) => {
                const dataRows = getRowsGVCSV(productsData.productsData);
                setRows(dataRows);
                setNameCSV(`Graphical_view ${formatDateWordEng(new Date())}`);
                resolve();
            }).then(() => {
                csvLinkGV.current.link.click();
            });
        } else {
            toast.info("There are no records to download the Excel file", alertOptions);
        }
    }

    useEffect(() => {
        if (dataSession._id !== "") {
            getOptions();
            getGeneralData({ year: new Date().getFullYear() });
            getProductData({ year: new Date().getFullYear() });
            setYearCurrent({...yearCurrent, first: new Date().getFullYear(), last: new Date().getFullYear()});
        }
    }, [dataSession]);

    return (
        <>
            <CSVLink headers={headersProducts} filename={nameCSV} data={rows} ref={csvLinkGV} />

            { loader ? <Loader /> : null }

            {
                showModalProducts ?
                    <InventorySpecification
                        onSelect={handleProduct}
                        onClose={() => setShowModalProducts(false)}
                        onLoader={(val) => setLoader(val)}
                        onCompatible={(prdSel) => setShowCompatible({...showCompatible, status: true, element: prdSel})}
                    />
                : null
            }

            {
                showCompatible.status ?
                    <ModalCompatible
                        products={showCompatible.element}
                        onClose={() => setShowCompatible({...showCompatible, status: false, element: null})}
                    />
                : null
            }

            <div>
                <div className="flex flex-row">
                    <div className="w-full bg-no-repeat bg-cover fixed z-10">
                        <Navbar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                    </div>
                </div>
                <div className="flex h-screen overflow-hidden">
                    <div className={`${sidebarOpen ? "w-[100vw] md:w-80 z-20" : "w-20 z-0"}`}>
                        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                    </div>
                    <div className="w-full overflow-auto flex-grow mt-[100px]">
                        <div className="space-y-5 px-3 py-5 md:px-8 md:py-8">
                            <div className="grid grid-cols-4 md:grid-cols-12 px-0 md:px-5">
                                <div>
                                    <button className="rounded-md" type="button" onClick={() => navigate(-1)}>
                                        <span className="text-ag-secondary-dark text-3xl"><FiChevronLeft /></span>
                                    </button>
                                </div>
                                <div className="flex justify-center items-center col-span-2 md:col-span-11 text-center">
                                    <h1 className="text-[26px] md:text-xl lg:text-[26px] font-light text-ag-secondary md:mr-[8%]">
                                        Graphical view
                                    </h1>
                                </div>
                                <div></div>
                            </div>
                            <div className="pt-3">
                                <div className="grid lg:flex lg:justify-center lg:items-center">
                                    <div className="w-full lg:w-2/3 order-last lg:order-first">
                                        <Filters
                                            enableFilters={permits['REPORTS']['FILTER']}
                                            warehouseOptions={warehouseOptions}
                                            yearOptions={yearOptions}
                                            monthOptions={monthOptions}
                                            onModal={() => setShowModalProducts(true)}
                                            productSelected={productSelected}
                                            onFilterGeneral={getGeneralData}
                                            onFilterProducts={getProductData}
                                            onFilterWithLimits={getDataWithFilters}
                                        />
                                    </div>
                                    <div className="w-full lg:w-1/3">
                                        <HeaderData data={generalData} totalYear={totalYear} />
                                    </div>
                                </div>
                            </div>
                            {
                                showByProduct ?
                                    <div>
                                        <div className="space-y-5" id="divToPrint">
                                            {
                                                toPrint ? 
                                                    <div className="flex justify-between items-center pb-5">
                                                        <h1 className="text-xl">Graphical view report</h1>
                                                        <h1>{ formatDateWordEng(new Date()) }</h1>
                                                    </div>
                                                : null
                                            }
                                            <div className={`${toPrint && !includeGraph ? "hidden" : ""} py-3`}>
                                                <BarsGraphics data={productsData.graphicData} year={yearCurrent} />
                                            </div>
                                            <div className={`${toPrint ? "hidden" : "lg:flex lg:justify-center lg:items-center space-y-2 lg:space-y-0"}`}>
                                                <div className="w-full md:flex md:justify-center md:items-center md:space-x-3 space-y-2 md:space-y-0 py-6 border border-ag-secondary">
                                                    <div className="w-full md:w-auto flex justify-center items-center space-x-3 px-5 py-1 border border-ag-secondary-light rounded-xl" title="Include graph in PDF">
                                                        <div className={`flex justify-center items-center w-4 h-4 border-[1.5px] ${ includeGraph ? "border-ag-primary" : "border-ag-secondary-light" } rounded-full`}>
                                                            <input
                                                                className={`w-2 h-2 accent-ag-primary cursor-pointer ${ includeGraph ? "bg-ag-primary" : "" } rounded-full appearance-none`}
                                                                type="checkbox"
                                                                checked={includeGraph}
                                                                onChange={(evt) => setIncludeGraph(evt.target.checked)}
                                                            />
                                                        </div>
                                                        <span className={`${includeGraph ? "text-ag-primary" : "text-ag-secondary-letter"} text-xs lg:text-sm`}>
                                                            Include graph in PDF
                                                        </span>
                                                    </div>
                                                    <button className="w-full md:w-auto bg-ag-secondary px-8 py-1 text-xs lg:text-sm text-white rounded-xl disabled:bg-zinc-200" disabled={!permits['REPORTS']['DOWNLOAD']} type="button" onClick={exportPDF}>
                                                        Download PDF
                                                    </button>
                                                </div>
                                                <div className="w-full flex justify-center py-[24.5px] border border-ag-secondary">
                                                    <button className="w-full md:w-auto bg-ag-secondary px-8 py-1 text-xs lg:text-sm text-white rounded-xl disabled:bg-zinc-200" disabled={!permits['REPORTS']['DOWNLOAD']} type="button" onClick={exportCSV}>
                                                        Download Excel
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="overflow-x-auto">
                                                <TableGraphics
                                                    products={productsData.productsData}
                                                    onPrint={toPrint}
                                                    onCompatible={(prdSel) => setShowCompatible({...showCompatible, status: true, element: prdSel})}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                : null
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default GraphicalView;