import { MdEdit } from "react-icons/md";
import { Link } from "react-router-dom";
import { formatCurrency } from "../../../utils/Utilities";
import { useEffect, useState } from "react";
import { Tooltip } from 'react-tooltip';

const RowsProducts = ({ enableEdit, enableDeactivate, products, onStatus, onCompatible }) => {
    const [vendorsStr, setVendorsStr] = useState("");

    useEffect(() => {
        if (products !== null) {
            const newVendors = products.vendors.map((vendor) => vendor.name);
            setVendorsStr(newVendors.join(', '));
        }
    }, [products]);

    return (
        <tr>
            <td className="w-[6%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-3">
                <p className="truncate w-16 m-auto">{products.nags}</p>
            </td>
            <td className="w-[10%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-3">
                <p className="truncate w-28 m-auto">{products.name}</p>
            </td>
            <td className="w-[32%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-3">
                {products.compatible_vehicles.slice(0, 3).map((prodComp, i) => (
                    <p className="truncate w-52 m-auto" key={i} title={
                        `${String(prodComp?.year).replace(/\s+/g, '').split(',').length > 1 
                            ? `${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[0]} - ${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[String(prodComp?.year).replace(/\s+/g, '').split(',').length-1]}`
                            : prodComp?.year} ` +
                        `${prodComp?.brand} ` +
                        `${prodComp?.model} ` +
                        `${prodComp?.type_car}`
                    }>
                        {String(prodComp?.year).replace(/\s+/g, '').split(',').length > 1 
                            ? `${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[0]} - ${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[String(prodComp?.year).replace(/\s+/g, '').split(',').length-1]}`
                            : prodComp?.year}{' '}
                        {prodComp?.brand}{' '}
                        {prodComp?.model}{' '}
                        {prodComp?.type_car}
                    </p>
                ))}
                {/* {products.compatible_vehicles.length > 3 ? (
                    <p className="truncate w-52 font-semibold m-auto cursor-pointer" onClick={() => onCompatible(products)}>
                        See compatible vehicles +
                    </p>
                ) : null} */}
                  <p className="truncate w-52 font-semibold m-auto cursor-pointer" onClick={() => onCompatible(products)}>
                    See compatible vehicles +
                </p>
            </td>
            <td className="w-[15%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-3">
                <p
                    className="truncate w-32 m-auto"
                    data-tooltip-id={`tooltip-description-${products._id}`}
                    data-tooltip-content={products.description}
                >
                    {products.description}
                </p>
                <Tooltip id={`tooltip-description-${products._id}`} place="top" effect="solid" />
            </td>
            <td className="w-[8%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-3">
                <p className="truncate w-20 m-auto" title={vendorsStr}>{vendorsStr}</p>
            </td>
            <td className="w-[6%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-3">
                <p className="truncate w-16 m-auto">{products.price ? formatCurrency(products.price) : "$0.00"}</p>
            </td>
            <td className="w-[8%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-3">
                <p className="truncate w-20 m-auto">{products.barcode1}</p>
            </td>
            <td className="w-[8%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-3">
                <p className="truncate w-20 m-auto">{products.barcode2}</p>
            </td>
            <td className="w-[6%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-3">
                <p className="truncate w-16 m-auto">{products.totalQuantity}</p>
            </td>
            <td className="w-[5%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-3">
                <Link to={!enableEdit ? "" : `/products/element/${products._id}`}>
                    <button className="text-2xl disabled:bg-zinc-200" type="button" disabled={!enableEdit}>
                        <MdEdit />
                    </button>
                </Link>
            </td>
            <td className="w-[6%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-3">
                <label className="inline-flex relative items-center cursor-pointer">
                    <input
                        className="sr-only peer disabled:bg-zinc-200"
                        type="checkbox"
                        checked={products.status === "active" || products.status === "true" || products.status === true}
                        onChange={(evt) => onStatus(evt)}
                        disabled={!enableDeactivate}
                    />
                    <div className="w-10 h-5 bg-ag-secondary rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-ag-primary z-[-1]"></div>
                    <span className="ml-3 text-base text-ag-secondary font-bold"></span>
                </label>
            </td>
        </tr>
    );
};

export default RowsProducts;
