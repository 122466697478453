import { useEffect, useState } from "react";
import { alertOptions, formatCurrency, getAllFeaturesWC } from "../../../../utils/Utilities";
import { IoMdImage } from "react-icons/io";
import { toast } from "react-toastify";
import IconMegaphone from "../../../../assets/icons/icon_megaphone.svg";

const FormDetail = ({ product, qtyDefault, onCart, onPurcharse, onAddWishList }) => {
    const [currentImage, setCurrentImage] = useState({ data: null, index: 0 });
    const [arrImages, setArrImages] = useState([]);
    const [quantity, setQuantity] = useState(1);
    const [features, setFeatures] = useState([]);

    const handleChange = (evt) => {
        const pattern = new RegExp(/^[0-9\s]+$/g);
        if (pattern.test(evt.currentTarget.value) || evt.currentTarget.value === "") {
            setQuantity(evt.target.value);
        }
    }

    const handlePurcharse = () => {
        if (quantity > 0) {
            if (quantity <= product?.quantity) {
                const objCart = {
                    warehouse: { _id: product?.warehouse },
                    productInventory: { _id: product?._id },
                    quantity: Number(quantity),
                    year: product.searchValueYear,
                    price: product?.price
                }
                onPurcharse(objCart);
                setQuantity(1);
            } else {
                toast.warning("The quantity entered exceeds the available stock", alertOptions);
            }
        } else {
            toast.warning("The quantity to buy must be greater than 0", alertOptions);
        }
    }

    const handleSubmit = () => {
        if (quantity > 0) {
            if (quantity <= product?.quantity) {
                const objCart = {
                    warehouse: { _id: product?.warehouse },
                    productInventory: { _id: product?._id },
                    quantity: Number(quantity),
                    year: product.searchValueYear,
                    price: product?.price
                }
                onCart(objCart);
                setQuantity(1);
            } else {
                toast.warning("The quantity entered exceeds the available stock", alertOptions);
            }
        } else {
            toast.warning("The quantity to buy must be greater than 0", alertOptions);
        }
    }

    useEffect(() => {
        if (product !== null && product?.features) {
            const featuresAux = getAllFeaturesWC();
            let distinctFeatures = [];

            for (let i = 0; i < featuresAux.length; i++) {
                const regex = new RegExp(featuresAux[i].value.toLowerCase(), 'i')
                if (
                    (product.compatible_vehicles.some(element => regex.test(element.ad_info_a.toLowerCase())) === true) ||
                    (String(product.features).toLowerCase().includes(String(featuresAux[i].value).toLowerCase()) === true)
                ) {
                    const foundFeatures = distinctFeatures.find(distFeatElem => distFeatElem === featuresAux[i].value);
                    if (!foundFeatures) distinctFeatures.push(featuresAux[i].value);
                }
            }
            if (product.compatible_vehicles.some(element => element.antena === "Y")) {
                const foundFeatures = distinctFeatures.find(feature => feature === "Antenna");
                if (!foundFeatures) distinctFeatures.push("Antenna");
            }
            if (product.compatible_vehicles.some(element => element.encapsulated === "Y")) {
                const foundFeatures = distinctFeatures.find(feature => feature === "Encapsulated");
                if (!foundFeatures) distinctFeatures.push("Encapsulated");
            }
            if (product.compatible_vehicles.some(element => element.hud === "Y")) {
                const foundFeatures = distinctFeatures.find(feature => feature === "HUD");
                if (!foundFeatures) distinctFeatures.push("HUD");
            }
            if (product.compatible_vehicles.some(element => element.slider === "Y")) {
                const foundFeatures = distinctFeatures.find(feature => feature === "Slider");
                if (!foundFeatures) distinctFeatures.push("Slider");
            }
            if (product.compatible_vehicles.some(element => element.solar === "Y")) {
                const foundFeatures = distinctFeatures.find(feature => feature === "Solar");
                if (!foundFeatures) distinctFeatures.push("Solar");
            }
            setFeatures(distinctFeatures);
        }
    }, [product]);

    useEffect(() => {
        if (product !== null && product?.images.length > 0) {
            setArrImages(product.images);
            setCurrentImage({...currentImage, data: product.images[0], index: 0});
        }
    }, [product]);

    useEffect(() => {
        setQuantity(qtyDefault.qty);
    }, [qtyDefault]);

    return (
        <div className="lg:flex lg:justify-center lg:items-center px-0 lg:px-10 lg:space-x-5 xl:space-x-10 space-y-5 lg:space-y-0">
            <div className="space-y-5">
                <div className="border border-ag-secondary-letter p-3">
                    <div className="w-[350px] md:w-[300px] lg:w-[350px] h-[350px] md:w-[300px] lg:h-[350px] flex justify-center items-center m-auto">
                        { 
                            currentImage.data === null ?
                                <span className="text-[70px] text-ag-secondary-letter"><IoMdImage /></span>
                            :
                                <img className="w-full h-full object-contain" src={currentImage.data} />
                        }
                    </div>
                    <div className="flex justify-center items-center space-x-5">
                        { arrImages.map((imgElement, i) => (
                            <div
                                className={`w-5 h-5 ${ i === currentImage.index ? "bg-zinc-500" : "bg-zinc-200" } cursor-pointer rounded-full`}
                                onClick={() => setCurrentImage({...currentImage, data: imgElement, index: i})}
                            >
                            </div>
                        ))}
                    </div>
                </div>
                {
                    product?.quantity > 0 ?
                        <>
                            <div>
                                <p className="text-ag-secondary-letter text-[28px] font-semibold">Sales Price: {formatCurrency(product.price)}</p>
                            </div>
                            <div className="flex justify-between items-center space-x-5 py-2">
                                <div>
                                    <p className="text-ag-secondary-letter text-[20px]"><b>Nags price:</b> {formatCurrency(product.nagsPrice)}</p>
                                </div>
                                <div>
                                    <input
                                        className="w-32 p-2 text-center border border-ag-secondary"
                                        name="quantity"
                                        type="text"
                                        onChange={handleChange}
                                        value={quantity}
                                    />
                                </div>
                            </div>
                        </>
                    : null
                }
                {
                    product?.quantity > 0 ?
                        <div className="space-y-2">
                            <button className="w-full p-3 bg-ag-primary-light text-white text-xs rounded-xl" type="button" onClick={() => handleSubmit()}>
                                Add to cart
                            </button>
                            <button className="w-full p-3 bg-ag-secondary text-white text-xs rounded-xl" type="button" onClick={() => handlePurcharse()}>
                                Buy it now
                            </button>
                        </div>
                    : null
                }
            </div>
            <div className="px-5 space-y-5">
                {
                    product?.wishList || !product?.available === false || product?.quantity === 0 ?
                        <div className="flex justify-end items-end space-x-3">
                            <img
                                className={`${product?.wishList ? "opacity-50" : "cursor-pointer"}`}
                                src={IconMegaphone}
                                onClick={() => product?.wishList ? null : onAddWishList(product)}
                            />
                        </div>
                    : null
                }
                <div className="px-5 px-0 lg:px-5 text-center w-full lg:w-60 xl:w-[400px] xl:w-[600px] truncate m-auto">
                    <span className="text-xl">
                        { product.nags } { product.name} { product?.features !== "" ? `[${product?.features}]` : "" }
                    </span>
                </div>
                <div className="px-5 px-0 lg:px-10">
                    <span >Nags: { product.nags }</span>
                </div>
                <div className="bg-zinc-200 text-center">
                    <span>Description</span>
                </div>
                <div className="text-ag-secondary-letter text-center">
                    <p className="w-full lg:w-60 xl:w-[400px] xl:w-[600px] truncate m-auto" title={product.description}>{ product.description }</p>
                    <p>Hour labor: { product.labor } hrs</p>
                </div>
                <div className="bg-zinc-200 text-center">
                    <span>Features</span>
                </div>
                <div className="text-ag-secondary-letter text-center">
                    <ul>
                        {
                            features.length > 0 ? features.map((elem, i) => (
                                <li key={i}>{ elem }</li>
                            )) : <li className="italic">Features not found...</li>
                        }
                    </ul>
                </div>
                <div className="bg-zinc-200 text-center">
                    <span>Compatible vehicles</span>
                </div>
                <div className="text-center h-[100px] overflow-y-auto">
                    <ul>
                        {
                            product?.compatible_vehicles?.map((prodComp, i) => (
                                <li className="w-full text-ag-secondary-letter" key={i}>
                                    { String(prodComp?.year).replace(/\s+/g, '').split(',').length > 1 ? `${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[0]} - ${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[Number(String(prodComp?.year).replace(/\s+/g, '').split(',').length-1)]}` : prodComp?.year }{' '}
                                    { prodComp?.brand }{' '}
                                    { prodComp?.model }{' '}
                                    { prodComp?.type_car }
                                </li>
                            ))
                        }
                    </ul>
                </div>
                {/* {
                    product?.quantity === 0 ?
                        <div className="space-y-2">
                            <button className="w-full p-3 bg-black text-white text-xs rounded-xl" type="button">
                                Add wish list
                            </button>
                        </div>
                    : null
                } */}
            </div>
        </div>
    );
}

export default FormDetail;