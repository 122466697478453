import { useEffect, useState, useContext } from "react";
import { attendWishList, getAllWishList } from "../../../services/WishList";
import { RiErrorWarningLine } from "react-icons/ri";
import { toast } from "react-toastify";
import { alertOptions } from "../../../utils/Utilities";
import Navbar from "../../../components/navbar/Navbar";
import Sidebar from "../../../components/sidebar/Sidebar";
import WishListCard from "./WishListCard";
import Modal from "../../../components/modal/Modal";
import Loader from "../../../components/loader/Loader";
import Pagination from "../../../components/pagination/Pagination";
import ModalCompatible from "../products/ModalCompatible";
import { PermitsContext } from "../../../services/Permits";

const WishList = () => {
    const { clearContext, logOutModal, setLogOutModal } = useContext(PermitsContext);
    const [loader, setLoader] = useState(false);
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const [showModal, setShowModal] = useState({ status: false, data: "" });
    const [showCompatible, setShowCompatible] = useState({ status: false, element: null });
    const [wishList, setWishList] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(10);

    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = wishList.slice(indexOfFirstRecord, indexOfLastRecord);

    // const getData = () => {
    //     getAllWishList().then((res) => {
    //         if (res.status === 200) {
    //             const array_sort = res.data.sort(function(a,b){
    //                 return b.count - a.count;
    //             });
    //             setWishList(array_sort);
    //         }
    //     });
    // }

    const getData = async () => {
        setLoader(true)
        await getAllWishList().then((res) => {
            if (res.status === 200) {
                const array_sort = res.data.sort(function (a, b) {
                    return b.count - a.count;
                });
                setWishList(array_sort);
            }
        });
        setLoader(false)
    }

    const handleAttend = (idPE) => {
        setLoader(true);
        setShowModal({...showModal, status: false, data: ""});
        // attendWishList({ productSpecification: { _id: idPE } }).then(res => {
        //     if (res.status === 200) {
        //         toast.success("The wish product was successfully served", alertOptions);
        //         setLoader(false);
        //         getData();
        //     } else {
        //         setLoader(false);
        //         toast.warning(res?.response?.data?.message, alertOptions);
        //     }
        // }).catch((error) => {
        //     setLoader(false);
        //     toast.warning(error.response.data.message, alertOptions);
        // });

        attendWishList({ nag: idPE }).then(res => {
            if (res.status === 200) {
                toast.success("The wish product was successfully served", alertOptions);
                setLoader(false);
                getData();
            } else {
                setLoader(false);
                toast.warning(res?.response?.data?.message, alertOptions);
            }
        }).catch((error) => {
            setLoader(false);
            toast.warning(error.response.data.message, alertOptions);
        });
    }

    const handleModalResponse = (sts) => {
        if (sts) {
            clearContext();
            window.location.reload(true);
        }
    };

    useEffect(() => {
        setCurrentPage(1);
    }, [wishList]);

    useEffect(() => {
        getData();
    }, []);

    return (
        <>
            { loader ? <Loader /> : null }

            {
                showModal.status ?
                    <Modal
                        title="Are you sure you want to serve the wish product?"
                        onClose={() => setShowModal({...showModal, status: false, data: "" })}
                        onResponse={() => handleAttend(showModal.data)}
                    />
                : null
            }

            {
                showCompatible.status ?
                    <ModalCompatible
                        products={showCompatible.element}
                        onClose={() => setShowCompatible({...showCompatible, status: false, element: null})}
                    />
                : null
            }

            {logOutModal.status && (
                <Modal
                title={logOutModal.title}
                onClose={() =>
                    setLogOutModal({ ...logOutModal, status: false, title: "", element: null })
                }
                onResponse={handleModalResponse}
                />
            )}

            <div>
                <div className="flex flex-row">
                    <div className="w-full bg-no-repeat bg-cover fixed z-10">
                        <Navbar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                    </div>
                </div>
                <div className="flex h-screen overflow-hidden">
                    <div className={`${sidebarOpen ? "w-[100vw] md:w-80 z-20" : "w-20 z-0"}`}>
                        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                    </div>
                    <div className="w-full overflow-auto flex-grow mt-[100px]">
                        <div className="space-y-5 px-3 py-5 md:px-8 md:py-8">
                            <div className="text-center">
                                <h1 className="text-[26px] md:text-xl lg:text-[26px] font-light text-ag-secondary">Wish List</h1>
                            </div>
                            {
                                wishList.length > 0 ?
                                    <div className="pt-3 pr-5 flex justify-end items-center space-x-3">
                                        <p className="text-ag-secondary-letter text-sm">Number of products:</p>
                                        <p className="text-sm font-semibold">{ wishList.length }</p>
                                    </div>
                                : null
                            }
                            <div>
                                {
                                    wishList.length > 0 ?
                                        <>
                                            <div className="space-y-2">
                                                {
                                                    currentRecords.map((wishListElem, i) => (
                                                        <WishListCard
                                                            key={i}
                                                            element={wishListElem}
                                                            onAttend={(idPE) => setShowModal({...showModal, status: true, data: idPE})}
                                                            onCompatible={(prdSel) => setShowCompatible({...showCompatible, status: true, element: prdSel})}
                                                        />
                                                    ))
                                                }
                                            </div>
                                            <div className="p-5 m-auto">
                                                <Pagination
                                                    currentPage={currentPage}
                                                    totalCount={wishList.length}
                                                    pageSize={recordsPerPage}
                                                    onPageChange={page => setCurrentPage(page)}
                                                />
                                            </div>
                                        </>
                                    :
                                        <div className="w-full flex justify-center items-center space-x-3 pt-[15%] text-center text-ag-secondary-letter">
                                            <div>
                                                <span className="flex justify-center pb-3 text-2xl"><RiErrorWarningLine /></span>
                                                <span className="text-xs italic md:text-base">
                                                    You currently do not have any products in the system...
                                                </span>
                                            </div>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default WishList;