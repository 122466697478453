import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { searchProductEspecification } from "../../../../services/Product";
import { emptyProductCart, saveProductCart } from "../../../../services/Shopping";
import { searchInventoryAdd } from "../../../../services/Inventory";
import { alertOptions, getAllCategories, getAllFeaturesWC } from "../../../../utils/Utilities";
import { FaThList } from "react-icons/fa";
import { RiLayoutGridFill } from "react-icons/ri";
import { toast } from "react-toastify";
import { PermitsContext } from "../../../../services/Permits";
import { saveWishList, getMyWishList } from "../../../../services/WishList";
import Loader from "../../../../components/loader/Loader";
import NavbarClients from "../../../../components/navbar/NavbarClients";
import SidebarClients from "../../../../components/sidebar/SidebarClients";
import SearchFilters from "./SearchFilters";
import ProductListCard from "./ProductListCard";
import ProductGridCard from "./ProductGridCard";
import ModalCompatible from "../../../admin/products/ModalCompatible";

const SearchResult = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const { dataSession, getCurrentCart } = useContext(PermitsContext);
    const [loader, setLoader] = useState(false);
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const [showCompatible, setShowCompatible] = useState({ status: false, element: null });
    const [viewList, setViewList] = useState(true);
    const [isParamsSearch, setIsParamsSearch] = useState(true);
    const [products, setProducts] = useState([]);

    const getData = async (prms) => {
        const wishListRes = await getMyWishList({ wholesalerUser: dataSession._id });
        const arrWishList = wishListRes.status === 200 ? wishListRes.data : [];

        const featuresAux = getAllFeaturesWC();
        const categoriesAux = getAllCategories();

        const paramsObjPE = {
            excludePriceZero: false
        };

        if (prms?.nag) {
            setIsParamsSearch(false);
            paramsObjPE.nag = prms.nag;
        } else {
            setIsParamsSearch(true);
            paramsObjPE.brand = prms.brand;
            paramsObjPE.model = prms.model;
            paramsObjPE.year = prms.year;
            paramsObjPE.type_car = prms.style;
            paramsObjPE.type_glass = prms.type;
        }
        await searchProductEspecification(paramsObjPE).then(async res => {
            setLoader(true);
            if (res.status === 200) {
                const paramsObjPI = { status: "active" };
                if (prms?.nag) {
                    paramsObjPI.nag = prms.nag;
                } else {
                    paramsObjPI.model = prms.model;
                    paramsObjPI.year = prms.year;
                    paramsObjPI.brand = prms.brand;
                }

                const inventoryRes = await searchInventoryAdd(1, 1000000, paramsObjPI);
                const productsInventory = inventoryRes.status === 200 ? inventoryRes.data.productsInventory : [];
                let productsAux = [];
                
                const dataFilter = res.data.filter(element => {
                    if (
                        !String(element.ad_info_a).toLowerCase().includes(String("Export Only").toLowerCase()) &&
                        !String(element.ad_info_a).toLowerCase().includes(String("Mexico Only").toLowerCase()) &&
                        !String(element.ad_info_a).toLowerCase().includes(String("Canada Only").toLowerCase()) &&
                        !String(element.ad_info_a).toLowerCase().includes(String("Right-Hand-Drive").toLowerCase()) &&
                        !String(element.ad_info_a).toLowerCase().includes(String("Right-Hand Drive").toLowerCase()) &&
                        !String(element.ad_info_a).toLowerCase().includes(String("Right Hand Drive").toLowerCase())
                    ) {
                        return element;
                    }
                }).map(prodAux => {
                    // get features
                    const featuresAux = getAllFeaturesWC();
                    let allFeatures = "";
                    for (let i = 0; i < featuresAux.length; i++) {
                        if (
                            (String(prodAux.ad_info_a).toLowerCase().includes(String(featuresAux[i].value).toLowerCase()) === true) ||
                            (prodAux.antena === "Y" && featuresAux[i].value === "Antenna")
                        ) {
                            if (allFeatures.includes(String(featuresAux[i].value)) === false){
                                allFeatures = allFeatures.length > 0? allFeatures+ "/"+featuresAux[i].value:featuresAux[i].value;
                            }
                        }
                    }
                    
                    allFeatures = allFeatures + (prodAux.encapsulated === "Y" ? ((allFeatures.length > 0 ? "/":"") + "Encapsulated") : "");
                    allFeatures = allFeatures + (prodAux.heated === "Y" ? ((allFeatures.length > 0 ? "/":"") + "Heated") : "");
                    allFeatures = allFeatures + (prodAux.hud === "Y" ? ((allFeatures.length > 0 ? "/":"") + "HUD") : "");
                    allFeatures = allFeatures + (prodAux.slider === "Y" ? ((allFeatures.length > 0 ? "/":"") + "Slider") : "");
                    allFeatures = allFeatures + (prodAux.solar === "Y" ? ((allFeatures.length > 0 ? "/":"") + "Solar") : "");
                    prodAux.allFeatures = allFeatures;
                    prodAux.searchValueYear = prms.year !== undefined ? prms.year : '';
                    let distinctFeatures = [];
                    const newCategories = categoriesAux.map(catAux => {
                        let newFeaturesAux = [];
                        const featuresFilters = featuresAux.filter(featAux => featAux.category === catAux.label);
                        for (let i = 0; i < featuresFilters.length; i++) {
                            if (
                                String(prodAux.ad_info_a).toLowerCase().includes(String(featuresFilters[i].value).toLowerCase()) === true ||
                                ((prodAux.hud === "Y" && featuresFilters[i].value === "HUD (Head up display)") ||
                                (prodAux.antena === "Y" && featuresFilters[i].value === "Antenna"))
                            ) {
                                const foundFeatures = distinctFeatures.find(distFeatElem => distFeatElem.value === featuresFilters[i].value);;
                                if (!foundFeatures) distinctFeatures.push(featuresFilters[i]);
                                newFeaturesAux.push(featuresFilters[i]);
                            }
                        }

                        if (newFeaturesAux.length > 0) return {...catAux, features: newFeaturesAux, status: true}
                        return catAux;
                    });

                    const inWishList = arrWishList.find(wlElem => wlElem?.nag === prodAux.nag);
                    const isAvailable = productsInventory.filter(piElem => piElem?.productInventory?.product?.nags === prodAux.nag);
                    if (isAvailable.length > 0) {
                        for (let i = 0; i < isAvailable.length; i++) {
                            for (let y = 0; y < featuresAux.length; y++) {
                                if (
                                    (String(isAvailable[i].productInventory.product.features).toLowerCase().includes(String(featuresAux[y].value).toLowerCase()) === true)
                                ) {
                                    if (prodAux.allFeatures.includes(String(featuresAux[y].value)) === false){
                                        prodAux.allFeatures = prodAux.allFeatures.length > 0? prodAux.allFeatures+ "/"+featuresAux[y].value:featuresAux[y].value;
                                    }
                                }
                            }
                            if ((i+1) === isAvailable.length) {
                                return {...prodAux,
                                    category: newCategories,
                                    available: isAvailable.length > 0 ? true : false,
                                    inventoryData: isAvailable.length > 0 ? isAvailable[i] : null,
                                    wishList: inWishList ? true : false
                                }
                            } else {
                                productsAux.push({...prodAux,
                                    category: newCategories,
                                    available: isAvailable.length > 0 ? true : false,
                                    inventoryData: isAvailable.length > 0 ? isAvailable[i] : null,
                                    wishList: inWishList ? true : false
                                });
                            }
                        }
                    } else {
                        return {...prodAux,
                            category: newCategories,
                            available: false,
                            inventoryData: null,
                            wishList: inWishList ? true : false
                        };
                    }
                    
                });
                const newDataFilter = productsAux.concat(dataFilter);
                setProducts(newDataFilter);
                navigate("/wholesaler/home/search/", { state: prms });
            } else {
                setProducts([]);
                toast.warning("No products found with these specifications", alertOptions);
            }
            setLoader(false);
        }).catch((error) => {
            setLoader(false);
            setProducts([]);
            toast.warning(error.response.data.message, alertOptions);
        });
    }

    const handlePurcharse = async (objCart) => {
        setLoader(true);
        await emptyProductCart(dataSession._id);
        // let newObjCart = {...objCart, year: state.year !== undefined? state.year : ''}
        await saveProductCart(dataSession._id, objCart).then(res => {
            if (res.status === 200) {
                getCurrentCart(dataSession._id);
                navigate("/wholesaler/mycart");
            } else {
                toast.warning(res.response.data.message, alertOptions);
            }
            setLoader(false);
        }).catch(error => {
            setLoader(false);
            toast.warning(error.response.data.message, alertOptions);
        });
    }

    const handleCart = (objCart) => {
        setLoader(true);
        // let newObjCart = {...objCart, year: state.year !== undefined? state.year : ''}
        saveProductCart(dataSession._id, objCart).then(res => {
            if (res.status === 200) {
                getCurrentCart(dataSession._id);
                toast.success("Product added successfully", alertOptions);
            } else {
                toast.warning(res.response.data.message, alertOptions);
            }
            setLoader(false);
        }).catch(error => {
            setLoader(false);
            toast.warning(error.response.data.message, alertOptions);
        });
    }

    const handleAddWishList = (product) => {
        setLoader(true);
        saveWishList({ wholesalerUser: { _id: dataSession._id }, nag: product.nag }).then(res => {
            if (res.status === 200) {
                getData(state);
                toast.success("The product request has been sent", alertOptions);
            } else {
                setLoader(false);
                toast.warning(res.response.data.message, alertOptions);
            }
        }).catch(error => {
            setLoader(false);
            toast.warning(error.response.data.message, alertOptions);
        });
    }

    useEffect(() => {
        if (state !== null) getData(state);
    }, []);

    return (
        <>
            { loader ? <Loader /> : null }

            {
                showCompatible.status ?
                    <ModalCompatible
                        products={showCompatible.element}
                        onClose={() => setShowCompatible({...showCompatible, status: false, element: null})}
                    />
                : null
            }

            <div>
                <div className="flex flex-row">
                    <div className="w-full bg-no-repeat bg-cover fixed z-10">
                        <NavbarClients type="wholesaler" sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                    </div>
                </div>
                <div className="flex h-screen overflow-hidden">
                    <div className={`${sidebarOpen ? "w-[100vw] md:w-80 z-20" : "w-20 z-0"}`}>
                        <SidebarClients type="wholesaler" sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                    </div>
                    <div className="w-full overflow-auto flex-grow mt-[100px]">
                        <div className="space-y-5 px-3 py-5 md:px-8 md:py-8">
                            <div className="flex justify-center items-center text-center">
                                <h1 className="w-full text-[26px] md:text-xl lg:text-[26px] font-light text-ag-secondary md:pl-14">
                                    { products.length } results in our warehouses.
                                </h1>
                                <div className="flex justify-center items-center">
                                    <button
                                        className={`${viewList ? "bg-lime-400" : "bg-zinc-200"} px-3 py-1 text-ag-secondary-letter border border-ag-secondary-light rounded-l-xl`}
                                        type="button"
                                        onClick={() => setViewList(true)}
                                    >
                                        <FaThList />
                                    </button>
                                    <button
                                        className={`${!viewList ? "bg-lime-400" : "bg-zinc-200"} px-3 py-1 text-ag-secondary-letter border border-ag-secondary-light rounded-r-xl`}
                                        type="button"
                                        onClick={() => setViewList(false)}
                                    >
                                        <RiLayoutGridFill />
                                    </button>
                                </div>
                            </div>
                            <div className="pt-3">
                                <SearchFilters defaultParams={state} onFilters={getData} />
                            </div>
                            <div className="pt-3">
                                { viewList ?
                                    <div className="space-y-3">
                                        { products.map((product, i) => (
                                            <ProductListCard
                                                key={i}
                                                product={product}
                                                isParamsSearch={isParamsSearch}
                                                onCart={handleCart}
                                                onPurcharse={handlePurcharse}
                                                onAddWishList={handleAddWishList}
                                                onCompatible={(prdSel) => setShowCompatible({...showCompatible, status: true, element: prdSel})}
                                            />
                                        ))}
                                    </div>
                                :
                                    <div className="grid gap-3 lg:grid-cols-3 xl:grid-cols-4">
                                        { products.map((product, i) => (
                                            <ProductGridCard
                                                key={i}
                                                product={product}
                                                isParamsSearch={isParamsSearch}
                                                onCart={handleCart}
                                                onPurcharse={handlePurcharse}
                                                onAddWishList={handleAddWishList}
                                                onCompatible={(prdSel) => setShowCompatible({...showCompatible, status: true, element: prdSel})}
                                            />
                                        ))}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SearchResult;