import { useEffect } from "react";
import { useState } from "react";
import Pagination from "../../../components/pagination/Pagination";
import RowsOrders from "./RowsOrders";
import RowsReturns from "./RowsReturns";

const TableReports = ({ reports }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(7);

    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = reports.slice(indexOfFirstRecord, indexOfLastRecord);

    useEffect(() => {
        setCurrentPage(1);
    }, [reports]);

    return (
        <div>
            <div className="overflow-auto min-h-[49vh] 2xl:min-h-[47vh]">
                <table className="w-full text-center text-ag-secondary">
                    <thead>
                        <tr className="bg-ag-secondary-table">
                            <th className="w-[15%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 md:px-3 lg:px-2 py-3">Warehouse</th>
                            <th className="w-[15%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 md:px-3 lg:px-2 py-3">Rol</th>
                            <th className="w-[15%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 md:px-3 lg:px-2 py-3">Name</th>
                            <th className="w-[8%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 md:px-3 lg:px-2 py-3">Sale number</th>
                            <th className="w-[8%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 md:px-3 lg:px-2 py-3">Order number</th>
                            <th className="w-[8%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 md:px-3 lg:px-2 py-3">Date</th>
                            <th className="w-[8%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 md:px-3 lg:px-2 py-3">Total</th>
                            <th className="w-[8%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 md:px-3 lg:px-2 py-3">Quantity</th>
                            <th className="w-[15%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 md:px-3 lg:px-2 py-3">Product</th>
                        </tr>
                    </thead>
                    <tbody>
                        { currentRecords.map((element, i) => {
                            return (element.type === "order" ?
                                <RowsOrders key={i} report={element} />
                            :
                                <RowsReturns key={i} report={element} />
                            )
                        })}
                    </tbody>
                </table>
            </div>
            <div className="p-5 m-auto">
                <Pagination
                    currentPage={currentPage}
                    totalCount={reports.length}
                    pageSize={recordsPerPage}
                    onPageChange={page => setCurrentPage(page)}
                />
            </div>
        </div>
    );
}

export default TableReports;