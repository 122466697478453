import { useContext, useEffect, useState } from "react";
import { attendAppointmentAdmin, getAppointmentsAdmin, cancelAppointment } from "../../../../services/Appointments";
import { toast } from "react-toastify";
import { alertOptions, formatReverseDate } from "../../../../utils/Utilities";
import { Link } from "react-router-dom";
import { FiChevronLeft } from "react-icons/fi";
import { PermitsContext } from "../../../../services/Permits";
import Loader from "../../../../components/loader/Loader";
import Navbar from "../../../../components/navbar/Navbar";
import Sidebar from "../../../../components/sidebar/Sidebar";
import Filters from "./Filters";
import TableAppointments from "./TableAppointments";
import Images from "../../../../components/carrousel/Images";
import Modal from "../../../../components/modal/Modal";

const Appointments = () => {
    const { dataSession } = useContext(PermitsContext);
    const [loader, setLoader] = useState(true);
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const [appointments, setAppointments] = useState([]);
    const [appointmentsUniv, setAppointmentsUniv] = useState([]);
    const [filter, setFilters] = useState({ status: "", startDate: "", endDate: "", type: "" });
    const [page, setPage] = useState(1);
    const [numRows, setNumRows] = useState(0);
    const [showImages, setShowImages] = useState({ status: false, images: [] });
    const [showModal, setShowModal] = useState({ status: false, title: "", type: "", data: null });
    const [statusOptions] = useState([
        { label: "Canceled", value: "CANCELED" },
        { label: "In Proccess", value: "INPROCESS" },
        { label: "Finished", value: "ATTEND" }
    ]);

    const [typeOptions] = useState([
        { label: "Order", value: "specifications" },
        { label: "By photo", value: "photos" },
        { label: "Power Window Repair", value: "POWER_WINDOW_REPAIR" },
        { label: "Window Tint", value: "WINDOW_TINT" }
    ]);

    const handleFilters = (options, appUniv, canUpd) => {
        setFilters(options);
        if (canUpd) setPage(1);
        if (options.status === "" && (options.startDate === "" || options.startDate === null) && (options.endDate === "" || options.endDate === null) && options.type === "") {
            setNumRows(appUniv.length);
            setAppointments(appUniv);
        } else {
            let appointmentsFound = appUniv;

            if (options.status !== "") {
                appointmentsFound = appointmentsFound.filter(element => (element?.status === options.status));
            }

            if (options.type !== "") {
                appointmentsFound = appointmentsFound.filter(element => (element?.type === options.type || element?.quote?.searchType === options.type));
            }
            
            if (((options.startDate !== "" && options.startDate !== null) && (options.endDate === "" || options.endDate === null)) || ((options.startDate === "" || options.startDate === null) && (options.endDate !== "" && options.endDate !== null))) {
                toast.info("Please enter both dates to perform the filter", alertOptions);
            } else if ((options.startDate !== "" && options.startDate !== null) && (options.endDate !== "" && options.endDate !== null)) {
                if (formatReverseDate(options.startDate) > formatReverseDate(options.endDate)) {
                    toast.info("The start date is greater than the end date", alertOptions);
                } else {
                    appointmentsFound = appointmentsFound.filter(element => (
                        (formatReverseDate(element?.date) >= formatReverseDate(options.startDate)) && 
                        (formatReverseDate(element?.date) <= formatReverseDate(options.endDate))
                    ));
                }
            }
            setNumRows(appointmentsFound.length);
            setAppointments(appointmentsFound);
        }
    }

    const getData = (canUpd) => {
        getAppointmentsAdmin().then(res => {
            if (res.status === 200 && res.data.length > 0) {
                let foundApp = [];
                if (dataSession?.userType === "ADMIN") {
                    foundApp = res.data;
                } else {
                    foundApp = res.data.filter(element => {
                        const foundWH = dataSession?.allWarehouse.find(elem => elem._id === element.warehouse._id);
                        if (foundWH) return element;
                    });
                }
                
                const appSort = foundApp.sort(function(a,b){
                    return new Date(b.date) - new Date(a.date);
                });

                setAppointmentsUniv(appSort);
                handleFilters(filter, appSort, canUpd);
            }
            setLoader(false);
        });
    }

    const changeStatus = (idAppointment, stsStr, stsOrder, idClover) => {
        setShowModal({...showModal, status: false, title: "", type: "", data: null});
        if (stsOrder === "delivered" || stsOrder === "paid") {
            setLoader(true);
            attendAppointmentAdmin(idAppointment, { status: stsStr }).then(res => {
                if (res.status === 200) {
                    getData(false);
                    toast.success("Quote attended successfully", alertOptions);
                } else {
                    toast.warning(res.response.data.message, alertOptions);
                    setLoader(false);
                }
            }).catch(error => {
                setLoader(false);
                toast.warning(error.response.data.message, alertOptions);
            });
        } else {
            setShowModal({...showModal, status: false, title: "", type: "", data: null});
            toast.warning("You cannot attend an appointment with an order that is not in delivered status", alertOptions);
        }
    }

    const handleCancel = (idRetail, idAppointment) => {
        setShowModal({...showModal, status: false, title: "", type: "", data: null});
        setLoader(true);
        cancelAppointment(idRetail, idAppointment, { status: "CANCELED" }).then(res => {
            if (res.status === 200) {
                getData(false);
                toast.success("Appointment canceled successfully", alertOptions);
            } else {
                toast.warning(res.response.data.message, alertOptions);
            }
            setLoader(false);
        }).catch(error => {
            setLoader(false);
            toast.warning(error.response.data.message, alertOptions);
        });
    }

    useEffect(() => {
        getData(true);
    }, [dataSession]);

    return (
        <>
            { loader ? <Loader /> : null }

            {
                showImages.status ?
                    <div>
                        <Images
                            images={showImages.images}
                            onClose={() => setShowImages({...showImages, status: false, images: []})}
                        />
                    </div>
                : null
            }

            {
                showModal.status ?
                    <Modal
                        title={showModal.title}
                        onResponse={() => showModal.type === "cancel" ?
                            handleCancel(showModal.data.idRetail, showModal.data.idAppointment)
                        :
                            changeStatus(showModal.data.id, showModal.data.status, showModal.data.statusOrder, showModal.data.idClover)
                        }
                        onClose={() => setShowModal({...showModal, status: false, title: "", type: "", data: null})}
                    />
                : null
            }

            <div>
                <div className="flex flex-row">
                    <div className="w-full bg-no-repeat bg-cover fixed z-10">
                        <Navbar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                    </div>
                </div>
                <div className="flex h-screen overflow-hidden">
                    <div className={`${sidebarOpen ? "w-[100vw] md:w-80 z-20" : "w-20 z-0"}`}>
                        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                    </div>
                    <div className="w-full overflow-auto flex-grow mt-[100px]">
                        <div className="space-y-5 px-3 py-5 md:px-8 md:py-8">
                            <div className="grid grid-cols-12 px-0 md:px-5">
                                <div>
                                    <Link to="/quotes">
                                        <button className="p-1 rounded-md" type="button">
                                            <span className="text-ag-secondary-dark text-3xl"><FiChevronLeft /></span>
                                        </button>
                                    </Link>
                                </div>
                                <div className="flex justify-center items-center col-span-11 text-center">
                                    <h1 className="text-[26px] md:text-xl lg:text-[26px] font-light text-ag-secondary md:mr-[8%]">
                                        Appointments
                                    </h1>
                                </div>
                            </div>
                            <div className="pt-3">
                                <Filters statusOptions={statusOptions} typeOptions={typeOptions} onFilters={(opts) => handleFilters(opts, appointmentsUniv, true)} />
                            </div>
                            <div className="pt-5">
                                <TableAppointments
                                    appointments={appointments}
                                    numRows={numRows}
                                    pageOrder={page}
                                    onNextPage={setPage}
                                    onAttend={(id, stsOrder, cloverId) => setShowModal({...showModal,
                                        status: true,
                                        title: "Are you sure you want to attend the appointment?",
                                        type: "attend",
                                        data: { id: id, status: "ATTEND", statusOrder: stsOrder, idClover: cloverId }
                                    })}
                                    onCancel={(idRetail, idAppointment) => setShowModal({...showModal,
                                        status: true,
                                        title: "Are you sure you want to cancel the appointment?",
                                        type: "cancel",
                                        data: { idRetail: idRetail, idAppointment: idAppointment }
                                    })}
                                    onImage={(imagesElement) => setShowImages({...showImages, status: true, images: imagesElement})}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Appointments;