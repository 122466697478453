import { useEffect, useState } from "react";
import Pagination from "../../../../components/pagination/Pagination";
import RowsPriceHistory from "./RowsPriceHistory";

const TablePriceHistory = ({ arrPriceHistory, onCompatible }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(7);

    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = arrPriceHistory.slice(indexOfFirstRecord, indexOfLastRecord);

    useEffect(() => {
        setCurrentPage(1);
    }, [arrPriceHistory]);

    return (
        <>
            <div>
                <table className="w-full text-center text-ag-secondary">
                    <thead>
                        <tr className="bg-ag-secondary-table">
                            <th className="w-[7%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 md:px-8 lg:px-2 py-4">Nags</th>
                            <th className="w-[8%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 md:px-14 lg:px-2 py-4">Name</th>
                            <th className="w-[22%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 md:px-14 lg:px-2 py-4">Compatible Vehicles</th>
                            <th className="w-[14%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 md:px-16 lg:px-2 py-4">Description</th>
                            <th className="w-[7%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 md:px-10 lg:px-2 py-4">Vendor</th>
                            <th className="w-[5%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 md:px-8 lg:px-2 py-4">Nags Price</th>
                            <th className="w-[5%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 md:px-8 lg:px-2 py-4">Factory Cost</th>
                            <th className="w-[5%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 md:px-8 lg:px-2 py-4">Sales Price</th>
                            <th className="w-[7%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 md:px-8 lg:px-2 py-4">Barcode 1</th>
                            <th className="w-[7%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 md:px-8 lg:px-2 py-4">Barcode 2</th>
                            <th className="w-[5%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 md:px-8 lg:px-2 py-4">Quantity</th>
                            <th className="w-[5%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 md:px-6 lg:px-2 py-4">Date</th>
                            <th className="w-[7%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 md:px-6 lg:px-2 py-4">Hour</th>
                            <th className="w-[6%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 md:px-6 lg:px-2 py-4">Role</th>
                            <th className="w-[7%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 md:px-6 lg:px-2 py-4">Name</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            currentRecords.map((element, i) => {
                                return (
                                    <RowsPriceHistory
                                        key={i}
                                        priceHistory={element}
                                        onCompatible={onCompatible}
                                    />
                                )
                            })
                        }
                    </tbody>
                </table>
                <div className="p-5 m-auto">
                    <Pagination
                        currentPage={currentPage}
                        totalCount={arrPriceHistory.length}
                        pageSize={recordsPerPage}
                        onPageChange={page => setCurrentPage(page)}
                />
                </div>
            </div>
        </>
    );
}

export default TablePriceHistory;