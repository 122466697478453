import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { getWarehouse } from "../../../../services/Warehouse";
import { alertOptions, removeAccents } from "../../../../utils/Utilities";
import { validateInventoryAdd } from "../../../../utils/Validators";
import { BsCaretDownFill, BsCaretUpFill } from "react-icons/bs";
import TableInventory from "./TableInventory";

const FormInventory = ({ inventoryData, warehouses, products, onSave, onEdit, onCompatible, onLoader }) => {
    const [nagSelected, setNagSelected] = useState("");
    const [productSelected, setProductSelected] = useState("");
    const [showNgOptions, setShowNgOptions] = useState(false);
    const [showPdOptions, setShowPdOptions] = useState(false);
    const [selOptions, setSelOptions] = useState([]);
    const [sumStock, setSumStock] = useState(0);
    const [racksOptions, setRacksOptions] = useState([]);
    const [racksToEdit, setRacksToEdit] = useState([]);
    const [inventory, setInventory] = useState({
        status: "active",
        warehouse: "",
        product: "",
        nags: "",
        nagPrice: "",
        cost: "",
       // sellerPrice: 0,
       // resellerPrice: 0,
        maxStock: "",
        minStock: "",
        stock: "",
        racks: []
    });

    const addRack = (rackElement) => {
        handleCloseSelect();
        if (rackElement._id === "" || (rackElement.quantity === 0 || rackElement.quantity === "")) {
            toast.warning("Please select a rack and quantity to add", alertOptions);
        } else {
            let racksAux = inventory.racks;
            const foundRack = racksAux.find(rckElmnt => rckElmnt._id === rackElement._id);
            if (!foundRack) {
                racksAux.push(rackElement);
                setInventory({...inventory, racks: racksAux});
                setSumStock(Number(sumStock) + Number(rackElement.quantity));
            } else {
                toast.warning("The selected rack is already added", alertOptions);
            }
        }
    }

    const editRack = (rackIndex, rackElement) => {
        handleCloseSelect();
        let sumStockAux = 0;
        const racksAux = inventory.racks.map((rckElmnt, i) => {
            if (i === rackIndex) {
                sumStockAux += Number(rackElement.quantity);
                return { _id: rackElement._id, quantity: rackElement.quantity};
            }
            sumStockAux += Number(rckElmnt.quantity);
            return rckElmnt;
        });
        setSumStock(sumStockAux);
        setInventory({...inventory, racks: racksAux});
    }

    const deleteRack = (idRack) => {
        handleCloseSelect();
        let sumStockAux = 0;
        const racksAux = inventory.racks.filter(rackElement => {
            if (rackElement._id !== idRack) {
                sumStockAux += Number(rackElement.quantity);
                return rackElement;
            }
        });
        setSumStock(sumStockAux);
        setInventory({...inventory, racks: racksAux});
    }

    const handleCanEdit = (rckElem, sts) => {
        if (sts) {
            setRacksToEdit([...racksToEdit, rckElem._id]);
        } else {
            const newRacks = racksToEdit.filter(elem => elem !== rckElem._id);
            setRacksToEdit(newRacks);
        }
    }

    const handleSelect = (ngOpt, type) => {
        if (type === "nags") {
            setShowNgOptions(false);
        } else {
            setShowPdOptions(false);
        }
        
        const productFound = products.find(productElement => productElement._id === ngOpt);
        if (productFound) {
            setNagSelected(productFound.nags);
            setProductSelected(productFound.name);
            setInventory({...inventory,
                product: type === "nags" ? ngOpt : productFound._id,
                nags: type === "nags" ? productFound.nags : ngOpt,
                nagPrice: productFound.price
            });
        }
    }

    const handleCloseSelect = () => {
        setShowNgOptions(false);
        setShowPdOptions(false);
    }

    const handleDownSelect = (type) => {
        if (type === "product") {
            setShowPdOptions(!showPdOptions);
            setShowNgOptions(false);
        } else {
            setShowNgOptions(!showNgOptions);
            setShowPdOptions(false);
        }
    }

    const handleChangeSel = (evt, type) => {
        if (type === "nags") {
            setNagSelected(evt.target.value);
        } else {
            setProductSelected(evt.target.value);
        }
        
        if (evt.target.value === "") {
            setSelOptions(products);
            setInventory({...inventory, product: "", nags: "", nagPrice: "" });
        } else {
            let palabra = new RegExp(`${removeAccents(evt.target.value)}.*`, "i");
            const newNagsOpts = products.filter(elem => palabra.test(elem?.name) || palabra.test(elem?.nags));
            setSelOptions(newNagsOpts);
        }
    }

    const handleChange = (evt) => {
        if (evt.currentTarget.name === "warehouse") {
            setInventory({...inventory, warehouse: evt.currentTarget.value});
            getWarehouse(evt.currentTarget.value).then(res => {
                if (res.status === 200) {
                    setRacksOptions(res.data.racks);
                }
            });
        } else {
            if (evt.currentTarget.name === "cost" /*|| evt.currentTarget.name === "sellerPrice" || evt.currentTarget.name === "resellerPrice"*/) {
                const pattern = new RegExp(/^\d*\.?\d*$/);
                if (pattern.test(evt.currentTarget.value) || evt.currentTarget.value === "") {
                    setInventory({...inventory, [evt.currentTarget.name]: evt.currentTarget.value});
                }
            } else if (evt.currentTarget.name === "maxStock" || evt.currentTarget.name === "minStock") {
                const pattern = new RegExp(/^[0-9\s]+$/g);
                if (pattern.test(evt.currentTarget.value) || evt.currentTarget.value === "") {
                    setInventory({...inventory, [evt.currentTarget.name]: evt.currentTarget.value});
                }
            } else {
                setInventory({...inventory, [evt.currentTarget.name]: evt.currentTarget.value});
            }
        }
    }

    const handleSubmit = (evt) => {
        evt.preventDefault();
        if (
            inventory.warehouse !== "" && inventory.product !== "" && inventory.cost !== "" && inventory.maxStock !== "" &&
            inventory.minStock !== ""
        ) {
            const productFound = products.find(prodSel => (prodSel.nags === nagSelected) && (prodSel.name === productSelected));
            if (!productFound) {
                toast.info("Please choose a product from the selector to find vehicle compatibility", alertOptions);
            } else {
                // Se verifica que no exista ningún rack repetido antes de agregar/editar
                let rackRepeat = false;
                let rack_array = [];
                for (let i = 0; i < inventory?.racks.length; i++) {
                    const foundRack = rack_array.find(element => element._id === inventory.racks[i]._id);
                    if (foundRack) {
                        rackRepeat = true;
                        break;
                    } else {
                        rack_array.push(inventory.racks[i]);
                    }
                }

                if (!rackRepeat) {
                    if (sumStock <= inventory.maxStock && sumStock >= inventory.minStock) {
                        if (racksToEdit.length === 0) {
                            let arrRackFormat = [];
                            for (let i = 0; i < inventory.racks.length; i++) {
                                arrRackFormat.push({ _id: inventory.racks[i]._id, quantity: Number(inventory.racks[i].quantity) });
                            }
                            const inventoryModel = {
                                warehouse: { _id: inventory.warehouse },
                                product: { _id: inventory.product },
                                status: inventory.status,
                                maxStock: inventory.maxStock,
                                minStock: inventory.minStock,
                                cost: inventory.cost,
                               // sellerPrice: inventory.sellerPrice,
                               // resellerPrice: inventory.resellerPrice,
                                racks: arrRackFormat
                            }
                            const result = validateInventoryAdd(inventoryModel);
                            if (result.status) {
                                if (inventoryData && inventoryData !== null) {
                                    inventoryModel._id = inventoryData.productInventory._id;
                                    onEdit(inventoryModel);
                                } else {
                                    onSave(inventoryModel);
                                }
                            } else {
                                toast.warning(result.msg, alertOptions);
                            }
                        } else {
                            toast.info("Please finish editing the table racks", alertOptions);
                        }
                    } else {
                        toast.warning("It is not possible to add more products than the maximum established in stock or less products than the minimum in stock", alertOptions);
                    }
                } else {
                    toast.warning("Please, do not duplicate the racks of the table", alertOptions);
                }
            }
        } else {
            toast.info("Please fill in all the fields of the form", alertOptions);
        }
    }

    const handleCompatible = () => {
        setShowNgOptions(false);
        setShowPdOptions(false);
        if (nagSelected) {
            const productFound = products.find(prodSel => prodSel.nags === nagSelected);
            if (!productFound) {
                toast.info("Please choose a product from the selector to find vehicle compatibility", alertOptions);
            } else {
                onCompatible(productFound);
            }
        } else {
            toast.info("Please select a product to check compatibility", alertOptions);
        } 
    }

    useEffect(() => {
        setSelOptions(products);
    }, [products]);

    useEffect(() => {
        if (inventoryData && inventoryData !== null) {
            onLoader(true);
            getWarehouse(inventoryData.warehouse._id).then(resWare => {
                if (resWare.status === 200) {
                    setRacksOptions(resWare.data.racks);
                }

                let racksAux = [];
                let stockAcum = 0;
                if (inventoryData.racks.length > 0) {
                    racksAux = inventoryData.racks.map((element, i) => {
                        stockAcum += Number(element.quantity);
                        return { _id: element.rack._id, quantity: element.quantity }
                    });
                }
                
                setSumStock(stockAcum);
                setProductSelected(inventoryData.productInventory.product.name);
                setNagSelected(inventoryData.productInventory.product.nags);
                setInventory({...inventory,
                    status: inventoryData.productInventory.status,
                    warehouse: inventoryData.warehouse._id,
                    product: inventoryData.productInventory.product._id,
                    nags: inventoryData.productInventory.product.nags,
                    cost: inventoryData.productInventory.cost,
                    nagPrice: inventoryData?.productInventory?.product?.price,
                    //sellerPrice: inventoryData.productInventory.sellerPrice,
                    //resellerPrice: inventoryData.productInventory.resellerPrice,
                    maxStock: inventoryData.productInventory.maxStock,
                    minStock: inventoryData.productInventory.minStock,
                    stock: stockAcum,
                    racks: racksAux
                });
                onLoader(false);
            }).catch(() => onLoader(false));
        }
    }, [inventoryData]);

    return (
        <div>
            <form className="space-y-10" onSubmit={handleSubmit}>
                <div className="grid gap-3 md:grid-cols-2 lg:grid-cols-3">
                    <div className="w-full flex justify-center items-center">
                        <span
                            className="w-[40%] bg-ag-secondary-dark text-xs xl:text-base text-white text-center border border-gray-500 py-3 rounded-l-xl"
                        >
                            Nags
                        </span>
                        <div className="w-[60%] relative">
                            <div className={`w-full flex justify-center items-center ${ inventoryData !== null ? "bg-zinc-200" : "bg-white" } text-ag-secondary-letter border border-gray-500 rounded-r-xl truncate`}>
                                <input
                                    className="w-full p-2 xl:p-3 text-left text-ag-secondary-letter disabled:bg-zinc-200 truncate"
                                    name="nags"
                                    autoComplete="off"
                                    onChange={(evt) => handleChangeSel(evt, "nags")}
                                    value={nagSelected}
                                    onFocus={() => setShowNgOptions(true)}
                                    disabled={inventoryData !== null ? true : false}
                                />
                                <span
                                    className={`text-ag-secondary-letter ${ inventoryData !== null ? "" : "cursor-pointer" } px-2`}
                                    onClick={() => inventoryData !== null ? null : handleDownSelect("nags")}
                                >
                                    { showNgOptions ? <BsCaretUpFill /> : <BsCaretDownFill /> }
                                </span>
                            </div>
                            {
                                showNgOptions ?
                                    <div className="w-full bg-white absolute text-center border-x border-b border-zinc-500 rounded-b-xl z-50">
                                        <ul>
                                            {
                                                selOptions.map((elemProd, i) => (
                                                    <li
                                                        key={i}
                                                        className="relative px-3 py-1 text-ag-secondary-letter text-xs cursor-pointer hover:bg-zinc-600 hover:text-white hover:rounded-xl z-50"
                                                        onClick={() => handleSelect(elemProd._id, "nags")}
                                                    >
                                                        {elemProd.nags}
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                : null
                            }
                        </div>
                    </div>
                    <div className="w-full flex justify-center items-center">
                        <span
                            className="w-[40%] bg-ag-secondary-dark text-xs xl:text-base text-white text-center border border-gray-500 py-3 rounded-l-xl"
                        >
                            Warehouse
                        </span>
                        <select
                            className="w-[60%] text-ag-secondary-letter border border-gray-500 px-2 xl:px-3 py-2 xl:py-[13px] rounded-r-xl disabled:bg-zinc-200 disabled:opacity-100 truncate"
                            id="select-inventoryAdd-warehouse"
                            name="warehouse"
                            onChange={handleChange}
                            value={inventory.warehouse}
                            disabled={inventoryData !== null ? true : false}
                        >
                            <option value="default"></option>
                            {
                                warehouses.map((element, index) => {
                                    return <option key={index} value={element._id}>{element.name}</option>;
                                })
                            }
                        </select>
                    </div>

                    <div className="w-full flex justify-center items-center">
                        <span
                            className="w-[40%] bg-ag-secondary-dark text-xs xl:text-base text-white text-center border border-gray-500 py-3 rounded-l-xl"
                        >
                            Name
                        </span>
                        <div className="w-[60%] relative">
                            <div className={`w-full flex justify-center items-center ${ inventoryData !== null ? "bg-zinc-200" : "bg-white" } text-ag-secondary-letter border border-gray-500 rounded-r-xl truncate`}>
                                <input
                                    className="w-full p-2 xl:p-3 text-left text-ag-secondary-letter disabled:bg-zinc-200 truncate"
                                    name="product"
                                    autoComplete="off"
                                    onChange={(evt) => handleChangeSel(evt, "product")}
                                    value={productSelected}
                                    onFocus={() => setShowPdOptions(true)}
                                    disabled={inventoryData !== null ? true : false}
                                />
                                <span
                                    className={`text-ag-secondary-letter ${ inventoryData !== null ? "" : "cursor-pointer" } px-2`}
                                    onClick={() => inventoryData !== null ? null : handleDownSelect("product")}
                                >
                                    { showPdOptions ? <BsCaretUpFill /> : <BsCaretDownFill /> }
                                </span>
                            </div>
                            {
                                showPdOptions ?
                                    <div className="w-full bg-white absolute text-center border-x border-b border-zinc-500 rounded-b-xl z-50">
                                        <ul>
                                            {
                                                selOptions.map((elemProd, i) => (
                                                    <li
                                                        key={i}
                                                        className="relative px-3 py-1 text-ag-secondary-letter text-xs cursor-pointer hover:bg-zinc-600 hover:text-white hover:rounded-xl z-50 text-ellipsis overflow-hidden ..."
                                                        onClick={() => handleSelect(elemProd._id, "product")}
                                                    >
                                                        {elemProd.name}
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                : null
                            }
                        </div>
                    </div>
                    <div className="w-full flex justify-center items-center">
                        <span
                            className="w-[40%] bg-ag-secondary-dark text-xs xl:text-base text-white text-center border border-gray-500 py-3 rounded-l-xl"
                        >
                            Nags Price
                        </span>
                        <input
                            className="w-[60%] text-ag-secondary-letter border border-gray-500 p-2 xl:p-3 rounded-r-xl disabled:bg-zinc-200 truncate"
                            type="text"
                            name="nagPrice"
                            value={inventory.nagPrice}
                            disabled={true}
                        />
                    </div>
                    <div className="w-full flex justify-center items-center">
                        <span
                            className="w-[40%] bg-ag-secondary-dark text-xs xl:text-base text-white text-center border border-gray-500 py-3 rounded-l-xl"
                        >
                            Sales Price
                        </span>
                        <input
                            className="w-[60%] text-ag-secondary-letter border border-gray-500 p-2 xl:p-3 rounded-r-xl truncate"
                            type="text"
                            name="cost"
                            value={inventory.cost}
                            onChange={handleChange}
                            maxLength={12}
                        />
                    </div>
                    <div className="w-full flex justify-center items-center">
                        <span
                            className="w-[40%] bg-ag-secondary-dark text-xs xl:text-base text-white text-center border border-gray-500 py-3 rounded-l-xl"
                        >
                            Maximum Stock
                        </span>
                        <input
                            className="w-[60%] text-ag-secondary-letter border border-gray-500 p-2 xl:p-3 rounded-r-xl truncate"
                            type="text"
                            name="maxStock"
                            value={inventory.maxStock}
                            onChange={handleChange}
                            maxLength={12}
                        />
                    </div>
                    <div className="w-full flex justify-center items-center">
                        <span
                            className="w-[40%] bg-ag-secondary-dark text-xs xl:text-base text-white text-center border border-gray-500 py-3 rounded-l-xl"
                        >
                            Minimum Stock
                        </span>
                        <input
                            className="w-[60%] text-ag-secondary-letter border border-gray-500 p-2 xl:p-3 rounded-r-xl truncate"
                            type="text"
                            name="minStock"
                            value={inventory.minStock}
                            onChange={handleChange}
                            maxLength={12}
                        />
                    </div>
                    <div className="w-full flex justify-center items-center">
                        <span
                            className="w-[40%] bg-ag-secondary-dark text-xs xl:text-base text-white text-center border border-gray-500 py-3 rounded-l-xl"
                        >
                            Stock
                        </span>
                        <input
                            className="w-[60%] text-ag-secondary-letter border border-gray-500 p-2 xl:p-3 rounded-r-xl disabled:bg-zinc-200 truncate"
                            type="text"
                            name="stock"
                            value={inventory.stock}
                            disabled={true}
                            maxLength={12}
                        />
                    </div>
                    <div className="w-full flex justify-center items-center">
                        <button className="w-full bg-ag-secondary-dark text-white border border-gray-500 p-2 xl:p-3 rounded-xl truncate" type="button" onClick={() => handleCompatible()}>
                            See compatible vehicles
                        </button>
                    </div>
                </div>
                <div>
                    <div className="md:flex md:justify-center md:items-center border-t border-ag-secondary py-6 space-y-3 md:space-y-0">
                        <div className="w-full md:w-[66%] text-center md:text-right px-0 md:px-[10%] pb-5 md:pb-0">
                            <h1 className="text-[26px] md:text-xl lg:text-[26px] font-light text-ag-secondary">Organize by racks</h1>
                        </div>
                        <div className="w-full md:w-[34%]">
                            <button
                                className="w-full bg-ag-primary text-white text-xs lg:text-base p-2 rounded-xl disabled:bg-zinc-200"
                                type="submit"
                            >
                                Save
                            </button>
                        </div>
                    </div>
                    <div className="px-5 md:px-20 py-5 md:py-10 border border-ag-secondary-light rounded-lg">
                        <TableInventory
                            type="transfer"
                            maxStock={inventory.maxStock}
                            sumStock={sumStock}
                            racksCurrent={inventory.racks}
                            racks={racksOptions}
                            onSave={addRack}
                            onEdit={editRack}
                            onDelete={deleteRack}
                            onCloseSelect={handleCloseSelect}
                            onCanEdit={handleCanEdit}
                        />
                    </div>
                </div>
            </form>
        </div>
    );
}

export default FormInventory;