import { formatCurrency, formatDateHourOrders } from "../../../../utils/Utilities";

const arrStatus = {
    CANCELED: { text: "Canceled", color: "bg-ag-order-canceled" },
    APPROVED: { text: "Approved", color: "bg-ag-order-delivered" },
    INPROCESS: { text: "In process", color: "bg-ag-order-pending" },
    FINISHED: { text: "Finished", color: "bg-blue-100" },
}

const RowsApproved = ({ enableActions, returnElement, onDetail, onInventory }) => {
    return (
        <tr>
            <td className="w-[10%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-6">
                <ul>
                    <li>{ formatDateHourOrders(returnElement.createdAt) }</li>    
                    <li>{`ID: ${String(returnElement?._id).substring(String(returnElement?._id).length - 13).toLocaleUpperCase()}`}</li>
                </ul>
            </td>
            <td className="w-[10%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-6">
                <p className="truncate w-32 m-auto">{ returnElement.wholesaler.names }</p>
            </td>
            <td className="w-[10%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-6">
                <p className="truncate w-32 m-auto">{ returnElement.productOrder.productInventory.product.nags }</p>
            </td>
            <td className="w-[10%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-6">
                <p className="truncate w-32 m-auto">{ returnElement.warehouse.name }</p>
            </td>
            <td className="w-[10%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-6">
                <p className="truncate w-32 m-auto">{ returnElement.reason }</p>
            </td>
            <td className="w-[10%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-6">
                <p className="truncate w-32 m-auto">{ formatCurrency(returnElement?.total) }</p>
            </td>
            <td className={`w-[10%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-6 ${ returnElement.status === "APPROVED" && returnElement?.productReturn ? arrStatus.FINISHED.color : arrStatus[returnElement.status]?.color }`}>
                { arrStatus[returnElement.status]?.text }
            </td>
            <td className="w-[10%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-6">
                <p className="truncate w-32 m-auto">{ returnElement.employee ? `${returnElement.employee?.names} ${returnElement.employee?.lastNames}` : "-----" }</p>
            </td>
            <td className="w-[10%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-6">
                <ul>
                    <li className="flex justify-center items-center space-x-2">
                        <p className="font-semibold text-green-600">Add:</p>    
                        <p>{ returnElement.productReturn ? returnElement.productReturn?.addQuantity : "-----" }</p>    
                    </li>    
                    {/* <li className="flex justify-center items-center space-x-2">
                        <p className="font-semibold text-red-600">Remove:</p>    
                        <p>{ returnElement.productReturn ? returnElement.productReturn?.removeQuantity : "-----" }</p>    
                    </li> */}
                </ul>
            </td>
            <td className="w-[10%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-3">
                {
                    returnElement.status === "APPROVED" ?
                        <button className="bg-ag-secondary text-white px-6 py-3 rounded-xl disabled:bg-zinc-200" disabled={!enableActions} type="button" onClick={() => onInventory(returnElement)}>
                            Add / Remove
                        </button>
                    : null
                }
                {
                    returnElement.status === "FINISHED" || returnElement.status === "CANCELED" ?
                        <button className="bg-ag-secondary text-white px-6 py-3 rounded-xl disabled:bg-zinc-200" disabled={!enableActions} type="button" onClick={() => onDetail(returnElement)}>
                            View Details
                        </button>
                    : null
                }
            </td>
        </tr>
    );
}

export default RowsApproved;