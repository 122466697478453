import { useEffect, useState } from "react";
import RowsInventory from "./RowsInventory";
import Pagination from "../../../components/pagination/Pagination";

const TableInventory = ({ enableEdit, enableRecord, enableOrderQuery, arrInventory, currntPg, pagesData, onPage, onHistory, onStatus, onImage, onPrice, onOrder, onCompatible }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(10);

    useEffect(() => {
        setCurrentPage(currntPg);
    }, [currntPg]);

    return (
        <div>
            <div className="overflow-auto min-h-[38vh] lg:min-h-[47vh] 3xl:min-h-[55vh]">
                <table className="w-full text-center text-ag-secondary">
                    <thead>
                        <tr className="bg-ag-secondary-table">
                            <th className="w-[25%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-32 md:px-40 xl:px-2 py-4">Products</th>
                            <th className="w-[9%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-10 md:px-10 xl:px-2 py-4">Compatible vehicles</th>
                            <th className="w-[14%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-10 md:px-10 xl:px-2 py-4">Description</th>
                            <th className="w-[15%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-10 md:px-10 xl:px-2 py-4">Features</th>
                            <th className="w-[10%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-10 md:px-10 xl:px-2 py-4">Warehouse</th>
                            <th className="w-[8%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-10 md:px-10 xl:px-2 py-4">Stock</th>
                            <th className="w-[8%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-10 md:px-10 xl:px-2 py-4">Sales price</th>
                            <th className="w-[11%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-10 md:px-10 xl:px-2 py-4">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            arrInventory.map((element, i) => {
                                return (
                                    <RowsInventory
                                        key={i}
                                        enableEdit={enableEdit}
                                        enableRecord={enableRecord}
                                        enableOrderQuery={enableOrderQuery}
                                        inventory={element}
                                        onHistory={onHistory}
                                        onStatus={onStatus}
                                        onImage={onImage}
                                        onPrice={onPrice}
                                        onOrder={onOrder}
                                        onCompatible={onCompatible}
                                    />
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
            <div className="px-5 pt-5 pb-2 m-auto">
                <Pagination
                    currentPage={currentPage}
                    totalCount={pagesData.records}
                    pageSize={recordsPerPage}
                    onPageChange={page => onPage(page)}
                />
            </div>
        </div>
    );
}

export default TableInventory;