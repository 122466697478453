import { useContext, useEffect, useState } from "react";
import { getInventoryAddId } from "../../../../services/Inventory";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getProductEspecification, getProductEspecificationByNag, searchProductEspecification } from "../../../../services/Product";
import { saveProductCart } from "../../../../services/Shopping";
import { PermitsContext } from "../../../../services/Permits";
import { toast } from "react-toastify";
import { alertOptions } from "../../../../utils/Utilities";
import { FiChevronLeft } from "react-icons/fi";
import { getMyWishList, saveWishList } from "../../../../services/WishList";
import NavbarClients from "../../../../components/navbar/NavbarClients";
import SidebarClients from "../../../../components/sidebar/SidebarClients";
import Loader from "../../../../components/loader/Loader";
import FormDetail from "./FormDetail";

const SearchDetail = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { state } = useLocation();
    const params = useParams();
    const { dataSession, getCurrentCart } = useContext(PermitsContext);
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const [loader, setLoader] = useState(false);
    const [product, setProduct] = useState({
        _idPE: "",
        _id: "",
        warehouse: "",
        nags: "",
        name: "",
        glassType: "",
        description: "",
        features: "",
        quantity: "",
        price: "",
        nagsPrice: "",
        labor: "",
        images: [],
        compatible_vehicles: []
    });

    const getData = () => {
        getMyWishList({ wholesalerUser: dataSession._id }).then(res => {
            const arrWishList = res.status === 200 ? res.data : [];
            getInventoryAddId(params.id).then(res => {
                if (res.status === 200) {
                    setLoader(false);
                    const inWishList = arrWishList.find(wlElem => wlElem?.nag === res.data?.productInventory?.product?.nags);
                    setProduct({...product,
                        warehouse: res.data?.warehouse?._id,
                        _idPE: res.data?.productInventory?.product?.productSpecification,
                        _id: res.data?.productInventory?._id,
                        nags: res.data?.productInventory?.product?.nags,
                        name: res.data?.productInventory?.product?.name,
                        glassType: res.data?.productInventory?.product?.glassType,
                        description: res.data?.productInventory?.product?.description,
                        features: res.data?.productInventory?.product?.features,
                        quantity: res.data?.totalQuantity,
                        price: res.data?.productInventory?.cost,
                        nagsPrice: res.data?.productInventory?.product?.price,
                        labor: res.data?.productInventory?.product?.serviceTime,
                        images: res.data?.productInventory?.product?.productImages,
                        wishList: inWishList ? true : false,
                        searchValueYear: state.searchValueYear,
                        compatible_vehicles: res.data?.productInventory?.product?.compatible_vehicles
                    });
                }
            }).catch(() => {
                getProductEspecification(params.id).then(async resProd=> {
                    if (resProd.status === 200) {
                        const compatibleVehRes = await searchProductEspecification({ nag: resProd?.data?.nag, excludePriceZero: false }).catch(() => []);
                        const inWishListPE = arrWishList.find(wlElem => wlElem?.nag === resProd.data?.nag);
                        setProduct({...product,
                            warehouse: "",
                            _id: resProd.data?._id,
                            _idPE: resProd.data?._id,
                            nags: resProd.data?.nag,
                            name: resProd.data?.name,
                            glassType: resProd.data?.type_glass,
                            description: resProd.data?.ad_info_b,
                            features: resProd.data?.ad_info_a,
                            quantity: 0,
                            price: 0,
                            nagsPrice: resProd.data?.price,
                            labor: resProd.data?.labor_time,
                            images: [],
                            wishList: inWishListPE ? true : false,
                            searchValueYear: state.searchValueYear,
                            compatible_vehicles: compatibleVehRes.status === 200 ? compatibleVehRes.data : []
                        });
                    }
                    setLoader(false);
                });
            });
        });
    }

    const handlePurcharse = (objCart) => {
        setLoader(true);
        saveProductCart(dataSession._id, objCart).then(res => {
            if (res.status === 200) {
                getCurrentCart(dataSession._id);
                navigate("/wholesaler/mycart");
            } else {
                toast.warning(res.response.data.message, alertOptions);
            }
            setLoader(false);
        }).catch(error => {
            setLoader(false);
            toast.warning(error.response.data.message, alertOptions);
        });
    }

    const handleCart = (objCart) => {
        setLoader(true);
        saveProductCart(dataSession._id, objCart).then(res => {
            if (res.status === 200) {
                getCurrentCart(dataSession._id);
                toast.success("Product added successfully", alertOptions);
            } else {
                toast.warning(res.response.data.message, alertOptions);
            }
            setLoader(false);
        }).catch(error => {
            setLoader(false);
            toast.warning(error.response.data.message, alertOptions);
        });
    }

    const handleAddWishList = (product) => {
        setLoader(true);
        saveWishList({ wholesalerUser: { _id: dataSession._id }, nag: product.nags }).then(res => {
            if (res.status === 200) {
                getData();
                toast.success("The product request has been sent", alertOptions);
            } else {
                setLoader(false);
                toast.warning(res.response.data.message, alertOptions);
            }
        }).catch(error => {
            setLoader(false);
            toast.warning(error.response.data.message, alertOptions);
        });
    }

    useEffect(() => {
        setLoader(true);
        getData();
    }, []);

    return (
        <>
            { loader ? <Loader /> : null }

            <div>
                <div className="flex flex-row">
                    <div className="w-full bg-no-repeat bg-cover fixed z-10">
                        <NavbarClients type="wholesaler" sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                    </div>
                </div>
                <div className="flex h-screen overflow-hidden">
                    <div className={`${sidebarOpen ? "w-[100vw] md:w-80 z-20" : "w-20 z-0"}`}>
                        <SidebarClients type="wholesaler" sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                    </div>
                    <div className="w-full overflow-auto flex-grow mt-[100px]">
                        <div className="space-y-5 px-3 py-5 md:px-8 md:py-8">
                            <div className="grid grid-cols-4 md:grid-cols-12 px-0 md:px-5">
                                <div>
                                    <button className="p-1 rounded-md" type="button" onClick={() => navigate(-1)}>
                                        <span className="text-ag-secondary-dark text-3xl"><FiChevronLeft /></span>
                                    </button>
                                </div>
                                <div className="flex justify-center items-center col-span-2 md:col-span-11 text-center">
                                    <h1 className="text-[26px] md:text-xl lg:text-[26px] font-light text-ag-secondary md:mr-[8%]">
                                        { product.nags } - Product Details
                                    </h1>
                                </div>
                                <div></div>
                            </div>
                            <div>
                                <FormDetail
                                    product={product}
                                    qtyDefault={location.state}
                                    onCart={handleCart}
                                    onPurcharse={handlePurcharse}
                                    onAddWishList={handleAddWishList}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SearchDetail;