import { useEffect, useState } from "react";
import { IoMdCloseCircle } from "react-icons/io";
import { getRetailUsers } from "../../../services/Retail";
import { getWholesalerUsers } from "../../../services/Wholesaler";
import { removeAccents } from "../../../utils/Utilities";

const SelectClient = ({ clientCurrent, onLoader, onClose }) => {
    const [clientGuest, setClientGuest] = useState([]);
    const [clients, setClients] = useState([]);
    const [clientsUniv, setClientsUniv] = useState([]);

    const handleChange = (evt) => {
        if (evt.target.value === "") {
            setClients(clientsUniv);
        } else {
            let palabra = new RegExp(`${removeAccents(evt.target.value)}.*`, "i");
            const newClients = clientsUniv.filter(element => (
                palabra.test(removeAccents(`${element.names} ${element.lastNames}`)) ||
                palabra.test(removeAccents(element?.user?.email))
            ));

            setClients(newClients);
        }
    }

    useEffect(() => {
        onLoader(true);
        let array_clients = [];
        getWholesalerUsers().then(resWU => {
            if (resWU.status === 200 && resWU.data.length > 0) {
                const guestFound = resWU.data.filter(element => !element.deleted && (element.names === "Guest" && element?.lastNames === "User" && element?.workshopName === "Avondale Auto Glass"));
                setClientGuest(guestFound);
                
                const clientsFound = resWU.data.filter(element => !element.deleted && (element.names !== "Guest" && element?.lastNames !== "User" && element?.workshopName !== "Avondale Auto Glass"));
                array_clients = array_clients.concat(clientsFound);
            }
            getRetailUsers().then(resRU => {
                if (resRU.status === 200 && resRU.data.length > 0) {
                    const clientsFoundAux = resRU.data.filter(element => !element.deleted);
                    array_clients = array_clients.concat(clientsFoundAux);
                }
                setClients(array_clients);
                setClientsUniv(array_clients);
                onLoader(false);
            }).catch(() => {
                onLoader(false);
            });
        }).catch(() => {
            onLoader(false);
        });
    }, []);

    return (
        <>
            <div className="block">
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-30 outline-none focus:outline-none">
                    <div className="w-[320px] md:w-[650px] lg:w-[900px]">
                        <div className="p-3 border-0 rounded-lg shadow-lg flex-col w-full bg-white outline-none focus:outline-none">
                            <div className="w-full flex items-center justify-end rounded text-center">
                                <div className="w-[95%]">
                                    <div className="text-center text-ag-secondary-letter font-normal">
                                        <h3 className="text-xl pl-[5%]">Select your client</h3>
                                    </div>
                                </div>
                                <div className="w-[5%]">
                                    <button
                                        className="text-ag-secondary float-right text-3xl leading-none outline-none focus:outline-none"
                                        onClick={() => onClose(false)}
                                    >
                                        <IoMdCloseCircle />
                                    </button>
                                </div>
                            </div>
                            <div className="relative p-5 flex-auto">
                                <div className="space-y-5">
                                    <div>
                                        <input
                                            className="w-full text-ag-secondary-letter border border-gray-500 px-5 py-3 rounded-xl disabled:bg-zinc-200"
                                            type="text"
                                            name="search"
                                            placeholder="Search"
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="overflow-y-auto h-[400px] border border-gray-500 px-3 py-3 rounded-xl">
                                        <ul>
                                            { clientGuest.map((client, i) => (
                                                <li className="md:flex md:justify-between md:items-center text-ag-secondary-letter px-5 py-3 border-b-[0.5px] border-gray-300 cursor-pointer hover:bg-zinc-100" key={i} onClick={() => clientCurrent(client)}>
                                                    <p className="truncate w-[175px] md:w-[220px] xl:w-[350px] font-semibold">{ client.names } { client.lastNames }</p>
                                                    <p className="truncate w-[175px] md:w-[220px] xl:w-[350px] text-xs italic" title="*** Select this option if the customer does not have an account">
                                                        *** Select this option if the customer does not have an account
                                                    </p>
                                                </li>
                                            ))}
                                            <br />
                                            { clients.map((client, i) => (
                                                <li className="md:flex md:justify-between md:items-center text-ag-secondary-letter px-5 py-3 border-b-[0.5px] border-gray-300 cursor-pointer hover:bg-zinc-100" key={i} onClick={() => clientCurrent(client)}>
                                                    <p className="truncate w-[175px] md:w-[220px] xl:w-[350px]">{ client.names } { client.lastNames }</p>
                                                    <p className="truncate w-[175px] md:w-[220px] xl:w-[350px]">{ client.user.email }</p>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="opacity-50 fixed inset-0 z-20 bg-black"></div>
        </>
    );
}

export default SelectClient;