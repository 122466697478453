import { useEffect, useState } from "react";
import { formatReverseDate } from "../../../utils/Utilities";
import ReactDatePicker from "react-datepicker";

const Filters = ({ enableFilters, enableAddRetail, enableAddWholesaler, typeOptions, yearsOptions, monthsOptions, isClean, onClean, onFilters, onClose }) => {
    const [enableAdd, setEnableAdd] = useState(true);
    const [buttonSel, setButtonSel] = useState({ btn1: false, btn2: false });
    const [focusClass, setFocusClass] = useState({
        search: { placeholder: "Search", status: false },
        typeUser: { placeholder: "Type", status: false },
        year: { placeholder: "Year", status: false },
        month: { placeholder: "Month", status: false },
        phoneNumber: { placeholder: "Phone Number", status: false },
        startDate: { placeholder: "Start date", status: false },
        endDate: { placeholder: "End date", status: false },
    });

    const [options, setOptions] = useState({
        search: "",
        typeUser: "",
        year: "",
        month: "",
        phoneNumber: "",
        startDate: "",
        endDate: "",
        typeSearch: ""
    });

    const handleChange = (evt, nameInpt) => {
        if (evt?.currentTarget?.name === "search") {
            setOptions ({...options, [evt?.currentTarget?.name]: evt?.currentTarget?.value});
            onFilters(evt?.currentTarget?.value, options, true);
        } else {
            if (evt?.currentTarget?.name === "year" || evt?.currentTarget?.name === "month") {
                if (evt?.currentTarget?.value === "default") {
                    setOptions({...options, [evt?.currentTarget?.name]: "", startDate: "", endDate: ""});
                } else {
                    setOptions({...options, [evt?.currentTarget?.name]: evt?.currentTarget?.value, startDate: "", endDate: ""});
                }

                setFocusClass({...focusClass,
                    startDate: { placeholder: "Start date", status: false },
                    endDate: { placeholder: "End date", status: false }
                });
            } else if (nameInpt === "startDate" || nameInpt === "endDate") {
                if (evt === "default") {
                    setOptions({...options, [nameInpt]: "", year: "", month: ""});
                } else {
                    setOptions({...options, [nameInpt]: evt, year: "", month: ""});
                }

                setFocusClass({...focusClass,
                    year: { placeholder: "Year", status: false },
                    month: { placeholder: "Month", status: false },
                });
            } else {
                if (evt?.currentTarget?.value === "default") {
                    setOptions({...options, [evt?.currentTarget?.name]: ""});
                } else {
                    setOptions({...options, [evt?.currentTarget?.name]: evt?.currentTarget?.value});
                }
            }
        }
    }

    const handleChangeBtn = (btnSel) => {
        if (btnSel === "mayor") {
            if (!buttonSel.btn1) {
                setButtonSel({...buttonSel, btn1: true, btn2: false});
                setOptions({...options, typeSearch: "mayor"});
            } else {
                setButtonSel({...buttonSel, btn1: false, btn2: false});
                setOptions({...options, typeSearch: ""});
            }
        } else {
            if (!buttonSel.btn2) {
                setButtonSel({...buttonSel, btn1: false, btn2: true});
                setOptions({...options, typeSearch: "minor"});
            } else {
                setButtonSel({...buttonSel, btn1: false, btn2: false});
                setOptions({...options, typeSearch: ""});
            }
        }
    }

    const handleSubmit = (evt) => {
        evt.preventDefault();
        onFilters(options.search, {...options,
            startDate: options.startDate !== "" ? formatReverseDate(options.startDate) : "",
            endDate: options.endDate !== "" ? formatReverseDate(options.endDate) : ""
        }, false);
    }

    useEffect(() => {
        if (isClean) {
            setOptions({...options,
                search: "",
                typeUser: "",
                year: "",
                month: "",
                phoneNumber: "",
                startDate: "",
                endDate: "",
                typeSearch: ""
            });

            setFocusClass({...focusClass,
                search: { placeholder: "Search", status: false },
                typeUser: { placeholder: "Type", status: false },
                year: { placeholder: "Year", status: false },
                month: { placeholder: "Month", status: false },
                phoneNumber: { placeholder: "Phone Number", status: false },
                startDate: { placeholder: "Start date", status: false },
                endDate: { placeholder: "End date", status: false },
            });

            setButtonSel({...buttonSel, btn1: false, btn2: false});
            onClean(false);
        }
    }, [isClean])

    useEffect(() => {
        if (enableAddRetail || enableAddWholesaler) {
            setEnableAdd(false);
        }
    }, [enableAddRetail, enableAddWholesaler]);

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <div className="space-y-2 md:flex md:justify-center md:items-center md:space-x-3 xl:space-x-8 md:space-y-0">
                    <div className="w-full space-y-2">
                        <div>
                            <input
                                className={`w-full text-ag-secondary-letter text-sm lg:text-base border border-gray-500 p-2 rounded-xl disabled:bg-zinc-200 truncate ${focusClass.search.status ? "text-left" : "text-center"}`}
                                type="text"
                                name="search"
                                placeholder={focusClass.search.placeholder}
                                onChange={(evt) => handleChange(evt, "")}
                                disabled={!enableFilters}
                                onFocus={() => setFocusClass({...focusClass, search: { placeholder: "", status: true }})}
                                onBlur={(evt) => evt.target.value === "" ? setFocusClass({...focusClass, search: { placeholder: "Search", status: false }}) : null}
                                maxLength={60}
                                value={options.search}
                            />
                        </div>
                        <div className="grid gap-3 xl:gap-6 grid-cols-2 md:grid-cols-5">
                            <div>
                                <select
                                    className={`w-full text-xs lg:text-sm p-2 md:p-1 text-ag-secondary-letter border border-gray-500 rounded-xl disabled:bg-zinc-200 truncate ${focusClass.typeUser.status ? "text-left" : "text-center"}`}
                                    name="typeUser"
                                    defaultValue="default"
                                    onChange={(evt) => handleChange(evt, "")}
                                    disabled={!enableFilters}
                                    onFocus={() => setFocusClass({...focusClass, typeUser: { placeholder: "", status: true }})}
                                    onBlur={(evt) => evt.target.value === "default" ? setFocusClass({...focusClass, typeUser: { type: "Type", status: false }}) : null}
                                    value={options.typeUser}
                                >
                                    <option value="default">Type</option>
                                    {
                                        typeOptions.map((element, index) => {
                                            return <option key={index} value={element.value}>{element.label}</option>;
                                        })
                                    }
                                </select>
                            </div>
                            <div>
                                <select
                                    className={`w-full text-xs lg:text-sm p-2 md:p-1 text-ag-secondary-letter border border-gray-500 rounded-xl disabled:bg-zinc-200 truncate ${focusClass.year.status ? "text-left" : "text-center"}`}
                                    name="year"
                                    defaultValue="default"
                                    onChange={(evt) => handleChange(evt, "")}
                                    disabled={!enableFilters}
                                    onFocus={() => setFocusClass({...focusClass, year: { placeholder: "", status: true }})}
                                    onBlur={(evt) => evt.target.value === "default" ? setFocusClass({...focusClass, year: { placeholder: "Year", status: false }}) : null}
                                    value={options.year}
                                >
                                    <option value="default">Year</option>
                                    {
                                        yearsOptions.map((element, index) => {
                                            return <option key={index} value={element.value}>{element.label}</option>;
                                        })
                                    }
                                </select>
                            </div>
                            <div>
                                <select
                                    className={`w-full text-xs lg:text-sm p-2 md:p-1 text-ag-secondary-letter border border-gray-500 rounded-xl disabled:bg-zinc-200 truncate ${focusClass.month.status ? "text-left" : "text-center"}`}
                                    name="month"
                                    defaultValue="default"
                                    onChange={(evt) => handleChange(evt, "")}
                                    disabled={!enableFilters}
                                    onFocus={() => setFocusClass({...focusClass, month: { placeholder: "", status: true }})}
                                    onBlur={(evt) => evt.target.value === "default" ? setFocusClass({...focusClass, month: { placeholder: "Month", status: false }}) : null}
                                    value={options.month}
                                >
                                    <option value="default">Month</option>
                                    {
                                        monthsOptions.map((element, index) => {
                                            return <option key={index} value={element.value}>{element.label}</option>;
                                        })
                                    }
                                </select>
                            </div>
                            <div className="md:col-span-2">
                                <input
                                    className={`w-full text-xs lg:text-sm p-2 md:p-1 text-ag-secondary-letter border border-gray-500 rounded-xl disabled:bg-zinc-200 truncate ${focusClass.phoneNumber.status ? "text-left" : "text-center"}`}
                                    type="text"
                                    name="phoneNumber"
                                    placeholder={focusClass.phoneNumber.placeholder}
                                    onChange={(evt) => handleChange(evt, "")}
                                    disabled={!enableFilters}
                                    onFocus={() => setFocusClass({...focusClass, phoneNumber: { placeholder: "", status: true }})}
                                    onBlur={(evt) => evt.target.value === "" ? setFocusClass({...focusClass, phoneNumber: { placeholder: "Phone Number", status: false }}) : null}
                                    maxLength={10}
                                    value={options.phoneNumber}
                                />
                            </div>
                        </div>
                        <div className="grid gap-3 xl:gap-6 grid-cols-2 md:grid-cols-4">
                            <div>
                                <button
                                    className={`w-full text-center text-xs lg:text-sm p-2 md:p-1 text-ag-secondary-letter border border-gray-500 rounded-xl disabled:bg-zinc-200 ${buttonSel.btn1 ? "bg-zinc-200" : ""}`}
                                    type="button"
                                    disabled={!enableFilters}
                                    onClick={() => handleChangeBtn("mayor")}

                                >
                                    Mayor purchases
                                </button>
                            </div>
                            <div>
                                <button
                                    className={`w-full text-center text-xs lg:text-sm p-2 md:p-1 text-ag-secondary-letter border border-gray-500 rounded-xl disabled:bg-zinc-200 ${buttonSel.btn2 ? "bg-zinc-200" : ""}`}
                                    type="button"
                                    disabled={!enableFilters}
                                    onClick={() => handleChangeBtn("minor")}
                                >
                                    Minor purchases
                                </button>
                            </div>
                            <div className="customDatePickerWidth">
                                <ReactDatePicker
                                    className={`w-full text-xs lg:text-sm p-2 md:p-[4px] text-ag-secondary-letter border border-gray-500 rounded-xl disabled:bg-zinc-200 ${focusClass.startDate.status ? "text-left" : "text-center"}`}
                                    placeholderText="Start date"
                                    name="startDate"
                                    selected={options.startDate}
                                    onChange={(valDate) => handleChange(valDate, "startDate")}
                                    onFocus={() => setFocusClass({...focusClass, startDate: { placeholder: "", status: true }})}
                                    onBlur={(evt) => evt.target.value === "" ? setFocusClass({...focusClass, startDate: { placeholder: "Start date", status: false }}) : <></> }
                                    disabled={!enableFilters}
                                />
                            </div>
                            <div className="customDatePickerWidth">
                                <ReactDatePicker
                                    className={`w-full text-xs lg:text-sm p-2 md:p-[4px] text-ag-secondary-letter border border-gray-500 rounded-xl disabled:bg-zinc-200 ${focusClass.endDate.status ? "text-left" : "text-center"}`}
                                    placeholderText="End date"
                                    name="endDate"
                                    selected={options.endDate}
                                    onChange={(valDate) => handleChange(valDate, "endDate")}
                                    onFocus={() => setFocusClass({...focusClass, endDate: { placeholder: "", status: true }})}
                                    onBlur={(evt) => evt.target.value === "" ? setFocusClass({...focusClass, endDate: { placeholder: "End date", status: false }}) : <></> }
                                    disabled={!enableFilters}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="w-full md:w-[200px] lg:w-[400px] space-y-3">
                        <div>
                            <button
                                className="w-full bg-ag-primary-light text-white text-sm lg:text-base p-3 rounded-xl disabled:bg-zinc-200"
                                type="button"
                                onClick={() => { onClose(true) }}
                                disabled={enableAdd}
                            >
                                New record
                            </button>
                        </div>
                        <div>
                            <button
                                className="w-full bg-ag-secondary-light text-white text-sm lg:text-base p-3 rounded-xl disabled:bg-zinc-200"
                                type="submit"
                                disabled={!enableFilters}
                            >
                                Filter
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default Filters;