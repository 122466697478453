import { _delete, _get, _getParams, _post, _put } from "./http";

export const getProduct = (id) => _get(`/products/${id}`);

export const getProducts = (page= null, limit= null, filterParams= null) => {
    if (page !== null && limit !== null && filterParams !== null){
        return _getParams(`/products?page=${page}&limit=${limit}`, filterParams)
    }else {
        return _get(`/products`);
    }
};

export const getProductEspecification = (id) => _get(`/productSpecifications/${id}`);

export const getProductEspecificationByNag = (nag) => _get(`/productSpecifications/${nag}/searchInventory`);

export const getCompatVehiclesWithInterchangesByNag = (nag) => _get(`/productSpecifications/seeCompatVehiclesWithInterchanges?nag=${nag}`);

export const getProductEspecifications = (page) => _get(`/productSpecifications?page=${page}&limit=10`);

export const getProductEspecificationsYears = () => _get(`/productSpecifications/yearsCatalog`);

export const getProductEspecificationsBrands = (year) => _get(`/productSpecifications/brandsCatalog?year=${year}`);

export const getProductEspecificationsModel = (year, brand) => _get(`/productSpecifications/modelsCatalog?year=${year}&brand=${brand}`);

export const getProductEspecificationsTypec = (year, brand, model) => _get(`/productSpecifications/typeCarCatalog?year=${year}&brand=${brand}&model=${model}`);

export const getProductEspecificationsTypeg = (year, brand, model, typec) => _get(`/productSpecifications/typeGlassCatalog?year=${year}&brand=${brand}&model=${model}&type_car=${typec}`);

export const getProductEspecificationVendors = () => _get(`/quotes/getAllVendors`);

export const searchProductEspecification = (filterParams) => _getParams(`/productSpecifications/search`, filterParams);

export const saveProduct = (productObject) => _post(`/products`, productObject);

export const updateProduct = (id, productObject) => _put(`/products/${id}`, productObject);

export const deleteProduct = (id) => _delete(`/products/${id}`);

export const getCompatiblesVehicles = (nag) => _get(`/productSpecifications/seeCompatVehiclesbyNag/${nag}`)
