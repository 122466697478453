import { useEffect } from "react";
import { useState } from "react";
import Pagination from "../../../components/pagination/Pagination";
import RowsAdmin from "./RowsAdmin";

const TableAdmin = ({ enableEdit, enableDelete, arrAdmins, onEdit, onDelete }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(7);

    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = arrAdmins.slice(indexOfFirstRecord, indexOfLastRecord);

    useEffect(() => {
        setCurrentPage(1);
    }, [arrAdmins]);

    return (
        <div>
            <div className="overflow-auto min-h-[55vh] 2xl:min-h-[49vh]">
                <table className="w-full text-center text-ag-secondary">
                    <thead>
                        <tr className="bg-ag-secondary-table">
                            <th className="w-[12%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 md:px-3 lg:px-2 py-3">Number of administrator</th>
                            <th className="w-[22%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 md:px-20 lg:px-2 py-3">Name</th>
                            <th className="w-[20%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 md:px-14 lg:px-2 py-3">Warehouse</th>
                            <th className="w-[22%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 md:px-14 lg:px-2 py-3">Email</th>
                            <th className="w-[12%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 md:px-20 lg:px-2 py-3">Rol</th>
                            <th className="w-[6%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 md:px-3 lg:px-2 py-3">Edit</th>
                            <th className="w-[6%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 md:px-3 lg:px-2 py-3">Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            currentRecords.map((element, i) => {
                                return (
                                    <RowsAdmin
                                        key={i}
                                        indexUser={((i+1)-recordsPerPage)+(currentPage*recordsPerPage)}
                                        enableEdit={enableDelete}
                                        enableDelete={enableDelete}
                                        administrators={element}
                                        onDelete={() => { onDelete(element) }}
                                        onEdit={() => { onEdit(element) }}
                                    />
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
            <div className="p-5 m-auto">
                <Pagination
                    currentPage={currentPage}
                    totalCount={arrAdmins.length}
                    pageSize={recordsPerPage}
                    onPageChange={page => setCurrentPage(page)}
                />
            </div>
        </div>
    );
}

export default TableAdmin;