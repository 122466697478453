import { useState } from "react";
import { validateNewUserWholesaler } from "../../../utils/Validators";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { alertOptions } from "../../../utils/Utilities";
import { saveWholesalerUser } from "../../../services/Wholesaler";
import { fileUpload } from "../../../services/File";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import LogoMAY from "../../../assets/images/logo_a.png";
import IconPDF from "../../../assets/icons/pdf-icon.png";
import Loader from "../../../components/loader/Loader";

const Register = () => {
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [user, setUser] = useState({
        workshopName: "",
        workshopAddress: "",
        names: "",
        lastNames: "",
        email: "",
        phoneNumber: "",
        zipCode: "",
        password: "",
        confirmPassword: "",
        invoice: "",
        invoiceFile: { file: null, name: "" },
        avaibleCredit: 0
    });

    const handleUpload = (evt) => {
        if (evt.currentTarget.files[0].type !== "application/pdf") {
            toast.warning("It is not possible to upload a file other than pdf", alertOptions);
        } else {
            const file = evt.currentTarget.files;
            if (file !== null) {
                setUser({...user, invoiceFile: { file: file[0], name: file[0].name }});
            }
        }
    }

    const handleChange = (evt) => {
        if (evt.currentTarget.name === "workshopName" || evt.currentTarget.name === "names" || evt.currentTarget.name === "lastNames") {
            const pattern = new RegExp(/^[A-Za-z\s]+$/g);
            if (pattern.test(evt.currentTarget.value) || evt.currentTarget.value === "") {
                setUser({...user, [evt.currentTarget.name]: evt.currentTarget.value});
            }
        } else if (evt.currentTarget.name === "invoice" || evt.currentTarget.name === "phoneNumber" || evt.currentTarget.name === "zipCode") {
            const pattern = new RegExp(/^[0-9\s]+$/g);
            if (pattern.test(evt.currentTarget.value) || evt.currentTarget.value === "") {
                setUser({...user, [evt.currentTarget.name]: evt.currentTarget.value});
            }
        } else {
            setUser({...user, [evt.currentTarget.name]: evt.currentTarget.value});
        }
    }

    const handleSubmit = async (evt) => {
        evt.preventDefault();
        if (user.names && user.lastNames && user.workshopName && user.email && user.phoneNumber && user.zipCode && user.password && user.invoice && user.invoiceFile.file !== null) {
            if (user.password === user.confirmPassword) {
                if (user.password.length >= 8) {
                    setLoader(true);
                    const result = await validateNewUserWholesaler(user);
                    if (result.status) {
                        let submitModel = {
                            names: user.names,
                            lastNames: user.lastNames,
                            email: user.email,
                            password: user.password,
                            phoneNumber: user.phoneNumber,
                            zipCode: user.zipCode,
                            workshopName: user.workshopName,
                            workshopAddress: user.workshopAddress,
                            invoice: user.invoice,
                            invoiceFile: user.invoiceFile
                        }

                        if (submitModel.invoiceFile.file !== null) {
                            let bodyFormData = new FormData();
                            bodyFormData.append('file', user.invoiceFile.file);
                            const valueUpload = await fileUpload(bodyFormData).then(res => {
                                if (res.status === 200) return res.data;
                                return "";
                            });
                            submitModel.invoiceFile = valueUpload;
                        }

                        await saveWholesalerUser(submitModel).then(res => {
                            if (res.status === 200) {
                                setUser({...user,
                                    workshopName: "",
                                    workshopAddress: "",
                                    names: "",
                                    lastNames: "",
                                    email: "",
                                    phoneNumber: "",
                                    zipCode: "",
                                    password: "",
                                    confirmPassword: "",
                                    invoice: "",
                                    invoiceFile: { file: null, name: "" },
                                });
                                navigate("/wholesaler");
                                toast.success("User added successfully", alertOptions);
                            } else {
                                toast.warning(res.response.data.message, alertOptions);
                            }
                            setLoader(false);
                        }).catch(error => {
                            setLoader(false);
                            toast.warning(error.response.data.message, alertOptions);
                        });
                    } else {
                        setLoader(false);
                        toast.warning(result.msg, alertOptions);
                    }
                } else {
                    toast.info("Password must contain at least eight characters", alertOptions);
                }
            } else {
                toast.warning("The passwords entered do not match", alertOptions);
            }
        } else {
            toast.info("Please fill in all the fields of the form", alertOptions);
        }
    }
    return (
        <>
            { loader ? <Loader /> : null }

            <div className="min-h-screen bg-black flex justify-center items-center overflow-x-hidden">
                <div className="flex flex-col md:grid md:gap-5 lg:gap-20 md:grid-cols-2 w-[80%] md:w-[800px] lg:w-[1100px] py-10 md:py-0 space-y-10 md:space-y-0">
                    <div className="flex justify-center items-center w-full">
                        <img className="h-auto w-auto" src={LogoMAY} />
                    </div>
                    <div className="flex justify-center p-0 md:p-7 lg:p-10">
                        <div className="w-full bg-white rounded-xl p-5 w-full space-y-5">
                            <div className="w-full text-center">
                                <h1 className="text-2xl text-ag-secondary font-bold">Sign In</h1>
                            </div>
                            <div className="w-full">
                                <form onSubmit={handleSubmit}>
                                    <div className="space-y-2">
                                        <div>
                                            <input
                                                className="w-full text-center border border-gray-500 p-2 rounded-xl"
                                                type="text"
                                                name="workshopName"
                                                placeholder="Company Name"
                                                onChange={handleChange}
                                                value={user.workshopName}
                                                maxLength={40}
                                            />
                                        </div>
                                        <div>
                                            <input
                                                className="w-full text-center border border-gray-500 p-2 rounded-xl"
                                                type="text"
                                                name="names"
                                                placeholder="Name"
                                                onChange={handleChange}
                                                value={user.names}
                                                maxLength={40}
                                            />
                                        </div>
                                        <div>
                                            <input
                                                className="w-full text-center border border-gray-500 p-2 rounded-xl"
                                                type="text"
                                                name="lastNames"
                                                placeholder="Last name"
                                                onChange={handleChange}
                                                value={user.lastNames}
                                                maxLength={40}
                                            />
                                        </div>
                                        <div>
                                            <input
                                                className="w-full text-center border border-gray-500 p-2 rounded-xl"
                                                type="text"
                                                name="email"
                                                placeholder="Email"
                                                onChange={handleChange}
                                                value={user.email}
                                                maxLength={40}
                                            />
                                        </div>
                                        <div>
                                            <input
                                                className="w-full text-center border border-gray-500 p-2 rounded-xl"
                                                type="text"
                                                name="phoneNumber"
                                                placeholder="Phone number"
                                                onChange={handleChange}
                                                value={user.phoneNumber}
                                                maxLength={10}
                                            />
                                        </div>
                                        <div>
                                            <input
                                                className="w-full text-center border border-gray-500 p-2 rounded-xl"
                                                type="text"
                                                name="zipCode"
                                                placeholder="ZIP code"
                                                onChange={handleChange}
                                                value={user.zipCode}
                                                maxLength={5}
                                            />
                                        </div>
                                        <div className="flex justify-center items-center border border-gray-500 rounded-xl">
                                            <input
                                                title="Password must contain at least: One number, one special character, one uppercase letter, one lowercase letter, must not contain spaces, and must be at least 8 characters."
                                                className="w-full text-center py-2 pl-7 rounded-xl"
                                                type={showPassword ? "text" : "password"}
                                                name="password"
                                                placeholder="Password"
                                                onChange={handleChange}
                                                value={user.password}
                                                maxLength={40}
                                            />
                                            { showPassword ?
                                                <button 
                                                    className="border-none p-2 rounded-xl disabled:bg-zinc-200"
                                                    type="button"
                                                    onClick={() => setShowPassword(false)}
                                                >
                                                    <span className="text-ag-secondary-letter text-base lg:text-xl"><BsFillEyeSlashFill /></span>
                                                </button>
                                            :
                                                <button 
                                                    className="border-none p-2 rounded-xl disabled:bg-zinc-200"
                                                    type="button"
                                                    onClick={() => setShowPassword(true)}
                                                >
                                                    <span className="text-ag-secondary-letter text-base lg:text-xl"><BsFillEyeFill /></span>
                                                </button>
                                            }
                                        </div>
                                        <div className="flex justify-center items-center border border-gray-500 rounded-xl">
                                            <input
                                                title="Password must contain at least: One number, one special character, one uppercase letter, one lowercase letter, must not contain spaces, and must be at least 8 characters."
                                                className="w-full text-center py-2 pl-7 rounded-xl"
                                                type={showConfirmPassword ? "text" : "password"}
                                                name="confirmPassword"
                                                placeholder="Confirm Password"
                                                onChange={handleChange}
                                                value={user.confirmPassword}
                                                maxLength={40}
                                            />
                                            { showConfirmPassword ?
                                                <button 
                                                    className="border-none p-2 rounded-xl disabled:bg-zinc-200"
                                                    type="button"
                                                    onClick={() => setShowConfirmPassword(false)}
                                                >
                                                    <span className="text-ag-secondary-letter text-base lg:text-xl"><BsFillEyeSlashFill /></span>
                                                </button>
                                            :
                                                <button 
                                                    className="border-none p-2 rounded-xl disabled:bg-zinc-200"
                                                    type="button"
                                                    onClick={() => setShowConfirmPassword(true)}
                                                >
                                                    <span className="text-ag-secondary-letter text-base lg:text-xl"><BsFillEyeFill /></span>
                                                </button>
                                            }
                                        </div>
                                        <div>
                                            <input
                                                className="w-full text-center border border-gray-500 p-2 rounded-xl"
                                                type="text"
                                                name="invoice"
                                                placeholder="EIN"
                                                onChange={handleChange}
                                                value={user.invoice}
                                                maxLength={40}
                                            />
                                        </div>
                                        <div>
                                            <input
                                                className="w-full text-center border border-gray-500 p-2 rounded-xl"
                                                type="text"
                                                name="workshopAddress"
                                                placeholder="Company Address"
                                                onChange={handleChange}
                                                value={user.workshopAddress}
                                                maxLength={60}
                                            />
                                        </div>
                                        <div>
                                            <label className="relative">
                                                <div className="w-full flex justify-center items-center space-x-1 text-ag-secondary-letter text-center border border-gray-500 h-[60px] rounded-xl">
                                                    { user.invoiceFile.name !== "" ? null :
                                                        <span><img className="w-[35px] m-auto" src={IconPDF} /></span>
                                                    }
                                                    { user.invoiceFile.name !== "" ?
                                                        <span>{ user.invoiceFile.name }</span>
                                                        :
                                                        <span>5000 Form</span>
                                                    }
                                                </div>
                                                <input type="file" className="absolute top-[0px] opacity-0" name="invoiceFile" accept=".pdf" onChange={handleUpload} />
                                            </label>
                                        </div>
                                        <div>
                                            <button
                                                className="w-full bg-ag-primary-light text-white p-2 rounded-xl"
                                                type="submit"
                                            >
                                                Create account
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="text-center">
                                <p className="text-xs text-ag-secondary-letter">
                                    <Link to="/wholesaler">
                                        <span>Do you already have an account?</span>
                                    </Link>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Register;