import { useContext, useEffect, useState } from "react";
import { getWarehouses } from "../../../../../services/Warehouse";
import { getInventoryStocktakeId, saveInventoryStocktake } from "../../../../../services/Stocktake";
import { FiChevronLeft } from "react-icons/fi";
import { Link, useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { alertOptions } from "../../../../../utils/Utilities";
import { PermitsContext } from "../../../../../services/Permits";
import Navbar from "../../../../../components/navbar/Navbar";
import Sidebar from "../../../../../components/sidebar/Sidebar";
import FormStocktake from "./FormStocktake";
import ProductsAvailable from "../../ProductsAvailable";
import Loader from "../../../../../components/loader/Loader";
import ModalCompatible from "../../../products/ModalCompatible";

const SaveStocktake = () => {
    const navigate = useNavigate();
    const params = useParams();
    const { dataSession } = useContext(PermitsContext); 
    const [loader, setLoader] = useState(false);
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const [showModal, setShowModal] = useState({ data: "", status: false });

    const [productShow, setProductShow] = useState(null);
    const [showCompatible, setShowCompatible] = useState({ status: false, element: null });
    const [productsStocktake, setProductsStocktake] = useState([]);
    const [warehouseOptions, setWarehouseOptions] = useState([]);
    const [reasonOptions] = useState([
        { label: "Inventory Count", value: "Inventory Count" },
        { label: "Other", value: "Other" },
        { label: "Reconciliation", value: "Reconciliation" }
    ]);

    const onResponseProducts = (respProducts) => {
        setShowModal({...showModal, data: "", status: false});

        const newProducts = respProducts.map((productElement) => {
            return {
                _id: productElement.productInventory._id,
                rack: productElement.rack._id,
                expected: productElement.quantity,
                actual: "",
                discrepancy: "",
                notes: "",
                data: productElement
            }
        });
        setProductsStocktake(newProducts);
    }

    const handleDown = (evt, productResp) => {
        if (evt.actual !== "" && productResp === true) {
            if ((evt.discrepancy !== 0 && evt.notes !== "") || evt.discrepancy === 0) {
                let element = {};
                const newArr = [];
                for (let i = 0; i < productsStocktake.length; i++) {
                    if (`${productsStocktake[i]._id}${productsStocktake[i].rack}` !== `${evt._id}${evt.rack}`) {
                        newArr.push(productsStocktake[i]);
                    } else {
                        element = productsStocktake[i];
                        element.check = productResp;
                    }
                }
                newArr.push(element);
                setProductsStocktake(newArr);
            } else {
                toast.warning("If the discrepancy is different from 0, the notes field cannot be empty", alertOptions);
            }
        } else if (productResp === false) {
            const newProducts = productsStocktake.map(element => {
                if (`${element._id}${element.rack}` === `${evt._id}${evt.rack}`) return {...element, check: productResp}
                return element;
            });
            setProductsStocktake(newProducts);
        } else {
            toast.warning("Please enter the current value of the product", alertOptions);
        }
    }

    const handleProduct = (evt, productResp) => {
        const newProducts = productsStocktake.map(element => {
            let newElement = element;
            if (`${newElement._id}${newElement.rack}` === `${productResp._id}${productResp.rack}`) {
                if (evt.target.name === "actual") {
                    newElement[evt.target.name] = Number(evt.target.value);
                    newElement["discrepancy"] = (evt.target.value)-Number(newElement["expected"]);
                } else {
                    newElement[evt.target.name] = evt.target.value;
                }
            }
            return newElement;
        });
        setProductsStocktake(newProducts);
    }

    const deleteProduct = (idProduct) => {
        const newProductsFound = productsStocktake.filter(elementProduct => {
            if (`${elementProduct._id}${elementProduct.rack}`!==`${idProduct._id}${idProduct.rack}`) {
                return elementProduct;
            }
        });
        setProductsStocktake(newProductsFound);
    }

    const addStocktake = (isDisabled, stocktakeModel) => {
        if (!isDisabled) {
            setLoader(true);
            saveInventoryStocktake(stocktakeModel).then(res => {
                setLoader(false);
                if (res.status === 200) {
                    toast.success("Stocktake added successfully", alertOptions);
                    navigate("/inventory/stocktake");
                } else {
                    toast.warning(res.response.data.message, alertOptions);
                }
            }).catch(error => {
                toast.warning(error.response.data.message, alertOptions);
            });
        } else {
            toast.warning("Not possible to add or edit, read-only module", alertOptions);
        }
    }

    useEffect(() => {
        setLoader(true);
        if (params && params.id) {
            getInventoryStocktakeId(params.id).then(res => {
                if (res.status === 200) {
                    setProductShow(res.data);
                }
            });
        }

        getWarehouses().then(res => {
            if (res.status === 200 && res.data.length > 0) {
                const warehousesCurrent = res.data.filter(element => {
                    if (dataSession.allWarehouse.length > 0) {
                        const foundWH = dataSession.allWarehouse.find(elem => element?._id === elem._id);
                        if (!element?.deleted && foundWH) return element;
                    } else if (dataSession.allWarehouse.length === 0 && dataSession.userType === "ADMIN") {
                        if (element?.deleted === false) return element;
                    }
                });
                setWarehouseOptions(warehousesCurrent);
            } else {
                setWarehouseOptions([]);
            }
        });
        setLoader(false);
    }, []);

    return (
        <>
            { loader ? <Loader /> : null }
            
            { showModal.status ?
                <ProductsAvailable
                    typeView="stocktake"
                    IdWarehouse={showModal.data}
                    currentProducts={productsStocktake}
                    onProducts={onResponseProducts}
                    onClose={() => setShowModal({ data: "", status: false })}
                    onCompatible={(prdSel) => setShowCompatible({...showCompatible, status: true, element: prdSel})}
                />
            :
                null
            }

            {
                showCompatible.status ?
                    <ModalCompatible
                        products={showCompatible.element}
                        onClose={() => setShowCompatible({...showCompatible, status: false, element: null})}
                    />
                : null
            }

            <div>
                <div className="flex flex-row">
                    <div className="w-full bg-no-repeat bg-cover fixed z-10">
                        <Navbar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                    </div>
                </div>
                <div className="flex h-screen overflow-hidden">
                    <div className={`${sidebarOpen ? "w-[100vw] md:w-80 z-20" : "w-20 z-0"}`}>
                        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                    </div>
                    <div className="w-full overflow-auto flex-grow mt-[100px]">
                        <div className="space-y-5 px-3 py-5 md:px-8 md:py-8">
                            <div className="grid grid-cols-4 md:grid-cols-12 px-0 md:px-5">
                                <div>
                                    <Link to="/inventory/stocktake">
                                        <button className="p-1 rounded-md" type="button">
                                            <span className="text-ag-secondary-dark text-3xl"><FiChevronLeft /></span>
                                        </button>
                                    </Link>
                                </div>
                                <div className="flex justify-center items-center col-span-2 md:col-span-11 text-center">
                                    <h1 className="text-[26px] md:text-xl lg:text-[26px] font-light text-ag-secondary md:mr-[8%]">
                                        Stocktake
                                    </h1>
                                </div>
                                <div></div>
                            </div>
                            <div className="py-3">
                                <FormStocktake
                                    productShow={productShow}
                                    warehouses={warehouseOptions}
                                    reasons={reasonOptions}
                                    products={productsStocktake}
                                    onSave={addStocktake}
                                    onProducts={handleProduct}
                                    onAccept={handleDown}
                                    onDelete={deleteProduct}
                                    onCompatible={(prdSel) => setShowCompatible({...showCompatible, status: true, element: prdSel})}
                                    onShowModal={(id) => {
                                        if (id === "") {
                                            toast.warning("Please select a warehouse", alertOptions);
                                        } else {
                                            setShowModal({...showModal, data: id, status: true});
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SaveStocktake;