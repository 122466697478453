import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PermitsContext } from "../../../../services/Permits";
import { getProducts } from "../../../../services/Product";
import { FiChevronLeft } from "react-icons/fi";
import { formatAMPM, formatDate, removeAccents } from "../../../../utils/Utilities";
import Navbar from "../../../../components/navbar/Navbar";
import Sidebar from "../../../../components/sidebar/Sidebar";
import Filters from "./Filters";
import TablePriceHistory from "./TablePriceHistory";
import Loader from "../../../../components/loader/Loader";
import ModalCompatible from "../ModalCompatible";

const PriceHistory = () => {
    const { permits } = useContext(PermitsContext);
    const navigate = useNavigate();

    const [loader, setLoader] = useState(false);
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const [prices, setPrices] = useState([]);
    const [pricesUniv, setPricesUniv] = useState([]);
    const [showCompatible, setShowCompatible] = useState({ status: false, element: null });
    const [products, setProducts] = useState([]);
    const [yearOptions, setYearOptions] = useState([]);
    const [monthOptions] = useState([
        { label: "January", value: 1 },
        { label: "February", value: 2 },
        { label: "March", value: 3 },
        { label: "April", value: 4 },
        { label: "May", value: 5 },
        { label: "June", value: 6 },
        { label: "July", value: 7 },
        { label: "August", value: 8 },
        { label: "September", value: 9 },
        { label: "October", value: 10 },
        { label: "November", value: 11 },
        { label: "December", value: 12 },
    ]);

    const handleFilters = (wordFilter, value) => {
        if (wordFilter === "" && value.year === "" && value.month === "") {
            getData();
        } else {
            let pricesFound = pricesUniv;

            if (wordFilter !== "") {
                let palabra = new RegExp(`${removeAccents(wordFilter)}.*`, "i");
                pricesFound = pricesFound.filter(element => (
                    palabra.test(removeAccents(element?.nags)) ||
                    palabra.test(removeAccents(element?.name)) ||
                    palabra.test(removeAccents(element?.description)) ||
                    palabra.test(removeAccents(element?.note)) ||
                    palabra.test(element?.vendors && element?.vendors.length ? removeAccents(element?.vendors[0].name) : "") ||
                    palabra.test(element?.price) ||
                    palabra.test(removeAccents(element?.barcode1)) ||
                    palabra.test(removeAccents(element?.barcode2)) ||
                    palabra.test(element?.totalQuantity) ||
                    palabra.test(removeAccents(element?.role)) ||
                    palabra.test(removeAccents(element?.user)) ||
                    palabra.test(formatAMPM(element?.hour)) ||
                    palabra.test(formatDate(element?.date))
                ));
            }

            if (value.year !== "") {
                pricesFound = pricesFound.filter(element => (`${new Date(element.date).getFullYear()}` === value.year));
            }

            if (value.month !== "") {
                pricesFound = pricesFound.filter(element => (`${(new Date(element.date).getMonth()+1)}` === value.month));
            }

            setPrices(pricesFound);
        }
    }

    const getOptions = (array_prices) => {
        let years = [];
        for (let i = 0; i < array_prices.length; i++) {
            const yearsFound = years.find(element => element.value === `${new Date(array_prices[i].date).getFullYear()}`);

            if (!yearsFound) {
                years.push({
                    label: `${new Date(array_prices[i].date).getFullYear()}`,
                    value: `${new Date(array_prices[i].date).getFullYear()}`
                });
            }
        }
        setYearOptions(years);
    }

    const getData = async () => {
        try {
            const res = await getProducts();
            if (res.status === 200 && res.data.length > 0) {
                setProducts(res.data);
                setLoader(false);
    
                let arrPricesPerProduct = [];
                for (let i = 0; i < res.data.length; i++) {
                    for (let j = 0; j < res.data[j].priceHistory.length; j++) {
                        arrPricesPerProduct.push({
                            nags: res.data[i].nags,
                            name: res.data[i].name,
                            compatible_vehicles: res.data[i].compatible_vehicles,
                            description: res.data[i].description,
                            note: res.data[i]?.note,
                            vendors: res.data[i].vendors,
                            price: res.data[i].priceHistory[j].price,
                            productInventory: res.data[i].productInventory,
                            barcode1: res.data[i].barcode1,
                            barcode2: res.data[i].barcode2,
                            totalQuantity: res.data[i].totalQuantity,
                            date: res.data[i].priceHistory[j].createdAt,
                            hour: res.data[i].priceHistory[j].createdAt,
                            role: res.data[i]?.by?.role?.name,
                            user: `${res.data[i]?.by?.names} ${res.data[i]?.by?.lastNames ? res.data[i]?.by?.lastNames : ""}`,
                        });
                    }
                }
                setPrices(arrPricesPerProduct);
                setPricesUniv(arrPricesPerProduct);
            } else {
                setProducts([]);
                setPrices([]);
                setPricesUniv([]);
                setLoader(false);
            }
        } catch (error) {
            console.error("Error fetching products:", error);
            setProducts([]);
            setPrices([]);
            setPricesUniv([]);
            setLoader(false);
        }
    };
    

    useEffect(() => {
        if (permits['PRODUCTS']['HISTORY']) {
            setLoader(true);
            getData();
        } else {
            navigate("/products");
        }
    }, []);

    useEffect(() => {
        getOptions(pricesUniv);
    }, [pricesUniv])

    return (
        <>
            { loader ? <Loader /> : null }

            {
                showCompatible.status ?
                    <ModalCompatible
                        products={showCompatible.element}
                        onClose={() => setShowCompatible({...showCompatible, status: false, element: null})}
                    />
                : null
            }

            <div>
                <div className="flex flex-row">
                    <div className="w-full bg-no-repeat bg-cover fixed z-10">
                        <Navbar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                    </div>
                </div>
                <div className="flex h-screen overflow-hidden">
                    <div className={`${sidebarOpen ? "w-[100vw] md:w-80 z-20" : "w-20 z-0"}`}>
                        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                    </div>
                    <div className="w-full overflow-auto flex-grow mt-[100px]">
                        <div className="space-y-5 px-3 py-5 md:px-8 md:py-8">
                            <div className="grid grid-cols-4 md:grid-cols-12 px-0 md:px-5">
                                <div>
                                    <button className="rounded-md" type="button" onClick={() => navigate(-1)}>
                                        <span className="text-ag-secondary-dark text-3xl"><FiChevronLeft /></span>
                                    </button>
                                </div>
                                <div className="flex justify-center items-center col-span-2 md:col-span-11 text-center">
                                    <h1 className="text-[26px] md:text-xl lg:text-[26px] font-light text-ag-secondary md:mr-[8%]">
                                        Price History
                                    </h1>
                                </div>
                                <div></div>
                            </div>
                            <div className="pt-3">
                                <Filters
                                    enableFilters={permits['PRODUCTS']['FILTER']}
                                    productsOptions={products}
                                    monthOptions={monthOptions}
                                    yearOptions={yearOptions}
                                    setPrices={setPrices}
                                    setPricesUniv={setPricesUniv}
                                    onFilters={handleFilters}
                                />
                            </div>
                            <div className="overflow-auto">
                                { 
                                    pricesUniv.length > 0 ?
                                        <TablePriceHistory
                                            arrPriceHistory={prices}
                                            onCompatible={(prdSel) => setShowCompatible({...showCompatible, status: true, element: prdSel})}
                                        />
                                    : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PriceHistory;