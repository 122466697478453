import { FiChevronRight, FiChevronLeft } from "react-icons/fi";
import { useEffect, useState } from "react";
import { Bar, BarChart, CartesianGrid, Legend, Tooltip, XAxis, YAxis } from "recharts";

const BarsGraphics = ({ data, year }) => {
    const [yearsData, setYearsData] = useState({ current: "-----", range: false, min: 0, max: 0 });

    useEffect(() => {
        if (year === "-----") {
            setYearsData({...yearsData, current: "-----", range: false, min: 0, max: 0});
        } else {
            if (year.first === year.last) {
                setYearsData({...yearsData, current: year.first, range: false, min: 0, max: 0});
            } else {
                setYearsData({...yearsData, current: year.first, range: false, min: year.first, max: year.last});
            }
        }
    }, [year]);
    
    return (
        <div className="space-y-3">
            <div className="flex justify-center items-center space-x-5">
                {
                    yearsData.range ?
                        <span className="text-ag-secondary-letter text-2xl cursor-pointer">
                            <FiChevronLeft />
                        </span>
                    : null
                }
                <span className="text-red-500 font-bold text-xs xl:text-base">
                    { yearsData.current }
                </span>
                {
                    yearsData.range ?
                        <span className="text-ag-secondary-letter text-2xl cursor-pointer">
                            <FiChevronRight />
                        </span>
                    : null
                }
            </div>
            <div className="border border-ag-secondary-light px-3 pt-5 pb-8 space-y-3 rounded-xl">
                <div className="w-full text-center">
                    <span className="text-red-500 font-bold text-xs xl:text-base">
                        Sales
                    </span>
                </div>
                <div className="w-full overflow-x-auto">
                    <BarChart className="m-auto z-[-1]" width={800} height={300} data={data} margin={{ top: 5, right: 5, left: 5, bottom: 5 }}>
                        <CartesianGrid horizontal={true} vertical={false} />
                        <XAxis dataKey="month" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="quantity" fill="#00A829" />
                    </BarChart>
                </div>
            </div>
        </div>
    );
}

export default BarsGraphics;