import { IoMdLock, IoMdUnlock, IoMdImage } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { formatCommaSeparate, formatCurrency, parseTextTypeGlass } from "../../../utils/Utilities";
import IconPlinkintong from "../../../assets/icons/logo_plinkintong_green.png";

const RowsInventory = ({ enableEdit, enableRecord, enableOrderQuery, inventory, onHistory, onStatus, onImage, onPrice, onOrder, onCompatible }) => {
    const navigate = useNavigate();

    const changeStatus = (stsProduct) => {
        const newModel = {
            warehouse: { _id: inventory.warehouse._id },
            productInventory: {
                _id: inventory.productInventory._id,
                status: stsProduct
            }
        };
        onStatus(newModel);
    };

    return (
        <tr>
            <td className="w-[25%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs p-2">
                <div className="flex space-x-3">
                    <div className="flex justify-center items-center">
                        { inventory.productInventory.status === "active" ?
                            <button type="button" onClick={() => changeStatus("dead")}>
                                <span className="text-green-600 text-3xl"><IoMdLock /></span>
                            </button>
                            :
                            <button type="button" onClick={() => changeStatus("active")}>
                                <span className="text-red-600 text-3xl"><IoMdUnlock /></span>
                            </button>
                        }
                        <button type="button" onClick={() => onImage(inventory.productInventory.product.productImages)}>
                            <span className="text-3xl">
                                {
                                    inventory.productInventory.product?.productImages[0] ?
                                        <div className="w-[35px] h-[35px] flex justify-center items-center border border-ag-secondary-light rounded-lg p-1">
                                            <img className="w-full h-full object-contain" src={inventory.productInventory.product.productImages[0]} />
                                        </div>
                                        :
                                        <IoMdImage />
                                }
                            </span>
                        </button>
                    </div>
                    <div className="space-y-2">
                        <div className="text-left">
                            <div>
                                <p className="text-ag-secondary-letter text-xs truncate w-56">{ inventory.productInventory.product.nags } { inventory.productInventory.product.name }</p>
                            </div>
                            <div>
                                {
                                    inventory.productInventory.product.compatible_vehicles.map((prodComp, i) => {
                                        if (i <= 2) {
                                            return (
                                                <p className="truncate w-48" key={i} title={
                                                    `${String(prodComp?.year).replace(/\s+/g, "").split(",").length > 1 ? `${String(prodComp?.year).replace(/\s+/g, "").split(",").sort()[0]} - ${String(prodComp?.year).replace(/\s+/g, "").split(",").sort()[Number(String(prodComp?.year).replace(/\s+/g, "").split(",").length-1)]}` : prodComp?.year} ` +
                                                    `${prodComp?.brand} ` +
                                                    `${prodComp?.model} ` +
                                                    `${prodComp?.type_car}`
                                                }>
                                                    { String(prodComp?.year).replace(/\s+/g, "").split(",").length > 1 ? `${String(prodComp?.year).replace(/\s+/g, "").split(",").sort()[0]} - ${String(prodComp?.year).replace(/\s+/g, "").split(",").sort()[Number(String(prodComp?.year).replace(/\s+/g, "").split(",").length-1)]}` : prodComp?.year }{" "}
                                                    { prodComp?.brand }{" "}
                                                    { prodComp?.model }{" "}
                                                    { prodComp?.type_car }
                                                </p>
                                            );
                                        }
                                    })
                                }
                                {
                                    inventory.productInventory.product.compatible_vehicles.length > 3 ?
                                        <p className="truncate w-48 font-semibold cursor-pointer" onClick={() => onCompatible(inventory.productInventory.product)}>
                                            See compatible vehicles +
                                        </p>
                                        :  null
                                }
                            </div>
                            <div className="pt-3">
                                <span>{ parseTextTypeGlass(inventory.productInventory.product.glassType) }</span>
                            </div>
                        </div>
                        <div className="text-left">
                            <span className="text-ag-secondary-letter text-[10px]">
                                <b>Vendors:</b>{" "}
                                {
                                    inventory.productInventory.product.vendors.map((vendor, i) => {
                                        if ((i+1) === inventory.productInventory.product.vendors.length) {
                                            return `${vendor.name}`;
                                        }

                                        return `${vendor.name}, `;
                                    })
                                }
                            </span>
                        </div>
                        <div className="text-left">
                            <span className="text-ag-secondary-letter text-[10px]">
                                <b>Racks:</b>{" "}
                                {
                                    inventory.racks.map((rack, i) => {
                                        if ((i+1) === inventory.racks.length) {
                                            return `${rack.rack.name}`;
                                        }

                                        return `${rack.rack.name}, `;
                                    })
                                }
                            </span>
                        </div>
                    </div>
                </div>
            </td>
            <td className="w-[9%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs p-2">
                {
                    inventory.productInventory.product.compatible_vehicles.map((prodComp, i) => {
                        if (i <= 2) {
                            return (
                                <p className="truncate w-32" key={i} title={
                                    `${String(prodComp?.year).replace(/\s+/g, "").split(",").length > 1 ? `${String(prodComp?.year).replace(/\s+/g, "").split(",").sort()[0]} - ${String(prodComp?.year).replace(/\s+/g, "").split(",").sort()[Number(String(prodComp?.year).replace(/\s+/g, "").split(",").length-1)]}` : prodComp?.year} ` +
                                    `${prodComp?.brand} ` +
                                    `${prodComp?.model} ` +
                                    `${prodComp?.type_car}`
                                }>
                                    { String(prodComp?.year).replace(/\s+/g, "").split(",").length > 1 ? `${String(prodComp?.year).replace(/\s+/g, "").split(",").sort()[0]} - ${String(prodComp?.year).replace(/\s+/g, "").split(",").sort()[Number(String(prodComp?.year).replace(/\s+/g, "").split(",").length-1)]}` : prodComp?.year }{" "}
                                    { prodComp?.brand }{" "}
                                    { prodComp?.model }{" "}
                                    { prodComp?.type_car }
                                </p>
                            );
                        }
                    })
                }
                {
                    inventory.productInventory.product.compatible_vehicles.length > 3 ?
                        <p className="truncate w-32 font-semibold cursor-pointer" onClick={() => onCompatible(inventory.productInventory.product)}>
                            See compatible vehicles +
                        </p>
                        : null
                }
            </td>
            <td className="w-[14%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs p-2">
                <p className="w-28 truncate m-auto" title={inventory.productInventory.product.description}>
                    { inventory.productInventory.product.description }
                </p>
            </td>
            <td className="w-[15%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs p-2">{ inventory.productInventory.product.features }</td>
            <td className="w-[10%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs p-2">
                <p className="w-20 truncate m-auto" title={inventory.warehouse.name}>
                    { inventory.warehouse.name }
                </p>
            </td>
            <td className="w-[8%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs p-2">{ formatCommaSeparate(inventory.totalQuantity) }</td>
            <td className="w-[8%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs p-2">{ formatCurrency(inventory.productInventory.cost) }</td>
            <td className="w-[11%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs p-2">
                <div className="space-y-1">
                    <button
                        className="w-full bg-ag-secondary text-white text-xs rounded-full py-[6px] disabled:bg-zinc-200"
                        type="button"
                        disabled={!enableEdit}
                        onClick={() => navigate(`/inventory/element/${inventory.productInventory._id}`)}
                    >
                        Edit
                    </button>
                    <button
                        className="w-full bg-ag-secondary text-white text-xs rounded-full py-[6px] disabled:bg-zinc-200"
                        type="button"
                        disabled={!enableRecord}
                        onClick={() => onHistory(inventory.productInventory._id)}
                    >
                        Record
                    </button>
                    <button
                        className="w-full bg-ag-secondary text-white text-xs rounded-full py-[6px] disabled:bg-zinc-200"
                        type="button"
                        disabled={!enableOrderQuery}
                        onClick={() => onOrder(inventory.productInventory?._id)}
                    >
                        Order Query
                    </button>
                    <button
                        className="w-full bg-[#199B5D] text-white text-xs rounded-full py-[6px]"
                        type="button"
                        onClick={() => onPrice(inventory.productInventory.product.nags)}
                    >
                        <img className="w-20 m-auto" src={IconPlinkintong} />
                    </button>
                </div>
            </td>
        </tr>
    );
};

export default RowsInventory;