import { useEffect, useState } from "react";
import { formatCurrency, formatDateEng } from "../../../utils/Utilities";

const RowsOrders = ({ report }) => {
    const [productsData, setProductsData] = useState({ quantity: "", product: "" });

    useEffect(() => {
        let quantityAux = 0;
        let productsAux = "";

        for (let i = 0; i < report.productsOrder.length; i++) {
            const element = report.productsOrder[i];
            quantityAux += Number(element.quantity);

            if (i === 0) productsAux += `${element?.productInventory?.product?.nags}`;
            else productsAux += `, ${element?.productInventory?.product?.nags}`;
        }
        
        setProductsData({...productsAux, quantity: quantityAux, product: productsAux});
    }, [report]);

    return (
        <tr>
            <td className="w-[15%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 py-3">
                <p className="truncate w-44 m-auto">{ report?.warehouse?.name }</p>
            </td>
            <td className="w-[15%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 py-3">
                <p className="truncate w-44 m-auto">{ report?.employee ? report?.employee?.role?.name : "-----" }</p>
            </td>
            <td className="w-[15%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 py-3">
                <p className="truncate w-44 m-auto">
                    {
                        report?.wholesalerUser ? `${report?.wholesalerUser?.names} ${report?.wholesalerUser?.lastNames ? report?.wholesalerUser?.lastNames : ""}` : 
                        report?.retailUser ? `${report?.retailUser?.names} ${report?.retailUser?.lastNames ? report?.retailUser?.lastNames : ""}` :
                        "-----"
                    }
                </p>
            </td>
            <td className="w-[8%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 py-3">
                <p className="truncate w-20 m-auto">{ report?.sale ? `SN-${String(report?.sale?._id).substring(String(report?.sale?._id).length - 5).toLocaleUpperCase()}` : "-----" }</p>
            </td>
            <td className="w-[8%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 py-3">
                <p className="truncate w-20 m-auto">{ `ON-${String(report?._id).substring(String(report?._id).length - 5).toLocaleUpperCase()}` }</p>
            </td>
            <td className="w-[8%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 py-3">
                <p className="truncate w-20 m-auto">{ formatDateEng(report?.createdAt) }</p>
            </td>
            <td className="w-[8%] border-[0.5px] border-ag-secondary-light text-xs font-normal px-2 py-3">
                <p className="truncate w-20 m-auto">{ formatCurrency(report?.total) }</p>
            </td>
            <td className="w-[8%] border-[0.5px] border-ag-secondary-light text-xs font-normal px-2 py-3">
                <p className="truncate w-20 m-auto">{ productsData.quantity }</p>
            </td>
            <td className="w-[15%] border-[0.5px] border-ag-secondary-light text-xs font-normal px-2 py-3">
                <p className="truncate w-44 m-auto">{ productsData.product }</p>
            </td>
        </tr>
    );
}

export default RowsOrders;