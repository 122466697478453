import { formatCurrency } from "../../../utils/Utilities";
import { AiFillCloseSquare } from "react-icons/ai";

const InformationSales = ({ products, clientCurrent, subtotal, total, onCancel, onDelete, onPay, creditsToPay }) => {
    return (
        <div className="w-full lg:w-[250px] xl:w-[400px] 3xl:w-[500px] pb-10 md:pb-0 space-y-3">
            <div className="p-4 border border-ag-secondary-light rounded-xl">
                <div className="pb-5">
                    <span className="font-semibold text-base lg:text-sm xl:text-base">
                        {
                            clientCurrent !== null ? clientCurrent?.user?.userType === "RETAIL_USER" ?
                                `${clientCurrent?.names} ${clientCurrent?.lastNames ? clientCurrent?.lastNames : ""}`
                            : clientCurrent?.workshopName : "Workshop name"
                        }
                    </span>
                </div>
                <div className="py-3 border-y border-ag-secondary-light flex justify-between items-center">
                    <span className="font-semibold text-base lg:text-sm xl:text-base">Credits:</span>
                    <span className="text-base lg:text-sm xl:text-base">
                        { clientCurrent !== null && clientCurrent?.availableCredit ? formatCurrency(clientCurrent?.availableCredit) : "$0.00" }
                    </span>
                </div>
                {
                    creditsToPay > 0 ?
                        <div className="py-3 border-b border-ag-secondary-light flex justify-between items-center">
                            <span className="font-semibold">Credits to pay:</span>
                            <span>{ formatCurrency(creditsToPay)}</span>
                        </div>
                    : null
                }
                {
                    products.length > 0 ? 
                        <div className="space-y-5 py-3 border-b border-ag-secondary-light">
                            <ul>
                                <li className="w-full flex justify-between items-center">
                                    <span className="w-[50%] text-base lg:text-sm xl:text-base font-semibold">Product</span>
                                    <span className="w-[30%] text-base lg:text-sm xl:text-base font-semibold">QTY</span>
                                    <span className="w-[20%]"></span>
                                </li>
                                { products.map((product, i) => (
                                    <li className="w-full flex justify-between items-center" key={i}>
                                        <span className="w-[50%] text-base lg:text-sm xl:text-base">{ product.productInventory.nags }</span>
                                        <span className="w-[30%] text-base lg:text-sm xl:text-base">{ product.quantity }</span>
                                        <span className="w-[20%] flex justify-center text-red-600 text-2xl cursor-pointer" onClick={() => onDelete(i)}>
                                            <AiFillCloseSquare />
                                        </span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    : null
                }
                <div className="space-y-5 py-3 border-b border-ag-secondary-light">
                    <div className="flex justify-between items-center">
                        <span className="text-base lg:text-sm xl:text-base font-semibold">Subtotal:</span>
                        <span className="text-base lg:text-sm xl:text-base font-semibold">{ formatCurrency(subtotal) }</span>
                    </div>
                    <div className="flex justify-between items-center">
                        <span className="text-base lg:text-sm xl:text-base font-semibold">Total:</span>
                        <span className="text-base lg:text-sm xl:text-base font-semibold">{ formatCurrency(total) }</span>
                    </div>
                </div>
                <div className="pt-3 pb-1 flex justify-between items-center space-x-3">
                    <button className="w-full bg-ag-primary-light text-base lg:text-sm xl:text-base text-white px-6 py-3 rounded-xl" type="button" onClick={() => onCancel(true)}>
                        Cancel
                    </button>
                    <button className="w-full bg-green-600 text-base lg:text-sm xl:text-base text-white px-6 py-3 rounded-xl" type="button" onClick={() => onPay(true)}>
                        Pay
                    </button>
                </div>
            </div>
        </div>
    );
}

export default InformationSales;