import { useContext, useEffect, useState } from "react";
import { searchInventoryAdd, updateInventoryAddStatus } from "../../../services/Inventory";
import { getWarehouses } from "../../../services/Warehouse";
import { toast } from "react-toastify";
import { alertOptions } from "../../../utils/Utilities";
import { PermitsContext } from "../../../services/Permits";
import Navbar from "../../../components/navbar/Navbar";
import Sidebar from "../../../components/sidebar/Sidebar";
import Filters from "./Filters";
import TableInventory from "./TableInventory";
import MovementHistory from "./MovementHistory";
import Loader from "../../../components/loader/Loader";
import Modal from "../../../components/modal/Modal";
import Images from "../../../components/carrousel/Images";
import PricePlinkington from "../../fragments/PricePlinkington";
import ModalOrders from "./ModalOrders";
import ModalCompatible from "../products/ModalCompatible";

const Inventory = () => {
    const { permits, dataSession, clearContext, logOutModal, setLogOutModal } = useContext(PermitsContext);
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const [loader, setLoader] = useState(true);
    const [inventory, setInventory] = useState([]);
    const [warehouses, setWarehouses] = useState([]);
    const [statusOptions] = useState([
        { label: "Active", value: "active" },
        { label: "Dead", value: "dead" }
    ]);
    const [page, setPage] = useState(1);
    const [pagesData, setPagesData] = useState({ total: 0, records: 0 });
    const [filterPagination, setFilterPagination] = useState({ data: {}, status: false });
    const [showModal, setShowModal] = useState({ status: false, element: null });
    const [showHistory, setShowHistory] = useState({ data: "", status: false });
    const [showCompatible, setShowCompatible] = useState({ status: false, element: null });
    const [showImages, setShowImages] = useState({ status: false, images: [] });
    const [showPrice, setShowPrice] = useState({ status: false, data: "" });
    const [showOrderQuery, setShowOrderQuery] = useState({ status: false, data: "" });

    const handleFilters = (filtersParams) => {
        let filterObject = {};
        if (
            filtersParams.warehouse === "" && filtersParams.model === "" && filtersParams.brand === "" && 
            filtersParams.year === "" && filtersParams.nag === "" && filtersParams.status === "" && filtersParams.search === ""
        ) {
            setPage(1);
            setFilterPagination({...filterPagination, data: {}, status: false});
            searchInventoryForPage(1, {});
        } else {
            for (const key in filtersParams) {
                if (Object.hasOwnProperty.call(filtersParams, key)) {
                    if (filtersParams[key] !== "" && filtersParams[key] !== "default") filterObject[key] = filtersParams[key];
                }
            }
            setPage(1);
            setFilterPagination({...filterPagination, data: filterObject, status: true});
            searchInventoryForPage(1, filterObject);
        }
    }

    const searchInventoryForPage = (pageInventory, paramsFilters) => {
        setLoader(true);
        searchInventoryAdd(pageInventory, 10, paramsFilters).then(res => {
            if (res.status === 200 && res.data.productsInventory.length > 0) {
                setInventory(res.data.productsInventory);
                setPage(pageInventory);
                setPagesData({...pagesData, total: res.data.totalRecords/10, records: res.data.totalRecords});
            } else {
                toast.warning("Request without records", alertOptions);
            }
            setLoader(false);
        }).catch(() => {
            toast.warning("Request without records", alertOptions);
            setLoader(false);
        });
    }

    const updateStatus = (sts) => {
        if (sts) {
            updateInventoryAddStatus(showModal.element.productInventory._id, showModal.element).then(res => {
                if (res.status === 200) {
                    searchInventoryForPage(page, filterPagination.data);
                    toast.success("Product updated successfully", alertOptions);
                } else {
                    toast.warning(res.response.data.message, alertOptions);
                }
                setShowModal({...showModal, status: false, element: null});
            }).catch(error => {
                toast.warning(error.response.data.message, alertOptions);
            });
        }
    }

    const changePage = (pge) => {
        new Promise((resolve, reject) => {
            searchInventoryForPage(pge, filterPagination.data);
            resolve();
        }).then(() => {
            setPage(pge);
        });
    }

    const handleModalResponse = (sts) => {
        if (sts) {
            clearContext();
            window.location.reload(true);
        }
    };

    useEffect(() => {
        searchInventoryForPage(1, {});
        getWarehouses().then(res => {
            if (res.status === 200 && res.data.length > 0) {
                const warehousesCurrent = res.data.filter(element => !element.deleted);
                setWarehouses(warehousesCurrent);
            }
        }, []);
    }, []);

    return (
        <>
            { loader ? <Loader /> : null }

            { showModal.status ?
                <Modal
                    title={`¿Are you sure you want to change the status to ${ showModal.element.productInventory.status}?`}
                    onClose={() => {setShowModal({...showModal, status: false, element: null}) }}
                    onResponse={updateStatus}
                />
            :
                null
            }

            {
                showHistory.status ?
                    <div>
                        <MovementHistory
                            id={showHistory.data}
                            onClose={() => setShowHistory({...showHistory, data: "", status: false})}
                        />
                    </div>
                : null
            }

            {
                showImages.status ?
                    <div>
                        <Images
                            images={showImages.images}
                            onClose={() => setShowImages({...showImages, status: false, images: []})}
                        />
                    </div>
                : null
            }

            {
                showPrice.status ?
                    <PricePlinkington
                        nagSearch={showPrice.data}
                        onLoader={(val) => setLoader(val)}
                        onClose={() => setShowPrice(false)}
                    />
                : null
            }

            {
                showOrderQuery.status ?
                    <ModalOrders
                        idInventory={showOrderQuery.data}
                        onClose={() => setShowOrderQuery({...showOrderQuery, status: false, data: ""})}
                    />
                : null
            }

            {
                showCompatible.status ?
                    <ModalCompatible
                        products={showCompatible.element}
                        onClose={() => setShowCompatible({...showCompatible, status: false, element: null})}
                    />
                : null
            }

            { logOutModal.status && (
                <Modal
                    title={logOutModal.title}
                    onClose={() =>
                        setLogOutModal({ ...logOutModal, status: false, title: "", element: null })
                    }
                    onResponse={handleModalResponse}
                />
            )}

            <div>
                <div className="flex flex-row">
                    <div className="w-full bg-no-repeat bg-cover fixed z-10">
                        <Navbar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                    </div>
                </div>
                <div className="flex h-screen overflow-hidden">
                    <div className={`${sidebarOpen ? "w-[100vw] md:w-80 z-20" : "w-20 z-0"}`}>
                        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                    </div>
                    <div className="w-full overflow-auto flex-grow mt-[100px]">
                        <div className="space-y-5 px-3 py-5 md:px-8 md:py-8">
                            <div className="grid grid-cols-12 px-0 md:px-5">
                                <div className="p-1"></div>
                                <div className="flex justify-center items-center col-span-11 text-center">
                                    <h1 className="text-[26px] md:text-xl lg:text-[26px] font-light text-ag-secondary md:mr-[8%]">
                                        Inventory
                                    </h1>
                                </div>
                            </div>
                            <div className="pt-3">
                                <Filters
                                    sidebarOpen={sidebarOpen}
                                    enableFilters={permits['INVENTORY']['FILTER']}
                                    enableActions={permits['INVENTORY']['ACTIONS']}
                                    enableWarehouse={dataSession.warehouse}
                                    warehousesOptions={warehouses}
                                    statusOptions={statusOptions}
                                    onFilters={handleFilters}
                                />
                            </div>
                            <div>
                                <TableInventory
                                    enableEdit={permits['INVENTORY']['EDIT']}
                                    enableRecord={permits['INVENTORY']['RECORD']}
                                    enableOrderQuery={permits['INVENTORY']['ORDER_QUERY']}
                                    arrInventory={inventory}
                                    currntPg={page}
                                    pagesData={pagesData}
                                    onPage={(pg) => changePage(pg)}
                                    onHistory={(id) => setShowHistory({...showHistory, data: id, status: true})}
                                    onStatus={(inventoryElement) => setShowModal({...showModal, status: true, element: inventoryElement })}
                                    onImage={(imagesElement) => setShowImages({...showImages, status: true, images: imagesElement})}
                                    onPrice={(nagRes) => setShowPrice({...showPrice, status: true, data: nagRes})}
                                    onOrder={(idInv) => setShowOrderQuery({...showOrderQuery, status: true, data: idInv})}
                                    onCompatible={(prdSel) => setShowCompatible({...showCompatible, status: true, element: prdSel})}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Inventory;