import { formatCurrency, formatDateHourOrders } from "../../../utils/Utilities";

const arrStatus = {
    CANCELED: { text: "Canceled", color: "bg-ag-order-canceled" },
    APPROVED: { text: "Approved", color: "bg-ag-order-delivered" },
    INPROCESS: { text: "In process", color: "bg-ag-order-pending" },
    FINISHED: { text: "Finished", color: "bg-ag-order-paid" },
}

const RowsReturns = ({ enableActions, returnElement, onDetail, onInventory }) => {
    return (
        <tr>
            <td className="w-[12%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-6">
                <ul>
                    <li>{ formatDateHourOrders(returnElement.createdAt) }</li>    
                    <li>
                        <p className="truncate w-32 m-auto">{`ID: ${String(returnElement?._id).substring(String(returnElement?._id).length - 13).toLocaleUpperCase()}`}</p>
                    </li>
                </ul>
            </td>
            <td className="w-[12%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-6">
                <p className="truncate w-32 m-auto">{ returnElement.wholesaler.names }</p>
            </td>
            <td className="w-[10%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-6">
                <p className="truncate w-32 m-auto">{ returnElement.productOrder.productInventory.product.nags }</p>
            </td>
            <td className="w-[12%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-6">
                <p className="truncate w-32 m-auto">{ returnElement.warehouse.name }</p>
            </td>
            <td className="w-[12%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-6">
                <p className="truncate w-32 m-auto">{ returnElement.reason }</p>
            </td>
            <td className="w-[6%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-6">
                <p className="truncate w-32 m-auto">{ formatCurrency(returnElement?.total) }</p>
            </td>
            <td className={`w-[10%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-6 ${ returnElement.status === "APPROVED" && returnElement?.productReturn ? arrStatus.FINISHED.color : arrStatus[returnElement.status]?.color }`}>
                { returnElement.status === "APPROVED" && returnElement?.productReturn ? arrStatus.FINISHED.text : arrStatus[returnElement.status]?.text }
            </td>
            <td className="w-[12%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-6">
                <p className="truncate w-32 m-auto">{ returnElement.requestedBy ? `${returnElement.requestedBy?.names} ${returnElement.requestedBy?.lastNames}` : "-----" }</p>
            </td>
            <td className="w-[14%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-3">
                {
                    returnElement.status === "INPROCESS" ?
                        <button className="bg-ag-secondary text-white px-6 py-3 rounded-xl disabled:bg-zinc-200" disabled={!enableActions} type="button" onClick={() => onDetail(returnElement)}>
                            View Details
                        </button>
                    : null
                }
            </td>
        </tr>
    );
}

export default RowsReturns;