import { formatDateWordEng } from "../../../../utils/Utilities";
import { ReactComponent as IconTicket } from "../../../../assets/icons/ticket.svg";
import { Link } from "react-router-dom";

const RowsStocktake = ({ stocktake }) => {
    return (
        <tr>
            <td className="w-[20%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 py-3">
                { `${String(stocktake?._id).substring(String(stocktake?._id).length - 3).toLocaleUpperCase()}` }
            </td>
            <td className="w-[20%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 py-3">
                { formatDateWordEng(stocktake.createdAt) }
            </td>
            <td className="w-[20%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 py-3">
                <p className="truncate w-52 m-auto">{ stocktake.warehouse[0].name }</p>
            </td>
            <td className="w-[20%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 py-3">
                { stocktake.reason }
            </td>
            <td className="w-[20%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 py-3">
                <Link to={`/inventory/stocktake/element/${stocktake._id}`}>
                    <button type="button">
                        <span>
                            <IconTicket />
                        </span>
                    </button>
                </Link>
            </td>
        </tr>
    );
}

export default RowsStocktake;