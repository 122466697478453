import { useContext, useEffect, useState } from "react";
import { PermitsContext } from "../../../services/Permits";
import { attendOrder, cancelOrder, getOrders, paidOrder } from "../../../services/Orders";
import { alertOptions, formatReverseDate } from "../../../utils/Utilities";
import { getWholesalerUsers } from "../../../services/Wholesaler";
import { getRetailUsers } from "../../../services/Retail";
import { getWarehouses } from "../../../services/Warehouse";
import { BiSearch } from "react-icons/bi";
import { toast } from "react-toastify";
import { getProducts } from "../../../services/Product";
import { searchInventoryAdd } from "../../../services/Inventory";
import Navbar from "../../../components/navbar/Navbar";
import Sidebar from "../../../components/sidebar/Sidebar";
import Filters from "./Filters";
import TableOrders from "./TableOrders";
import Loader from "../../../components/loader/Loader";
import ModalOrders from "../../../components/modal/ModalOrders";
import ModalFormProduct from "../quotes/withoutInventory/ModalFormProduct";
import ModalFormInventory from "../quotes/withoutInventory/ModalFormInventory";
import ModalCompatible from "../products/ModalCompatible";
import MyCartFeatures from "../../wholesaler/mycart/MyCartFeatures";
import Modal from "../../../components/modal/Modal";

const Orders = () => {
    const { permits, dataSession, clearContext, logOutModal, setLogOutModal } = useContext(PermitsContext);
    const [loader, setLoader] = useState(true);
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const [showModal, setShowModal] = useState({ status: false, type: "", data: "" });
    const [showFormProduct, setShowFormProduct] = useState({ status: false, data: null });
    const [showFormInventory, setShowFormInventory] = useState({ status: false, data: null, type: "", mod: "", orderId: "" });
    const [showCompatible, setShowCompatible] = useState({ status: false, element: null });
    const [showFeatures, setShowFeatures] = useState({ status: false, element: null });
    const [page, setPage] = useState(1);
    const [pagesData, setPagesData] = useState({ total: 0, records: 0 });
    const [filterPagination, setFilterPagination] = useState({ data: { sort: "DESC" }, status: false });
    const [orders, setOrders] = useState([]);
    const [orderCurrent, setOrderCurrent] = useState("");
    const [searchWord, setSearchWord] = useState(null);
    const [warehouseOptions, setWarehouseOptions] = useState([]);
    const [clientOptions, setClientOptions] = useState([]);
    const [statusOptions] = useState([
        { label: "Attended", value: "attend" },
        { label: "Canceled", value: "canceled" },
        { label: "Delivered", value: "delivered" },
        { label: "In process", value: "inprocess" },
        { label: "Paid", value: "paid" },
        { label: "Pending", value: "pending" },
    ]);

    const [focusClass, setFocusClass] = useState({
        search: { placeholder: "Search", status: false }
    });

    const handleFilters = (wordFilter, options) => {
        let filterObject = { sort: "DESC" };
        if (options.warehouse === "" && options.status === "" && options.user === "" && options.startDate === "" && options.endDate === "") {
            setPage(1);
            setFilterPagination({...filterPagination, data: { sort: "DESC" }, status: false});
            getData(1, { sort: "DESC" });
        } else {
            if ((options.startDate !== "" && options.endDate === "") || (options.startDate === "" && options.endDate !== "")) {
                toast.info("Please enter both dates to perform the filter", alertOptions);
                return 0;
            } else if (options.startDate !== "" && options.endDate !== "") {
                if (formatReverseDate(options.startDate) > formatReverseDate(options.endDate)) {
                    toast.info("The start date is greater than the end date", alertOptions);
                    return 0;
                }
            }

            for (const key in options) {
                if (Object.hasOwnProperty.call(options, key)) {
                    if (options[key] !== "") filterObject[key] = options[key];
                }
            }
            setPage(1);
            setFilterPagination({...filterPagination, data: filterObject, status: true});
            getData(1, filterObject);
        }
    }

    const handleModal = () => {
        if (showModal.type === "cancel") {
            handleCancel(showModal.data);
        } else {
            handleAttend(showModal.data);
        }
        setShowModal({...showModal, status: false, type: "", data: ""});
    }

    const getData = (pageInventory, paramsFilters) => {
        setLoader(true);
        getOrders(pageInventory, 4, paramsFilters).then(resGet => {
            if (resGet.data?.result && resGet.data?.result.length > 0) {
                let ordersWH = [];
                if (dataSession.userType === "ADMIN") {
                    ordersWH = resGet.data?.result;
                } else {
                    ordersWH = resGet.data?.result.filter(element => {
                        const foundWH = dataSession.allWarehouse.find(elem => element?.warehouse?._id === elem._id);
                        if (foundWH) return element;
                    });
                }
                
                const array_sort = ordersWH.sort((a, b) => (new Date(b.createdAt) - new Date(a.createdAt)));
                setPage(pageInventory);
                setPagesData({...pagesData, total: resGet.data.count/10, records: resGet.data.count});
                setOrders(array_sort);
                // setOrdersUniv(array_sort);
            }
            else {
                toast.warning("Records not found", alertOptions);
            }
            setLoader(false);
        });
    }

    const handlePaid = (id) => {
        setLoader(true);
        paidOrder(id, { _id: id, status: "paid" }).then(res => {
            if (res.status === 200) {
                toast.success("Order paied successfully", alertOptions);
                getData(page, filterPagination.data);
            } else {
                setLoader(false);
                toast.warning(res.response.data.message, alertOptions);
            }
        }).catch(error => {
            setLoader(false);
            toast.warning(error.response.data.message, alertOptions);
        });
    }

    const handleCancel = (id) => {
        setLoader(true);
        cancelOrder(id, { _id: id, status: "canceled" }).then(res => {
            if (res.status === 200) {
                toast.success("Order canceled successfully", alertOptions);
                getData(page, filterPagination.data);
            } else {
                setLoader(false);
                toast.warning(res.response.data.message, alertOptions);
            }
        }).catch(error => {
            setLoader(false);
            toast.warning(error.response.data.message, alertOptions);
        });
    }

    const handleCanAttend = (id) => {
        setShowModal({...showModal, type: "attend", status: true, data: id });
    }

    const handleAttend = (id) => {
        setLoader(true);
        attendOrder(id, { _id: id, employee: { _id: dataSession._id } }).then(res => {
            if (res.status === 200) {
                toast.success("Order attended successfully", alertOptions);
                getData(page, filterPagination.data);
            } else {
                setLoader(false);
                toast.warning(res.response.data.message, alertOptions);
            }
        }).catch(error => {
            setLoader(false);
            toast.warning(error.response.data.message, alertOptions);
        });
    }

    const handleFillFormShow = (productSelected, idOrder, whExist) => {
        setOrderCurrent(idOrder);
        setLoader(true);
        getProducts().then(res => {
            if (res.status === 200) {
                const existProduct = res.data.find(elem => elem?.nags === productSelected.nag);
                if (existProduct) {
                    searchInventoryAdd(1, 10000, { nag: existProduct?.nags, year: existProduct?.year, model: existProduct?.model, brand: existProduct?.brand }).then(resInv => {
                        if (resInv.status === 200 && resInv?.data?.totalRecords > 0) {
                            const productWhCurrent = resInv.data?.productsInventory.find(elem => {
                                if (dataSession.userType === "ADMIN") {
                                    if (elem?.warehouse?._id === whExist?._id) return elem;
                                } else {
                                    const foundWH = dataSession.allWarehouse.find(elemWh => elemWh._id === elem?.warehouse._id && elemWh._id === whExist?._id);
                                    if (foundWH) return elem;
                                }
                            });
                            if (productWhCurrent) {
                                const foundInv = { productInventoryAllInfo: productWhCurrent }
                                setShowFormInventory({...showFormInventory, status: true, data: {...foundInv, whCurrent: whExist }, type: "update", mod: "order", orderId: idOrder});
                                setLoader(false);
                            } else {
                                setShowFormInventory({...showFormInventory, status: true, data: {...existProduct, whCurrent: whExist }, type: "add", mod: "order", orderId: idOrder});
                                setLoader(false);
                            }
                        } else {
                            setLoader(false);
                            setShowFormInventory({...showFormInventory, status: true, data: {...existProduct, whCurrent: whExist }, type: "add", mod: "order", orderId: idOrder});
                        }
                    }).catch(() => {
                        setLoader(false);
                        setShowFormInventory({...showFormInventory, status: true, data: {...existProduct, whCurrent: whExist }, type: "add", mod: "order", orderId: idOrder});
                    });
                } else {
                    setLoader(false);
                    setShowFormProduct({...showFormProduct, status: true, data: {...productSelected, whCurrent: whExist}})
                }
            } else {
                setLoader(false);
                setShowFormProduct({...showFormProduct, status: true, data: {...productSelected, whCurrent: whExist}});
            }
        }).catch(() => setLoader(false));
    }

    const handleFillFormProduct = (productSelected) => {
        setShowFormProduct({...showFormProduct, status: false, data: null});
        setShowFormInventory({...showFormInventory, status: true, data: productSelected, type: "add", mod: "order", orderId: orderCurrent});
    }

    const handleFillFormInventory = () => {
        setLoader(true);
        toast.success("Inventory product edited successfully", alertOptions);
        setShowFormInventory({...showFormInventory, status: false, data: null, type: "", mod: "", orderId: ""});
        getData(page, {sort: "DESC"});
    }

    const changePage = (pge) => {
        new Promise((resolve, reject) => {
            getData(pge, filterPagination.data);
            resolve();
        }).then(() => {
            setPage(pge);
        });
    }

    const handleModalResponse = (sts) => {
        if (sts) {
            clearContext();
            window.location.reload(true);
        }
    };

    useEffect(() => {
        if (dataSession._id !== null) {
            getData(1, { sort: "DESC" });
        }
    }, [dataSession._id]);

    useEffect(() => {
        let array_clients = [];
        getWholesalerUsers().then(resWU => {
            if (resWU.status === 200 && resWU.data.length > 0) array_clients = array_clients.concat(resWU.data);
            getRetailUsers().then(resRU => {
                if (resRU.status === 200 && resRU.data.length > 0) array_clients = array_clients.concat(resRU.data);
                setClientOptions(array_clients);
            })
        });

        getWarehouses().then(res => {
            if (res.status === 200 && res.data.length > 0) {
                const warehousesCurrent = res.data.filter(element => {
                    if (!element?.deleted) {
                        if (dataSession.userType === "ADMIN") {
                            return element;
                        } else {
                            const foundAllWH = dataSession?.allWarehouse.find(elemWH => elemWH?._id === element?._id);
                            if (foundAllWH) return element;
                        }
                    }
                });
                setWarehouseOptions(warehousesCurrent);
            }
        });
    }, [dataSession]);

    return (
        <>
            { loader ? <Loader /> : null }

            {
                showModal.status ?
                    <ModalOrders
                        type={showModal.type}
                        onClose={() => setShowModal({...showModal, status: false, type: "", data: null})}
                        onResponse={handleModal}
                    />
                : null
            }

            {
                showFormProduct.status ?
                    <ModalFormProduct
                        productSelect={showFormProduct.data}
                        onLoader={(sts) =>setLoader(sts)}
                        onClose={() => setShowFormProduct({...showFormProduct, status: false, data: null})}
                        onProduct={handleFillFormProduct}
                    />
                : null
            }

            {
                showFormInventory.status ?
                    <ModalFormInventory
                        type={showFormInventory.type}
                        mod={showFormInventory.mod}
                        orderId={showFormInventory.orderId}
                        productSelect={showFormInventory.data}
                        warehouses={warehouseOptions}
                        warehouseCurrent={showFormInventory.data?.whCurrent ? showFormInventory.data?.whCurrent : null}
                        onLoader={(sts) =>setLoader(sts)}
                        onClose={() => setShowFormInventory({...showFormInventory, status: false, data: null, type: "", mod: "", orderId: ""})}
                        onSave={handleFillFormInventory}
                        onEdit={handleFillFormInventory}
                    />
                : null
            }

            {
                showCompatible.status ?
                    <ModalCompatible
                        products={showCompatible.element}
                        onClose={() => setShowCompatible({...showCompatible, status: false, element: null})}
                    />
                : null
            }

            {
                showFeatures.status ?
                    <MyCartFeatures
                        features={showFeatures.element}
                        onClose={() => setShowFeatures({...showFeatures, status: false, element: null})}
                    />
                : null
            }

            { logOutModal.status && (
                <Modal
                    title={logOutModal.title}
                    onClose={() =>
                        setLogOutModal({ ...logOutModal, status: false, title: "", element: null })
                    }
                    onResponse={handleModalResponse}
                />
            )}

            <div>
                <div className="flex flex-row">
                    <div className="w-full bg-no-repeat bg-cover fixed z-10">
                        <Navbar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                    </div>
                </div>
                <div className="flex h-screen overflow-hidden">
                    <div className={`${sidebarOpen ? "w-[100vw] md:w-80 z-20" : "w-20 z-0"}`}>
                        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                    </div>
                    <div className="w-full overflow-auto flex-grow mt-[100px]">
                        <div className="space-y-3 px-3 py-5 md:px-8 md:py-8">
                            <div className="pb-3 text-center">
                                <h1 className="text-[26px] md:text-xl lg:text-[26px] font-light text-ag-secondary">Orders</h1>
                            </div>
                            <div className="">
                                <Filters
                                    enableFilters={permits['ORDERS']['ORDERS_FILTER']}
                                    warehouses={warehouseOptions}
                                    status={statusOptions}
                                    clients={clientOptions}
                                    wordFilter={searchWord}
                                    onFilters={handleFilters}
                                />
                            </div>
                            <div className="pt-3">
                                <TableOrders
                                    enableActions={permits['ORDERS']['ACTIONS_ORDERS']}
                                    orders={orders}
                                    currntPg={page}
                                    pagesData={pagesData}
                                    onPage={(pg) => changePage(pg)}
                                    onPaid={handlePaid}
                                    onCancel={(id) => setShowModal({...showModal, type: "cancel", status: true, data: id })}
                                    onAttend={(id) => handleCanAttend(id)}
                                    onProduct={(prdSel, idOrder, whExist) => handleFillFormShow(prdSel, idOrder, whExist)}
                                    onCompatible={(prdSel) => setShowCompatible({...showCompatible, status: true, element: prdSel})}
                                    onFeatures={(ftSel) => setShowFeatures({...showFeatures, status: true, element: ftSel})}
                                    // featuresArr={featuresArr}
                                    // featuresArr2={featuresArr2}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Orders;