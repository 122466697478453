import { useContext, useEffect, useState } from "react";
import { deleteProductCart, emptyProductCart, getProductsCart, updateProductCart } from "../../../services/Shopping";
import { PermitsContext } from "../../../services/Permits";
import { getWarehouse } from "../../../services/Warehouse";
import { toast } from "react-toastify";
import { alertOptions } from "../../../utils/Utilities";
import { RiErrorWarningLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { getInventoryAddId } from "../../../services/Inventory";
import SidebarClients from "../../../components/sidebar/SidebarClients";
import NavbarClients from "../../../components/navbar/NavbarClients";
import MyCartList from "./MyCartList";
import MyCartPayment from "./MyCartDetail";
import Loader from "../../../components/loader/Loader";
import ModalCompatible from "../../admin/products/ModalCompatible";
import MyCartFeatures from "./MyCartFeatures";

const MyCart = () => {
    const navigate = useNavigate();
    const { dataSession, getCurrentCart } = useContext(PermitsContext);
    const [loader, setLoader] = useState(false);
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const [showCompatible, setShowCompatible] = useState({ status: false, element: null });
    const [showFeatures, setShowFeatures] = useState({ status: false, element: null });
    const [groups, setGroups] = useState([]);
    const [purchaseOrders, setPurchaseOrders] = useState("");

    const getData = async () => {
        setLoader(true);
        try {
            const res = await getProductsCart(dataSession._id);
            if (res.status === 200 && res.data.length > 0) {
                const arrUniqueWH = [...new Map(res.data.map(item => [item.warehouse, item.warehouse])).values()];
    
                const arrGroups = await Promise.all(arrUniqueWH.map(async warehouse => {
                    const warehouseData = await getWarehouse(warehouse);
                    const foundProducts = res.data.filter(element => element.warehouse === warehouse);
    
                    const newProducts = await Promise.all(foundProducts.map(async product => {
                        const quantityData = await getInventoryAddId(product?.productInventory?._id);
                        return {
                            ...product,
                            quantityTotal: quantityData.status === 200 ? quantityData.data?.totalQuantity : 0,
                            compatible_vehicles: quantityData.status === 200 ? quantityData.data?.productInventory?.product?.compatible_vehicles : [],
                            nags: quantityData.status === 200 ? quantityData.data?.productInventory?.product?.nags : ""
                        };
                    }));
    
                    return {
                        idWarehouse: warehouseData.status === 200 ? warehouseData.data?._id : "",
                        nameWarehouse: warehouseData.status === 200 ? warehouseData?.data?.name : "",
                        addressWarehouse: warehouseData.status === 200 ? warehouseData?.data?.address?._id : "",
                        products: newProducts
                    };
                }));
    
                setGroups(arrGroups);
            } else {
                setGroups([]);
            }
        } catch (error) {
            console.error(error);
            setGroups([]);
        }

        setLoader(false);
    };

    const handleQuantity = (idWarehouse, idProduct, qty) => {
        const newGroups = groups.map((whElem => {
            if (whElem.idWarehouse === idWarehouse) {
                const newProducts = whElem.products.map(pdElem => {
                    if (pdElem.productInventory?._id === idProduct) return { ...pdElem, quantity: Number(qty) };
                    return pdElem;
                });
                return { ...whElem, products: newProducts };
            }
            return whElem;
        }));
        setGroups(newGroups)
    };

    // const handlePurchaseOrder = (evt) => {
    //     console.log(evt)
    //     setPurchaseOrders(evt.target.value);
    //     const newGroups = groups.map((groupElem) => ({...groupElem, po: evt.target.value}));
    //     setGroups(newGroups);
    // }

    const handlePurchaseOrder = (evt) => {
        setPurchaseOrders(evt);
        const newGroups = groups.map((groupElem) => ({ ...groupElem, po: evt }));
        setGroups(newGroups);
    }

    const handleRemoveProduct = (idCart, qtyPrd) => {
        setLoader(true);
        deleteProductCart(dataSession._id, idCart).then(res => {
            if (res.status === 200) {
                getCurrentCart(dataSession._id);
                toast.success("Product removed successfully", alertOptions);
                getData();
            } else {
                toast.warning(res.response.data.message, alertOptions);
            }
        }).catch(error => {
            setLoader(false);
            toast.warning(error.response.data.message, alertOptions);
        });
    }

    const handleEmpty = () => {
        setLoader(true);
        emptyProductCart(dataSession._id).then(res => {
            if (res.status === 200) {
                getCurrentCart(dataSession._id);
                toast.success("Cart cleaned successfully", alertOptions);
                getData();
            } else {
                toast.warning(res.response.data.message, alertOptions);
            }
            setLoader(false);
        }).catch(error => {
            setLoader(false);
            toast.warning(error.response.data.message, alertOptions);
        });
    }

    const handlePayment = () => {
        if (groups.length > 0) {
            const validProducts = groups.filter(elem => {
                const productsWith0 = elem?.products.filter(elemProd =>
                    (elemProd?.quantity === 0 || elemProd?.quantity > elemProd?.quantityTotal)
                );
                if (productsWith0.length > 0) return elem;
            });
            if (validProducts.length === 0) {
                navigate(`/wholesaler/mycart/shipping`, { state: groups });
            } else {
                toast.warning("It is not possible to continue with products with quantity 0, or a quantity greater than inventory", alertOptions);
            }
        } else {
            toast.info("There are currently no products in the cart", alertOptions);
        }
    }

    const handleUpdate = (productModel, qty) => {
        const productCartModel = { price: productModel?.price, quantity: qty };
        updateProductCart(dataSession._id, productModel._id, productCartModel).then(() => { });
    }

    useEffect(() => {
        getData();
    }, [dataSession]);

    return (
        <>
            {loader ? <Loader /> : null}

            {
                showCompatible.status ?
                    <ModalCompatible
                        products={showCompatible.element}
                        onClose={() => setShowCompatible({ ...showCompatible, status: false, element: null })}
                    />
                    : null
            }

            {
                showFeatures.status ?
                    <MyCartFeatures
                        product={showFeatures.element}
                        onClose={() => setShowFeatures({ ...showFeatures, status: false, element: null })}
                    />
                    : null
            }

            <div>
                <div className="flex flex-row">
                    <div className="w-full bg-no-repeat bg-cover fixed z-10">
                        <NavbarClients type="wholesaler" sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                    </div>
                </div>
                <div className="flex h-screen overflow-hidden">
                    <div className={`${sidebarOpen ? "w-[100vw] md:w-80 z-20" : "w-20 z-0"}`}>
                        <SidebarClients type="wholesaler" sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                    </div>
                    <div className="w-full overflow-auto flex-grow mt-[100px]">
                        <div className="space-y-5 px-3 py-5 md:px-8 md:py-8">
                            <div className="text-center">
                                <h1 className="text-[26px] md:text-xl lg:text-[26px] font-light text-ag-secondary">
                                    My Cart
                                </h1>
                            </div>
                            {
                                groups.length > 0 ?
                                    <div className="lg:flex lg:justify-center lg:items-start lg:space-x-5 space-y-3 lg:space-y-0 pt-3">
                                        <div className="w-full lg:w-[65%] xl:w-[70%]">
                                            <MyCartList
                                                groups={groups}
                                                onQuantity={handleQuantity}
                                                onRemoveProduct={handleRemoveProduct}
                                                onUpdate={handleUpdate}
                                                onCompatible={(prdSel) => setShowCompatible({ ...showCompatible, status: true, element: prdSel })}
                                                onFeatures={(ftSel) => setShowFeatures({ ...showFeatures, status: true, element: ftSel })}
                                            />
                                        </div>
                                        <div className="w-full lg:w-[35%] xl:w-[30%] space-y-10">
                                            <MyCartPayment groups={groups} />
                                            <div>
                                                <div>
                                                    <p className="font-semibold">Enter Purchase Order {'(PO)'}</p>
                                                </div>
                                                <div className="border border-ag-secondary rounded-xl">
                                                    <textarea
                                                        className={`w-full ${purchaseOrders !== "" ? "" : "pt-12"} flex text-center border-none rounded-xl`}
                                                        placeholder="Purchase Order (PO)"
                                                        // onChange={handlePurchaseOrder}
                                                        onChange={(e) => handlePurchaseOrder(e.target.value)}
                                                        value={purchaseOrders}
                                                        rows={purchaseOrders !== "" ? 5 : 3}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : null
                            }
                            {
                                groups.length > 0 ?
                                    <div className="w-full lg:w-[70%] md:flex md:justify-center md:items-center md:space-x-5 space-y-2 md:space-y-0 pt-5">
                                        <button className="w-full md:w-[300px] bg-ag-secondary-light px-8 py-2 text-base lg:text-sm xl:text-base text-white rounded-xl" type="button" onClick={() => handleEmpty()}>
                                            Empty cart
                                        </button>
                                        <button className="w-full md:w-[300px] bg-ag-primary-light px-8 py-2 text-base lg:text-sm xl:text-base text-white rounded-xl" type="button" onClick={() => handlePayment()}>
                                            Continue with the payment
                                        </button>
                                    </div>
                                    : null
                            }
                            {
                                groups.length === 0 ?
                                    <div className="w-full flex justify-center items-center space-x-3 pt-[15%] text-center text-ag-secondary-letter">
                                        <div>
                                            <span className="flex justify-center pb-3 text-2xl"><RiErrorWarningLine /></span>
                                            <span className="text-xs italic md:text-base">
                                                Your cart is currently empty...
                                            </span>
                                        </div>
                                    </div>
                                    : null
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default MyCart;