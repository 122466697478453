import { useContext, useEffect, useState } from "react";
import { FiChevronLeft } from "react-icons/fi";
import { Link } from "react-router-dom";
import { getInventoryStocktake } from "../../../../services/Stocktake";
import { getWarehouses } from "../../../../services/Warehouse";
import { removeAccents } from "../../../../utils/Utilities";
import { PermitsContext } from "../../../../services/Permits";
import Navbar from "../../../../components/navbar/Navbar";
import Sidebar from "../../../../components/sidebar/Sidebar";
import Filters from "./Filters";
import TableStocktake from "./TableStocktake";
import Loader from "../../../../components/loader/Loader";

const Stocktake = () => {
    const { dataSession } = useContext(PermitsContext);
    const [loader, setLoader] = useState(true);
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const [inventory, setInventory] = useState([]);
    const [inventoryUniv, setInventoryUniv] = useState([]);
    const [warehouseOptions, setWarehouseOptions] = useState([]);
    const [stocktakesOptions, setStocktakesOptions] = useState([]);

    const handleFilters = (wordFilter, value) => {
        if (wordFilter === "" && value.stocktake === "" && value.warehouse === "") {
            setInventory(inventoryUniv);
        } else {
            let inventoryFound = inventoryUniv;

            if (wordFilter !== "") {
                let palabra = new RegExp(`${removeAccents(wordFilter)}.*`, "i");
                inventoryFound = inventoryFound.filter(element => (
                    palabra.test(element.no) ||
                    palabra.test(element.warehouse && element.warehouse.length ? removeAccents(element.warehouse[0].name  ) : "") ||
                    palabra.test(removeAccents(element.reason))
                ));
            }

            if (value.stocktake !== "") {
                inventoryFound = inventoryFound.filter(element => 
                    (`${String(element?._id).substring(String(element?._id).length - 3).toLocaleUpperCase()}` === value.stocktake)
                );
            }

            if (value.warehouse !== "") {
                inventoryFound = inventoryFound.filter(element => (removeAccents(element.warehouse[0]._id) === removeAccents(value.warehouse)));
            }

            setInventory(inventoryFound);
        }
    }

    const getOptions = (stocktakes, warehouses) => {
        setWarehouseOptions(warehouses);

        let newStocktakeAux = [];
        let newStocktakesOptions = [];
        for (let i = 0; i < stocktakes.length; i++) {
            let newStocktake = stocktakes[i];
            newStocktake.no = (i+1);
            newStocktakeAux.push(newStocktake);
            newStocktakesOptions.push({
                label: `${String(newStocktake?._id).substring(String(newStocktake?._id).length - 3).toLocaleUpperCase()}`,
                value: `${String(newStocktake?._id).substring(String(newStocktake?._id).length - 3).toLocaleUpperCase()}`
            });
        }
        setInventory(newStocktakeAux);
        setInventoryUniv(newStocktakeAux);
        setStocktakesOptions(newStocktakesOptions);
    }

    useEffect(() => {
        getInventoryStocktake().then(res => {
            if (res.status === 200 && res.data.length > 0) {
                let stocktakeWH = [];
                if (dataSession.userType === "ADMIN") {
                    stocktakeWH = res.data;
                } else {
                    stocktakeWH = res.data.filter(element => {
                        const foundWH = dataSession.allWarehouse.find(elem => element?.warehouse[0]._id === elem._id);
                        if (foundWH) return element;
                    });
                }

                const stocktakeSort = stocktakeWH.sort(function(a,b){
                    return new Date(b.createdAt) - new Date(a.createdAt);
                });
                
                getWarehouses().then(resWH => {
                    if (resWH.status === 200 && resWH.data.length > 0) {
                        if (dataSession.userType === "ADMIN") {
                            const warehousesCurrent = resWH.data.filter(element => !element.deleted)
                            getOptions(stocktakeSort, warehousesCurrent);
                        } else {
                            const warehousesCurrent = resWH.data.filter(element => {
                                const foundWH = dataSession.allWarehouse.find(elem => element?._id === elem._id);
                                if (!element?.deleted && foundWH) return element;
                            });
                            getOptions(stocktakeSort, warehousesCurrent);
                        }
                    }
                });
            }
            setLoader(false);
        }).catch(() => {
            setLoader(false);
        });
    }, [dataSession]);

    return (
        <div>
            { loader ? <Loader /> : null }

            <div className="flex flex-row">
                <div className="w-full bg-no-repeat bg-cover fixed z-10">
                    <Navbar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                </div>
            </div>
            <div className="flex h-screen overflow-hidden">
                <div className={`${sidebarOpen ? "w-[100vw] md:w-80 z-20" : "w-20 z-0"}`}>
                    <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                </div>
                <div className="w-full overflow-auto flex-grow mt-[100px]">
                    <div className="space-y-5 px-3 py-5 md:px-8 md:py-8">
                        <div className="grid grid-cols-4 md:grid-cols-12 px-0 md:px-5">
                            <div>
                                <Link to="/inventory">
                                    <button className="rounded-md" type="button">
                                        <span className="text-ag-secondary-dark text-3xl"><FiChevronLeft /></span>
                                    </button>
                                </Link>
                            </div>
                            <div className="flex justify-center items-center col-span-2 md:col-span-11 text-center">
                                <h1 className="text-[26px] md:text-xl lg:text-[26px] font-light text-ag-secondary md:mr-[8%]">
                                    Stocktake
                                </h1>
                            </div>
                            <div></div>
                        </div>
                        <div>
                            <Filters
                                stocktakes={stocktakesOptions}
                                warehouses={warehouseOptions}
                                onFilters={handleFilters}
                            />
                        </div>
                        <div>
                            <TableStocktake
                                inventory={inventory}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Stocktake;