import { useState } from "react";
import { BiSearch } from "react-icons/bi";

const Filters = ({ enableFilters, enableAdd, warehouseOptions, onFilters, onClose }) => {
    const [focusClass, setFocusClass] = useState({
        search: { placeholder: "Search", status: false },
        warehouse: { placeholder: "Warehouse", status: false }
    });

    const [options, setOptions] = useState({
        warehouse: ""
    });

    const handleChange = (evt) =>{
        if (evt.currentTarget.name === "search") {
            onFilters(evt.currentTarget.value, "filters");
        } else {
            if (evt.currentTarget.value === "default") {
                setOptions({...options, [evt.currentTarget.name]: ""});
            } else {
                setOptions({...options, [evt.currentTarget.name]: evt.currentTarget.value});
            }
        }
    }

    const handleSubmit = (evt) =>{
        evt.preventDefault();
        onFilters(options, "options");
    }

    return (
        <>
            <form onSubmit={handleSubmit}>
                <div className="space-y-3">
                    <div className="grid gap-3 lg:gap-6 md:grid-cols-4">
                        <div className="md:col-span-3">
                            <div className="flex justify-center items-center border border-gray-500 rounded-xl">
                                <input
                                    className={`w-full text-ag-secondary-letter text-xs lg:text-base border-none p-2 md:p-1 lg:p-2 rounded-xl disabled:bg-zinc-200 ${focusClass.search.status ? "text-left" : "text-center"}`}
                                    type="text"
                                    name="search"
                                    placeholder={focusClass.search.placeholder}
                                    onChange={handleChange}
                                    disabled={!enableFilters}
                                    onFocus={() => setFocusClass({...focusClass, search: { placeholder: "", status: true }})}
                                    onBlur={(evt) => evt.target.value === "" ? setFocusClass({...focusClass, search: { placeholder: "Search", status: false }}) : null}
                                    maxLength={60}
                                />
                                <button 
                                    className="bg-ag-primary-dark border-none p-[10px] md:p-[8px] lg:p-[10px] rounded-xl disabled:bg-zinc-200"
                                    type="button"
                                    disabled={!enableFilters}
                                >
                                    <span className="text-white text-base lg:text-xl"><BiSearch /></span>
                                </button>
                            </div>
                        </div>
                        <div>
                            <button
                                className="w-full bg-ag-primary-light text-white text-xs lg:text-base p-2 rounded-xl disabled:bg-zinc-200"
                                type="button"
                                onClick={() => { onClose(true) }}
                                disabled={!enableAdd}
                            >
                                Create Warehouse
                            </button>
                        </div>
                    </div>
                    <div className="grid gap-3 lg:gap-6 md:grid-cols-8">
                        <div className="md:col-span-2 xl:col-span-1">
                            <select
                                className={`w-full p-1 text-ag-secondary-letter text-xs lg:text-sm border border-gray-500 truncate rounded-xl ${focusClass.warehouse.status ? "text-left" : "text-center"}`}
                                name="warehouse"
                                defaultValue="default"
                                onChange={handleChange}
                                disabled={!enableFilters}
                                onFocus={() => setFocusClass({...focusClass, warehouse: { placeholder: "", status: true }})}
                                onBlur={(evt) => evt.target.value === "default" ? setFocusClass({...focusClass, warehouse: { placeholder: "Warehouse", status: false }}) : null}
                            >
                                <option value="default">Warehouse</option>
                                {
                                    warehouseOptions.map((element, index) => {
                                        return <option key={index} value={element.value}>{element.label}</option>;
                                    })
                                }
                            </select>
                        </div>
                        {/* <div>
                            <select
                                className="w-full text-center text-sm p-1 text-ag-secondary-letter border border-gray-500 rounded-xl"
                                name="location"
                                defaultValue="default"
                                onChange={handleChange}
                            >
                                <option value="default">Location</option>
                                {
                                    locationOptions.map((element, index) => {
                                        return <option key={index} value={element.value}>{element.label}</option>;
                                    })
                                }
                            </select>
                        </div> */}
                        <div>
                            <button
                                className="w-full text-center p-1 text-ag-secondary-letter text-xs lg:text-sm border border-gray-500 rounded-xl disabled:bg-zinc-200"
                                type="submit"
                                disabled={!enableFilters}
                            >
                                Filter
                            </button>
                        </div>
                        <div className="md:col-span-5 xl:col-span-6"></div>
                    </div>
                </div>
            </form>
        </>
    );
}

export default Filters;