import { useState } from "react";

import Navbar from "../../../../components/navbar/Navbar";
import Sidebar from "../../../../components/sidebar/Sidebar";
import FormProductContent from "./FormProductContent";


const FormProducts = () => {
    const [sidebarOpen, setSidebarOpen] = useState(true);

    return (
        <>
            <div>
                <div className="flex flex-row">
                    <div className="w-full bg-no-repeat bg-cover fixed z-10">
                        <Navbar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                    </div>
                </div>
                
                <div className="flex h-screen overflow-hidden">
                    <div className={`${sidebarOpen ? "w-[100vw] md:w-80 z-20" : "w-20 z-0"}`}>
                        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                    </div>

                    <FormProductContent />
                </div>
            </div>
        </>
    );
}

export default FormProducts;