import { useState, useContext } from "react";
import Navbar from "../../components/navbar/Navbar";
import Sidebar from "../../components/sidebar/Sidebar";
import Modal from "../../components/modal/Modal";
import { PermitsContext } from "../../services/Permits";

const Admin = () => {
    const { clearContext, logOutModal, setLogOutModal } = useContext(PermitsContext);
    const [sidebarOpen, setSidebarOpen] = useState(true);

    const handleModalResponse = (sts) => {
        if (sts) {
            clearContext();
            window.location.reload(true);
        }
    };

    return (
        <>
            <div>
                <div className="flex flex-row">
                    <div className="w-full bg-no-repeat bg-cover fixed z-10">
                        <Navbar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                    </div>
                </div>
                <div className="flex h-screen overflow-hidden">
                    <div className={`${sidebarOpen ? "w-[100vw] md:w-80 z-20" : "w-20 z-0"}`}>
                        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                    </div>
                    <div className="w-full overflow-auto flex-grow mt-[100px]">
                        
                    </div>
                </div>
            </div>

            {logOutModal.status && (
                <Modal
                title={logOutModal.title}
                onClose={() =>
                    setLogOutModal({ ...logOutModal, status: false, title: "", element: null })
                }
                onResponse={handleModalResponse}
                />
            )}
        </>
    )
}

export default Admin;