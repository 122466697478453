import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { deleteRole, getRoles, saveRole } from "../../../../services/Role";
import { getUsers } from "../../../../services/Administrator";
import { FiChevronLeft } from "react-icons/fi";
import { PermitsContext } from "../../../../services/Permits";
import { toast } from "react-toastify";
import { alertOptions } from "../../../../utils/Utilities";
import Navbar from "../../../../components/navbar/Navbar";
import Pagination from "../../../../components/pagination/Pagination";
import Sidebar from "../../../../components/sidebar/Sidebar";
import CardRoles from "./CardRoles";
import Modal from "../../../../components/modal/Modal";
import Loader from "../../../../components/loader/Loader";

const Roles = () => {
    const { permits } = useContext(PermitsContext);
    const navigate = useNavigate();

    const [loader, setLoader] = useState(false);
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const [roles, setRoles] = useState([]);
    const [showModal, setShowModal] = useState({ status: false, title: "", element: null });

    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(12);

    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = roles.slice(indexOfFirstRecord, indexOfLastRecord);

    const doubleRole = (sts) => {
        const rolModel = {
            name: showModal.element.data.name ? `${showModal.element.data.name} (copy)` : "",
            permissions: showModal.element.data.permissions ? JSON.parse(showModal.element.data.permissions) : [],
        };
        setLoader(true);
        saveRole(rolModel).then(res => {
            if (res.status === 200) {
                getRoles().then(resGet => {
                    if (resGet.status === 200 && resGet.data.length > 0) {
                        // The number of users by role is searched
                        getUsers().then(resUsr => {
                            let rolesWithUsers = resGet.data;
                            for (let i = 0; i < rolesWithUsers.length; i++) {
                                const usersFound = resUsr.data.filter(userElement => userElement.role?._id === rolesWithUsers[i]._id && !userElement.deleted);
                                rolesWithUsers[i].users = usersFound.length;
                            }
                            setRoles(rolesWithUsers);
                            setCurrentPage(1);
                        });
                    } else {
                        setRoles([]);
                        setCurrentPage(1);
                    }
                });
                setLoader(false);
                toast.success("Role duplicate successfully", alertOptions);
            } else {
                setLoader(false);
                toast.warning(res.response.data.message, alertOptions);
            }
        }).catch(error => {
            setLoader(false);
            toast.warning(error.response.data.message, alertOptions);
        });
        setShowModal({...showModal, status: false, title: "", element: null});
    }

    const removeRole = (sts) => {
        if (sts) {
            if (showModal.element.data.name === "DEFAULT ROLE") {
                toast.error("Cannot delete default role", alertOptions);
            } else {
                setLoader(true);
                deleteRole(showModal.element.data.id).then((res) => {
                    if (res.status === 200) {
                        getRoles().then(resGet => {
                            if (resGet.status === 200 && resGet.data.length > 0) {
                                // The number of users by role is searched
                                getUsers().then(resUsr => {
                                    let rolesWithUsers = resGet.data;
                                    for (let i = 0; i < rolesWithUsers.length; i++) {
                                        const usersFound = resUsr.data.filter(userElement => userElement.role?._id === rolesWithUsers[i]._id);
                                        rolesWithUsers[i].users = usersFound.length;
                                    }
                                    setRoles(rolesWithUsers);
                                    setCurrentPage(1);
                                });
                            } else {
                                setRoles(resGet.data);
                                setCurrentPage(1);
                            }
                            setLoader(false);
                            toast.success("Role deleted successfully", alertOptions);
                        });
                    } else {
                        setLoader(false);
                        toast.warning(res.response.data.message, alertOptions);
                    }
                }).catch(error => {
                    setLoader(false);
                    toast.warning(error.response.data.message, alertOptions);
                });
            }
        }
        setShowModal({...showModal, status: false, title: "", element: null});
    }

    useEffect(() => {
        if (permits['ADMINISTRATORS']['ROLE']) {
            setLoader(true);
            getRoles().then(res => {
                if (res.status === 200 && res.data.length > 0) {
                    // The number of users by role is searched
                    getUsers().then(resUsr => {
                        let rolesWithUsers = res.data;
                        for (let i = 0; i < rolesWithUsers.length; i++) {
                            const usersFound = resUsr.data.filter(userElement => userElement.role?._id === rolesWithUsers[i]._id && !userElement.deleted);
                            rolesWithUsers[i].users = usersFound.length;
                        }
                        setRoles(rolesWithUsers);
                        setCurrentPage(1);
                        setLoader(false);
                    });
                } else {
                    setRoles([]);
                    setCurrentPage(1);
                    setLoader(false);
                }
            }).catch(() => {
                setLoader(false);
            })
        } else {
            navigate("/home");
        }
    }, []);

    return (
        <>
            { loader ? <Loader /> : null }

            { showModal.status ?
                <Modal
                    title={showModal.title}
                    onClose={() => { setShowModal({...showModal, status: false, title: "", element: null}) }}
                    onResponse={(sts) => {
                        if (showModal.element.type === "duplicate") {
                            doubleRole(showModal.element);
                        } else {
                            removeRole(sts);
                        }
                    }}
                />
            :
                null
            }

            <div>
                <div className="flex flex-row">
                    <div className="w-full bg-no-repeat bg-cover fixed z-10">
                        <Navbar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                    </div>
                </div>
                <div className="flex h-screen overflow-hidden">
                    <div className={`${sidebarOpen ? "w-[100vw] md:w-80 z-20" : "w-20 z-0"}`}>
                        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                    </div>
                    <div className="w-full overflow-auto flex-grow mt-[100px]">
                        <div className="space-y-5 py-8 px-5 md:px-16">
                            <div className="grid gap-3 grid-cols-6">
                                <div>
                                    <Link to="/administrators">
                                        <button className="p-1 rounded-md" type="button">
                                            <span className="text-ag-secondary-dark text-3xl"><FiChevronLeft /></span>
                                        </button>
                                    </Link>
                                </div>
                                <div className="col-span-4 text-center">
                                    <h1 className="text-[26px] md:text-xl lg:text-[26px] font-light text-ag-secondary">Roles</h1>
                                </div>
                                <div className="col-span-6 md:col-span-1">
                                    <Link to="/administrators/roles/element">
                                        <button className="w-full bg-ag-primary-light text-white p-2 rounded-xl" type="button">
                                            New Rol
                                        </button>
                                    </Link>
                                </div>
                            </div>
                            <div className="overflow-auto py-3 min-h-[69vh] xl:min-h-[62vh]">
                                <div className="grid lg:grid-cols-2 xl:grid-cols-3 gap-3 md:gap-5">
                                    {
                                        currentRecords.map((elementRole, i) => {
                                            return (
                                                <div className="" key={i}>
                                                    <CardRoles
                                                        id={elementRole._id}
                                                        name={elementRole.name}
                                                        users={elementRole.users}
                                                        onDuplicate={() => {
                                                            setShowModal({...showModal,
                                                                status: true,
                                                                title: "¿Are you sure you want to duplicate the role?",
                                                                element: {type: "duplicate", data: elementRole}
                                                            });
                                                        }}
                                                        onDelete={(idRole, nameRole) => {
                                                            setShowModal({...showModal,
                                                                status: true,
                                                                title: "¿Are you sure you want to delete the role?",
                                                                element: { type: "delete", data: { id: idRole, name: nameRole } }
                                                            });
                                                        }}
                                                    />
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <div className="bottom-0 mb-0 pb-0">
                                <Pagination
                                    currentPage={currentPage}
                                    totalCount={roles.length}
                                    pageSize={recordsPerPage}
                                    onPageChange={page => setCurrentPage(page)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Roles;