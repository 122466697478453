import { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { completeInventoryTransferId, getInventoryTransferId } from "../../../../../services/Transfer";
import { getWarehouses } from "../../../../../services/Warehouse";
import { getInventoryAddWithRacks } from "../../../../../services/Inventory";
import { alertOptions, formatReverseDate, removeAccents } from "../../../../../utils/Utilities";
import { PermitsContext } from "../../../../../services/Permits";
import { FiChevronLeft } from "react-icons/fi";
import { toast } from "react-toastify";
import Navbar from "../../../../../components/navbar/Navbar";
import Sidebar from "../../../../../components/sidebar/Sidebar";
import Filters from "./Filters";
import FormTransfer from "./FormTransfer";
import TableReceivedProducts from "./TableReceivedProducts";
import OrganizeByRacks from "./OrganizeByRacks";
import TableHistoryTransfer from "../TableHistoryTransfer";
import Modal from "../../../../../components/modal/Modal";
import Loader from "../../../../../components/loader/Loader";
import ModalCompatible from "../../../products/ModalCompatible";

const ConfirmTransfer = () => {
    const navigate = useNavigate();
    const params = useParams();
    const [loader, setLoader] = useState(false);
    const { dataSession } = useContext(PermitsContext);
    const [sidebarOpen, setSidebarOpen] = useState(true);

    const [showConfirmModal, setShowConfirmModal] = useState({ status: false, title: "", element: null });
    const [showCompatible, setShowCompatible] = useState({ status: false, element: null });
    const [showOrganizeRacks, setShowOrganizeRacks] = useState({ data: "", status: false });
    const [warehouseOptions, setWarehouseOptions] = useState([]);
    const [warehouseFormOptions, setWarehouseFormOptions] = useState([]);
    const [statusOptions] = useState([
        { label: "Canceled", value: "canceled" },
        { label: "Complete", value: "complete" },
        { label: "Draft", value: "draft" },
        { label: "In Process", value: "inprocess" },
    ]);
    const [productsTransfer, setProductsTransfer] = useState([]);
    const [productsTransferUniv, setProductsTransferUniv] = useState([]);
    const [transfer, setTransfer] = useState({
        _id: "",
        status: "",
        fromWarehouse: "",
        toWarehouse: "",
        date: "",
        expectedBy: "",
        products: [],
        totalExpectedQuantity: "",
        totalRealQuantity: "",
        totalDiscrepancy: "",
        subTotal: "",
        total: "",
        message: "",
        updateBy: "",
        movements: []
    });

    const handleFilters = (evt) => {
        if (evt.target.value === "") {
            setProductsTransfer(productsTransferUniv);
        } else {
            let palabra = new RegExp(`${removeAccents(evt.target.value)}.*`, "i");
            const productsFound = productsTransferUniv.filter(element => (
                palabra.test(removeAccents(element.data.productInventory.product?.nags)) ||
                palabra.test(removeAccents(element.data.productInventory.product?.name)) ||
                palabra.test(removeAccents(element.data.productInventory.product?.model)) ||
                palabra.test(removeAccents(element.data.productInventory.product?.brand)) ||
                palabra.test(removeAccents(element.data.productInventory.product?.year)) ||
                palabra.test(removeAccents(element.data.productInventory.product?.glassType)) ||
                palabra.test(removeAccents(element.data.productInventory.product?.type_car)) ||
                palabra.test(removeAccents(element.data.productInventory.product?.description)) ||
                palabra.test(element.data.productInventory.product?.price) ||
                palabra.test(removeAccents(element.data.productInventory.product?.barcode1)) ||
                palabra.test(removeAccents(element.data.productInventory.product?.barcode2)) ||
                palabra.test(element.data.productInventory.product?.totalQuantity) ||
                palabra.test(removeAccents(element?.note)) ||
                palabra.test(element?.quantity) ||
                palabra.test(element?.totalCost)
            ));
            setProductsTransfer(productsFound);
        }
    }

    const onResponseProducts = (respProducts, valStock) => {
        let newAcumQty = 0;
        let newAcumDis = 0;
        let newAcumSub = 0;
        let newAcumTot = 0;
        const newProducts = productsTransfer.map((productElement) => {
            if (respProducts.id === productElement._id) {
                let newObject = productElement;
                newObject.realQuantity = valStock;
                newObject.discrepancy = Number(productElement?.quantity) - valStock;
                newObject.racks = respProducts.racks;
                newObject.totalCost = Number(productElement?.defaultCost * valStock);

                newAcumDis += Number(productElement?.quantity) - valStock;
                newAcumQty += Number(valStock);
                newAcumSub += Number(productElement?.defaultCost * valStock);
                newAcumTot += Number(productElement?.defaultCost * valStock);

                return newObject;
            } else {
                newAcumDis += productElement?.discrepancy ? Number(productElement?.discrepancy) : 0;
                newAcumQty += productElement?.realQuantity ? Number(productElement?.realQuantity) : 0;
                newAcumSub += Number(productElement?.totalCost);
                newAcumTot += productElement?.realQuantity ? Number(productElement?.realQuantity * productElement?.defaultCost) : 0;
                return productElement;
            }
        });

        setTransfer({...transfer,
            totalRealQuantity: newAcumQty,
            totalDiscrepancy: newAcumDis,
            subTotal: newAcumSub,
            total: newAcumTot
        });

        setProductsTransfer(newProducts);
        setProductsTransferUniv(newProducts);
        setShowOrganizeRacks({...showOrganizeRacks, data: "", status: false});
    }

    const deleteProduct = (productElement) => {
        const newProductsFound = productsTransfer.filter(elmntProd => `${elmntProd._id}${elmntProd.rack}` !== `${productElement._id}${productElement.rack}`);
        let acumExpectedQuantity = 0;
        let acumRealQuantity = 0;
        let acumDiscrepancy = 0;
        let acumSubtotal = 0;
        let acumTotal = 0;
        const newProductsAux = newProductsFound.map((prdElm) => {
            acumExpectedQuantity += prdElm.quantity;
            acumRealQuantity += (prdElm.realQuantity ? prdElm.realQuantity : prdElm.quantity);
            acumDiscrepancy += (prdElm.discrepancy ? prdElm.discrepancy : ((prdElm.realQuantity ? prdElm.realQuantity : prdElm.quantity)-prdElm.quantity));
            acumSubtotal += prdElm.defaultCost;
            acumTotal += (prdElm.quantity * prdElm.defaultCost);
            return prdElm;
        });

        setProductsTransfer(newProductsAux);
        setProductsTransferUniv(newProductsAux);
        setTransfer({...transfer,
            totalExpectedQuantity: acumExpectedQuantity,
            totalRealQuantity: acumRealQuantity,
            totalDiscrepancy: acumDiscrepancy,
            subTotal: acumSubtotal,
            total: acumTotal
        });
    }

    const handleProduct = (evt, productResp) => {
        let acumExpectedQuantity = 0;
        let acumRealQuantity = 0;
        let acumDiscrepancy = 0;
        let acumSubtotal = 0;
        let acumTotal = 0;

        const newProducts = productsTransfer.map(element => {
            let newObject = element;
            if (evt.target.name === "notes") {
                if (`${productResp._id}${productResp.rack}` === `${element._id}${element.rack}`) {
                    newObject.notes = evt.target.value;
                }
            } else {
                acumSubtotal += Number(element.defaultCost);
                acumExpectedQuantity += Number(element.quantity);
                if (`${productResp._id}${productResp.rack}` === `${element._id}${element.rack}`) {
                    acumRealQuantity += Number(evt.target.value);
                    acumDiscrepancy += (Number(evt.target.value) - Number(element.quantity));
                    acumTotal += (Number(evt.target.value)*Number(element.defaultCost));
                    
                    newObject.realQuantity = Number(evt.target.value);
                    newObject.discrepancy = (Number(evt.target.value) - Number(element.quantity));
                    newObject.totalCost = (Number(evt.target.value)*Number(element.defaultCost));
                } else {
                    acumRealQuantity += Number(element.realQuantity ? element.realQuantity : 0);
                    acumTotal += Number(element.totalCost);
                }
            }
            return newObject;
        });
        setProductsTransfer(newProducts);
        setProductsTransferUniv(newProducts);

        if (evt.target.name !== "notes") {
            setTransfer({...transfer,
                totalExpectedQuantity: acumExpectedQuantity,
                totalRealQuantity: acumRealQuantity,
                totalDiscrepancy: acumDiscrepancy,
                subTotal: acumSubtotal,
                total: acumTotal
            });
        }
    }

    const handleSubmit = (evt) => {
        evt.preventDefault();
        if (
            transfer.status !== "" && transfer.date !== "" && transfer.expectedBy !== "" && transfer.fromWarehouse !== "" &&
            transfer.fees !== "" && transfer.toWarehouse !== "" && transfer.freight !== "" && productsTransfer.length > 0
        ) {
            setLoader(true);
            const transferModel = {
                _id: params.id,
                fromWarehouse: { _id: transfer.fromWarehouse },
                toWarehouse: { _id: transfer.toWarehouse },
                status: "completed",
                products: productsTransfer.map((transferElement) => {
                    return {
                        product: { _id: transferElement.productInventory.product._id },
                        racks: transferElement.racks,
                        expectedQuantity: Number(transferElement.quantity),
                        realQuantity: Number(transferElement.realQuantity),
                        discrepancy: Number(transferElement.discrepancy),
                        note: transferElement.notes,
                        defaultCost: transferElement.defaultCost,
                        totalCost: transferElement.totalCost
                    }
                }),
                totalRealQuantity: transfer.totalRealQuantity,
                totalDiscrepancy: transfer.totalDiscrepancy,
                subTotal: transfer.subTotal,
                total: transfer.total,
                updateBy: { _id: dataSession._id }
            }
            completeInventoryTransferId(params.id, transferModel).then(res => {
                if (res.status === 200) {
                    toast.success("Transfer confirmed successful", alertOptions);
                    setLoader(false);
                    navigate("/inventory/transfer");
                } else {
                    setLoader(false);
                    toast.warning(res.response.data.message, alertOptions);
                }
            }).catch(error => {
                setLoader(false);
                toast.warning(error.response.data.message, alertOptions);
            });
        } else {
            toast.info("Please fill in all the fields of the form", alertOptions);
        }
    }

    const getData = async (idTransfer) => {
        setLoader(true);
        await getInventoryTransferId(idTransfer).then(async res => {
            if (res.status === 200) {
                let newProducts = res.data.productsTransfer && res.data.productsTransfer.length > 0 ? res.data.productsTransfer : [];
                for (let i = 0; i < newProducts.length; i++) {
                    const resQtyStock = await getInventoryAddWithRacks(newProducts[i].productInventory._id);
                    newProducts[i].data = { productInventory: newProducts[i].productInventory };
                    newProducts[i].data.quantity = resQtyStock.status === 200 ? resQtyStock.data.totalQuantity : 0;
                }

                setProductsTransfer(newProducts);
                setProductsTransferUniv(newProducts);
                setTransfer({...transfer,
                    _id: res.data._id,
                    status: res.data.status,
                    fromWarehouse: res.data.fromWarehouse ? res.data.fromWarehouse._id : "",
                    date: formatReverseDate(res.data.date),
                    expectedBy: formatReverseDate(res.data.expectedBy),
                    fees: res.data.fees,
                    toWarehouse: res.data.toWarehouse ? res.data.toWarehouse._id : "",
                    freight: res.data.freight,
                    productsInventory: [],
                    totalExpectedQuantity: res.data.totalQuantity,
                    totalRealQuantity: res.data.totalRealQuantity,
                    totalDiscrepancy: res.data.totalDiscrepancy,
                    totalQuantity: res.data.totalQuantity,
                    subTotal: res.data.total,
                    total: res.data.total,
                    message: res.data.message,
                    cratedBy: res.data.cratedBy,
                    movements: res.data.movementsTransfer
                });
            }
        });
        setLoader(false);
    }

    useEffect(() => {
        getWarehouses().then(res => {
            if (res.status === 200 && res.data.length > 0) {
                setWarehouseOptions(res.data);
                setWarehouseFormOptions(res.data);
            } else {
                setWarehouseOptions([]);
                setWarehouseFormOptions([]);
            }
        });

        if (params && params.id) getData(params.id);
    }, []);

    return (
        <>
            { loader ? <Loader /> : null }

            {
                showConfirmModal.status ?
                    <Modal
                        title={showConfirmModal.title}
                        onClose={() => { setShowConfirmModal({...showConfirmModal, status: false, title: "", element: null}) }}
                        onResponse={(sts) => {
                            if (sts) {
                                navigate("/inventory/transfer");
                            }
                        }}
                    />
                : null
            }

            { showOrganizeRacks.status ?
                <OrganizeByRacks
                    type="transfer"
                    data={showOrganizeRacks.data}
                    warehouse={transfer.toWarehouse}
                    onOrganize={onResponseProducts}
                    onClose={() => setShowOrganizeRacks({...showOrganizeRacks, status: false, data: ""})}
                    onCompatible={(prdSel) => setShowCompatible({...showCompatible, status: true, element: prdSel})}
                    onLoader={(sts) => setLoader(sts)}
                />
            :
                null
            }

            {
                showCompatible.status ?
                    <ModalCompatible
                        products={showCompatible.element}
                        onClose={() => setShowCompatible({...showCompatible, status: false, element: null})}
                    />
                : null
            }

            <div>
                <div className="flex flex-row">
                    <div className="w-full bg-no-repeat bg-cover fixed z-10">
                        <Navbar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                    </div>
                </div>
                <div className="flex h-screen overflow-hidden">
                    <div className={`${sidebarOpen ? "w-[100vw] md:w-80 z-20" : "w-20 z-0"}`}>
                        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                    </div>
                    <div className="w-full overflow-auto flex-grow mt-[100px]">
                        <div className="space-y-5 px-3 py-5 md:px-8 md:py-8">
                            <div className="grid grid-cols-12 px-0 md:px-5">
                                <div>
                                    <Link to="/inventory/transfer">
                                        <button className="p-1 rounded-xl" type="button">
                                            <span className="text-ag-secondary-dark text-3xl"><FiChevronLeft /></span>
                                        </button>
                                    </Link>
                                </div>
                                <div className="flex justify-center items-center col-span-11 text-center">
                                    <h1 className="text-[26px] md:text-xl lg:text-[26px] font-light text-ag-secondary md:mr-[8%]">
                                        TRANSFER #TF-{String(params.id).substring(String(params.id).length - 5).toLocaleUpperCase()}
                                    </h1>
                                </div>
                            </div>
                            <div className="px-0 md:px-5">
                                <FormTransfer
                                    headerTransferData={transfer}
                                    statusOptions={statusOptions}
                                    warehousesOptions={warehouseOptions}
                                    warehouseFromOptions={warehouseFormOptions}
                                    onHandleChange={() => {}}
                                    onlyRead={true}
                                />
                            </div>
                            <div className="px-0 md:px-5">
                                <Filters onHandleChange={handleFilters} />
                            </div>
                            <div className="px-0 md:px-5 overflow-x-auto">
                                <TableReceivedProducts
                                    products={productsTransfer}
                                    totalExpectedQuantity={transfer.totalExpectedQuantity}
                                    totalRealQuantity={transfer.totalRealQuantity}
                                    totalDiscrepancy={transfer.totalDiscrepancy}
                                    subTotal={transfer.subTotal}
                                    total={transfer.total}
                                    onProduct={handleProduct}
                                    onOrganize={(productRes) => setShowOrganizeRacks({...showOrganizeRacks, status: true, data: productRes})}
                                    onDelete={deleteProduct}
                                    onlyRead={false}
                                    onCompatible={(prdSel) => setShowCompatible({...showCompatible, status: true, element: prdSel})}
                                />
                            </div>
                            <div className="px-0 md:px-5">
                                <div className="py-3 space-y-5 pb-5 border-b-2 border-dashed border-ag-secondary-light">
                                    <div className="w-full">
                                        <h1 className="text-xs lg:text-base">SHIPPING WAREHOUSE MESSAGE</h1>
                                    </div>
                                    <div className="w-full">
                                        <textarea
                                            className="w-full text-ag-secondary-letter text-xs lg:text-base border border-gray-500 p-2 rounded-xl disabled:bg-zinc-100"
                                            rows="6"
                                            placeholder="Insert message to Warehouse..."
                                            name="message"
                                            value={transfer.message ? transfer.message : "-----"}
                                            disabled={true}
                                        >
                                        </textarea>
                                    </div>
                                </div>
                            </div>
                            <div className="px-0 md:px-5">
                                <div className="py-3 space-y-5 pb-5 border-b-2 border-dashed border-ag-secondary-light">
                                    <div className="w-full">
                                        <h1 className="text-xs lg:text-base">HISTORY</h1>
                                    </div>
                                    <div className="w-full">
                                        <TableHistoryTransfer movements={transfer.movements} />
                                    </div>
                                </div>
                            </div>
                            <div className="px-0 md:px-5 flex justify-between items-center">
                                <button
                                    className="w-[40%] md:w-[20%] p-2 bg-ag-secondary-dark text-white rounded-xl"
                                    type="button"
                                    onClick={() => {
                                        setShowConfirmModal({...showConfirmModal,
                                            status: true,
                                            title: "Are you sure you want to discard the changes?",
                                            element: ""
                                        });
                                    }}
                                >
                                    Close
                                </button>
                                <button
                                    className="w-[40%] md:w-[20%] p-2 bg-ag-primary-dark text-white rounded-xl"
                                    type="button"
                                    onClick={handleSubmit}
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ConfirmTransfer;