import { useContext, useEffect, useState } from "react";
import { deleteRetailUser, saveRetailUser, updateRetailUser } from "../../../services/Retail";
import { deleteWholesalerUser, saveWholesalerUser, updateWholesalerUser } from "../../../services/Wholesaler";
import { getUsersMayMinPurcharse } from "../../../services/User";
import { alertOptions, formatReverseDate, removeAccents } from "../../../utils/Utilities";
import { PermitsContext } from "../../../services/Permits";
import { toast } from "react-toastify";
import Modal from "../../../components/modal/Modal";
import Navbar from "../../../components/navbar/Navbar";
import Sidebar from "../../../components/sidebar/Sidebar";
import Filters from "./Filters";
import FormUser from "./FormUser";
import TableUser from "./TableUser";
import Loader from "../../../components/loader/Loader";

const Users = () => {
    const { permits, clearContext, logOutModal, setLogOutModal } = useContext(PermitsContext);

    const [loader, setLoader] = useState(false);
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const [showForm, setShowForm] = useState(false);
    const [showModal, setShowModal] = useState({ status: false, element: null });
    const [cleanFilters, setCleanFilters] = useState(false);
    const [userData, setUserData] = useState(null);
    const [users, setUsers] = useState([]);
    const [usersUniv, setUsersUniv] = useState([]);
    const [yearsOptions, setYearsOptions] = useState([]);
    const [typeOptions] = useState([
        { label: "Retail client", value: "retail" },
        { label: "Wholeseller", value: "wholesaler" }
    ]);

    const [monthsOptions] = useState([
        { label: "January", value: 0 },
        { label: "February", value: 1 },
        { label: "March", value: 2 },
        { label: "April", value: 3 },
        { label: "May", value: 4 },
        { label: "June", value: 5 },
        { label: "July", value: 6 },
        { label: "August", value: 7 },
        { label: "September", value: 8 },
        { label: "October", value: 9 },
        { label: "November", value: 10 },
        { label: "December", value: 11 }
    ]);

    const searchUsers = async (params) => {
        setLoader(true);
        return await getUsersMayMinPurcharse(params).then(res => {
            if (res.status === 200) {
                const filterUser = res.data.map(monthsElement => {
                    const usersFound = monthsElement.result.filter(usersElement => {
                        if (!usersElement?.wholesalerUser?.deleted && !usersElement?.retailUser?.deleted) {
                            if ((usersElement?.wholesalerUser && usersElement?.wholesalerUser?.approve) || usersElement?.retailUser) {
                                return usersElement;
                            }
                        }
                    });
                    return {...monthsElement, result: usersFound};
                });
                setLoader(false);
                return filterUser;
            } else {
                setLoader(false);
                return [];
            }
        }).catch(() => {
            setLoader(false);
            return [];
        })
    }

    const getData = async () => {
        setLoader(true);
        const wholesalerUsers = await searchUsers({ year: new Date().getFullYear(), typeUser: "wholesaler", typeSearch: "minor" });
        const retailUsers = await searchUsers({ year: new Date().getFullYear(), typeUser: "retail", typeSearch: "minor" });

        let arrFinal = [];
        for (let i = 0; i < 12; i++) {
            // Agrupacion de usuarios mayoristas
            for (let x = 0; x < wholesalerUsers[i]?.result.length; x++) {
                const indexUser = arrFinal.findIndex(elem => elem?.wholesalerUser?._id === wholesalerUsers[i].result[x]?.wholesalerUser?._id);
                if (indexUser === -1) {
                    arrFinal.push(wholesalerUsers[i].result[x]);
                } else {
                    arrFinal[indexUser] = {...arrFinal[indexUser],
                        totalPurchased: Number(arrFinal[indexUser].totalPurchased) + Number(wholesalerUsers[i].result[x]?.totalPurchased)
                    }
                }
            }

            // Agrupacion de usuarios minoristas
            for (let x = 0; x < retailUsers[i]?.result.length; x++) {
                const indexUser = arrFinal.findIndex(elem => elem?.retailUser?._id === retailUsers[i].result[x]?.retailUser?._id);
                if (indexUser === -1) {
                    arrFinal.push(retailUsers[i].result[x]);
                } else {
                    arrFinal[indexUser] = {...arrFinal[indexUser],
                        totalPurchased: Number(arrFinal[indexUser].totalPurchased) + Number(retailUsers[i].result[x]?.totalPurchased)
                    }
                }
            }
        }

        let newArrFinal = arrFinal.map(userElem => {
            let userInfo = userElem?.retailUser ? userElem?.retailUser : userElem?.wholesalerUser;
            return {...userInfo, totalPerYear: userElem?.totalPurchased}
        }).filter(userElemAux => {
            let userInfo = userElemAux?.retailUser ? userElemAux?.retailUser : userElemAux?.wholesalerUser;
            if (userInfo?.names !== "Guest" && userInfo?.lastNames !== "User" && userInfo?.workshopName !== "Avondale Auto Glass") {
                return userElemAux;
            }
        });
        
        setCleanFilters(true);
        setUsers(newArrFinal);
        setUsersUniv(newArrFinal);
        getOptions(newArrFinal);
        setLoader(false);
    }

    const handleFilters = async (wordFilter, value, inptSrch) => {
        if (wordFilter === "" && value.typeUser === "" && value.typeSearch === "" && value.year === "" && value.month === "" && value.phoneNumber === "" && value.startDate === "" && value.endDate === "") {
            getData();
        } else {
            if (inptSrch) {
                let palabra = new RegExp(`${removeAccents(String(wordFilter).replace("+", ""))}.*`, "i");
                let newArrFinal = usersUniv.filter(element => (
                    palabra.test(`${element?.names} ${element?.lastNames}`) ||
                    palabra.test(element?.user ? String(element?.user?.email).replace("+", "") : "") ||
                    palabra.test(element?.phoneNumber)
                ));
                setUsers(newArrFinal);
            } else {
                if ((value.startDate !== "" && value.endDate === "") || (value.startDate === "" && value.endDate !== "")) {
                    toast.info("Please enter both dates to perform the filter", alertOptions);
                    return 0;
                } else if (value.startDate !== "" && value.endDate !== "") {
                    if (formatReverseDate(value.startDate) > formatReverseDate(value.endDate)) {
                        toast.info("The start date is greater than the end date", alertOptions);
                        return 0;
                    }
                }

                let filterObject = {};
                for (const key in value) {
                    if (Object.hasOwnProperty.call(value, key)) {
                        if (value[key] !== "" && key !== "phoneNumber") {
                            filterObject[key] = value[key];
                        }
                    }
                }

                if (!filterObject?.typeSearch) filterObject.typeSearch = "mayor";
                if (!filterObject?.year && !filterObject?.startDate && !filterObject?.endDate) filterObject.year = new Date().getFullYear();

                let arrFinal = [];
                if (filterObject?.typeUser) {
                    const valueUsers = await searchUsers(filterObject);

                    for (let wi = 0; wi < 12; wi++) {
                        if (valueUsers[wi]?.result.length > 0) arrFinal = arrFinal.concat(valueUsers[wi]?.result);
                    }
                } else {
                    const wholesalerUsers = await searchUsers({...filterObject, typeUser: "wholesaler"});
                    const retailUsers = await searchUsers({...filterObject, typeUser: "retail" });
                    
                    for (let wi = 0; wi < 12; wi++) {
                        // if (wholesalerUsers[wi]?.result.length > 0) arrFinal = arrFinal.concat(wholesalerUsers[wi]?.result);
                        // if (retailUsers[wi]?.result.length > 0) arrFinal = arrFinal.concat(retailUsers[wi]?.result);

                        // Agrupacion de usuarios mayoristas
                        for (let x = 0; x < wholesalerUsers[wi]?.result.length; x++) {
                            const indexUser = arrFinal.findIndex(elem => elem?.wholesalerUser?._id === wholesalerUsers[wi].result[x]?.wholesalerUser?._id);
                            if (!indexUser || indexUser === -1) {
                                arrFinal.push(wholesalerUsers[wi].result[x]);
                            } else {
                                arrFinal[indexUser] = {...arrFinal[indexUser],
                                    totalPurchased: Number(arrFinal[indexUser].totalPurchased) + Number(wholesalerUsers[wi].result[x]?.totalPurchased)
                                }
                            }
                        }

                        // Agrupacion de usuarios minoristas
                        for (let x = 0; x < retailUsers[wi]?.result.length; x++) {
                            const indexUser = arrFinal.findIndex(elem => elem?.retailUser?._id === retailUsers[wi].result[x]?.retailUser?._id);
                            if (!indexUser || indexUser === -1) {
                                arrFinal.push(retailUsers[wi].result[x]);
                            } else {
                                arrFinal[indexUser] = {...arrFinal[indexUser],
                                    totalPurchased: Number(arrFinal[indexUser].totalPurchased) + Number(retailUsers[wi].result[x]?.totalPurchased)
                                }
                            }
                        }
                    }
                }

                let newArrFinal = arrFinal.map(userElem => {
                    let userInfo = userElem?.retailUser ? userElem?.retailUser : userElem?.wholesalerUser;
                    if (userInfo?.names !== "Guest" && userInfo?.lastNames !== "User" && userInfo?.workshopName !== "Avondale Auto Glass") {
                        return {...userInfo, totalPerYear: userElem?.totalPurchased}
                    }
                });

                if (wordFilter !== "") {
                    let palabra = new RegExp(`${removeAccents(wordFilter)}.*`, "i");
                    newArrFinal = newArrFinal.filter(element => (
                        palabra.test(`${element?.names} ${element?.lastNames}`) ||
                        palabra.test(element?.user ? element?.user?.email : "") ||
                        palabra.test(element?.phoneNumber)
                    ));
                }

                if (value.phoneNumber !== "") {
                    let numberWord = new RegExp(`${value.phoneNumber}.*`, "i");
                    newArrFinal = newArrFinal.filter(element => numberWord.test(element?.phoneNumber));
                }

                if (value?.typeSearch !== "") {
                    if (value?.typeSearch === "minor") {
                        newArrFinal = newArrFinal.sort((a, b) => (a?.totalPerYear - b?.totalPerYear));
                    } else {
                        newArrFinal = newArrFinal.sort((a, b) => (b?.totalPerYear - a?.totalPerYear));
                    }
                }

                setUsers(newArrFinal);
            }
        }
    }

    const addUser = (userObject, type) => {
        setLoader(true);
        if (type === "wsu") {
            saveWholesalerUser(userObject).then(res => {
                if (res.status === 200) {
                    setShowForm(false);
                    toast.success("User added successfully", alertOptions);
                    getData();
                } else {
                    setLoader(false);
                    toast.warning(res.response.data.message, alertOptions);
                }
            }).catch(error => {
                setLoader(false);
                toast.warning(error.response.data.message, alertOptions);
            });
        } else {
            saveRetailUser(userObject).then(res => {
                if (res.status === 200) {
                    setShowForm(false);
                    toast.success("User added successfully", alertOptions);
                    getData();
                } else {
                    setLoader(false);
                    toast.warning(res.response.data.message, alertOptions);
                }
            }).catch(error => {
                setLoader(false);
                toast.warning(error.response.data.message, alertOptions);
            });
        }
    }

    const editUser = (id, userObject, type) => {
        setLoader(true);
        if (type === "wsu") {
            updateWholesalerUser(id, userObject).then((res) => {
                if (res.status === 200) {
                    setShowForm(false);
                    toast.success("User updated successfully", alertOptions);
                    getData();
                } else {
                    setLoader(false);
                    toast.warning(res.response.data.message, alertOptions);
                }
            }).catch(error => {
                setLoader(false);
                toast.warning(error.response.data.message, alertOptions);
            });
        } else {
            updateRetailUser(id, userObject).then((res) => {
                if (res.status === 200) {
                    setShowForm(false);
                    getData();
                    toast.success("User updated successfully", alertOptions);
                } else {
                    setLoader(false);
                    toast.warning(res.response.data.message, alertOptions);
                }
            }).catch(error => {
                setLoader(false);
                toast.warning(error.response.data.message, alertOptions);
            });
        }
    }

    const removeUser = (sts) => {
        if (sts) {
            setLoader(true);
            if (showModal.element.user.userType === "WHOLESALER_USER") {
                deleteWholesalerUser(showModal.element._id).then((res) => {
                    if (res.status === 200) {
                        toast.success("User deleted successfully", alertOptions);
                        getData();
                    } else {
                        setLoader(false);
                        toast.warning(res.response.data.message, alertOptions);
                    }
                }).catch(error => {
                    setLoader(false);
                    toast.warning(error.response.data.message, alertOptions);
                });
            } else {
                deleteRetailUser(showModal.element._id).then((res) => {
                    if (res.status === 200) {
                        toast.success("User deleted successfully", alertOptions);
                        getData();
                    } else {
                        setLoader(false);
                        toast.warning(res.response.data.message, alertOptions);
                    }
                }).catch(error => {
                    setLoader(false);
                    toast.warning(error.response.data.message, alertOptions);
                });
            }
        }
        setShowModal({...showModal, status: false, element: null});
    }

    const getOptions = (array_users) => {
        let array_years = [];
        for (let i = 0; i < array_users.length; i++) {
            const userDate = array_users[i]?.user?.createdAt;
            const yearFound = array_years.find(elem => Number(elem.value) === new Date(userDate).getFullYear());

            if (!yearFound && userDate) array_years.push({ label: new Date(userDate).getFullYear(), value: new Date(userDate).getFullYear()});
        }
        setYearsOptions(array_years);
    }

    const handleModalResponse = (sts) => {
        if (sts) {
            clearContext();
            window.location.reload(true);
        }
    };

    useEffect(() => {
        getData();
    }, []);

    return (
        <>
            { loader ? <Loader /> : null }

            { showModal.status ?
                <Modal
                    title="¿Are you sure you want to delete the user?"
                    onClose={() => {setShowModal({...showModal, status: false, element: null}) }}
                    onResponse={removeUser}
                />
            :
                null
            }

            { logOutModal.status && (
                <Modal
                    title={logOutModal.title}
                    onClose={() =>
                        setLogOutModal({ ...logOutModal, status: false, title: "", element: null })
                    }
                    onResponse={handleModalResponse}
                />
            )}
            
            { showForm ?
                <FormUser
                    enableAddRetail={permits['USERS']['ADD_RETAILER']}
                    enableAddWholesaler={permits['USERS']['ADD_WOLESALER']}
                    userType={userData && userData.user.userType === "WHOLESALER_USER" ? "wholesaler" : "retailer"}
                    userInfo={userData}
                    onSave={addUser}
                    onEdit={(id, user, type) => { editUser(id, user, type); }}
                    onClose={() => {
                        setShowForm(false);
                        setUserData(null);
                    }}
                />
            : 
                null
            }

            <div>
                <div className="flex flex-row">
                    <div className="w-full bg-no-repeat bg-cover fixed z-10">
                        <Navbar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                    </div>
                </div>
                <div className="flex h-screen overflow-hidden">
                    <div className={`${sidebarOpen ? "w-[100vw] md:w-80 z-20" : "w-20 z-0"}`}>
                        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                    </div>
                    <div className="w-full overflow-auto flex-grow mt-[100px]">
                        <div className="space-y-5 px-3 py-5 md:px-8 md:py-8">
                            <div className="text-center">
                                <h1 className="text-[26px] md:text-xl lg:text-[26px] font-light text-ag-secondary">Users</h1>
                            </div>
                            <div className="pt-3">
                                <Filters
                                    enableFilters={permits['USERS']['FILTER']}
                                    enableAddRetail={permits['USERS']['ADD_RETAILER']}
                                    enableAddWholesaler={permits['USERS']['ADD_WOLESALER']}
                                    typeOptions={typeOptions}
                                    yearsOptions={yearsOptions}
                                    monthsOptions={monthsOptions}
                                    isClean={cleanFilters}
                                    onClean={(valClean) => setCleanFilters(valClean)}
                                    onFilters={handleFilters}
                                    onClose={() => {
                                        setShowForm(true)
                                    }}
                                />
                            </div>
                            <div>
                                <TableUser
                                    enableApprove={permits['USERS']['APPROVE_DISPPROVE']}
                                    enableEdit={permits['USERS']['EDIT']}
                                    enableDelete={permits['USERS']['DELETE']}
                                    arrUsers={users}
                                    onDelete={(userElement) => {
                                        setShowModal({...showModal, status: true, element: userElement})
                                    }}
                                    onEdit={(userInfo) => {
                                        setUserData(userInfo);
                                        setShowForm(true);
                                    }}
                                    onStatus={(userInfo, sts) => {
                                        let newUserModel = {};
                                        if (userInfo.user.userType === "WHOLESALER_USER") {
                                            newUserModel = {
                                                _id: userInfo._id,
                                                names: userInfo.names,
                                                lastNames: userInfo.lastNames,
                                                email: userInfo.user.email,
                                                phoneNumber: userInfo.phoneNumber,
                                                zipCode: userInfo.zipCode,
                                                workshopName: userInfo.workshopName,
                                                invoice: userInfo.invoice,
                                                invoiceFile: userInfo.invoiceFile,
                                                approve: sts
                                            };
                                            editUser(userInfo._id, newUserModel, "wsu");
                                        } else {
                                            newUserModel = {
                                                _id: userInfo._id,
                                                names: userInfo.names,
                                                lastNames: userInfo.lastNames,
                                                email: userInfo.user.email,
                                                phoneNumber: userInfo.phoneNumber,
                                                approve: sts
                                            };
                                            editUser(userInfo._id, newUserModel, "ru");
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Users;