import { formatCurrency, formatDateEng } from "../../../utils/Utilities";

const RowsReturns = ({ report }) => {
    return (
        <tr>
            <td className="w-[15%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 py-3">
                <p className="truncate w-44 m-auto">{ report?.warehouse?.name }</p>
            </td>
            <td className="w-[15%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 py-3">
                <p className="truncate w-44 m-auto">{ report?.employee ? report?.employee?.role?.name : "-----" }</p>
            </td>
            <td className="w-[15%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 py-3">
                <p className="truncate w-44 m-auto">{ report?.wholesaler ? report?.wholesaler?.names : "-----"  }</p>
            </td>
            <td className="w-[8%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 py-3">
                <p className="truncate w-20 m-auto">{ report?.sale ? `SN-${String(report?.sale?._id).substring(String(report?.sale?._id).length - 5).toLocaleUpperCase()}` : "-----" }</p>
            </td>
            <td className="w-[8%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 py-3">
                <p className="truncate w-20 m-auto">{ `ON-${String(report?._id).substring(String(report?._id).length - 5).toLocaleUpperCase()}` }</p>
            </td>
            <td className="w-[8%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 py-3">
                <p className="truncate w-20 m-auto">{ formatDateEng(report?.createdAt) }</p>
            </td>
            <td className="w-[8%] border-[0.5px] border-ag-secondary-light text-xs font-normal px-2 py-3">
                <p className="truncate w-20 m-auto">{ formatCurrency(report?.total) }</p>
            </td>
            <td className="w-[8%] border-[0.5px] border-ag-secondary-light text-xs font-normal px-2 py-3">
                <p className="truncate w-20 m-auto">{ report?.quantity }</p>
            </td>
            <td className="w-[15%] border-[0.5px] border-ag-secondary-light text-xs font-normal px-2 py-3">
                <p className="truncate w-44 m-auto">{ report?.productOrder?.productInventory?.product?.nags }</p>
            </td>
        </tr>
    );
}

export default RowsReturns;