import { formatCurrency } from "../../../../utils/Utilities";

const HeaderData = ({ data, totalYear }) => {
    return (
        <div className="border border-ag-secondary-light rounded-xl">
            <div className="bg-ag-secondary text-center p-6 rounded-t-xl">
                <span className="text-white text-xs xl:text-sm font-semibold">General data</span>
            </div>
            <div>
                <div className="flex justify-between items-center space-x-5 py-[9px]">
                    <div className="w-full text-right text-xs xl:text-sm text-ag-secondary-letter font-semibold">
                        <span>Total of the year:</span>
                    </div>
                    <div className="w-full text-left text-xs xl:text-sm text-ag-secondary-letter">
                        <span>{ formatCurrency(totalYear) }</span>
                    </div>
                </div>
                <div className="flex justify-between items-center space-x-5 py-[9px]">
                    <div className="w-full text-right text-xs xl:text-sm text-ag-secondary-letter font-semibold">
                        <span>Total transactions:</span>
                    </div>
                    <div className="w-full text-left text-xs xl:text-sm text-ag-secondary-letter">
                        <span>{ data.totalTransactions }</span>
                    </div>
                </div>
                <div className="flex justify-between items-center space-x-5 py-[9px]">
                    <div className="w-full text-right text-xs xl:text-sm text-ag-secondary-letter font-semibold">
                        <span>Total sales:</span>
                    </div>
                    <div className="w-full text-left text-xs xl:text-sm text-ag-secondary-letter">
                        <span>{ data.totalSales }</span>
                    </div>
                </div>
                <div className="flex justify-between items-center space-x-5 py-[9px]">
                    <div className="w-full text-right text-xs xl:text-sm text-ag-secondary-letter font-semibold">
                        <span>Total returns:</span>
                    </div>
                    <div className="w-full text-left text-xs xl:text-sm text-ag-secondary-letter">
                        <span>{ data.totalReturns }</span>
                    </div>
                </div>
                <div className="flex justify-between items-center space-x-5 py-[9px]">
                    <div className="w-full text-right text-xs xl:text-sm text-ag-secondary-letter font-semibold">
                        <span>Total credits:</span>
                    </div>
                    <div className="w-full text-left text-xs xl:text-sm text-ag-secondary-letter">
                        <span>{ formatCurrency(data.totalCredits) }</span>
                    </div>
                </div>
                <div className="flex justify-between items-center space-x-5 py-[9px]">
                    <div className="w-full text-right text-xs xl:text-sm text-ag-secondary-letter font-semibold">
                        <span>Total sales with credits:</span>
                    </div>
                    <div className="w-full text-left text-xs xl:text-sm text-ag-secondary-letter">
                        <span>{ formatCurrency(data.totalSalesCredits) }</span>
                    </div>
                </div>
                <div className="flex justify-between items-center space-x-5 py-[9px]">
                    <div className="w-full text-right text-xs xl:text-sm text-ag-secondary-letter font-semibold">
                        <span>Total:</span>
                    </div>
                    <div className="w-full text-left text-xs xl:text-sm text-ag-secondary-letter">
                        <span>{ formatCurrency(Number(data.total)-Number(data.totalSalesCredits)) }</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HeaderData;