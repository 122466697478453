import { useEffect, useState } from "react";
import Pagination from "../../../../components/pagination/Pagination";
import RowsDelivery from "./RowsDelivery";

const TableDelivery = ({ items, onItem, onCheck, onReturn, onCompatible, onFeatures }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(5);

    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = items.slice(indexOfFirstRecord, indexOfLastRecord);

    useEffect(() => {
        setCurrentPage(1);
    }, [items]);

    return (
        <div>
            <table className="w-full text-center text-ag-secondary">
                <thead>
                    <tr className="bg-ag-secondary-table">
                        <th className="w-[35%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-[100px] md:px-20 lg:px-2 py-4">Product</th>
                        <th className="w-[10%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-6 md:px-8 lg:px-2 py-4">Features</th>
                        <th className="w-[10%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-6 md:px-8 lg:px-2 py-4">Expected</th>
                        <th className="w-[15%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-14 md:px-16 lg:px-2 py-4">Real</th>
                        <th className="w-[15%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-14 md:px-10 lg:px-2 py-4">Discrepancy</th>
                        <th className="w-[15%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-14 md:px-16 lg:px-2 py-4">Notes</th>
                        <th className="w-[10%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-14 md:px-16 py-4">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        currentRecords.map((element, i) => {
                            return (
                                <RowsDelivery
                                    key={i}
                                    item={element}
                                    onItem={onItem}
                                    onCheck={onCheck}
                                    onReturn={onReturn}
                                    onCompatible={onCompatible}
                                    onFeatures={onFeatures}
                                />
                            )
                        })
                    }
                </tbody>
            </table>
            <div className="p-5 m-auto">
                <Pagination
                    currentPage={currentPage}
                    totalCount={items.length}
                    pageSize={recordsPerPage}
                    onPageChange={page => setCurrentPage(page)}
                />
            </div>
        </div>
    );
}

export default TableDelivery;