import { RiBankCard2Line } from "react-icons/ri";
import { IoMdCar } from "react-icons/io";
import { BiPlus, BiSearch } from "react-icons/bi";
import { AiFillCloseSquare } from "react-icons/ai";
import { useContext, useEffect, useState } from "react";
import { alertOptions } from "../../../utils/Utilities";
import { toast } from "react-toastify";
import { PermitsContext } from "../../../services/Permits";
import { getWarehouse } from "../../../services/Warehouse";
import InformationSales from "./InformationSales";
import IconCash from "../../../assets/icons/icon_cash_gray.svg";
import IconMix from "../../../assets/icons/Icon_circle_mix.svg";
import ModalInputNumber from "../../../components/modal/ModalInputNumber";

const FormSales = ({ clientCurrent, productsCurrent, isClean, onClean, onShow, onCancel, onPay, onShowClients, onShowProducts }) => {
    const { dataSession } = useContext(PermitsContext);
    const [buttonStyle, setButtonStyle] = useState({ btn1: false, btn2: false, btn3: false, btn4: false});
    const [disabledButton, setDisabledButton] = useState(false);
    const [showModal, setShowModal] = useState({ status: false, data: "" });
    const [quantity, setQuantity] = useState("");
    const [products, setProducts] = useState([]);
    const [sales, setSales] = useState({
        warehouse: "",
        client: { _id: "", name: "" },
        product: { _id: "", nags: "", name: "", price: "", stock: 0 },
        paymentMethod: "",
        subTotal: "",
        total: "",
        storeCredit: false,
        storeCreditTotal: 0
    });

    const handleQuantity = (evt) => {
        if (evt.target.value === "" || (!isNaN(evt.target.value) && evt.target.value >= 0 && String(evt.target.value).trim().length > 0)) {
            setQuantity(evt.target.value);
        } else {
            toast.warning("Please check that the quantity field is a numeric value", alertOptions);
        }
    }

    const handleProduct = () => {
        if (sales.product._id !== "" && (quantity !== "" && quantity > 0)) {
            if (sales.product.stock >= quantity) {
                setSales({...sales,
                    product: { _id: "", nags: "", name: "", price: "" },
                    subTotal: Number(sales.subTotal) + Number(quantity * sales.product.price),
                    total: Number(sales.total) + Number(quantity * sales.product.price),
                    paymentMethod: "",
                    storeCredit: false,
                    storeCreditTotal: 0
                });
                
                const foundProduct = products.find(element => element.productInventory?._id === sales.product._id);
                if (!foundProduct) {
                    setProducts([...products, {
                        quantity: Number(quantity),
                        price: sales.product.price,
                        year: sales.product.year,
                        productInventory: {
                            _id: sales.product._id,
                            nags: sales.product.nags
                        }
                    }]);
                } else {
                    const newProducts = products.map(element => {
                        if (element?.productInventory?._id === sales.product._id) {
                            return {... element, quantity: Number(element.quantity) + Number(quantity)}
                        }
                        return element;
                    });
                    setProducts(newProducts);
                }
    
                setQuantity("");
                setButtonStyle({...buttonStyle, btn1: false, btn2: false, btn3: false, btn4: false});
            } else {
                toast.warning(`The current number in stock is ${sales.product.stock}`, alertOptions);
            }
        } else {
            toast.warning("Please select a product and a valid quantity", alertOptions);
        }
    }

    const handlePaymentMethod = (methodStr) => {
        if (products.length > 0 && sales.client._id !== "") {
            switch (methodStr) {
                case "CREDIT_CARD":
                    setButtonStyle({...buttonStyle, btn1: true, btn2: false, btn3: false, btn4: false});
                    setSales({...sales, paymentMethod: "CASH", storeCredit: false, storeCreditTotal: 0});
                    break;
                case "CASH":
                    setButtonStyle({...buttonStyle, btn1: false, btn2: true, btn3: false, btn4: false});
                    setSales({...sales, paymentMethod: methodStr, storeCredit: false, storeCreditTotal: 0});
                    break;
                case "MIXED":
                    if (clientCurrent?.availableCredit !== 0) {
                        setShowModal({...showModal, status: true, data: "MIXED"});
                        setSales({...sales, paymentMethod: "CASH"});
                    } else {
                        toast.warning("The customer does not have enough credits for this payment option", alertOptions);
                    }
                    break;
                case "CREDIT":
                    if ((Number(sales.total) <= Number(clientCurrent?.availableCredit)) && clientCurrent?.availableCredit !== 0) {
                        setButtonStyle({...buttonStyle, btn1: false, btn2: false, btn3: false, btn4: true});
                        setSales({...sales, paymentMethod: "CASH", storeCredit: true, storeCreditTotal: sales.total});
                    } else {
                        toast.warning("The customer does not have enough credits for this payment option", alertOptions);
                    }
                    break;
                default:
                    break;
            }
        } else {
            toast.info("Select the products and customer first to buy", alertOptions)
        }
    }

    const handleMixed = (creditsToPay) => {
        setButtonStyle({...buttonStyle, btn1: false, btn2: false, btn3: true, btn4: false});
        setSales({...sales, storeCredit: true, storeCreditTotal: creditsToPay});
        setShowModal({...showModal, status: false, data: ""});
    }

    const handlePay = async () => {
        if (sales.client._id !== "" && sales.paymentMethod !== "" && products.length > 0) {
            const idCurrentWarehouse = dataSession.warehouse;
            const warehouseRes = await getWarehouse(idCurrentWarehouse);
            const warehouseAddress = warehouseRes.status === 200 ? warehouseRes.data?.address?._id : "";

            const body = {
                status: "INPROCESS",
                warehouse: { _id: idCurrentWarehouse },
                shippingMethod: "PICK_UP",
                costShipping: 0,
                warehouseAddress: { _id: warehouseAddress },
                fromWarehouse: { _id: idCurrentWarehouse },
                paymentMethod: sales.paymentMethod,
                productsSale: products.map((prod) => ({
                    quantity: prod.quantity,
                    price: prod.price,
                    year: prod.year,
                    productInventory: { _id: prod.productInventory._id }
                })),
                subTotal: sales.subTotal,
                total: sales.total,
                storeCredit: sales.storeCredit,
                storeCreditTotal: sales.storeCreditTotal
            }

            if (sales.client?.role === "RETAIL_USER") {
                body.retailUser = { _id: sales.client._id };
            } else {
                body.wholesalerUser = { _id: sales.client._id };
            }

            if (sales.paymentMethod === "CASH" && sales.storeCredit === true) {
                if (clientCurrent?.availableCredit !== 0) {
                    onPay(body);
                } else {
                    toast.warning("The customer does not have enough credits for this payment option", alertOptions);
                    return 0;
                }
            } else {
                onPay(body);
            }
        } else {
            toast.info("Please fill in all the fields of the form", alertOptions);
        }
    }

    const removeProduct = (index) => {
        let newTotal = 0;
        const newProducts = products.filter((prd, i) => {
            if (i !== index) {
                newTotal += (prd.price*prd.quantity);
                return prd;
            }
        });
        setSales({...sales, subTotal: newTotal, total: newTotal});
        setProducts(newProducts);
    }

    useEffect(() => {
        if (clientCurrent !== null) {
            if (clientCurrent?.user?.userType !== "WHOLESALER_USER") {
                setDisabledButton(true);
                setButtonStyle({...buttonStyle, btn1: false, btn2: false, btn3: false, btn4: false});
                setSales({...sales,
                    paymentMethod: "",
                    client: {
                        _id: clientCurrent?._id,
                        name: `${clientCurrent?.names ? clientCurrent?.names : ""} ${clientCurrent?.lastNames ? clientCurrent?.lastNames : ""}`,
                        role: clientCurrent?.user?.userType
                    }
                });
            } else {
                setDisabledButton(false);
                setSales({...sales, client: {
                    _id: clientCurrent?._id,
                    name: `${clientCurrent?.names ? clientCurrent?.names : ""} ${clientCurrent?.lastNames ? clientCurrent?.lastNames : ""}`,
                    role: clientCurrent?.user?.userType
                }});
            }
        }
    }, [clientCurrent]);

    useEffect(() => {
        if (productsCurrent !== null) {
            setSales({...sales, product: {
                _id: productsCurrent?.productInventory?._id,
                nags: productsCurrent?.productInventory?.product?.nags,
                name: productsCurrent?.productInventory?.product?.name,
                year: productsCurrent?.year,
                price: productsCurrent?.productInventory?.cost,
                stock: productsCurrent?.totalQuantity
            }});
        }
    }, [productsCurrent]);

    useEffect(() => {
        if (isClean) {
            setQuantity("");
            setProducts([]);
            setButtonStyle({...buttonStyle, btn1: false, btn2: false, btn3: false, btn4: false});
            setDisabledButton(false);
            setSales({...sales,
                warehouse: "",
                client: { _id: "", name: "" },
                product: { _id: "", nags: "", name: "", price: "", stock: 0 },
                paymentMethod: "",
                subTotal: "",
                total: "" ,
                storeCredit: false,
                storeCreditTotal: 0
            });
            onClean(true);
        }
    }, [isClean]);

    return (
        <>
            {
                showModal.status ?
                    <ModalInputNumber
                        type="number"
                        forDelivery={false}
                        title="Credits to use:"
                        totalCredits={clientCurrent?.availableCredit ? clientCurrent?.availableCredit : 0}
                        maxCredits={sales.total}
                        onClose={() => setShowModal({...showModal, status: false, data: ""})}
                        onBack={() => setShowModal({...showModal, status: false, data: ""})}
                        onResponse={handleMixed}
                    />
                : null
            }

            <div className="grid lg:flex lg:justify-between lg:items-start lg:space-x-8">
                <div className="w-full order-last lg:order-first">
                    <form>
                        <div className="pb-5 md:pb-10 space-y-0">
                            <div>
                                <div className="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                                    <div className="bg-zinc-300 text-ag-secondary-letter rounded-t-xl px-3 pt-3 pb-5">
                                        <span className="text-xs xl:text-sm">Select your client:</span>
                                    </div>
                                    <div className="hidden lg:block xl:col-span-2"></div>
                                    <div className="bg-green-600 text-center text-white rounded-t-xl px-3 pt-2 pb-5 cursor-pointer" onClick={() => onShow(true)}>
                                        <span className="text-xs xl:text-sm">Add Customer</span>
                                    </div>
                                </div>
                                <div className="-mt-3 xl:-mt-2 w-full">
                                    <div className="bg-white flex justify-end items-center border border-gray-500 rounded-xl">
                                        <input
                                            className="w-full text-xs xl:text-base text-ag-secondary-letter px-8 py-3 rounded-l-xl disabled:bg-zinc-200"
                                            type="text"
                                            name="search"
                                            placeholder="Search"
                                            value={sales.client !== "" ? sales.client.name : ""}
                                            onClick={() => onShowClients(true)}
                                            autoComplete="off"
                                        />
                                        <div className="bg-white -mt-2 border-none p-[10px] rounded-r-xl disabled:bg-zinc-200">
                                            <span className="text-ag-secondary-letter text-xs xl:text-xl"><BiSearch /></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                                    <div className="bg-zinc-300 text-ag-secondary-letter rounded-t-xl mt-12 px-3 pt-3 pb-5">
                                        <span className="text-xs xl:text-sm">Select your product:</span>
                                    </div>
                                    <div className="hidden lg:block xl:col-span-2"></div>
                                    <div className="bg-green-600 rounded-t-xl mt-12 px-3 pt-3 pb-5">
                                        <div className="flex justify-center items-center space-x-1">
                                            <span className="text-white text-xs xl:text-sm">Quantity:</span>
                                            <input
                                                className="w-full text-center rounded-lg truncate"
                                                type="text"
                                                onChange={handleQuantity}
                                                value={quantity}
                                                maxLength={8}
                                            />
                                            <button className="text-white border border-white rounded-full" type="button" onClick={() => handleProduct()}>
                                                <BiPlus />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="-mt-3 xl:-mt-2 w-full">
                                    <div className="bg-white flex justify-end items-center border border-gray-500 rounded-xl">
                                        <input
                                            className="w-full text-xs xl:text-base text-ag-secondary-letter px-8 py-3 rounded-l-xl disabled:bg-zinc-200"
                                            type="text"
                                            name="search"
                                            placeholder="Search"
                                            onClick={() => onShowProducts(true)}
                                            value={sales.product !== "" ? sales.product.name : ""}
                                            autoComplete="off"
                                        />
                                        <div className="bg-white -mt-2 border-none p-[10px] rounded-r-xl disabled:bg-zinc-200">
                                            <span className="text-ag-secondary-letter text-xs xl:text-xl"><BiSearch /></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="pt-5">
                                <div className="w-full md:w-[80%] xl:w-[60%] grid gap-3 md:grid-cols-2 m-auto">
                                    { products.map((element, index) => (
                                        <div className="w-full px-5 py-1 border border-gray-500 rounded-xl" key={index}>
                                            <div className="flex justify-between items-center text-ag-secondary-letter">
                                                <span className="text-xs md:text-sm">
                                                    { element?.productInventory?.nags }
                                                </span>
                                                <button className="text-2xl" type="button" onClick={() => removeProduct(index)}>
                                                    <AiFillCloseSquare />
                                                </button>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div>
                                <div className="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                                    <div className="col-span-2 lg:col-span-3 xl:col-span-1 bg-zinc-300 text-ag-secondary-letter rounded-t-xl mt-12 px-3 pt-3 pb-5">
                                        <span className="text-xs md:text-sm">Select your payment method:</span>
                                    </div>
                                    <div className="hidden xl:col-span-2"></div>
                                </div>
                                <div className="-mt-2 w-full pb-10 md:pb-0">
                                    <div className="md:flex md:justify-between md:items-center space-y-1 md:space-y-0">
                                        <button
                                            className={`w-full flex justify-center items-center space-x-2 xl:space-x-3 text-ag-secondary-letter border border-ag-secondary-light px-3 py-3 lg:py-4 rounded-xl ${buttonStyle.btn1 ? "bg-zinc-200" : "bg-white"}`}
                                            type="button"
                                            onClick={() => handlePaymentMethod("CREDIT_CARD")}
                                        >
                                            <span className="text-xl xl:text-2xl"><RiBankCard2Line /></span>
                                            <span className="text-xs lg:text-[10px] xl:text-sm">Credit Card</span>
                                        </button>
                                        <button
                                            className={`w-full flex justify-center items-center space-x-2 xl:space-x-3 text-ag-secondary-letter border border-ag-secondary-light px-3 py-3 lg:py-4 rounded-xl ${buttonStyle.btn2 ? "bg-zinc-200" : "bg-white"}`}
                                            type="button"
                                            onClick={() => handlePaymentMethod("CASH")}
                                        >
                                            <img className="w-5 xl:w-6 h-5 xl:h-6" src={IconCash} />
                                            <span className="text-xs lg:text-[10px] xl:text-sm">Cash</span>
                                        </button>
                                        <button
                                            className={`w-full flex justify-center items-center space-x-2 xl:space-x-3 text-ag-secondary-letter border border-ag-secondary-light px-3 py-3 lg:py-4 rounded-xl disabled:bg-red-100 ${buttonStyle.btn3 ? "bg-zinc-200" : "bg-white"}`}
                                            type="button"
                                            onClick={() => handlePaymentMethod("MIXED")}
                                            disabled={disabledButton}
                                        >
                                            <img className="w-5 xl:w-6 h-5 xl:h-6" src={IconMix} />
                                            <span className="text-xs lg:text-[10px] xl:text-sm">Mixed</span>
                                        </button>
                                        <button
                                            className={`w-full flex justify-center items-center space-x-2 xl:space-x-3 text-ag-secondary-letter border border-ag-secondary-light px-3 py-3 lg:py-4 rounded-xl disabled:bg-red-100 ${buttonStyle.btn4 ? "bg-zinc-200" : "bg-white"}`}
                                            type="button"
                                            onClick={() => handlePaymentMethod("CREDIT")}
                                            disabled={disabledButton}
                                        >
                                            <span className="text-xl xl:text-2xl"><IoMdCar /></span>
                                            <span className="text-xs lg:text-[10px] xl:text-sm">Credit</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            {
                                buttonStyle.btn1 ?
                                    <div className="pt-1">
                                        <span className="text-ag-secondary-letter text-xs italic">
                                            *** The payment by card will be carried out through a bank terminal
                                        </span>
                                    </div>
                                : null
                            }
                        </div>
                    </form>
                </div>
                <div className="w-full lg:w-auto pb-6 md:pb-10 lg:pb-0 space-y-3">
                    <InformationSales
                        products={products}
                        clientCurrent={clientCurrent}
                        subtotal={sales.subTotal}
                        total={sales.total}
                        onCancel={onCancel}
                        onDelete={removeProduct}
                        onPay={handlePay}
                        creditsToPay={sales.storeCreditTotal}
                    />
                </div>
            </div>
        </>
    );
}

export default FormSales;