import { useEffect, useState } from "react";
import { getAllFeaturesWC } from "../../../utils/Utilities";

const FeaturesByProductOrder = ({ features }) => {
    const [featuresArr, setFeaturesArr] = useState([]);

    useEffect(() => {
        const featuresAux = getAllFeaturesWC();
        let distinctFeatures = [];

        if (features !== undefined) {
            for (let i = 0; i < featuresAux.length; i++) {
                const regex = new RegExp(featuresAux[i].value.toLowerCase(), 'i')
                if (
                    (features.compatible_vehicles.some(element => regex.test(element.ad_info_a.toLowerCase())) === true) ||
                    (String(features.features).toLowerCase().includes(String(featuresAux[i].value).toLowerCase()) === true) ||
                    (features.antena === "Y" && featuresAux[i].value === "Antenna")
                ) {
                    const foundFeatures = distinctFeatures.find(distFeatElem => distFeatElem === featuresAux[i].value);
                    if (!foundFeatures) distinctFeatures.push(featuresAux[i].value);
                }
            }
            if (features.compatible_vehicles !== undefined) {
                if (features.compatible_vehicles.some(element => element.encapsulated === "Y")) {
                    const foundFeatures = distinctFeatures.find(feature => feature === "Encapsulated");
                    if (!foundFeatures) distinctFeatures.push("Encapsulated");
                }
                if (features.compatible_vehicles.some(element => element.hud === "Y")) {
                    const foundFeatures = distinctFeatures.find(feature => feature === "HUD");
                    if (!foundFeatures) distinctFeatures.push("HUD");
                }
                if (features.compatible_vehicles.some(element => element.slider === "Y")) {
                    const foundFeatures = distinctFeatures.find(feature => feature === "Slider");
                    if (!foundFeatures) distinctFeatures.push("Slider");
                }
                if (features.compatible_vehicles.some(element => element.solar === "Y")) {
                    const foundFeatures = distinctFeatures.find(feature => feature === "Solar");
                    if (!foundFeatures) distinctFeatures.push("Solar");
                }
            } else if (features.compatible_vehicles === undefined) {
                if (features.encapsulated === "Y") {
                    const foundFeatures = distinctFeatures.find(feature => feature === "Encapsulated");
                    if (!foundFeatures) distinctFeatures.push("Encapsulated");
                }
                if (features.hud === "Y") {
                    const foundFeatures = distinctFeatures.find(feature => feature === "HUD");
                    if (!foundFeatures) distinctFeatures.push("HUD");
                }
                if (features.slider === "Y") {
                    const foundFeatures = distinctFeatures.find(feature => feature === "Slider");
                    if (!foundFeatures) distinctFeatures.push("Slider");
                }
                if (features.solar === "Y") {
                    const foundFeatures = distinctFeatures.find(feature => feature === "Solar");
                    if (!foundFeatures) distinctFeatures.push("Solar");
                }
            }
        }

        setFeaturesArr(distinctFeatures);
    }, [features]);
    return (
        <>
            {
                featuresArr.length > 0 ?
                    featuresArr.map((featuresElem, i) => (
                        // <li className="w-full text-ag-secondary" key={i}>{featuresElem}</li>
                        <p className="w-full text-ag-secondary" key={i}>{'• ' + featuresElem}</p>
                    ))
                    :
                    <p className="w-full text-ag-secondary italic">-----</p>
            }
        </>
    );
}

export default FeaturesByProductOrder;