import { IoMdImage } from "react-icons/io";
import { alertOptions, formatCurrency } from "../../../../utils/Utilities";
import { useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import IconMegaphone from "../../../../assets/icons/icon_megaphone.svg";

const ProductGridCard = ({ product, isParamsSearch, onCart, onPurcharse, onAddWishList, onCompatible }) => {
    const navigate = useNavigate();
    const [quantity, setQuantity] = useState(1);

    const handleChange = (evt) => {
        const pattern = new RegExp(/^[0-9\s]+$/g);
        if (pattern.test(evt.currentTarget.value) || evt.currentTarget.value === "") {
            setQuantity(evt.target.value);
        }
    }

    const handlePurcharse = () => {
        if (quantity > 0) {
            if (quantity <= product?.inventoryData?.totalQuantity) {
                const objCart = {
                    warehouse: { _id: product?.inventoryData?.warehouse?._id },
                    productInventory: { _id: product?.inventoryData?.productInventory?._id },
                    quantity: Number(quantity),
                    year: product.searchValueYear,
                    price: product?.price
                }
                onPurcharse(objCart);
            } else {
                toast.warning("The quantity entered exceeds the available stock", alertOptions);
            }
        } else {
            toast.warning("The quantity to buy must be greater than 0", alertOptions);
        }
    }

    const handleSubmit = () => {
        if (quantity > 0) {
            if (quantity <= product?.inventoryData?.totalQuantity) {
                const objCart = {
                    warehouse: { _id: product?.inventoryData?.warehouse?._id },
                    productInventory: { _id: product?.inventoryData?.productInventory?._id },
                    quantity: Number(quantity),
                    year: product.searchValueYear,
                    price: product?.price
                }
                onCart(objCart);
                setQuantity(1);
            } else {
                toast.warning("The quantity entered exceeds the available stock", alertOptions);
            }
        } else {
            toast.warning("The quantity to buy must be greater than 0", alertOptions);
        }
    }

    return (
        <div className="w-full border border-gray-500 p-2 space-y-3 rounded-xl">
            <div className={`flex justify-center items-center border border-ag-secondary ${product?.inventoryData?.productInventory?.product?.productImages.length > 0 ? "bg-black" : ""}`}>
                {
                    product?.inventoryData && product?.inventoryData?.productInventory?.product?.productImages.length > 0 ?
                        <div className="w-[160px] h-[160px] flex justify-center items-center m-auto">
                            <img className="w-full h-full object-contain" src={product?.inventoryData?.productInventory?.product?.productImages[0]} />
                        </div>
                    :
                        <span className="py-10 text-[80px] text-ag-secondary-letter">
                            <IoMdImage />
                        </span>
                }
            </div>
            <div className="p-1 space-y-3">
                <div className="text-center text-xs lg:text-sm text-ag-secondary-letter cursor-pointer" onClick={() => navigate(`/wholesaler/search/${product?.available ? product?.inventoryData?.productInventory?._id : product?._id}`, { state: { qty: quantity, searchValueYear: product?.searchValueYear } })}>
                    <p>{ product?.nag }</p>
                    { isParamsSearch ? <p>{ `${String(product.year).replace(/\s+/g, '').split(',').sort()[0]} - ${String(product.year).replace(/\s+/g, '').split(',').sort()[Number(String(product.year).replace(/\s+/g, '').split(',').length-1)]}` } { product?.brand } { product?.model }</p> : null }
                    { isParamsSearch ? <p>{ product?.type_car }</p> : null }
                </div>
                <div className="flex justify-center lg:justify-start items-center space-x-3">
                    {
                        product?.inventoryData?.productInventory?.product?.compatible_vehicles.length >= 1 ?
                            <p className="truncate text-sm text-ag-secondary-letter font-semibold m-auto cursor-pointer" onClick={() => onCompatible(product?.inventoryData?.productInventory?.product)}>
                                See compatible vehicles +
                            </p>
                        :
                            <div className="py-[10px]"></div>
                    }
                </div>
                <div className="text-center text-xs lg:text-sm text-ag-secondary-letter">
                    <p>{ product?.inventoryData !== null ? product?.inventoryData?.warehouse?.name : "---" }</p>
                </div>
                <div className="py-3 border-y border-ag-secondary-light text-center text-xs lg:text-sm text-ag-secondary-letter">
                    <span>{product?.type_glass}</span>
                </div>
                <div className="py-3 border-y border-ag-secondary-light text-center text-xs lg:text-sm text-ag-secondary-letter">
                    <span>{product?.type_car}</span>
                </div>
                {
                    isParamsSearch ?
                        <></>
                        :
                        <div className="py-3 border-y border-ag-secondary-light text-center text-xs lg:text-sm text-ag-secondary-letter">
                            <span>{product?.model}</span>
                        </div>
                }
            </div>
            <div className="text-center text-xs lg:text-sm text-ag-secondary-letter">
                {
                    product?.available && product?.inventoryData?.totalQuantity > 0 ?
                        <>
                            <p className="text-xl"><b>Price: { formatCurrency(product?.inventoryData?.productInventory?.cost) }</b></p>
                            <p><b>Nags price:</b> { formatCurrency(product?.inventoryData?.productInventory?.product?.price) }</p>
                        </>
                    :
                        <div className="py-[30px]"></div>
                }
            </div>
            <div className="text-center text-xs lg:text-sm text-ag-secondary-letter">
                <div className="flex justify-center items-center pt-2 space-x-2">
                    { product.category.map((catElement, j) => (
                        catElement.status ? 
                            <div key={j}>
                                <img title={catElement.label} src={catElement.icont} />
                            </div>
                        : null
                    ))}
                </div>
            </div>
            <div className="px-5 text-center">
                <p className="text-ag-secondary-letter italic text-xs h-[auto]" title={product?.allFeatures}>
                    { String(product?.allFeatures).length > 130 ? `${String(product?.allFeatures).substring(0, 130)}...` : product?.allFeatures }
                </p>
            </div>
            <div className="flex justify-center items-center space-x-5">
                <div className={`w-5 h-5 ${ product?.available && product?.inventoryData?.totalQuantity > 0 ? "bg-green-500" : "bg-red-600" } rounded-full`}></div>
                <span className="text-ag-secondary-letter">{ product?.available && product?.inventoryData?.totalQuantity > 0 ? "Available" : "No available" }</span>
            </div>
            {
                product?.available && product?.inventoryData?.totalQuantity > 0 ?
                    <div className="flex justify-center">
                        <input
                            className="px-3 py-1 text-center text-ag-secondary-letter border border-ag-secondary-letter rounded-xl"
                            type="text"
                            name="quantity"
                            onChange={handleChange}
                            value={quantity}
                        />
                    </div>
                :
                    <div className="py-[14px]"></div>
            }
            {
                product?.available && product?.inventoryData?.totalQuantity > 0 ?
                    <div className="flex justify-center items-center space-x-2 pb-2">
                        <button className="w-full px-2 py-[6px] bg-ag-primary-light text-white text-xs rounded-xl" type="button" onClick={() => handleSubmit()}>
                            Add to cart
                        </button>
                        <button className="w-full px-2 py-[6px] bg-ag-secondary text-white text-xs rounded-xl" type="button" onClick={() => handlePurcharse()}>
                            Quick purchase
                        </button>
                    </div>
                : null
            }
            {
                product?.wishList || !product?.available || product?.inventoryData?.totalQuantity === 0 ?
                    <div className={`${!product?.available || product?.inventoryData?.totalQuantity === 0 ? "" : ""}`}>
                        <div className="flex justify-end items-end space-x-3">
                            <img
                                className={`${product?.wishList ? "opacity-50" : "cursor-pointer"} pr-2`}
                                src={IconMegaphone}
                                onClick={() => product?.wishList ? null : onAddWishList(product)}
                            />
                        </div>
                        <p
                            className={`${product?.wishList ? "opacity-50" : "cursor-pointer"} text-xs text-ag-secondary-letter text-right`}
                            onClick={() => product?.wishList ? null : onAddWishList(product)}
                        >
                            Request
                        </p>
                    </div>
                : null
            }
        </div>
    );
}

export default ProductGridCard;